import React, { useEffect, useState } from 'react'

import { Button, ButtonLoading, Input } from '~/components'

import ErrToast from '../toast'
import { Checked, Cross, OutlineDots } from '~/svg'

const NewPassword = ({ errToast, errCount, isLoading, closeToast, handleNewPassword }) => {
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [isShow, setIsShow] = useState(false)
  const [hasLength, setHasLength] = useState(false)
  const [hasCriteria, setHasCriteria] = useState(false)
  const [hasLowercase, setHasLowercase] = useState(false)
  const [hasUppercase, setHasUppercase] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [hasSymbol, setHasSymbol] = useState(false)

  const lengthRegex = /^.{8,16}$/
  const lowercaseRegex = /[a-z]/
  const uppercaseRegex = /[A-Z]/
  const numberRegex = /[0-9]/
  const symbolRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/\-|=]/

  const validatePassword = (password) => {
    setHasLength(lengthRegex.test(password))
    setHasLowercase(lowercaseRegex.test(password))
    setHasUppercase(uppercaseRegex.test(password))
    setHasNumber(numberRegex.test(password))
    setHasSymbol(symbolRegex.test(password))
  }

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value
    setPassword(newPassword)
    validatePassword(newPassword)
  }

  useEffect(() => {
    setTimeout(function () {
      setHasCriteria(hasLowercase + hasUppercase + hasNumber + hasSymbol >= 2)
      setIsValid(hasLength && hasLowercase + hasUppercase + hasNumber + hasSymbol >= 2)
    }, 300)
  }, [hasLength, hasLowercase, hasUppercase, hasNumber, hasSymbol])

  useEffect(() => {}, [isValid])

  return (
    <div className='relative w-full md:w-[431px]'>
      {errToast ? (
        <div className='absolute top-[-140px] w-full left-0 px-8 md:px-0'>
          <ErrToast errCount={errCount} closeToast={closeToast} />
        </div>
      ) : (
        <></>
      )}

      <div className='md:border-[1px] md:border-neutral-700 px-9 py-10 mx-0 w-full rounded-lg rounded-tl-none'>
        <p className='mt-2 mb-2 leading-7 text-xl font-semibold font-sans text-neutral-200 text-center'>
          Make New Password
        </p>
        <p className='mt-2 mb-2 leading-5 text-sm font-normal font-sans text-neutral-200'>
          Create your new password and keep your account secure.
        </p>
        <form className='w-full'>
          <p className='font-semibold text-base text-neutral-200 mt-6 mb-1'>New Password</p>
          <Input
            name={'password'}
            type={'password'}
            value={password}
            onChange={handlePasswordChange}
            success={isValid}
            error={!isValid && password != ''}
            empty={password == '' && !isShow}
            onFocus={() => setIsShow(true)}
            onBlur={() => setIsShow(false)}
            wrong={!isValid && !isShow && password != ''}
          />
          {isShow && (
            <div className='text-neutral-200 mt-2'>
              <div
                className={
                  hasLength && password != '' ? 'flex gap-1 text-success-200' : 'flex gap-1'
                }>
                {hasLength && password != '' ? <Checked /> : <OutlineDots />}
                8-16 characters
              </div>
              <div
                className={
                  hasCriteria && password != ''
                    ? 'flex gap-1 text-success-200'
                    : !hasCriteria && password != ''
                    ? 'flex gap-1 text-danger-200'
                    : 'flex gap-1'
                }>
                {hasCriteria && password != '' ? (
                  <Checked />
                ) : password == '' ? (
                  <OutlineDots />
                ) : (
                  <Cross />
                )}
                Contains at least two or the followings:
              </div>
              <div className='grid grid-cols-2'>
                <div
                  className={
                    hasLowercase && password != '' ? 'flex gap-1 text-success-200' : 'flex gap-1'
                  }>
                  {hasLowercase && password != '' ? <Checked /> : <OutlineDots />}
                  Lowercase
                </div>
                <div
                  className={
                    hasNumber && password != '' ? 'flex gap-1 text-success-200' : 'flex gap-1'
                  }>
                  {hasNumber && password != '' ? <Checked /> : <OutlineDots />}
                  Numbers
                </div>
                <div
                  className={
                    hasUppercase && password != '' ? 'flex gap-1 text-success-200' : 'flex gap-1'
                  }>
                  {hasUppercase && password != '' ? <Checked /> : <OutlineDots />}
                  Uppercase
                </div>
                <div
                  className={
                    hasSymbol && password != '' ? 'flex gap-1 text-success-200' : 'flex gap-1'
                  }>
                  {hasSymbol && password != '' ? <Checked /> : <OutlineDots />}
                  Symbols (!#$, etc)
                </div>
              </div>
            </div>
          )}
          <div className='mt-10'>
            <Button
              type={'primary'}
              onClick={() => {
                if (isValid) {
                  handleNewPassword(password)
                }
              }}>
              <p>Set Password</p>
              {isLoading ? <ButtonLoading /> : <></>}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewPassword
