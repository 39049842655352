import React from 'react'

import { Shimmer } from '~/components'

const NavbarShimmer = ({ isSearch }) => {
  return (
    <div className='w-full flex justify-between'>
      <div className='w-full flex items-center justify-start gap-8'>
        <div className='w-[128px]'>
          <Shimmer height={8} />
        </div>
        <div className='hidden md:block w-10'>
          <Shimmer height={16} />
        </div>
        <div className='hidden md:block w-10'>
          <Shimmer height={16} />
        </div>
        {isSearch ? (
          <div className='w-1/2 hidden md:block'>
            <div className='w-full ml-16 flex justify-start items-center gap-1'>
              <div className='w-full'>
                <Shimmer height={24} />
              </div>
              <div className='w-20'>
                <Shimmer height={18} />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='flex items-center justify-start gap-5'>
        <div className='w-[15px]'>
          <Shimmer height={15} />
        </div>
        <div className='hidden md:block ml-2'>
          <div className='w-[15px]'>
            <Shimmer height={15} />
          </div>
        </div>
        <div className='w-8'>
          <Shimmer rounded height={32} />
        </div>
      </div>
    </div>
  )
}

export default NavbarShimmer
