import React from 'react'

function CevronUp({ small }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={small ? '16' : '25'}
      height={small ? '16' : '24'}
      fill='none'
      viewBox='0 0 25 24'>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M5.19 15l7-7 7 7'></path>
    </svg>
  )
}

export default CevronUp
