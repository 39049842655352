import React from 'react'

import { CevronUp, Info } from '~/svg'

const SaveAndSort = ({
  sorts,
  sortRef,
  openInfo,
  openSort,
  saveInfo,
  showDatas,
  closeInfo,
  saveSearch,
  handleSort,
  selectedSort,
  handleOpenSort,
}) => {
  return (
    <div className='flex w-full justify-between items-center'>
      <div className='flex items-center gap-[3px] text-sm'>
        <p
          data-testid='match-text'
          className='text-neutral-400'>{`${showDatas.length} videos match •`}</p>
        <p onClick={saveSearch} className='text-primary-50 font-semibold underline cursor-pointer'>
          Save Search
        </p>
        <div
          onMouseEnter={() => openInfo(true)}
          onMouseLeave={() => closeInfo(true)}
          className='relative'>
          <Info />
          {saveInfo ? (
            <div
              data-testid='save-info'
              className='absolute left-[-87px] top-[-54px] w-[180px] bg-[#334155] rounded px-3 py-1'>
              <p className='text-start text-sm text-white'>
                Save search on Search <br /> to revisit anytime.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='flex items-center gap-2 text-sm'>
        <p className='text-neutral-200'>Sort by</p>
        <div ref={sortRef} className='relative'>
          <div
            onClick={handleOpenSort}
            className='flex items-center justify-between px-4 py-2 rounded-lg border-[1.5px] border-neutral-600 w-[180px] cursor-pointer select-none'>
            <p className='text-base text-neutral-200'>{selectedSort}</p>
            <div className='rotate-180'>
              <CevronUp small />
            </div>
          </div>
          {openSort ? (
            <div className='absolute z-10 top-[46px] rounded-lg border-[1.5px] border-neutral-200 w-[180px] overflow-hidden'>
              {sorts &&
                sorts
                  .filter((it) => it === selectedSort)
                  .map((it) => (
                    <div
                      data-testid='sort-selected'
                      onClick={() => handleSort(it)}
                      className='py-3 px-4 cursor-pointer bg-[#0B4470]'
                      key={it}>
                      <p className='text-base leading-4 text-neutral-200'>{it}</p>
                    </div>
                  ))}
              {sorts &&
                sorts
                  .filter((it) => it !== selectedSort)
                  .map((it) => (
                    <div
                      data-testid='sort-options'
                      onClick={() => handleSort(it)}
                      className='py-3 px-4 cursor-pointer bg-neutral-900'
                      key={it}>
                      <p className='text-base leading-4 text-neutral-200'>{it}</p>
                    </div>
                  ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default SaveAndSort
