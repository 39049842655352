import React from 'react'

import { CheckCircle } from '~/svg'
import { Button } from '~/components'
import { useSystem } from './hooks'
import { ModalStorage } from './components'

const SystemSetting = () => {
  const {
    datas: { dataSystem, formStorage, stateModalStorage },
    methods: { handleChangeStorage, handleSubmitStorage, setStateModalStorage },
  } = useSystem()

  return (
    <>
      <div className='flex flex-col w-full md:w-1/2 px-1 md:px-0 '>
        <p className='font-semibold text-2xl text-neutral-50'>System</p>
        <p className='font-semibold text-xl mt-8 text-neutral-50'>Help and Support</p>
        <p className='mt-2 text-neutral-300'>
          If you encounter any issues, please contact admin by clicking the button below.
        </p>
        <div className='w-fit mt-6'>
          {' '}
          <Button type={'primary'}>Contact Admin</Button>
        </div>
        <p className='font-semibold text-xl mt-9 text-neutral-50'>Information</p>
        <div className='flex flex-row justify-between mt-6 items-center'>
          <p className='text-neutral-50'>System status</p>
          <p className='font-semibold flex flex-row items-center text-neutral-200 gap-1'>
            <CheckCircle fill={'success-300'} />
            Running
          </p>
        </div>
        <div className='flex flex-row justify-between mt-2 items-center'>
          <p className='text-neutral-50'>Storage</p>
          <p className='font-semibold flex flex-row gap-2 text-neutral-200'>
            {dataSystem ? dataSystem.storage : 0} GB
            <span
              data-testid='edit-path'
              className='px-4 cursor-pointer hover:bg-neutral-800'
              onClick={() => setStateModalStorage(true)}>
              Edit Path
            </span>
          </p>
        </div>
        <div className='flex flex-row justify-between mt-2 items-center'>
          <p className='text-neutral-50'>Version</p>
          <p className='font-semibold text-neutral-200'>
            {dataSystem ? dataSystem.version : '1.0.0'}
          </p>
        </div>
      </div>
      <ModalStorage
        formStorage={formStorage}
        isOpen={stateModalStorage}
        handleChangeStorage={handleChangeStorage}
        handleSubmitStorage={handleSubmitStorage}
        closeModal={() => setStateModalStorage(false)}
      />
    </>
  )
}

export default SystemSetting
