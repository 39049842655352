import React from 'react'

import { CAM_STATUS } from '~/constant'
import { CheckCircle, Exclamation, ExclamationCircle, LoadingCircle } from '~/svg'

const AllCamStatus = ({ data }) => {
  const errors = data.filter((it) => it.status === CAM_STATUS.ERROR) || []
  const loadings = data.filter((it) => it.status === CAM_STATUS.LOADING) || []
  const warnings = data.filter((it) => it.status === CAM_STATUS.WARNING) || []

  function getAllfloor(list) {
    let result = ''
    list.forEach((it, idx) => {
      result += idx === list.length - 1 ? it.floor : it.floor + ', '
    })
    return result
  }
  return (
    <div className='flex items-center gap-1'>
      {loadings.length < 1 && errors.length < 1 && warnings.length < 1 ? (
        <div className='flex items-center gap-1'>
          <CheckCircle />
          <p className='text-base font-normal text-neutral-200'>All</p>
        </div>
      ) : (
        <>
          {loadings.length > 0 ? (
            <div className='flex items-center gap-1'>
              <LoadingCircle />
              <p className='text-base font-normal text-neutral-200'>{getAllfloor(loadings)}</p>
            </div>
          ) : (
            <></>
          )}
          {errors.length > 0 ? (
            <div className='flex items-center gap-1'>
              <ExclamationCircle />
              <p className='text-base font-normal text-neutral-200'>{getAllfloor(errors)}</p>
            </div>
          ) : (
            <></>
          )}
          {warnings.length > 0 ? (
            <div className='flex items-center gap-1'>
              <Exclamation />
              <p className='text-base font-normal text-neutral-200'>{getAllfloor(warnings)}</p>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  )
}

export default AllCamStatus
