import React from 'react'

function Clock({ white }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke={white ? '#FFFFFF' : '#DEEAF5'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M8 5.333V8l2 2m4-2A6 6 0 112 8a6 6 0 0112 0z'></path>
    </svg>
  )
}

export default Clock
