import React from 'react'

function Result() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke='#76C2FC'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6 3.333L10.667 8 6 12.666'></path>
    </svg>
  )
}

export default Result
