import React from 'react'

import { useSearchBar } from './hooks'
import { Dropzone, Recent, SearchInput, SearchSimmer } from './components'

const SearchBarHome = ({ isLoading, isNavbar, dispatchSearch, previousHistory }) => {
  const {
    datas: {
      keyword,
      searchRef,
      showUpload,
      showButton,
      activeSearch,
      uploadedFiles,
      selectedSearchType,
    },
    methods: {
      open,
      reseImage,
      openUpload,
      closeUpload,
      getRootProps,
      handleActive,
      submitSearch,
      handleChange,
      resetKeyword,
      getInputProps,
      handleKeyDown,
      selectSearchType,
      setSuggestionKeyword,
    },
  } = useSearchBar({ dispatchSearch })

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <div ref={searchRef} className='w-full flex flex-col relative'>
        {isLoading ? (
          <SearchSimmer />
        ) : (
          <SearchInput
            keyword={keyword}
            isNavbar={isNavbar}
            reseImage={reseImage}
            openUpload={openUpload}
            showUpload={showUpload}
            showButton={showButton}
            closeUpload={closeUpload}
            activeSearch={activeSearch}
            handleActive={handleActive}
            handleChange={handleChange}
            submitSearch={submitSearch}
            resetKeyword={resetKeyword}
            handleKeyDown={handleKeyDown}
            uploadedFiles={uploadedFiles}
          />
        )}

        {activeSearch && previousHistory !== undefined && previousHistory.length > 0 ? (
          <div className='absolute w-full top-14 z-10'>
            <Recent dummyHistory={previousHistory} setSuggestionKeyword={setSuggestionKeyword} />
          </div>
        ) : (
          <></>
        )}

        {showUpload ? (
          <div className='absolute w-full  top-14 z-10'>
            <Dropzone
              open={open}
              closeUpload={closeUpload}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              selectSearchType={selectSearchType}
              selectedSearchType={selectedSearchType}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default SearchBarHome
