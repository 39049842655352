import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SearchTypes, imageBast64String } from "~/constant";

import { axios, b64toBlob } from "~/utils";

const initialState = {
  floors: [],
  keyword: "",
  cameras: {},
  camerasV2: {},
  allCameras: [],
  id_allCameras: {},
  savedsearch: [],
  boxesData: {
    updated_at: "",
    polygon: [],
  },
  previewData: {},
  searchResult: [],
  isLoading: false,
  uploadedFiles: [],
  successAdd: false,
  postLoading: false,
  isShowToast: false,
  dangerAlert: false,
  notifLoading: false,
  errAddCamera: false,
  isHaveupdate: false,
  searchLoading: false,
  searchType: SearchTypes.IMAGE,
};

const baseUrl = process.env.BASE_URL;

export const getCamera = createAsyncThunk("camera/getSync", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${baseUrl}/setting/cameraGet?site=${args.site}`);
    return response.data;
  } catch (err) {
    const message = { ...err.response.data.message, ...args };
    return rejectWithValue(message);
  }
});

export const getCameraAsync = createAsyncThunk("camera/get", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${baseUrl}/setting/cameraGet?site=${args.site}`);
    return response.data;
  } catch (err) {
    const message = { ...err.response.data.message, ...args };
    return rejectWithValue(message);
  }
});

export const getCameraAsyncV2 = createAsyncThunk(
  "camera/getV2",
  async (args, { rejectWithValue }) => {
    try {
      const site = args.site;
      const response = await axios.get(`${baseUrl}/AI/getCameras`);
      return { site: site, data: response.data };
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const addCameraAsync = createAsyncThunk("camera/add", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${baseUrl}/setting/cameraAdd`, args);
    return response.data;
  } catch (err) {
    const message = { ...err.response.data.message, ...args };
    return rejectWithValue(message);
  }
});

export const getSearchAsync = createAsyncThunk(
  "search/get",
  async (args, { getState, rejectWithValue }) => {
    const {
      camera: { keyword, uploadedFiles },
    } = getState();

    try {
      const formData = new FormData();
      formData.append("keyword", keyword);
      uploadedFiles.forEach((f) => formData.append("image", f));

      const response = await axios.post(`${baseUrl}/search`, formData);

      // mutate base64 response to blob first to save storage
      response.data.data.forEach((d) => {
        d.frames = d.frames.map((f) => {
          const blob = b64toBlob(f, imageBast64String);
          return URL.createObjectURL(blob);
        });
      });

      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getSearchDetailAsync = createAsyncThunk(
  "search/get-detail",
  async (args, { getState, rejectWithValue }) => {
    const {
      camera: { searchType },
    } = getState();

    try {
      const response = await axios.get(
        `${baseUrl}/search/detail?videoType=${args.videoType}&videoPath=${args.videoPath}&searchType=${searchType}`
      );

      const res = response.data;
      // mutate base64 response to blob first to save storage
      Object.keys(res.data).forEach((key) => {
        const face = res.data[key];
        face.detections = face.detections.map((base64String) => {
          // Assume the content type is image/jpeg. Change this if necessary.
          const blob = b64toBlob(base64String, imageBast64String);
          return URL.createObjectURL(blob);
        });
      });

      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getSavedSearchAsync = createAsyncThunk(
  "saved_search/get",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/search/saved`);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const saveSearchAsync = createAsyncThunk(
  "search/post",
  async (args, { getState, rejectWithValue }) => {
    const {
      camera: { keyword, searchResult },
    } = getState();

    try {
      const formData = new FormData();
      formData.append("keyword", keyword);
      formData.append("results", JSON.stringify(searchResult));

      const response = await axios.post(`${baseUrl}/search/save`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getBoxesAsync = createAsyncThunk(
  "camera/getBoxes",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/setting/boxes?cameraId=${args.cameraId}`, {
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
      });
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const postBoxesAsync = createAsyncThunk(
  "camera/postBoxes",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/setting/boxes`, {
        camera: args.camera,
        type: args.type,
        polygon: args.polygon,
      });
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    initLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.dangerAlert = false;
      state.isHaveupdate = false;
      state.notifLoading = false;
      state.searchLoading = false;
    },
    initSearchLoading: (state) => {
      state.searchLoading = true;
    },
    initNotifLoading: (state) => {
      state.notifLoading = true;
    },
    updateKeywordCamera: (state, action) => {
      state.keyword = action.payload.keyword;
      state.uploadedFiles = action.payload.files;
      state.searchType = action.payload.type;
    },
    resetError: (state) => {
      state.errAddCamera = false;
    },
    resetToast: (state) => {
      state.successAdd = false;
      state.isShowToast = false;
    },
    resetcameraStore: () => initialState,
    resetPreviewImage: (state) => {
      state.previewData = {};
    },
    resetBoxPolygon: (state) => {
      state.boxesPolygon = {};
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCameraAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCameraAsync.fulfilled, (state, action) => {
        const { cameras, floors } = action.payload;

        state.floors = floors;
        state.cameras = cameras;
        state.isLoading = false;
        state.errAddCamera = false;
      })
      .addCase(getCameraAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(getCameraAsyncV2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCameraAsyncV2.fulfilled, (state, action) => {
        state.allCameras = action.payload.data.data.camera;
        state.id_allCameras = Object.fromEntries(action.payload.data.data.camera.map((camera_info) => [camera_info._id, camera_info]))
        const filtered_camera = action.payload.data.data.camera.filter(
          (info) => info.site === action.payload.site
        );
        state.camerasV2 = filtered_camera.reduce((group, rtsp_info) => {
          if (group[rtsp_info.floor] === undefined) {
            group[rtsp_info.floor] = [];
          }
          group[rtsp_info.floor].push({
            camera_id: rtsp_info._id,
            rtsp: rtsp_info.rtspStream,
            name: rtsp_info.name,
            floor: rtsp_info.floor,
            proxy: rtsp_info.proxyStream,
            proxy2: rtsp_info.proxyStream2,
          });
          return group;
        }, {});
      })
      .addCase(getCameraAsyncV2.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(addCameraAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(addCameraAsync.fulfilled, (state) => {
        state.postLoading = false;
        state.successAdd = true;
      })
      .addCase(addCameraAsync.rejected, (state) => {
        state.postLoading = false;
        state.successAdd = false;
      });

    builder.addCase(getCamera.fulfilled, (state, action) => {
      const { cameras, floors } = action.payload;

      state.floors = floors;
      state.cameras = cameras;
    });

    builder
      .addCase(getSearchAsync.pending, (state) => {
        state.isLoading = true;
        state.searchLoading = true;
      })
      .addCase(getSearchAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchLoading = false;
        state.searchResult = action.payload.data;
      })
      .addCase(getSearchAsync.rejected, (state) => {
        state.isLoading = false;
        state.searchLoading = false;
      });

    builder.addCase(saveSearchAsync.fulfilled, (state) => {
      state.isShowToast = true;
    });

    builder
      .addCase(getSavedSearchAsync.fulfilled, (state, action) => {
        const { saved_search } = action.payload;

        state.isLoading = false;
        state.savedsearch = saved_search;
      })
      .addCase(getSavedSearchAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(getSearchDetailAsync.fulfilled, (state, action) => {
        state.previewData = action.payload.data;
      })
      .addCase(getSearchDetailAsync.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSearchDetailAsync.pending, (state) => {
        state.isLoading = true;
      });

    builder
      .addCase(getBoxesAsync.pending, (state) => {})
      .addCase(getBoxesAsync.fulfilled, (state, action) => {
        state.boxesData = action.payload.data;
      })
      .addCase(getBoxesAsync.rejected, (state) => {});
  },
});

export const {
  resetToast,
  resetError,
  initLoading,
  stopLoading,
  updateKeywordCamera,
  resetcameraStore,
  initNotifLoading,
  initSearchLoading,
  resetPreviewImage,
  resetBoxPolygon,
} = cameraSlice.actions;
export default cameraSlice.reducer;
