import React from 'react'

function CheckList({ dark }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8'>
      <path
        stroke={dark ? '#9EA0AA' : '#F5FAFF'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6.5 2L3 6 1.5 4.5'></path>
    </svg>
  )
}

export default CheckList
