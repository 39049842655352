import React from "react";
import { CheckCircle, Exclamation, ExclamationCircle } from "~/svg";

import { useState } from "react";

function MachineStatus({ number, status }) {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const tooltipText = `Machine ${number} is ${
    status === 0 ? "OK" : status === 1 ? "in Maintenance" : "Error"
  }`;

  return (
    <div
      className="relative"
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      {status === 0 && <CheckCircle className="relative" />}
      {status === 1 && <Exclamation className="relative" />}
      {status === 2 && <ExclamationCircle className="relative" />}
      {tooltipVisible && (
        <div className="absolute z-10 top-full left-0">
          <div className="bg-neutral-900 p-2 rounded-md shadow-lg">
            <p className="text-xs text-white">{tooltipText}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default MachineStatus;
