import React, { useEffect, useRef, useState } from 'react'

import { EyeInput, IconX } from '~/svg'
import { useOutsideClick } from '~/utils'
import { Button, ButtonLoading, CanvasAdd, Input, Modal } from '~/components'

const AddCameraModal = ({
  isOpen,
  floors,
  cameraId,
  isLoading,
  closeModal,
  floorsCams,
  cameraForm,
  selectBrand,
  selectFloor,
  handleSubmit,
  errAddCamera,
  handleChange,
  showPassword,
  addCoordinate,
  handlePassword,
}) => {
  const labelClassName = 'font-semibold text-neutral-200 mb-1'
  const cameraBrand = [
    'Bosch',
    'Hikvision',
    'Panasonic',
    '360 Vision',
    '3S Vision',
    'LB Technology',
    'LG',
    'Luxriot A&H',
    'NorthQ',
    'OpenEye',
  ]

  const brandRef = useRef(null)
  const [showBrand, setShowBrand] = useState(false)
  const [listBrand, setListBrand] = useState(cameraBrand)

  function select(it) {
    selectBrand(it)
    setShowBrand(false)
  }

  useOutsideClick({ ref: brandRef, action: () => setShowBrand(false) })

  useEffect(() => {
    const filterred = cameraBrand.filter((it) => it.includes(cameraForm.cameraBrand))
    setListBrand(filterred)
  }, [cameraForm.cameraBrand])

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <form className='w-full' onSubmit={handleSubmit}>
        <div className='w-full md:w-[556px] border-[0px] md:border-[1px] md:border-neutral-200 rounded-2xl'>
          {/* Desktop Header */}
          <div className='hidden md:flex w-full justify-between pt-[10px] pl-8 pr-6 items-center'>
            <p className='text-xl font-semibold text-neutral-200'>{cameraId==='' ? 'Add' : 'Edit'} Camera</p>
            <div onClick={closeModal} className='cursor-pointer'>
              <IconX />
            </div>
          </div>

          {/* Mobile Header */}
          <div className='flex md:hidden flex-col w-full items-center pt-2'>
            <div className='w-11 h-1 rounded bg-neutral-800' />
            <p className='mt-6 text-neutral-50 font-semibold text-sm'>{cameraId==='' ? 'Add' : 'Edit'} Camera</p>
            <div className='w-full h-[1px] bg-neutral-800 mt-5' />
          </div>

          {/* Form */}
          <div className='mt-6 md:mt-[30px] w-full px-5 md:px-8 grid grid-cols-1 md:grid-cols-2 gap-5'>
            <div className='w-full'>
              <p className={labelClassName}>RTSP stream</p>
              <Input
                type={'text'}
                name={'rtspStream'}
                onChange={handleChange}
                empty={cameraForm.emptyRtsp}
                value={cameraForm.rtspStream}
              />
            </div>
            <div className='w-full relative'>
              <p className={labelClassName}>Brand of camera</p>
              <Input
                type={'text'}
                name={'cameraBrand'}
                onChange={handleChange}
                empty={cameraForm.emptyBrand}
                value={cameraForm.cameraBrand}
                onFocus={() => setShowBrand(true)}
              />
              {showBrand && listBrand.length > 0 ? (
                <div
                  data-testid='brand-dropdown'
                  ref={brandRef}
                  className='absolute w-full top-[82px] bg-neutral-900 rounded-lg border-[1px] border-neutral-400 z-20'>
                  {listBrand &&
                    listBrand.map((it, idx) => {
                      return idx < 5 ? (
                        <div
                          data-testid='brand-items'
                          key={idx}
                          onClick={() => select(it)}
                          className='w-full px-4 py-2 cursor-pointer'>
                          <p className='text-sm text-neutral-200 w-full truncate'>{it}</p>
                        </div>
                      ) : (
                        <div key={idx} />
                      )
                    })}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className='w-full'>
              <p className={labelClassName}>Username (if required)</p>
              <Input
                type={'text'}
                name={'userName'}
                onChange={handleChange}
                value={cameraForm.userName}
              />
            </div>
            <div className='w-full'>
              <p className={labelClassName}>Password (if required)</p>
              <div className='w-full relative'>
                <Input
                  name={'password'}
                  error={errAddCamera}
                  onChange={handleChange}
                  value={cameraForm.password}
                  type={showPassword ? 'text' : 'password'}
                  customMessage={errAddCamera ? 'Incorrect password' : ''}
                />
                <div onClick={handlePassword} className='absolute top-4 right-4 cursor-pointer '>
                  <EyeInput />
                </div>
              </div>
            </div>
          </div>

          {/* Canvas */}
          <div className='mt-5 px-8 w-full overflow-x-scroll no-scrollbar'>
            <p className={labelClassName}>Tag the camera location</p>
            <div className='w-[343px] relative'>
              <CanvasAdd cameras={floorsCams} addCoordinate={addCoordinate} />
              <div className='absolute top-0 right-2 z-[6] rounded-[4px] bg-[#1C1C1C] border-[1px] border-neutral-600'>
                {floors &&
                  floors.map((it, idx) => (
                    <div
                      data-testid='floor-options'
                      key={idx}
                      onClick={() => selectFloor(it)}
                      className='text-xs text-neutral-50 leading-3 py-[8px] px-[8px] cursor-pointer text-center select-none'>
                      <p>{it}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* Button */}
          <div className='w-full h-[1px] md:h-[0px] bg-[#1E1E1E] mt-6' />
          <div className='w-full p-4 flex flex-col md:flex-row justify-end gap-2'>
            <div className='w-full md:w-[86px]'>
              <Button data-testid='cancel' onClick={closeModal} type={'secondary'}>
                <p>Cancel</p>
              </Button>
            </div>
            <div className={`w-full ${isLoading ? 'md:w-[156px]' : 'md:w-[128px]'} `}>
              <Button data-testid='add-camera' submit type={'primary'}>
                <div className='flex items-center gap-2'>
                  <p>{cameraId==='' ? 'Add' : 'Edit'} Camera</p>
                  {isLoading ? <ButtonLoading /> : <></>}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default AddCameraModal
