import React from 'react'

function WrongOutline({ big }) {
  return big ? (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <path
        stroke='#FEABAB'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'></path>
    </svg>
  ) : (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke='#FEABAB'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6.667 9.333L8 8m0 0l1.333-1.333M8 8L6.667 6.667M8 8l1.333 1.333M14 8A6 6 0 112 8a6 6 0 0112 0z'></path>
    </svg>
  )
}

export default WrongOutline
