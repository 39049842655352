import IconX from './x';
import Pip from './pip';
import Info from './info';
import Play from './play';
import Pause from './pause';
import CogIcon from './cog';
import EyeIcon from './eye';
import Sort from './sort';
import Plus from './plus';
import Cross from './cross';
import Clock from './clock';
import Trash from './trash';
import Result from './result';
import Search from './search';
import Upload from './upload';
import Camera from './camera';
import Volume from './volume';
import Filter from './filter';
import UserIcon from './user';
import BellIcon from './bell';
import Pencil from './pencil';
import Checked from './checked';
import Calendar from './calendar';
import Settings from './settings';
import UpdateIcon from './update';
import EyeInput from './eye-input';
import OutlineX from './outline-x';
import NoAlerts from './no-alerts';
import CevronUp from './cevron-up';
import CheckList from './checklist';
import VolumeOff from './volume-off';
import MajorFire from './major-fire';
import WallCrack from './wall-crack';
import Photograph from './photograph';
import WhatsAppIcon from './whatsapp';
import TelegramIcon from './telegram';
import CevronLeft from './cevron-left';
import LockedLogo from './locked-logo';
import PauseSolid from './pause-solid';
import CevronDown from './cevron-down';
import FullScreen from './full-screen';
import FastForward from './fast-forward';
import Exclamation from './exclamation';
import CheckCircle from './check-circle';
import CevronRight from './cevron-right';
import FireOutline from './fire-outline';
import NoIncidents from './no-incidents';
import SearchNotFound from './not-found';
import OutlineDots from './outline-dots';
import Notification from './notification';
import ChatBubbleIcon from './chat-bubble';
import WrongOutline from './wrong-outline';
import ActiveCamera from './active-camera';
import OutlineCloud from './outline-cloud';
import CheckOutline from './check-outline';
import LoadingCircle from './loading-circle';
import PaperAirplane from './paper-airplane';
import CheckedAvatar from './checked-avatar';
import AccountLocked from './account-locked';
import VideoCameraIcon from './video-camera';
import NoAlertToShow from './no-alert-to-show';
import EllipsisVertical from './ellipsis-vertical';
import ActivePhotograph from './active-photograph';
import ExclamationCircle from './exclamation-circle';
import FireNotifications from './fire-notifications';
import FallDetection from './fall-detection';
import PeopleLoitering from './people-loitering';
import Checkbox from './checkbox';
import CheckboxOutline from './checkbox-outline';
import Refresh from './refresh';

export {
  Pip,
  Sort,
  Play,
  Pause,
  Info,
  Plus,
  Clock,
  Cross,
  Trash,
  Filter,
  Upload,
  Search,
  Result,
  Camera,
  Volume,
  Pencil,
  CogIcon,
  Checked,
  EyeIcon,
  Settings,
  NoAlerts,
  Calendar,
  EyeInput,
  OutlineX,
  CevronUp,
  UserIcon,
  BellIcon,
  WallCrack,
  CheckList,
  MajorFire,
  VolumeOff,
  CevronLeft,
  LockedLogo,
  CevronDown,
  FullScreen,
  UpdateIcon,
  Photograph,
  PauseSolid,
  NoIncidents,
  FireOutline,
  OutlineDots,
  CevronRight,
  Exclamation,
  CheckCircle,
  FastForward,
  OutlineCloud,
  CheckOutline,
  WrongOutline,
  ActiveCamera,
  Notification,
  TelegramIcon,
  WhatsAppIcon,
  CheckedAvatar,
  LoadingCircle,
  PaperAirplane,
  NoAlertToShow,
  AccountLocked,
  ChatBubbleIcon,
  SearchNotFound,
  VideoCameraIcon,
  EllipsisVertical,
  ActivePhotograph,
  FireNotifications,
  ExclamationCircle,
  FallDetection,
  PeopleLoitering,
  Checkbox,
  CheckboxOutline,
  Refresh,
  IconX
};
