import { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  postVideoAlert,
  updateVideoInfo,
  uploadVideoSearch,
  getVideoSearchHistory,
  resetVideoStore,
} from '~/store/slice/video'
import { PATH } from '~/constant'
import { checkIsVideo, getFileExtension, useWindowSize } from '~/utils'
import { useDispatch, useSelector } from 'react-redux'

export function useVideo() {
  const mediaPath = process.env.MEDIA_PATH
  const navigate = useNavigate()
  const [width, height] = useWindowSize()

  const dispatch = useDispatch()
  const location = useLocation()

  const videoSearchResult = useSelector((state) => state.video.videoSearchResult) || []
  const isLoadingSearchVideo = useSelector((state) => state.video.isLoadingSearchVideo)

  const urlVideo = `${mediaPath}/video/${location.state?.path}`
  const [videoObject, setVideoObject] = useState({})
  const [videoName, setVideoName] = useState(location.state?.label)
  const [stateExpandSearch, setExpandSearch] = useState(0)
  const [stateMaxSearch, setMaxSearch] = useState(5)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [isMobile, setIsMobile] = useState(false)
  const [isFrameView, setIsFrameView] = useState(false)
  const [stateFrames, setStateFrames] = useState({
    // test video, watermarked
    url: urlVideo,
    object: [],
    searchHistory: [],
  })
  const [frameLimit, setFrameLimit] = useState(0)
  const [saveInfo, setSaveInfo] = useState(false)

  useEffect(() => {
    fetch(urlVideo)
      .then((res) => res.blob())
      .then((blob) => {
        setVideoObject(URL.createObjectURL(blob))
      })
  }, [])

  useEffect(() => {
    dispatch(resetVideoStore())
    dispatch(
      updateVideoInfo({
        videoPath: location.state?.path,
        videoType: location.state?.path_type,
        videoId: location.state?._id,
        videoFrameLimit: frameLimit,
      }),
    )

    dispatch(getVideoSearchHistory())
  }, [location, frameLimit])

  useEffect(() => {
    setStateFrames((prevState) => ({
      ...prevState,
      object: videoSearchResult,
      searchHistory: extractAllSearch(videoSearchResult),
    }))
  }, [videoSearchResult])

  function switchToFrames(frameView) {
    if (videoSearchResult.length > 0) {
      setIsFrameView(frameView)
      if (frameView) {
        setFrameLimit(videoSearchResult.length)
      }
    }
  }

  // user click on actual frame on frame state
  function toVideoFrame(frameView, time) {
    switchToFrames(frameView)
    setStateFrames((prevState) => ({
      ...prevState,
      url: `${urlVideo}#t=${time}`,
    }))
  }

  function backPreviousPage() {
    navigate(-1)
  }

  function loadSearch() {
    setMaxSearch(stateMaxSearch + 5)
  }

  function backToVideos() {
    navigate(PATH.UPLOADED)
  }

  function extractAllSearch(videoSearchResult) {
    if (videoSearchResult.length === 0) {
      return []
    }
    return videoSearchResult.map((result) => ({ keyword: result.name }))
  }

  // upload files
  const onDrop = useCallback((acceptedFiles) => {
    const ext = getFileExtension(acceptedFiles[0].name)

    if (acceptedFiles.length > 1) {
      alert('Upload just 1 image File')
      return
    }

    if (!checkIsVideo(ext)) {
      alert('Upload just 1 image File')
      return
    }

    setUploadedFiles(URL.createObjectURL(acceptedFiles[0]))

    dispatch(
      uploadVideoSearch({
        files: acceptedFiles[0],
      }),
    )
    dispatch(postVideoAlert())
  }, [])
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true })

  useEffect(() => {
    setIsMobile(width < 769)
  }, [width, height])

  return {
    datas: {
      isMobile,
      saveInfo,
      videoName,
      frameLimit,
      videoObject,
      stateFrames,
      isFrameView,
      uploadedFiles,
      stateMaxSearch,
      stateExpandSearch,
      isLoadingSearchVideo,
    },
    methods: {
      open,
      loadSearch,
      setSaveInfo,
      backToVideos,
      setVideoName,
      getRootProps,
      toVideoFrame,
      getInputProps,
      setFrameLimit,
      switchToFrames,
      setExpandSearch,
      backPreviousPage,
    },
  }
}
