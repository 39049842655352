import React, { useState } from 'react'

import { StatusBadges } from '~/components'
import { Info, Notification, PaperAirplane } from '~/svg'

const HistoryItems = ({ it }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div className='flex gap-2 mt-2'>
      {it.type === 'message' ? <PaperAirplane /> : <Notification />}
      {it.type === 'message' ? (
        <div>
          <p className='text-neutral-200 font-semibold inline'>
            <span className='underline mr-1'>{it.author}</span>
            {it.action}
            <span className='underline ml-1'>{it.target}</span>
          </p>
          <div className='flex items-center gap-1'>
            <p className='text-sm text-neutral-400'>{`${it.date} • Changed to`}</p>
            <StatusBadges status={it.changeTo} />
          </div>
        </div>
      ) : (
        <div>
          <div className='block md:flex items-center gap-1'>
            <p className='text-neutral-200 font-semibold inline md:flex'>{it.desc}</p>
            <div
              className='cursor-pointer relative inline-block md:flex'
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}>
              <Info />
              {showTooltip ? (
                <>
                  <div className='hidden md:block'>
                    <div className='absolute bg-[#334155] rounded px-[10px] py-[5px] left-0 bottom-[20px] flex flex-col gap-2'>
                      {it.target &&
                        it.target.map((it, idx) => (
                          <div key={idx} className='flex items-center gap-[10px]'>
                            <div className='h-5 w-5 flex justify-center items-center bg-neutral-800 rounded-full'>
                              <p className='text-[10px] text-neutral-200'>
                                {it.charAt(0).toUpperCase()}
                              </p>
                            </div>
                            <p className='text-sm text-white'>{it}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className='block md:hidden'>
                    <div className='absolute bg-[#334155] rounded px-[10px] py-[5px] right-0 bottom-[20px] flex flex-col gap-2'>
                      {it.target &&
                        it.target.map((it, idx) => (
                          <div key={idx} className='flex items-center gap-[10px]'>
                            <div className='h-5 w-5 flex justify-center items-center bg-neutral-800 rounded-full'>
                              <p className='text-[10px] text-neutral-200'>
                                {it.charAt(0).toUpperCase()}
                              </p>
                            </div>
                            <p className='text-sm text-white'>{it}</p>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <p className='text-sm text-neutral-400'>{it.date}</p>
        </div>
      )}
    </div>
  )
}

export default HistoryItems
