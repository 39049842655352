import React from 'react'

function FireOutline() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke='#FFEBEB'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M11.771 12.438A5.333 5.333 0 014.23 4.895S4.667 6 6 6.667C6 5.333 6.333 3.333 7.99 2c1.343 1.333 2.737 1.852 3.781 2.895a5.317 5.317 0 011.562 3.772c0 1.365-.52 2.73-1.562 3.77z'></path>
      <path
        stroke='#FFEBEB'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6.586 10.748A2 2 0 108.01 7.333l-.677 2H6c0 .512.195 1.024.586 1.415z'></path>
    </svg>
  )
}

export default FireOutline
