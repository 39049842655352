import React from 'react'

import { Button, Input, Modal } from '~/components'
import { IconX } from '~/svg'

const ModalStorage = ({
  isOpen,
  closeModal,
  formStorage,
  handleChangeStorage,
  handleSubmitStorage,
}) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className='flex flex-col py-2 px-8 w-[375px] border rounded-tl-3xl rounded-tr-3xl border-neutral-900 md:w-[556px]'>
        <div className='flex flex-row w-full justify-between max-md:border-b max-md:border-neutral-800 mt-3'>
          <p className='w-full text-neutral-50 font-semibold text-sm md:text-xl text-center md:text-left my-5'>
            Set Storage Path
          </p>
          <div className='flex max-md:hidden cursor-pointer' onClick={closeModal}>
            <IconX />
          </div>
        </div>
        <label className='flex flex-col gap-1'>
          <div className='w-full text-neutral-200 font-semibold '>File path</div>
          <Input
            name={'path'}
            type={'text'}
            value={formStorage.path}
            onChange={handleChangeStorage}
          />
        </label>
        <div className='flex flex-col md:flex-row gap-5 mt-5'>
          <label className='flex flex-col gap-1'>
            <div className='w-full text-neutral-200 font-semibold '>Username (if required)</div>
            <Input
              name={'username'}
              type={'text'}
              value={formStorage.username}
              onChange={handleChangeStorage}
            />
          </label>
          <label className='flex flex-col gap-1'>
            <div className='w-full text-neutral-200 font-semibold'>Password (if required)</div>
            <Input
              name={'password'}
              type={'password'}
              value={formStorage.password}
              onChange={handleChangeStorage}
            />
          </label>
        </div>
        <div className='mt-8 flex md:flex-row flex-col md:justify-end gap-2'>
          <div className='md:w-fit'>
            <Button type={'secondary'} onClick={closeModal}>
              Cancel
            </Button>
          </div>
          <div className='md:w-fit'>
            <Button type={'primary'} onClick={handleSubmitStorage}>
              Set Priority Level
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalStorage
