import React, { useRef, useState } from 'react'
import { ALERTMODALTYPE, ALERTTYPE } from '~/constant'
import { EllipsisVertical, Pencil, Trash } from '~/svg'
import { useOutsideClick } from '~/utils'

const AlertSettingPage = ({
  dataAlerts,
  IconNotification,
  openIncidentModal,
  openModalPassword,
}) => {
  const alertsRef = useRef()

  const [dropdownAlertState, setDropdownAlert] = useState(false)

  function openDropdownAlert() {
    setDropdownAlert(!dropdownAlertState)
  }

  useOutsideClick({ ref: alertsRef, action: () => setDropdownAlert(false) })

  return (
    <div>
      <div
        className='flex flex-col border bg-neutral-900 p-4 rounded-xl'
        style={{ borderColor: `${dataAlerts.color}50` }}>
        <div className='flex flex-row w-full justify-between'>
          <div className='flex flex-row items-center gap-2'>
            <div className='h-5 w-5 rounded-full' style={{ backgroundColor: dataAlerts.color }} />
            <p className='font-semibold text-xl text-neutral-200'> {dataAlerts.name}</p>
          </div>
          <div>
            <div data-testid='ellipsis' className='cursor-pointer' onClick={openDropdownAlert}>
              <EllipsisVertical />
            </div>
            {dropdownAlertState ? (
              <div
                data-testid='dropdown-alert'
                className='absolute py-2 bg-neutral-900 z-50 rounded-xl'
                ref={alertsRef}>
                <div
                  data-testid='edit-priority'
                  className='px-4 py-3 gap-2 flex flex-row cursor-pointer'
                  onClick={() =>
                    openModalPassword(
                      ALERTMODALTYPE.EDIT,
                      ALERTTYPE.PRIORITY,
                      dataAlerts.name,
                      dataAlerts,
                    )
                  }>
                  <Pencil /> <span className='text-neutral-200'>Edit Priority Level</span>
                </div>
                <div
                  data-testid='delete-priority'
                  className='px-4 py-3 gap-2 flex flex-row cursor-pointer'
                  onClick={() =>
                    openModalPassword(
                      ALERTMODALTYPE.DELETE,
                      ALERTTYPE.PRIORITY,
                      dataAlerts.name,
                      dataAlerts,
                    )
                  }>
                  <Trash className={'stroke-danger-600'} />
                  <span className='text-danger-600'>Delete Priority Level</span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <p className='mt-3 text-neutral-400'>{dataAlerts.description}</p>
        <div className='flex flex-row pt-4'>
          <div className='gap-2 flex flex-row'>
            {dataAlerts.apps_notification.map((n, nkey) => {
              return <IconNotification key={nkey} n={n} />
            })}
            <p className='pl text-neutral-200'>every {dataAlerts.loop} mins</p>
          </div>
        </div>
        <div className='mt-3'>
          {dataAlerts.incidents.map((i, ikey) => {
            return (
              <div
                className='border border-neutral-800 rounded-md text-xs mt-3 flex flex-row'
                key={ikey}>
                <div className='ml-3 py-6 flex flex-col w-3/4'>
                  <p className='w-full font-semibold text-lg text-neutral-200'>{i.name}</p>
                  <p className='w-full mt-2 text-neutral-400'>{i.description}</p>
                </div>
                <div className='mx-auto w-1/4 overflow-auto rounded-r-md relative'>
                  <p
                    data-testid='edit-incident'
                    className='absolute w-full font-semibold z-10 text-end underline cursor-pointer pt-3 pr-3 text-white'
                    onClick={() =>
                      openModalPassword(ALERTMODALTYPE.EDIT, ALERTTYPE.INCIDENT, i.name, i)
                    }>
                    Edit
                  </p>
                  {i.image ? (
                    <div className='overflow-hidden'>
                      {' '}
                      <img src={i.image} alt='' className='z-0 w-full h-full opacity-50' />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div
          data-testid='add-alert'
          className='border border-neutral-800 bg-neutral-900 py-5 mt-3 rounded-xl cursor-pointer'
          onClick={() => openIncidentModal(undefined, dataAlerts)}>
          <p className='text-neutral-300 font-semibold text-center w-full'>Add Alert</p>
        </div>
      </div>
    </div>
  )
}

export default AlertSettingPage
