import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { postEditPathStorage } from '~/store/slice/account'

export function useSystem() {
  const dispatch = useDispatch()
  const dataSystem = useSelector((state) => state.account.dataSystem, shallowEqual)

  const [formStorage, setFormStorage] = useState({ path: '', username: '', password: '' })
  const [stateModalStorage, setStateModalStorage] = useState(false)

  function handleChangeStorage(e) {
    const { name, value } = e.target

    setFormStorage((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    setFormStorage((prevState) => ({
      ...prevState,
      path: dataSystem.path,
    }))
  }, [dataSystem])

  function handleSubmitStorage() {
    dispatch(postEditPathStorage(formStorage))
  }

  return {
    datas: { dataSystem, formStorage, stateModalStorage },
    methods: { handleChangeStorage, handleSubmitStorage, setStateModalStorage },
  }
}
