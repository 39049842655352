import React from 'react'

import { checkTryLeft } from '~/utils'
import { OutlineX, WrongOutline } from '~/svg'

const ErrToast = ({ errCount, closeToast }) => {
  const tryLeft = checkTryLeft(errCount)
  return (
    <div className='mx-0 w-full flex items-start justify-between py-4 px-6 rounded-md border-[1px] border-danger-800 mb-5 bg-[#9D101029]'>
      <div className='flex items-start justify-start gap-4'>
        <WrongOutline big />
        <div>
          <p className='text-base font-semibold text-danger-200'>Incorrect Credentials</p>
          {tryLeft > 1 ? (
            <p className='mt-2 text-base font-normal text-neutral-200 max-w-[179px] md:max-w-full'>
              {`Please try again. You have ${tryLeft} tries left.`}
            </p>
          ) : (
            <p className='mt-2 text-base font-normal text-neutral-200 max-w-[179px] md:max-w-full'>
              You have 1 try left.{' '}
              <span className='underline cursor-pointer'>Forgot your password?</span>
            </p>
          )}
        </div>
      </div>
      <div onClick={closeToast} className='cursor-pointer'>
        <OutlineX />
      </div>
    </div>
  )
}

export default ErrToast
