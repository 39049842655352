import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { axios } from "~/utils";

const initialState = {
  machineStatus: [
    {
      site: 1,
      id: "",
      machines: [],
    },
    {
      site: 2,
      id: "",
      machines: [],
    },
    {
      site: 3,
      id: "",
      machines: [],
    },
    {
      site: 4,
      id: "",
      machines: [],
    },
    {
      site: 5,
      id: "",
      machines: [],
    },
    {
      site: 6,
      id: "",
      machines: [],
    },
    {
      site: 7,
      id: "",
      machines: [],
    },
  ],
  loading: true,
};

const baseUrl = process.env.BASE_URL;

export const getMachineStatus = createAsyncThunk(
  "machine/getMachineStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/machine/status`);

      console.log(response)

      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message };
      return rejectWithValue(message);
    }
  }
);

const machineSlice = createSlice({
  name: "machine",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMachineStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMachineStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.machineStatus = action.payload.data;
      })
      .addCase(getMachineStatus.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default machineSlice.reducer;
