import React from 'react'

import { Button } from '~/components'
import { SearchNotFound } from '~/svg'

const NotFound = ({ toHomePage }) => {
  return (
    <div className='w-full h-[80vh] flex flex-col justify-center items-center text-center px-5'>
      <SearchNotFound />
      <p className='text-xl font-semibold text-neutral-50'>No Results Found</p>
      <p className='text-sm text-neutral-200 my-4'>
        Try to refine the keywords or search by image instead.
      </p>
      <Button onClick={toHomePage} type={'secondary'} small>
        <p>Back to Home</p>
      </Button>
    </div>
  )
}

export default NotFound
