import { useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'

import { useOutsideClick } from '~/utils'

export function useMenuSetting() {
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const tabValue = queryParams.get('tab')

  const dropdownRef = useRef(null)
  const [dropdownSetting, setDropdown] = useState(false)

  useOutsideClick({ ref: dropdownRef, action: () => setDropdown(false) })

  useEffect(() => {
    setDropdown(false)
  }, [tabValue])

  return {
    datas: {
      dropdownRef,
      dropdownSetting,
    },
    methods: { setDropdown },
  }
}
