import React from 'react'

function UpdateIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke='#F5FAFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6 7.333l2-2m0 0l2 2m-2-2v5.334M8 2a6 6 0 110 12A6 6 0 018 2z'></path>
    </svg>
  )
}

export default UpdateIcon
