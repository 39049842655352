import React from "react";
import { Link } from "react-router-dom";

import { PATH } from "~/constant";
import { Notification, Settings } from "~/svg";
import { useLocation, useNavigate } from "react-router-dom";

import { useNavbar } from "./hooks";
import SearchBarHome from "../search-bar-home";
import { DangerAlert, NavbarShimmer, Notifications, UpdateAlert } from "./components";
import { getSearchAsync } from "~/store/slice/camera";
import Button from "../button";

const Navbar = () => {
  const {
    datas: {
      admin,
      siteName,
      dropdownRef,
      notifRef,
      hasLogin,
      notFound,
      isSearch,
      statusRef,
      showNotif,
      isLoading,
      dataNotif,
      notifStatus,
      dangerAlert,
      navBarMenus,
      notifLoading,
      alertLoading,
      isHaveupdate,
      searchLoading,
      profilePhotos,
      selectedStatus,
      dummyEmergency,
      countNotifData,
      showNotifStatus,
      notifCountLoading,
      siteNumber,
      toggleLogout,
    },
    methods: {
      openStatus,
      handleShowNotif,
      handleNotifStatus,
      handeMarkAsRead,
      openSettings,
      openAlert,
      handleProfileClick,
      logout,
    },
  } = useNavbar();

  const location = useLocation();

  return notFound ? (
    <></>
  ) : (
    <div className="relative">
      {/* <div ref={notifRef}>
        {showNotif ? (
          <div className='absolute top-[48px] left-0 w-full block md:hidden'>
            <Notifications
              statusRef={statusRef}
              dataNotif={dataNotif}
              openStatus={openStatus}
              isLoading={notifLoading}
              notifStatus={notifStatus}
              selectedStatus={selectedStatus}
              dummyEmergency={dummyEmergency}
              handeMarkAsRead={handeMarkAsRead}
              showNotifStatus={showNotifStatus}
              handleNotifStatus={handleNotifStatus}
            />
          </div>
        ) : (
          <></>
        )}
      </div> */}
      <div
        className={`w-full border-b-[2px] border-b-neutral-800 px-4 md:px-8 py-4 md:pt-5 md:pb-6 ${
          isSearch ? "border-none md:border-solid md:border-b-[2px] md:border-b-neutral-800" : ""
        }`}
      >
        {hasLogin && (isLoading || searchLoading || alertLoading) ? (
          <NavbarShimmer isSearch={isSearch} />
        ) : hasLogin ? (
          <div className="w-full flex justify-between">
            <div className="w-full flex items-center justify-start gap-8">
              <Link to={PATH.HOME}>
                <img
                  alt="logo"
                  src="public/logo.png"
                  style={{ width: "144px", height: "80px", cursor: "pointer" }}
                />
              </Link>
              {location.state !== null ? (
                <>
                  {navBarMenus.map((m, midx) => (
                    location.state.site === 7 ? (
                      <></>
                    ) : (
                      <Link to={m.link} key={midx} state={{ site: location.state.site }}>
                        <p className="cursor-pointer text-sm font-semibold text-primary-50 hidden md:block">
                          {m.menu}
                        </p>
                      </Link>
                    )
                  ))}
                  <p className="cursor-pointer text-sm font-semibold text-gray-500 hidden md:block">
                    Map
                  </p>
                </>
              ) : (
                <></>
              )}
              {isSearch ? (
                <div className="h-8 w-1/2 ml-10 hidden md:block">
                  <SearchBarHome isNavbar isLoading={isLoading} dispatchSearch={getSearchAsync} />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              <h1 className="h-full flex text-lg font-bold text-white w-32 items-center justify-center">
                {siteName}
              </h1>
            </div>
            <div className="flex items-center justify-start gap-5">
              {isHaveupdate ? (
                <div className="h-8">
                  <UpdateAlert />
                </div>
              ) : (
                <></>
              )}
              {dangerAlert ? (
                <div className="h-8 cursor-pointer" onClick={openAlert}>
                  <DangerAlert alertData={dangerAlert} />
                </div>
              ) : (
                <></>
              )}
              {/* <div */}
              {/*   ref={notifRef} */}
              {/*   className={`relative ${isHaveupdate || dangerAlert ? 'hidden md:block' : ''}`}> */}
              {/*   <div onClick={handleShowNotif} className='cursor-pointer'> */}
              {/*     <Notification /> */}
              {/*   </div> */}
              {/*   {notifCountLoading || countNotifData == 0 ? ( */}
              {/*     <></> */}
              {/*   ) : countNotifData > 10 ? ( */}
              {/*     <div */}
              {/*       onClick={handleShowNotif} */}
              {/*       className='cursor-pointer absolute top-[-4px] right-[-14px] px-1 pt-[2px] pb-[1px] rounded-xl bg-primary-600'> */}
              {/*       <p className='text-[9px] font-semibold text-neutral-50'>10+</p> */}
              {/*     </div> */}
              {/*   ) : ( */}
              {/*     <div */}
              {/*       onClick={handleShowNotif} */}
              {/*       className='cursor-pointer absolute top-[-4px] right-[-14px] px-1 pt-[2px] pb-[1px] rounded-xl bg-primary-600'> */}
              {/*       <p className='text-[9px] font-semibold text-neutral-50'>{countNotifData}</p> */}
              {/*     </div> */}
              {/*   )} */}
              {/*   {showNotif ? ( */}
              {/*     <div className='absolute top-[32px] right-[-14px] w-[440px] hidden md:block'> */}
              {/*       <Notifications */}
              {/*         statusRef={statusRef} */}
              {/*         dataNotif={dataNotif} */}
              {/*         openStatus={openStatus} */}
              {/*         isLoading={notifLoading} */}
              {/*         notifStatus={notifStatus} */}
              {/*         selectedStatus={selectedStatus} */}
              {/*         dummyEmergency={dummyEmergency} */}
              {/*         handeMarkAsRead={handeMarkAsRead} */}
              {/*         showNotifStatus={showNotifStatus} */}
              {/*         handleNotifStatus={handleNotifStatus} */}
              {/*       /> */}
              {/*     </div> */}
              {/*   ) : ( */}
              {/*     <></> */}
              {/*   )} */}
              {/* </div> */}
              {(admin && location.state !== null && siteNumber > 0 && (
                <div className="hidden md:block cursor-pointer ml-2" onClick={openSettings}>
                  <Settings />
                </div>
              )) || <></>}
              <div className="relative" ref={dropdownRef}>
                <div onClick={handleProfileClick}>
                  {profilePhotos === "" ? (
                    <div className="h-8 w-8 rounded-full bg-neutral-800 cursor-pointer ml-2 md:ml-0 flex justify-center items-center">
                      <p className="text-neutral-200 text-sm font-semibold">H</p>
                    </div>
                  ) : (
                    <div className="h-8 w-8 rounded-full bg-neutral-800 cursor-pointer ml-2 md:ml-0">
                      <img
                        alt="ailiverse_logo"
                        src="public/ailiverse_logo.png"
                        style={{ width: "32px", height: "32px" }}
                      />
                    </div>
                  )}
                </div>
                {toggleLogout ? (
                  <div className="rounded-xl bg-black absolute right-0 mt-2 z-50 p-5">
                    <p className="text-white w-full mb-4">
                      For maintenance contact Ailiverse at: support@ailiverse.com
                    </p>
                    <Button type={"danger"} onClick={logout}>
                      <p>Log Out</p>
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          <img
            alt="logo"
            src="public/logo.png"
            style={{ width: "144px", height: "80px", cursor: "pointer" }}
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
