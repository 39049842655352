import SettingMenu from './menus'
import AlertSetting from './alert'
import SystemSetting from './system'
import CameraSetting from './cameras'
import AccountSetting from './account'
import { CamSettingShimmer } from './cameras/components'

export {
  SettingMenu,
  AlertSetting,
  SystemSetting,
  CameraSetting,
  AccountSetting,
  CamSettingShimmer,
}
