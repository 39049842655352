import React from 'react'

import { Shimmer } from '~/components'

const CamSettingShimmer = () => {
  return (
    <>
      <div className='block md:hidden w-full py-6 px-4'>
        <div className='w-20'>
          <Shimmer height={24} />
        </div>
        <div className='w-full mt-4'>
          <Shimmer height={44} />
        </div>
        <div className='w-full mt-4'>
          <Shimmer height={228} />
        </div>
        <div className='w-full mt-4'>
          <Shimmer height={400} />
        </div>
      </div>
      <div className='hidden md:block w-full px-8 py-11'>
        <div className='w-20'>
          <Shimmer height={24} />
        </div>
        <div className='flex w-full gap-11 mt-7'>
          <div className='w-[193px]'>
            <Shimmer height={176} />
          </div>
          <div className='w-[667px]'>
            <div className='flex w-full justify-between mt-2'>
              <div className='w-20'>
                <Shimmer height={24} />
              </div>
              <div className='w-24'>
                <Shimmer height={24} />
              </div>
            </div>
            <div className='w-full mt-10'>
              <Shimmer height={444} />
            </div>
            <div className='w-full mt-6'>
              <Shimmer height={400} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CamSettingShimmer
