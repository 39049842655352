import React from "react";

import { Search } from "~/svg";

const SearchBarFilter = ({
  keyword,
  handleChange,
  submitSearch,
  placeholder,
}) => {
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      submitSearch();
    }
  }

  return (
    <div className="w-full flex justify-between items-center h-10">
      <div className="w-full h-full flex items-center justify-between px-5 rounded-lg border-[1px] gap-2 border-neutral-600">
        <div className="flex w-full items-center gap-2">
          <input
            type="text"
            id="search"
            name="search"
            value={keyword}
            autoComplete="off"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            className="w-full bg-transparent outline-none border-none text-base font-normal placeholder:text-neutral-400 text-neutral-50 truncate"
          />
        </div>
        <div
          onClick={submitSearch}
          className={`flex items-center cursor-pointer h-full`}
        >
          <Search />
        </div>
      </div>
    </div>
  );
};

export default SearchBarFilter;
