import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { axios } from "~/utils";
import { store } from "..";
import { INCIDENT_TYPE, STATUS } from "~/constant";

const initialState = {
  fire_timestamps: [],
  fire_data: [],
  fall_timestamps: [],
  fall_data: [],
  loitering_timestamps: [],
  loitering_data: [],
  pending_alerts: [],
  non_pending_alerts: [],
  today_alerts: [],
  yesterday_alerts: [],
  alerts: [],
  incident_count: [],
  isLoading: true,
  incident_fire_analytics: { average: 0, total: 0, mostFloor: 0, mostHour: 0, chartData: [] },
  latest_fire_incidents: [],
  incident_loitering_analytics: { average: 0, total: 0, mostFloor: 0, mostHour: 0, chartData: [] },
  latest_loitering_incidents: [],
  incident_fall_analytics: { average: 0, total: 0, mostFloor: 0, mostHour: 0, chartData: [] },
  latest_fall_incidents: [],
  incident_count_all: 0,
  incident_count_pending: 0,
  incident_summary_data: {},
  incident_summary_total: {},
  lastest_camera: undefined,
  count_alert_read: 0,
  showLoadingToast: false,
};

const baseUrl = process.env.BASE_URL;

export const getLatestLocation = createAsyncThunk(
  "incident/getLatestLocation",
  async (args, { rejectWithValue }) => {
    const { incident_id } = args;
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/incident/getLatestLocation?incident_id=${incident_id}`,
        headers: { },
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const getIncidentAlerts = createAsyncThunk(
  "incident/getIncidentAlerts",
  async (args, { rejectWithValue }) => {
    const { site, page, limit } = args;
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/incident/getIncident?location=All Locations&camera=All Cameras&floor=All Floors&site=${site}&after=${null}&before=${null}&page=${page}&limit=${limit}`,
        headers: {},
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getIncidentSummary = createAsyncThunk(
  "incident/getIncidentSummary",
  async (args, { rejectWithValue }) => {
    const { site, type } = args;

    const before = new Date();

    const after = new Date(before.getTime() - 10 * 60 * 1000);

    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/incident/getIncidentSummary?site=${site}&type=${type}&after=${after.toISOString()}&before=${before.toISOString()}`,
        headers: {},
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getPendingIncidentAlerts = createAsyncThunk(
  "incident/getPendingIncidentAlerts",
  async (args, { rejectWithValue }) => {
    const { site, page, limit } = args;
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/incident/getIncident?location=All Locations&camera=All Cameras&floor=All Floors&seen=${1}&site=${site}&after=${null}&before=${null}`,
        headers: {},
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getNonPendingIncidentAlerts = createAsyncThunk(
  "incident/getNonPendingIncidentAlerts",
  async (args, { rejectWithValue }) => {
    const { site, page, limit, search, after, before } = args;
    const afterDate = after == null ? null : after.toISOString();
    const beforeDate = before == null ? null : before.toISOString();

    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/incident/getIncident?location=All Locations&camera=All Cameras&floor=All Floors&seen=${2}&site=${site}&after=${afterDate}&before=${beforeDate}&page=${page}&limit=${limit}&search=${search}`,
        headers: {},
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getIncident = createAsyncThunk(
  "incident/getIncident",
  async (args, { rejectWithValue }) => {
    const { location, floor, camera, site, after, before } = args;

    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/incident/getIncident?location=${location}&camera=${camera}&floor=${floor}&site=${site}&after=${after}&before=${before}`,
        headers: {},
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getIncidentAnalytics = createAsyncThunk(
  "incident/getAnalytics",
  async (args, { rejectWithValue }) => {
    let { type, floor, camera, site, after, before } = args;

    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    after = after ? new Date(after) : todayStart;
    before = before ? new Date(before) : now;

    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/incident/getAnalytics?type=${type}&floor=${floor}&camera=${camera}&site=${site}&after=${after.toISOString()}&before=${before.toISOString()}`,
        headers: {},
      };

      const response = await axios.request(config);
      return { ...response.data, type };
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getIncidentCount = createAsyncThunk(
  "incident/getCount",
  async (args, { rejectWithValue }) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/incident/count/new`,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const readIncident = createAsyncThunk(
  "incident/read",
  async (incidentId, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/incident/read`, {
        incident_id: incidentId,
      });
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, incidentId };
      return rejectWithValue(message);
    }
  }
);

export const exportIncident = createAsyncThunk(
  "incident/export",
  async (args, { rejectWithValue }) => {
    const { site, after, before, type, fileName } = args;

    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/incident/export?site=${site}&after=${after}&before=${before}&type=${type}`,
        timeout: 60 * 10 * 1000
      };

      const response = await axios.request(config);

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return { success: true };
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getIncidentCountAll = createAsyncThunk(
  "incident/getCountAll",
  async (args, { rejectWithValue }) => {
    const { site } = args;
    try {
      let config = {
        method: "get",
        url: `${baseUrl}/incident/count?site=${site}`,
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const incidentSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    resetincidentStore: () => initialState,
    resetIncidentToast: (state) => {
      state.showLoadingToast = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getIncident.pending, (state) => {})
      .addCase(getIncident.fulfilled, (state, action) => {
        state.fire_data = [];
        state.fire_timestamps = [];
        state.fall_data = [];
        state.fall_timestamps = [];
        state.loitering_data = [];
        state.loitering_timestamps = [];

        for (let i = 0; i < action.payload.data.length; i++) {
          const incident_info = action.payload.data[i];
          if (incident_info["type"] === "Fire/Smoke") {
            state.fire_data.push({ data: 1, label: incident_info.floor });
            state.fire_timestamps.push(Date.parse(incident_info.created_at));
          } else if (incident_info["type"] === "Fall") {
            state.fall_data.push({ data: 1, label: incident_info.floor });
            state.fall_timestamps.push(Date.parse(incident_info.created_at));
          } else if (incident_info["type"] === "loitering") {
            state.loitering_data.push({ data: 1, label: incident_info.floor });
            state.loitering_timestamps.push(Date.parse(incident_info.created_at));
          }
        }
      })
      .addCase(getIncident.rejected, (state) => {});

    builder
      .addCase(getIncidentAlerts.pending, (state) => {})
      .addCase(getIncidentAlerts.fulfilled, (state, action) => {
        state.count_alert_read = action.payload.total;
        state.pending_alerts = [];
        state.today_alerts = [];
        state.yesterday_alerts = [];
        for (let i = 0; i < action.payload.data.length; i++) {
          const incident_info = action.payload.data[i];
          const alert = {
            id: incident_info._id,
            location: incident_info.location,
            floor: incident_info.floor,
            clip: incident_info.clip,
            camera_id: incident_info.camera_id,
            camera_name: incident_info.camera_name,
            emergencies: {
              police: "999",
              firefighter: "995",
              ambulance: "995",
            },
            site_name: incident_info.site_name,
            seen: incident_info.seen,
            rtsp: incident_info.rtsp,
          };
          if (incident_info["type"] === "Fire/Smoke") {
            alert["type"] = "Fire/Smoke";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          } else if (incident_info["type"] === "Fall") {
            alert["type"] = "Fall";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          } else if (incident_info["type"] === "loitering") {
            alert["type"] = "loitering";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          } else if (incident_info["type"] === "Unguarded") {
            alert["type"] = "Unguarded";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          }
          if (incident_info.seen) {
            if (
              moment(new Date(incident_info.created_at)).format("YYYY-MM-DD") >=
              moment().format("YYYY-MM-DD")
            ) {
              state.today_alerts.push(alert);
            } else {
              state.yesterday_alerts.push(alert);
            }
          } else {
            state.pending_alerts.push(alert);
          }
        }
        state.isLoading = false;
      })
      .addCase(getIncidentAlerts.rejected, (state) => {
        state.isLoading = false;
      });

      // builder
      //   .addCase(getPendingIncidentAlerts.pending, (state) => { })
      //   .addCase(getPendingIncidentAlerts.fulfilled, (state, action) => {
      //     state.pending_alerts = [];
      //     for (let i = 0; i < action.payload.data.length; i++) {
      //       const incident_info = action.payload.data[i];
      //       const alert = {
      //         id: incident_info._id,
      //         location: incident_info.location,
      //         floor: incident_info.floor,
      //         clip: incident_info.clip,
      //         camera_id: incident_info.camera_id,
      //         camera_name: incident_info.camera_name,
      //         emergencies: {
      //           police: "999",
      //           firefighter: "995",
      //           ambulance: "995",
      //         },
      //         site_name: incident_info.site_name,
      //         seen: false,
      //       };
      //       if (incident_info["type"] === "Fire/Smoke") {
      //         alert["type"] = "Fire/Smoke";
      //         alert["start_time"] = incident_info.created_at;
      //         alert["end_time"] = incident_info.created_at;
      //       } else if (incident_info["type"] === "Fall") {
      //         alert["type"] = "Fall";
      //         alert["start_time"] = incident_info.created_at;
      //         alert["end_time"] = incident_info.created_at;
      //       } else if (incident_info["type"] === "loitering") {
      //         alert["type"] = "loitering";
      //         alert["start_time"] = incident_info.created_at;
      //         alert["end_time"] = incident_info.created_at;
      //       }
      //       state.pending_alerts.push(alert);
      //     }
      //     state.isLoading = false;
      //   })
      //   .addCase(getPendingIncidentAlerts.rejected, (state) => {
      //     state.isLoading = false;
      //   });

      // builder
      //   .addCase(getNonPendingIncidentAlerts.pending, (state) => { })
      //   .addCase(getNonPendingIncidentAlerts.fulfilled, (state, action) => {
      //     state.non_pending_alerts = [];
      //     for (let i = 0; i < action.payload.data.length; i++) {
      //       const incident_info = action.payload.data[i];
      //       const alert = {
      //         id: incident_info._id,
      //         location: incident_info.location,
      //         floor: incident_info.floor,
      //         clip: incident_info.clip,
      //         camera_id: incident_info.camera_id,
      //         camera_name: incident_info.camera_name,
      //         emergencies: {
      //           police: "999",
      //           firefighter: "995",
      //           ambulance: "995",
      //         },
      //         site_name: incident_info.site_name,
      //         seen: true,
      //         rtsp: incident_info.rtsp
      //       };
      //       if (incident_info["type"] === "Fire/Smoke") {
      //         alert["type"] = "Fire/Smoke";
      //         alert["start_time"] = incident_info.created_at;
      //         alert["end_time"] = incident_info.created_at;
      //       } else if (incident_info["type"] === "Fall") {
      //         alert["type"] = "Fall";
      //         alert["start_time"] = incident_info.created_at;
      //         alert["end_time"] = incident_info.created_at;
      //       } else if (incident_info["type"] === "loitering") {
      //         alert["type"] = "loitering";
      //         alert["start_time"] = incident_info.created_at;
      //         alert["end_time"] = incident_info.created_at;
      //       }
      //       state.non_pending_alerts.push(alert);
    builder
      .addCase(getPendingIncidentAlerts.pending, (state) => {})
      .addCase(getPendingIncidentAlerts.fulfilled, (state, action) => {
        state.pending_alerts = [];
        for (let i = 0; i < action.payload.data.length; i++) {
          const incident_info = action.payload.data[i];
          const alert = {
            id: incident_info._id,
            location: incident_info.location,
            floor: incident_info.floor,
            clip: incident_info.clip,
            camera_id: incident_info.camera_id,
            camera_name: incident_info.camera_name,
            emergencies: {
              police: "999",
              firefighter: "995",
              ambulance: "995",
            },
            site_name: incident_info.site_name,
            seen: false,
          };
          if (incident_info["type"] === "Fire/Smoke") {
            alert["type"] = "Fire/Smoke";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          } else if (incident_info["type"] === "Fall") {
            alert["type"] = "Fall";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          } else if (incident_info["type"] === "loitering") {
            alert["type"] = "loitering";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          } else if (incident_info["type"] === "Unguarded") {
            alert["type"] = "Unguarded";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          }
          // if (!incident_info.seen) {
          state.pending_alerts.push(alert);
          // }
        }
        state.isLoading = false;
      })
      .addCase(getPendingIncidentAlerts.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(getNonPendingIncidentAlerts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNonPendingIncidentAlerts.fulfilled, (state, action) => {
        state.count_alert_read = action.payload.total;
        state.non_pending_alerts = [];
        for (let i = 0; i < action.payload.data.length; i++) {
          const incident_info = action.payload.data[i];
          const alert = {
            id: incident_info._id,
            location: incident_info.location,
            floor: incident_info.floor,
            clip: incident_info.clip,
            camera_id: incident_info.camera_id,
            camera_name: incident_info.camera_name,
            emergencies: {
              police: "999",
              firefighter: "995",
              ambulance: "995",
            },
            site_name: incident_info.site_name,
            seen: true,
            rtsp: incident_info.rtsp,
          };
          if (incident_info["type"] === "Fire/Smoke") {
            alert["type"] = "Fire/Smoke";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          } else if (incident_info["type"] === "Fall") {
            alert["type"] = "Fall";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          } else if (incident_info["type"] === "loitering") {
            alert["type"] = "loitering";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          } else if (incident_info["type"] === "Unguarded") {
            alert["type"] = "Unguarded";
            alert["start_time"] = incident_info.created_at;
            alert["end_time"] = incident_info.created_at;
          }
          state.non_pending_alerts.push(alert);
        }
        state.isLoading = false;
      })
      .addCase(getNonPendingIncidentAlerts.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(getIncidentAnalytics.fulfilled, (state, action) => {
        switch (action.payload.type) {
          case INCIDENT_TYPE.FIRE:
            state.incident_fire_analytics = action.payload.analytics;
            state.latest_fire_incidents = action.payload.latestData;
            break;
          case INCIDENT_TYPE.FALL:
            state.incident_fall_analytics = action.payload.analytics;
            state.latest_fall_incidents = action.payload.latestData;

            break;
          case INCIDENT_TYPE.LOITERING:
            state.incident_loitering_analytics = action.payload.analytics;
            state.latest_loitering_incidents = action.payload.latestData;
            break;
          default:
            break;
        }
      })
      .addCase(getIncidentAnalytics.rejected, (state) => {})
      .addCase(getIncidentAnalytics.pending, (state) => {});

    builder
      .addCase(getIncidentCount.fulfilled, (state, action) => {
        state.incident_count = action.payload.incidents;
      })
      .addCase(getIncidentCount.pending, (state) => {
        state.incident_count = [];
      })
      .addCase(getIncidentCount.rejected, (state) => {
        state.incident_count = [];
      });

    builder
      .addCase(readIncident.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(readIncident.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readIncident.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(getIncidentCountAll.fulfilled, (state, action) => {
        state.incident_count_all = action.payload.incidents;
      })
      .addCase(getIncidentCountAll.pending, (state) => {
        state.incident_count_all = 0;
      })
      .addCase(getIncidentCountAll.rejected, (state) => {
        state.incident_count_all = 0;
      });

    builder
      .addCase(getIncidentSummary.fulfilled, (state, action) => {
        state.incident_summary_total[action.payload.type] = action.payload.total;
        state.incident_summary_data[action.payload.type] = action.payload.data.reduce(
          (acc, current) => {
            acc[current.floor] = parseInt(current.count, 10);
            return acc;
          },
          {}
        );
      })
      .addCase(getIncidentSummary.pending, (state) => {})
      .addCase(getIncidentSummary.rejected, (state) => {});

    builder
      .addCase(getLatestLocation.fulfilled, (state, action) => {
        state.lastest_camera = action.payload;
      })
      .addCase(getLatestLocation.pending, (state) => {
      })
      .addCase(getLatestLocation.rejected, (state) => {
      });

    builder
      .addCase(exportIncident.pending, (state) => {
        state.showLoadingToast = true;
      })
      .addCase(exportIncident.fulfilled, (state) => {
        state.showLoadingToast = false;
      })
      .addCase(exportIncident.rejected, (state) => {
        state.showLoadingToast = false;
      });
  },
});

export const { resetincidentStore, resetIncidentToast } = incidentSlice.actions;
export default incidentSlice.reducer;
