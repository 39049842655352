import React, { useState } from "react";

import { CevronUp, CheckCircle, Exclamation } from "~/svg";

const LevelItems = ({ idx, it, cameras, openCameraModal }) => {
  const [expand, setExpand] = useState(false);
  return (
    <div>
      <div className="w-full flex px-7 py-[17px] text-neutral-200 items-center justify-between border-t-[1px] border-t-neutral-800">
        <div className="flex items-center gap-2">
          <p className="text-[18px] font-semibold">{`Level ${it}`}</p>
          <CheckCircle />
        </div>
        <div
          data-testid="button-expand"
          onClick={() => setExpand(!expand)}
          className={`cursor-pointer ${expand ? "rotate-0" : "rotate-180"}`}
        >
          <CevronUp />
        </div>
      </div>
      {expand ? (
        <>
          {cameras[it] &&
            cameras[it].map((it, idx) => (
              <div
                data-testid="camera-item"
                key={idx}
                className="w-full flex px-7 py-[17px] text-neutral-200 items-center border-t-[1px] border-t-neutral-800 gap-2"
              >
                <div className="w-[33%] md:w-1/4">
                  <p className="w-full truncate">{it.name}</p>
                </div>
                <div className="w-[33%] md:w-[30%]">
                  <p className="w-full truncate">{it.proxyStream2}</p>
                </div>
                {(it.boxes && (
                  <div className="w-[33%] m:w-[45%] flex items-center justify-between">
                    <div className="flex itedms-center gap-2">
                      <CheckCircle />
                      <p className="w-full truncate hidden md:block">Working</p>
                    </div>
                    <p
                      className="font-semibold text-neutral-300 cursor-pointer"
                      onClick={() => openCameraModal(it)}
                    >
                      Reset Cam
                    </p>
                  </div>
                )) || (
                  <div className="w-[33%] m:w-[45%] flex items-center justify-between">
                    <div className="flex itedms-center gap-2">
                      <Exclamation />
                      <p className="w-full truncate hidden md:block">Not set yet</p>
                    </div>
                    <p
                      className="font-semibold text-neutral-300 cursor-pointer"
                      onClick={() => openCameraModal(it)}
                    >
                      Set Camera
                    </p>
                  </div>
                )}
              </div>
            ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LevelItems;
