import Home from './home'
import Vms from './vms'
import Login from './login'
import Video from './video'
import Alerts from './alert'
import Search from './search'
import Setting from './settings'
import Analytics from './analytics'
import UploadedVideos from './uploaded'
import AnalyticsDetail from './analytics/detail'
import Sites from './sites' 

export { Login, Home, Search, Alerts, Setting, Video, Analytics, UploadedVideos, AnalyticsDetail, Sites, Vms }
