import React from 'react'

const AccuracyBadges = ({ accuracy }) => {
  return (
    <>
      <div
        className={`rounded-[4px] h-[24px] px-2 py-1  ${
          accuracy > 79
            ? 'text-success-900 bg-[#B0FCA9A3]'
            : accuracy > 59
            ? 'text-warning-900 bg-[#FEE895A3]'
            : 'text-neutral-800 bg-[#ECF4FDA3]'
        }`}>
        <p className='text-base font-normal leading-4'>{Math.round(accuracy) + '%'}</p>
      </div>
    </>
  )
}

export default AccuracyBadges
