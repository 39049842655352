import React from 'react'

function Pip() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <g clipPath='url(#clip0_705_6360)'>
        <path
          fill='#fff'
          d='M19 12a1 1 0 00-1-1h-6a1 1 0 00-1 1v4a1 1 0 001 1h6a1 1 0 001-1v-4zm4 7V4.98C23 3.88 22.1 3 21 3H3c-1.1 0-2 .88-2 1.98V19c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2zm-2 .02H3V4.97h18v14.05z'></path>
      </g>
      <defs>
        <clipPath id='clip0_705_6360'>
          <path fill='#fff' d='M0 0H24V24H0z'></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Pip
