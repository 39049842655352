import React, { useEffect } from 'react';
import { useDatepicker, START_DATE } from '@datepicker-react/hooks';

import { Month } from './components';
import DatepickerContext from './context';
import Button from '../button';

function Datepicker({
  closeModal,
  state,
  setState,
  isSingleDate,
  isBlock,
  minDate,
}) {
  const {
    isEndDate,
    isStartDate,
    focusedDate,
    onDateHover,
    onDateFocus,
    activeMonths,
    onDateSelect,
    isDateHovered,
    isDateBlocked,
    isDateFocused,
    firstDayOfWeek,
    isDateSelected,
    isFirstOrLastSelectedDate,
    goToNextMonthsByOneMonth,
    goToPreviousMonthsByOneMonth,
    goToDate,
  } = useDatepicker({
    endDate: state.endDate,
    minBookingDate: minDate,
    startDate: state.startDate,
    onDatesChange: handleDateChange,
    focusedInput: state.focusedInput,
    changeActiveMonthOnSelect: true,
  });

  const now = new Date();
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  function dateToString(date, isStart) {
    if (date === null) {
      return isStart ? 'Start' : 'Today';
    }
    const arrDate = date.toString().split(' ');
    return `${arrDate[2]} ${arrDate[1].substring(0, 3)} ${arrDate[3]}`;
  }

  function handleDateChange(data) {
    // it will use end date for single date
    if (isSingleDate) {
      setState({
        startDate: null,
        endDate: data.startDate,
        focusedInput: START_DATE,
      });
      return;
    }

    if (data.endDate) {
      const date = new Date(data.endDate);
      date.setHours(23, 59, 59, 999);
      data.endDate = date;
    }

    if (!data.focusedInput) {
      setState({ ...data, focusedInput: START_DATE });
    } else {
      setState(data);
    }
    if (
      data.endDate !== null &&
      data.startDate !== null &&
      data.focusedInput === null
    ) {
      closeModal();
    }
  }

  useEffect(() => {
    if (state.endDate) {
      goToDate(state.endDate);
    }
  }, [state.endDate]);

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        onDateFocus,
        onDateHover,
        onDateSelect,
        isDateFocused,
        isDateHovered,
        isDateBlocked,
        isDateSelected,
        isFirstOrLastSelectedDate,
      }}
    >
      <div
        className={`${
          isBlock ? '' : 'absolute -left-16 z-50 w-64'
        }  rounded-lg bg-neutral-900 w-80`}
      >
        <div className="px-4 my-3">
          {!isSingleDate && (
            <div className="w-full flex justify-end items-center gap-1">
              <div
                className={
                  'w-full py-2 rounded-md flex justify-center items-center text-center border-[1px]'
                }
              >
                <p className="text-base text-neutral-200">
                  {dateToString(state.startDate, true)}
                </p>
              </div>
              <p className="text-base text-neutral-200">-</p>
              <div
                className={
                  'w-full py-2 rounded-md flex justify-center items-center text-center border-[1px]'
                }
              >
                <p className="text-base text-neutral-200">
                  {dateToString(state.endDate)}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="pb-1">
          <Month
            isSingleDate={isSingleDate}
            year={activeMonths[0].year}
            month={activeMonths[0].month}
            isEndDate={isEndDate}
            isStartDate={isStartDate}
            firstDayOfWeek={firstDayOfWeek}
            key={`${activeMonths[0].year}-${activeMonths[0].month}`}
            goToNextMonthsByOneMonth={goToNextMonthsByOneMonth}
            goToPreviousMonthsByOneMonth={goToPreviousMonthsByOneMonth}
          />
        </div>
        <Button
          type={'primary'}
          onClick={() =>
            setState({ ...state, endDate: now, startDate: todayStart })
          }
        >
          Reset
        </Button>
      </div>
    </DatepickerContext.Provider>
  );
}

export default Datepicker;
