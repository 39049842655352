import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { NoAlertToShow, Refresh } from "~/svg";
import { Button, StatusBadges, VideoPlayer } from "~/components";

import HistoryItems from "../history-items";
import { STATUS } from "~/constant";
import RSTPVideoStream from "../video-stream";

const DesktopContent = ({
  notes,
  typeToStr,
  historyLog,
  dateToStatus,
  alertVideoRef,
  selectedAlert,
  personLastSeen,
  openReportModal,
  getDifferentTimes,
  handleChangeNotes,
  fetchPersonLastSeen,
  isLoadingPersonLastSeen,
}) => {
  const navigate = useNavigate();

  const mediaUrl = process.env.MEDIA_PATH;

  const [delay, setDelay] = useState("Loading...");

  useEffect(() => {
    if(personLastSeen !== undefined){
      setDelay(personLastSeen.name);
    }
  }, [personLastSeen]);

  const handleClick = () => {
    setDelay('Loading...');

    setTimeout(() => {
      // Simulating an asynchronous operation
      fetchPersonLastSeen(selectedAlert.id);
    }, 2000);
  };

  const [toggleModal, setToggleModal] = useState(false);

  return (
    <div className="hidden md:block flex-auto">
      {selectedAlert === undefined ? (
        <div className="h-full w-full flex flex-col justify-center items-center">
          <NoAlertToShow />
          <p className="text-xl font-semibold text-neutral-50">
            No Alert to Show
          </p>
          <p className="mt-4 text-sm text-neutral-200">
            Select an incident alert from the left panel.
          </p>
        </div>
      ) : (
        <div className="w-full h-full md:overflow-y-scroll no-scrollbar">
          <div className="w-full p-9">
            <div className="flex justify-start items-center gap-2">
              <p className="text-neutral-200 font-semibold text-xl md:text-2xl">
                {typeToStr(selectedAlert.type)}{" "}
              </p>
              <StatusBadges
                big
                status={selectedAlert.seen ? STATUS.RESOLVED : STATUS.PENDING}
              />
              {/* <StatusBadges big status={dateToStatus(selectedAlert.start_time)} /> */}
            </div>
            <div className="w-full flex mt-6 gap-6">
              <div className="w-[640px]">
                {selectedAlert.clip.endsWith(".mp4") ? (
                  <VideoPlayer
                    incident_name={moment(
                      new Date(selectedAlert.start_time)
                    ).format("DD-MMM-YYYY HH:mm:ss")}
                    playerRef={alertVideoRef}
                    url={`${mediaUrl}/api/video/media/${selectedAlert.clip}`}
                  />
                ) : (
                  <img src={`${mediaUrl}/api/video/media/${selectedAlert.clip}`}/>
                )}
              </div>
              <div className="flex-auto">
                <p className="text-neutral-200">Floor</p>
                <p className="cursor-pointer text-neutral-50 font-semibold text-[18px] mt-2">
                  {selectedAlert.floor}
                </p>
                <p className="mt-8 text-neutral-200">Camera</p>
                <p className="text-neutral-200 font-semibold text-[18px] mt-2">
                  {selectedAlert.camera_name}
                </p>
                <p className="text-neutral-200 mt-8">Date, time</p>
                <p className="text-neutral-50 font-semibold text-[18px] mt-2">
                  {moment(new Date(selectedAlert.start_time)).format(
                    "DD MMM YYYY"
                  )}
                </p>
                <p className="text-neutral-50 font-semibold text-[18px] mt-[2px]">
                  {moment(new Date(selectedAlert.start_time)).format(
                    "HH:mm:ss"
                  )}
                </p>
                <p className="text-neutral-200 mt-8">Person last seen</p>
                <div className="flex justify-start items-center gap-2 mt-2">
                  <p className="text-neutral-50 font-semibold text-[18px] cursor-pointer" onClick={() => {setToggleModal((prevState => !prevState))}}>
                    {delay}
                  </p>
                  <div
                    className="cursor-pointer"
                    onClick={() => handleClick()}
                  >
                    <Refresh />
                  </div>
                </div>
              </div>
              <div className="flex w-[640px]">
                {personLastSeen !== undefined && selectedAlert !== undefined && toggleModal ? (<RSTPVideoStream
                  key={selectedAlert.camera_name}
                  name={selectedAlert.camera_name}
                  url={personLastSeen.rtspStream}
                  className="aspect-w-16 aspect-h-9 w-full"
                />) : (
                <>
                </>
              )}
              </div>
            </div>
          </div>
        </div>
       
      )}
    </div>
  );
};

export default DesktopContent;
