import React from 'react'

function FireNotifications() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none' viewBox='0 0 32 32'>
      <path
        stroke='#F5FAFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M23.542 24.876c-4.165 4.165-10.919 4.165-15.085 0a10.633 10.633 0 01-3.124-7.543c0-2.73 1.042-5.46 3.124-7.542 0 0 .876 2.209 3.543 3.542 0-2.666.667-6.666 3.981-9.333 2.686 2.667 5.474 3.703 7.561 5.79a10.633 10.633 0 013.125 7.543c0 2.73-1.042 5.46-3.125 7.543z'></path>
      <path
        stroke='#F5FAFF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M13.171 21.495a4 4 0 102.848-6.828l-1.352 4H12c0 1.023.39 2.047 1.171 2.828z'></path>
    </svg>
  )
}

export default FireNotifications
