import React, { useRef, useEffect } from 'react'

const Canvas = ({
  width,
  scale,
  images,
  height,
  cameras,
  dynamic,
  withScale,
  withLiveFeed,
  openLiveFeed,
  closeLiveFeed,
}) => {
  const canvasRef = useRef()

  // some constant style for canvas and images
  const canvasStyle = { position: 'absolute', left: 0, top: 0 }
  const imgSize = {
    width: `${withScale ? scale * width : width}px`,
    height: `${withScale ? scale * height : height}px`,
  }
  const imgStyle = { left: 0, top: 0, zIndex: 4, opacity: 0, position: 'absolute' }

  function renderRef() {
    // get canvas ref
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    return { canvas, ctx }
  }

  function initCanvas() {
    const { canvas, ctx } = renderRef()

    renderCanvas(canvas, ctx)
  }

  function renderCanvas(canvas, ctx) {
    // create camera image
    const image = new Image()
    image.src = '/cam.svg'

    // set canvas size
    canvas.width = withScale ? scale * width : width
    canvas.height = withScale ? scale * height : height

    // map data and add image by x and y
    image.onload = () => {
      const imgWidth = withScale && scale > 1 ? scale * image.width : image.width
      const imgHeight = withScale && scale > 1 ? scale * image.height : image.height

      cameras.forEach((it) => {
        const scaleX = withScale ? scale * it.x : it.x
        const scaleY = withScale ? scale * it.y : it.y
        ctx.drawImage(image, scaleX, scaleY, imgWidth, imgHeight)
      })
    }
  }

  function xyToPoly(x, y) {
    // change xy to polygon shape for even handler
    const scalePos = withScale ? scale * 32 : 32
    return [x, y, x + scalePos, y, x + scalePos, y + scalePos, x, y + scalePos].join(',')
  }

  function mapArea() {
    // return area of camera to add even handler
    return cameras.map((it, idx) => (
      <Area
        key={idx}
        data={it}
        shape={'poly'}
        coor={withScale ? xyToPoly(scale * it.x, scale * it.y) : xyToPoly(it.x, it.y)}
        openLiveFeed={
          withLiveFeed
            ? openLiveFeed
            : () => {
                return
              }
        }
        closeLiveFeed={
          withLiveFeed
            ? closeLiveFeed
            : () => {
                return
              }
        }
      />
    ))
  }

  useEffect(() => {
    initCanvas()
  }, [])

  useEffect(() => {
    if (dynamic) {
      initCanvas()
    }
  }, [cameras])

  return (
    <div className='relative select-none' style={imgSize}>
      {/* floor image to show */}
      <img alt='img' src={images} style={{ ...canvasStyle, zIndex: 1 }} />
      {/* floor image to handle event : not show */}
      <img
        alt='imgMap'
        src={images}
        useMap='#imgMap'
        style={{
          ...imgSize,
          ...imgStyle,
        }}
      />
      {/* canvas for mapping camera place */}
      <canvas ref={canvasRef} style={{ ...canvasStyle, zIndex: 2 }} />
      {/* map for mapping area components */}
      <map name='imgMap'>{mapArea()}</map>
    </div>
  )
}

export default Canvas

const Area = ({ shape, coor, data, openLiveFeed, closeLiveFeed }) => {
  function handleHover(e) {
    // preventDefault is always needed for area even handler
    e.preventDefault()
    openLiveFeed(data)
  }
  function handleLeave(e) {
    // preventDefault is always needed for area even handler
    e.preventDefault()
    closeLiveFeed()
  }
  return (
    <area
      className='cursor-pointer'
      shape={shape}
      coords={coor}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    />
  )
}
