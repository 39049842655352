import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  status: 'idle',
}

// asynchronous function with createAsyncThunk
export const incrementAsync = createAsyncThunk(
  'posts/addPost',
  async (post, { rejectWithValue }) => {
    try {
      return await axios
        .post('https://jsonplaceholder.typicode.com/posts', {
          post,
        })
        .then((res) => {
          return res.data
        })
    } catch (err) {
      // You can choose to use the message attached to err or write a custom erroraler()
      // return rejectWithValue('Opps there seems to be an error') willl accetp in action.payload
      return rejectWithValue()
    }
  },
)

// Redux Toolkit slice
export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    resetCounterState: () => initialState,
  },

  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle'
        state.value += action.payload.post
      })
      .addCase(incrementAsync.rejected, (state) => {
        state.status = 'rejected'
      })
  },
})

export const { increment, decrement, incrementByAmount, resetCounterState } = counterSlice.actions
export default counterSlice.reducer
