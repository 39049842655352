import React, { useState } from 'react'

import { EyeIcon, IconX } from '~/svg'
import { Button, ButtonLoading, Input, Modal } from '~/components'

const EditUserModal = ({
  formEdit,
  editModal,
  dataProfile,
  editLoading,
  setEditModal,
  handleChangeEdit,
  submitEditUser,
}) => {
  const [hideState, setHide] = useState({
    email: true,
    phone: true,
  })
  function actionHide(e) {
    switch (e.currentTarget.id) {
      case 'email':
        setHide(() => ({
          ...hideState,
          email: !hideState.email,
        }))
        break
      case 'phone':
        setHide(() => ({
          ...hideState,
          phone: !hideState.phone,
        }))
        break
      default:
        break
    }
  }
  return (
    <Modal isOpen={editModal} closeModal={() => setEditModal(false)}>
      <div className='flex flex-col py-2 px-8 w-[420px]'>
        <div className='flex flex-row w-full justify-between max-md:border-b max-md:border-neutral-800 mt-3'>
          <p className='w-full text-neutral-50 font-semibold text-sm md:text-xl text-center md:text-left my-5'>
            Edit Account Information
          </p>
          <div
            data-testid='iconx'
            className='flex max-md:hidden cursor-pointer'
            onClick={() => setEditModal(false)}>
            <IconX />
          </div>
        </div>
        <div className='flex flex-row items-center gap-4 mt-6'>
          <img
            alt='ailiverse_logo'
            src='public/ailiverse_logo.png'
            style={{ width: '32px', height: '32px' }}
          />
          <div className='w-fit'>
            <Button type={'primary'}>Upload Image</Button>
          </div>
        </div>
        <p className='pt-5 font-semibold text-neutral-200 text-left mb-2'>Name</p>
        <Input
          name={'fullname'}
          type={'text'}
          value={formEdit.fullname}
          onChange={handleChangeEdit}
        />
        <div className='flex flex-row justify-between mt-5'>
          <p data-testid='test-email' className='text-neutral-400'>
            Email: {hideState.email ? dataProfile.email : formEdit.email}
          </p>
          <div data-testid='eye-email' id='email' className='cursor-pointer' onClick={actionHide}>
            <EyeIcon />
          </div>
        </div>
        <div className='flex flex-row justify-between mt-5'>
          <p data-testid='test-phone' className='text-neutral-400'>
            Phone number:
            {` ${dataProfile.country_code}${
              hideState.phone ? dataProfile.phone_number : formEdit.phone_number
            }`}
          </p>
          <div data-testid='eye-phone' id='phone' className='cursor-pointer' onClick={actionHide}>
            <EyeIcon />
          </div>
        </div>
        <div className='mt-11 py-5 md:mt-4 flex md:flex-row flex-col justify-end w-full gap-2'>
          <div className='w-full md:w-fit'>
            <Button
              data-testid='button-cancel'
              type={'secondary'}
              onClick={() => setEditModal(false)}>
              Cancel
            </Button>
          </div>
          <div className='w-full md:w-fit'>
            <Button data-testid='button-save' type={'primary'} onClick={() => submitEditUser()}>
              <div className='flex items-center gap-2'>
                <p> Save Changes</p>
                {editLoading ? <ButtonLoading /> : <></>}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditUserModal
