import React, { useRef, useState } from "react";

import { EyeIcon, IconX } from "~/svg";
import { Button, ButtonLoading, Datepicker, Input, Modal } from "~/components";
import { ANALYTICS_TYPE, EXPORT_TYPE } from "~/constant";

// import "./index.css";
import { useOutsideClick } from "~/utils";
import { useExportModal } from "./hooks";

const ExportModal = ({ exportType, exportModal, closeModalExport, editLoading }) => {
  const {
    datas: {
      dateRange,
      displayDate,
      showDatePicker,
      selectedOption,
      hourExportState,
      selectedCheckboxes,
    },
    methods: {
      submitExport,
      setDateRange,
      handleHourChange,
      toggleDatePicker,
      handleOptionChange,
      handleCheckboxChange,
    },
  } = useExportModal({ exportType });

  return (
    <Modal notOverflow isOpen={exportModal} closeModal={closeModalExport}>
      <div className="flex flex-col py-2 px-8 w-[500px]">
        <div className="flex flex-row w-full justify-between max-md:border-b max-md:border-neutral-800 mt-3">
          <p className="w-full text-neutral-50 font-semibold text-sm md:text-xl text-center md:text-left my-5">
            Export {exportType === EXPORT_TYPE.IMAGE ? "Graphs" : "Analytics"}
          </p>
          <div
            data-testid="iconx"
            className="flex max-md:hidden cursor-pointer"
            onClick={closeModalExport}
          >
            <IconX />
          </div>
        </div>
        <p className="text-sm text-neutral-200">
          Export{" "}
          {exportType === EXPORT_TYPE.IMAGE
            ? "Select all analytic group graphs you'd like to export. The exported file(s) will be PNG."
            : "Select all analytic group data you'd like to export. The exported file(s) will be CSV."}
        </p>
        <div className="flex flex-col mt-8 gap-2">
          {selectedCheckboxes.map(({ name, checked }) => (
            <div key={name} className="flex gap-2">
              <CheckboxButton isChecked={checked} name={name} setCheckbox={handleCheckboxChange} />
              <p className="text-sm text-neutral-200">{name}</p>
            </div>
          ))}
        </div>
        <p className="text-sm text-neutral-200 mt-5">What time period do you want to export? </p>
        <div className="flex flex-col gap-2 mt-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio radio-button"
              name="time-period"
              value="today"
              checked={selectedOption == "today"}
              onChange={handleOptionChange}
            />
            <span className="ml-2 text-sm text-neutral-50">Today</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio radio-button"
              name="time-period"
              value="week"
              checked={selectedOption == "week"}
              onChange={handleOptionChange}
            />
            <span className="ml-2 text-sm text-neutral-50">
              This week (dd/mm/yyyy - dd/mm/yyyy)
            </span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio radio-button"
              name="time-period"
              value="month"
              checked={selectedOption == "month"}
              onChange={handleOptionChange}
            />
            <span className="ml-2 text-sm text-neutral-50">This month (mm)</span>
          </label>
          <label className="inline-flex items-start">
            <input
              type="radio"
              className="form-radio radio-button"
              name="time-period"
              value="custom"
              checked={selectedOption == "custom"}
              onChange={handleOptionChange}
            />
            <div className="flex flex-col w-full">
              <span className="ml-2 text-sm text-neutral-50">Specific date and time</span>
              <span className="ml-2 text-sm text-neutral-50 font-semibold mt-2">Date</span>
              <div className="w-full mt-1">
                <button
                  data-testid="dropdown-button"
                  className="w-1/2 px-4 py-2 flex flex-row justify-between items-center bg-grey-800 border border-grey-600 rounded-lg"
                  onClick={toggleDatePicker}
                >
                  <p className="leading-loose text-neutral-50">{displayDate}</p>
                </button>
                {showDatePicker ? (
                  <div className="absolute bg-neutral-900 z-50">
                    <Datepicker
                      state={dateRange}
                      show={showDatePicker}
                      setState={setDateRange}
                      closeModal={toggleDatePicker}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-row gap-2 mt-2">
                <div className="flex flex-col gap-1 w-full">
                  <p className="text-neutral-200 font-semibold text-">From</p>
                  <Input
                    type={"time"}
                    value={hourExportState.startHour}
                    name="startHour"
                    onChange={handleHourChange}
                  />
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <p className="text-neutral-200 font-semibold text-">To</p>
                  <Input
                    type={"time"}
                    value={hourExportState.endHour}
                    name="endHour"
                    onChange={handleHourChange}
                  />
                </div>
              </div>
            </div>
          </label>
        </div>
        <div className="mt-11 py-5 md:mt-4 flex md:flex-row flex-col justify-end w-full gap-2">
          <div className="w-full md:w-fit">
            <Button data-testid="button-cancel" type={"secondary"} onClick={closeModalExport}>
              Cancel
            </Button>
          </div>
          <div className="w-full md:w-fit">
            <Button data-testid="button-save" type={"primary"} onClick={submitExport}>
              <div className="flex items-center gap-2">
                <p>
                  Export {exportType === EXPORT_TYPE.IMAGE ? "Graphs" : "Analytics"}(
                  {selectedCheckboxes.filter((item) => item.checked).length})
                </p>
                {editLoading ? <ButtonLoading /> : <></>}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

{
  /* checkbox component */
}
const CheckboxButton = ({ isChecked, name, setCheckbox }) => {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="hidden"
        name={name}
        checked={isChecked}
        onChange={setCheckbox}
      />
      <div
        className={`w-4 h-4 flex items-center justify-center border-2 rounded ${
          isChecked ? `bg-primary-500 border-transparent` : "border-gray-300"
        }`}
      >
        {/* Checkmark */}
        {isChecked && (
          <svg className="fill-current text-black w-4 h-4" viewBox="0 0 20 20">
            <path d="M16.707,5.293a1,1,0,0,1,0,1.414l-8,8a1,1,0,0,1-1.414,0l-4-4a1,1,0,0,1,1.414-1.414L8,12.586l7.293-7.293a1,1,0,0,1,1.414,0Z" />
          </svg>
        )}
      </div>
    </label>
  );
};

export default ExportModal;
