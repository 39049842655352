import React from 'react'

function Filter() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M2.5 3.5a1 1 0 011-1h13a1 1 0 011 1v1.92a1 1 0 01-.293.706l-5.247 5.248a1 1 0 00-.293.707v2.086L8.333 17.5v-5.42a1 1 0 00-.293-.706L2.793 6.126A1 1 0 012.5 5.42V3.5z'></path>
    </svg>
  )
}

export default Filter
