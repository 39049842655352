import React from 'react'

function NoIncidents() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='217'
      height='171'
      fill='none'
      viewBox='0 0 217 171'>
      <path
        fill='#105F9C'
        d='M176.239 46.526l-5.777-5.777 5.777-5.776 5.777 5.776-5.777 5.777zm-5.053-5.777l5.053 5.048 5.047-5.048-5.047-5.052-5.053 5.052zM54.91 25.478l-5.776-5.777 5.776-5.776 5.772 5.776-5.772 5.777zm-5.047-5.777l5.047 5.048 5.053-5.048-5.053-5.052-5.047 5.052zM19.204 98.597l-5.762-5.776 5.777-5.777 5.761 5.776-5.776 5.777zm-5.048-5.776l5.048 5.052 5.052-5.053-5.052-5.047-5.048 5.047zM46.608 81.519L43.64 78.55l2.968-2.968 2.967 2.968-2.968 2.968zm-2.244-2.968l2.244 2.244 2.238-2.244-2.238-2.239-2.244 2.239zM183.72 49.175l-2.968-2.968 2.968-2.968 2.968 2.968-2.968 2.968zm-2.238-2.968l2.238 2.239 2.244-2.239-2.244-2.244-2.238 2.244zM133.729 18.562l-2.968-2.968 2.968-2.968 2.947 2.968-2.947 2.968zm-2.244-2.968l2.244 2.244 2.238-2.244-2.238-2.239-2.244 2.239zM171.746 139.954l-2.968-2.968 2.968-2.967 2.968 2.967-2.968 2.968zm-2.239-2.968l2.239 2.239 2.244-2.239-2.244-2.243-2.239 2.243zM77.287 158.373l-2.968-2.968 2.968-2.967 2.968 2.967-2.968 2.968zm-2.244-2.968l2.244 2.244 2.244-2.244-2.244-2.238-2.244 2.238zM134.201 69.045H80.554c-9.172 0-16.606 7.435-16.606 16.606 0 9.17 7.434 16.605 16.605 16.605h53.648c9.171 0 16.606-7.434 16.606-16.605s-7.435-16.606-16.606-16.606z'></path>
      <path
        fill='#ABDAFE'
        d='M134.427 104.295c10.3 0 18.65-8.35 18.65-18.65 0-10.3-8.35-18.649-18.65-18.649-10.3 0-18.649 8.35-18.649 18.65 0 10.3 8.349 18.649 18.649 18.649z'></path>
      <path
        fill='#105F9C'
        d='M134.427 104.551a18.911 18.911 0 01-17.471-11.667 18.903 18.903 0 0113.775-25.781 18.909 18.909 0 0122.597 18.542 18.93 18.93 0 01-18.901 18.906zm0-37.293a18.391 18.391 0 00-18.045 21.98 18.4 18.4 0 0014.453 14.452 18.395 18.395 0 0018.882-7.824 18.397 18.397 0 003.097-10.22 18.41 18.41 0 00-18.387-18.388z'></path>
      <path
        fill='#ABDAFE'
        d='M91.367 96.202a2.054 2.054 0 01-1.453-.6L79.383 85.07a2.054 2.054 0 012.901-2.9l8.857 8.851 12.385-16.59a2.058 2.058 0 012.876-.416 2.058 2.058 0 01.416 2.876L93.01 95.376a2.053 2.053 0 01-1.499.816l-.144.01z'></path>
      <path
        stroke='#105F9C'
        strokeMiterlimit='10'
        strokeWidth='0.7'
        d='M66.074 121.639l-3.35 1.935v-1.586a1.382 1.382 0 00-1.382-1.382H53.79a1.382 1.382 0 00-1.382 1.382v6.093a1.38 1.38 0 001.382 1.382h7.565a1.378 1.378 0 001.381-1.382v-1.585l3.351 1.934-.014-6.791zM200.982 93.36l-4.409 2.544v-2.086A1.819 1.819 0 00194.755 92h-9.937A1.817 1.817 0 00183 93.818v8.018a1.818 1.818 0 001.818 1.819h9.955a1.817 1.817 0 001.818-1.819V99.75l4.409 2.545-.018-8.936z'></path>
      <path
        stroke='#105F9C'
        strokeMiterlimit='10'
        strokeWidth='0.5'
        d='M88.257 55.7l-2.27 1.31v-1.074a.936.936 0 00-.935-.936h-5.116a.936.936 0 00-.936.936v4.128a.936.936 0 00.936.936h5.125a.936.936 0 00.936-.936V58.99l2.27 1.31-.01-4.6z'></path>
      <path
        stroke='#105F9C'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.7'
        d='M136.6 49.227a3.28 3.28 0 01-1.113-2.53l.049-2.734a4.608 4.608 0 00-3.906-4.629 1.247 1.247 0 00.589-1.393 1.253 1.253 0 00-1.185-.94 1.251 1.251 0 00-1.269 1.23 1.221 1.221 0 00.595 1.084 4.606 4.606 0 00-4.062 4.482l-.049 2.705a3.277 3.277 0 01-.928 2.246l-.263.274a1.042 1.042 0 00-1.055 1.123 1.065 1.065 0 001.064.976l4.102.078c-.01.084-.01.17 0 .254a1.64 1.64 0 002.732 1.129 1.64 1.64 0 00.539-1.07 2.32 2.32 0 000-.264l4.102.078a1.073 1.073 0 001.094-.918 1.054 1.054 0 00-.25-.825 1.049 1.049 0 00-.786-.356zm-5.556-11.719a.735.735 0 01.713.752.735.735 0 01-.728.648.733.733 0 01-.727-.648.74.74 0 01.485-.697.743.743 0 01.296-.045l-.039-.01z'></path>
      <path
        stroke='#105F9C'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M129.892 51.043l1.816.03'></path>
      <path
        stroke='#105F9C'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.5'
        d='M152.513 126.349a1.432 1.432 0 01-.487-1.106l.021-1.197a2.014 2.014 0 00-1.709-2.025.546.546 0 10-.555-.008 2.013 2.013 0 00-1.778 1.961l-.021 1.183c-.005.368-.15.719-.406.983l-.115.119a.467.467 0 00-.339.145.455.455 0 00.027.651c.086.08.199.123.316.123l1.794.034a.722.722 0 00.704.788.72.72 0 00.728-.766l1.794.034a.474.474 0 00.479-.402.462.462 0 00-.265-.476.451.451 0 00-.188-.041zm-2.431-5.127a.324.324 0 01.224.1.326.326 0 01.088.229.323.323 0 01-.319.284.322.322 0 01-.318-.284.329.329 0 01.101-.236.329.329 0 01.241-.088l-.017-.005z'></path>
      <path
        stroke='#105F9C'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M149.578 127.144l.794.012'></path>
    </svg>
  )
}

export default NoIncidents
