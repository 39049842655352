import React from 'react';
import {
    Chart as ChartJS,
    ArcElement
} from 'chart.js';

ChartJS.register(
    ArcElement
);

import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChart = ({data}) => {
    const pieData = {
        labels: [
          'Male',
          'Female',
          'Unknown'
        ],
        datasets: [{
          label: 'Pie Chart',
          data: data,
          backgroundColor: [
            '#518CF9',
            '#5EF550',
            '#F7D149'
          ],
          hoverOffset: 4
        }]
    };

    const chartOptions = {
        plugins: {
        datalabels: {
            formatter: (value, context) => {
            const total = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
            const percentage = (value / total) * 100;
            return percentage.toFixed(0) + '%';
            },
            color: 'black',
            font: {
            weight: 'bold',
            size: 16,
            },
        },
        },
    };
    
    return <Pie data={pieData} options={chartOptions} plugins={[ChartDataLabels]} />;

};

export default PieChart;
