// custom history object to allow navigation outside react components
export const history = {
  navigate: null,
  location: null,
}

export function getOS() {
  const { userAgent } = navigator

  let os = ''

  if (userAgent.includes('Mac')) {
    os = 'MacOS'
  } else if (userAgent.includes('Linux')) {
    os = 'Linux'
  } else if (userAgent.includes('Android')) {
    os = 'Android'
  } else if (userAgent.includes('iOS')) {
    os = 'iOS'
  } else if (
    userAgent.includes('Windows NT 10.0') &&
    userAgent.includes('Win64') &&
    userAgent.includes('WOW64') &&
    !userAgent.includes('Trident/7.0')
  ) {
    os = 'Windows 11'
  } else if (userAgent.includes('Windows NT 10.0')) {
    os = 'Windows 10'
  } else if (userAgent.includes('Windows NT 6.3')) {
    os = 'Windows 8.1'
  } else if (userAgent.includes('Windows NT 6.2')) {
    os = 'Windows 8'
  } else if (userAgent.includes('Windows NT 6.1')) {
    os = 'Windows 7'
  } else if (userAgent.includes('Windows NT 6.0')) {
    os = 'Windows Vista'
  } else if (userAgent.includes('Windows NT 5.1')) {
    os = 'Windows XP'
  } else {
    os = 'Windows'
  }

  return os
}
