import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { START_DATE } from "@datepicker-react/hooks";
import { formatDate, useWindowSize } from "~/utils";
import { PATH, PENDING_ALERTS, SETTINGS, STATUS } from "~/constant";
import {
  initAlertLoading,
  setSelectedAlert,
  getAllAlertsAsync,
  searchAlertsAsync,
} from "~/store/slice/alert";

import {
  incidentSlice,
  getIncidentAlerts,
  getPendingIncidentAlerts,
  getNonPendingIncidentAlerts,
  readIncident,
  getLatestLocation,
  getIncidentCountAll,
} from "~/store/slice/incident";
import { getPersonLastSeen, getPerson } from "~/store/slice/people";

export function useAlert() {
  const historyLog = [
    {
      type: "message",
      author: "Rama",
      action: "scheduled a notify to",
      target: "Retail Manager",
      date: "20 Sep 2023, 22:31",
      changeTo: STATUS.NOTIFIED,
    },
    {
      type: "notifications",
      desc: "Detected incident and alerted 3 officers",
      target: ["John", "Keny", "Mark"],
      date: "20 Sep 2023, 22:31",
    },
  ];

  const selectedUser = [
    { name: "Retail Manager", selected: true },
    { name: "John", selected: false },
    { name: "Keny", selected: false },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [width, height] = useWindowSize();
  const alertVideoRef = useRef();

  const queryParams = new URLSearchParams(location.search);
  const fromValue = queryParams.get("from");

  const incident = useSelector((state) => state.alert.incident) || [];
  const selectedAlert = useSelector((state) => state.alert.selectedAlert);
  const todayAlerts = useSelector((state) => state.alert.allAlerts.today) || [];
  const pendingAlerts = useSelector((state) => state.alert.allAlerts.pending) || [];
  const yesterdayAlerts = useSelector((state) => state.alert.allAlerts.yesterday) || [];
  const isLoading = useSelector((state) => state.incident.isLoading);
  const pending_alerts = useSelector((state) => state.incident.pending_alerts);
  const non_pending_alerts = useSelector((state) => state.incident.non_pending_alerts);
  const _todayAlerts = useSelector((state) => state.incident.today_alerts);
  const _yesterdayAlerts = useSelector((state) => state.incident.yesterday_alerts);
  const total_data = useSelector((state) => state.incident.incident_count_all);
  const countAlertRead = useSelector((state) => state.incident.count_alert_read);
  const people_data = useSelector((state) => state.people.people_data);
  const lastest_camera = useSelector((state) => state.incident.lastest_camera);
  const isLoadingPersonLastSeen = useSelector((state) => state.people.isLoadingPersonLastSeen);
  const personLastSeen = useSelector((state) => state.people.personLastSeen);

  let limit = 7;
  const [page, setPage] = useState(1);
  const [notes, setNotes] = useState("");
  const [keyword, setKeyword] = useState("");
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [isNoAlert, setIsNoAlert] = useState(false);
  const [isAnonymous, setisAnonymous] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [userSelect, setUserSelect] = useState(selectedUser);
  const [reportBotomSheet, setReportBotomSheet] = useState(false);

  // move this to the hook
  const currentDate = new Date();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    endDate: null,
    startDate: null,
    focusedInput: START_DATE,
  });
  const [displayDate, setDisplayDate] = useState("All Dates");

  function typeToStr(it) {
    switch (it.toUpperCase()) {
      case PENDING_ALERTS.MAJOR_FIRE:
        return "Major Fire";
      case PENDING_ALERTS.FALL_DETECTION:
        return "Fall Detection";
      case PENDING_ALERTS.PEOPLE_LOITERING:
        return "People Loitering";
      default:
        return "Alert";
    }
  }

  useEffect(() => {
    if(selectedAlert !== undefined){
      dispatch(getLatestLocation({incident_id: selectedAlert.id}));
    }
  }, [selectedAlert])

  function dateToStatus(it) {
    const currentDay = moment().startOf("day");
    const yesterdayDay = moment().subtract(1, "days").startOf("day");

    if (moment(new Date(it)).isSame(currentDay, "day")) {
      return STATUS.SCHEDULED;
    }

    if (moment(new Date(it)).isAfter(currentDay, "day")) {
      return STATUS.PENDING;
    }

    if (moment(new Date(it)).isSame(yesterdayDay, "day")) {
      return STATUS.RESOLVED;
    }
  }

  function getDifferentTimes(it) {
    const time1 = moment(new Date(it.start_time), "YYYY-MM-DD HH:mm");
    const time2 = moment(new Date(it.end_time), "YYYY-MM-DD HH:mm");

    const diffInMinutes = time2.diff(time1, "minutes");

    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    return `${hours} hours ${minutes} minutes`;
  }

  function selectUser(name) {
    const newUser = [];
    userSelect.forEach((it) => {
      if (it.name === name) {
        it.selected = !it.selected;
      }
      newUser.push(it);
    });
    setUserSelect(newUser);
  }

  function openReportModal() {
    if (isMobile) {
      setReportBotomSheet(true);
      return;
    }
    setReportModal(true);
  }

  function closeModal() {
    setReportModal(false);
    setReportBotomSheet(false);
  }

  function backToList() {
    dispatch(setSelectedAlert(undefined));
  }

  function handleChangeNotes(e) {
    if (e.target.value.length > 200) {
      return;
    }
    setNotes(e.target.value);
  }

  function toLogin() {
    navigate(PATH.LOGIN);
  }

  function changeKeyword(e) {
    setKeyword(e.target.value);
  }

  function selectAlert(it) {
    dispatch(setSelectedAlert(it));
    dispatch(readIncident(it.id));
    fetchPersonLastSeen(it._id);
  }

  function searchAlerts(e) {
    e.preventDefault();
    setSearchedKeyword(keyword);
    setPage(1);
  }

  function toAddAlerts() {
    navigate({
      pathname: PATH.SETTING,
      search: createSearchParams({
        tab: SETTINGS.ALERTS,
      }).toString(),
    });
  }

  function linkToVMS(){
    const camera_id = selectedAlert.camera_id;
    const start_time = Math.floor(new Date(selectedAlert.start_time).getTime() / 1000);

    navigate(`/vms`, {
      state: { site: location.state?.site, time: start_time, camera_id: camera_id },
    });
  }

  function timeToStatus(time) {
    let status = "";
    const now = moment();
    const alertTime = moment(new Date(time));

    const isToday = now.isSame(alertTime, "day");
    const isPending = now.isBefore(alertTime, "day");

    if (isToday) {
      status = STATUS.SCHEDULED;
    } else if (isPending) {
      status = STATUS.PENDING;
    } else {
      status = STATUS.RESOLVED;
    }

    return status;
  }

  useEffect(() => {
    dispatch(incidentSlice.actions.resetincidentStore());
    dispatch(initAlertLoading());
  }, []);

  useEffect(() => {
    if (dateRange.focusedInput == "endDate") {
      return;
    }
    if (location.state !== null) {
      dispatch(getAllAlertsAsync());
      dispatch(getPendingIncidentAlerts({ site: location.state.site, page: page, limit: limit }));
      dispatch(
        getNonPendingIncidentAlerts({
          site: location.state.site,
          page: page,
          limit: limit,
          search: searchedKeyword,
          after: dateRange.startDate,
          before: dateRange.endDate,
        })
      );
      dispatch(getIncidentCountAll({ site: location.state.site }));
    }
  }, [page, location.state, searchedKeyword, dateRange]);

  useEffect(() => {
    setIsMobile(width < 840);
  }, [width, height]);

  useEffect(() => {
    const hasAlert =
      todayAlerts.length > 0 || yesterdayAlerts.length > 0 || pendingAlerts.length > 0;

    setIsNoAlert(!hasAlert);
    if (hasAlert) {
      dispatch(setSelectedAlert(todayAlerts[0]));
    }
  }, [todayAlerts, yesterdayAlerts, pendingAlerts]);

  const dummmyisNoAlert = false;

  function fetchChangePage(number) {
    setPage(number);
  }

  function fetchPersonLastSeen(incident_id) {
    if(incident_id !== undefined){
      dispatch(getLatestLocation({incident_id: incident_id}));
    }
  }

  useEffect(() => {
    let filtered_dates = "All Dates";
    if (dateRange.startDate !== null) {
      const startDate = formatDate(dateRange.startDate);
      filtered_dates = "start: " + startDate;
    }
    if (dateRange.endDate !== null) {
      const endDate = formatDate(dateRange.endDate);
      filtered_dates += "\n end: " + endDate;
    }
    setDisplayDate(filtered_dates);
  }, [dateRange]);

  return {
    datas: {
      page,
      limit,
      notes,
      keyword,
      isMobile,
      incident,
      isNoAlert,
      isLoading,
      total_data,
      userSelect,
      historyLog,
      todayAlerts,
      reportModal,
      isAnonymous,
      alertVideoRef,
      pendingAlerts,
      selectedAlert,
      personLastSeen,
      yesterdayAlerts,
      reportBotomSheet,
      pending_alerts,
      non_pending_alerts,
      dummmyisNoAlert,
      dateRange,
      showDatePicker,
      displayDate,
      lastest_camera,
      isLoadingPersonLastSeen,
      countAlertRead
    },
    methods: {
      toLogin,
      typeToStr,
      linkToVMS,
      backToList,
      closeModal,
      selectUser,
      selectAlert,
      toAddAlerts,
      timeToStatus,
      searchAlerts,
      dateToStatus,
      changeKeyword,
      openReportModal,
      fetchChangePage,
      getDifferentTimes,
      handleChangeNotes,
      setDateRange,
      setShowDatePicker,
      fetchPersonLastSeen,
    },
  };
}
