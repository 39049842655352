import React from 'react'

import Shimmer from '~/components/shimmer'
import { CevronDown, CheckOutline } from '~/svg'

const NotifShimmer = () => {
  return (
    <div className='w-full rounded-lg pb-0 overflow-hidden bg-transparent absolute z-50 cursor-default'>
      <div className='flex justify-between items-center px-4 pb-2 border-b-[1px] border-b-neutral-800 bg-neutral-900 pt-3'>
        <div className='flex items-center gap-2'>
          <p className='text-sm font-semibold text-neutral-200'>Notifications</p>
          <div className='flex items-center w-16 justify-between p-1'>
            <p className='text-sm font-semibold text-neutral-400'>All</p>
            <CevronDown />
          </div>
        </div>
        <div className='flex items-center gap-1'>
          <p className='text-sm text-[#FFFFFFA6]'>Mark all as read</p>
          <CheckOutline dark />
        </div>
      </div>
      <div className='flex px-4 pb-3 gap-4 mt-2'>
        <div className='w-[36px]'>
          <Shimmer rounded height={32} />
        </div>
        <div className='w-full'>
          <div className='w-full mt-[6px]'>
            <Shimmer height={20} />
          </div>
          <div className='w-1/2 mt-5'>
            <Shimmer height={20} />
          </div>
        </div>
      </div>
      <div className='flex px-4 pt-3 pb-6 gap-4 bg-neutral-900'>
        <div className='w-[36px]'>
          <Shimmer rounded height={32} />
        </div>
        <div className='w-full'>
          <div className='w-full mt-[6px]'>
            <Shimmer height={20} />
          </div>
          <div className='w-1/2 mt-5'>
            <Shimmer height={20} />
          </div>
        </div>
      </div>
      <div className='flex px-4 pb-6 gap-4 bg-neutral-900'>
        <div className='w-[36px]'>
          <Shimmer rounded height={32} />
        </div>
        <div className='w-full'>
          <div className='w-full mt-[6px]'>
            <Shimmer height={20} />
          </div>
          <div className='w-1/2 mt-5'>
            <Shimmer height={20} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotifShimmer
