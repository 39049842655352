import React from 'react'

import { Canvas, Chip, MultiRangeSlider } from '~/components'
import { Calendar, CevronUp, Clock, Info } from '~/svg'

const Sidebar = ({
  filter,
  floors,
  source,
  openInfo,
  openModal,
  dateRange,
  closeInfo,
  timeRange,
  resetFilter,
  selectFloors,
  selectSource,
  dateToString,
  handleFilter,
  dummyCameras,
  setConfidence,
  confidenceInfo,
  changeTimeRange,
}) => {
  return (
    <aside className='sticky top-0 w-60 mt-10 ml-8'>
      <div className='flex w-full justify-between items-center'>
        <p className='text-xl font-semibold text-neutral-200'>Filter</p>
        <p
          onClick={resetFilter}
          className='text-sm font-semibold text-primary-200 underline cursor-pointer'>
          Reset All
        </p>
      </div>
      <div className='w-[208px] bg-neutral-900 rounded-r-lg mt-5 py-3'>
        {/* Source Type */}
        <div className='w-full border-b-[1px] border-b-neutral-800 pb-3'>
          <div className='flex w-full justify-between items-center px-3'>
            <p className='text-sm font-semibold text-neutral-50'>Source</p>
            <div
              data-testid='source-chevron'
              onClick={() => handleFilter('source')}
              className={`cursor-pointer ${filter.source ? 'rotate-0' : 'rotate-180'}`}>
              <CevronUp />
            </div>
          </div>
          {filter.source ? (
            <div className='flex flex-row flex-wrap px-3 mt-3 gap-2'>
              {source &&
                source.map((it, idx) => (
                  <Chip
                    data-testid='source-chip'
                    key={idx}
                    onClick={() => selectSource(it.source.type)}
                    selected={it.selected}>
                    <p className='text-sm font-medium text-neutral-50'>{it.source.label}</p>
                  </Chip>
                ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Level */}
        <div className='w-full border-b-[1px] border-b-neutral-800 pb-3'>
          <div className='flex w-full justify-between items-center px-3'>
            <p className='text-sm font-semibold text-neutral-50'>Level</p>
            <div
              data-testid='level-chevron'
              onClick={() => handleFilter('level')}
              className={`cursor-pointer ${filter.level ? 'rotate-0' : 'rotate-180'}`}>
              <CevronUp />
            </div>
          </div>
          {filter.level ? (
            <div className='grid grid-cols-3 px-3 mt-[10px] gap-2'>
              {floors &&
                floors.map((it, idx) => (
                  <Chip
                    data-testid='level-chip'
                    key={idx}
                    onClick={() => selectFloors(it.name)}
                    selected={it.selected}>
                    <p className='text-sm font-medium text-neutral-50 leading-tight tracking-tight'>
                      {it.name}
                    </p>
                  </Chip>
                ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Camera */}
        <div className='w-full border-b-[1px] border-b-neutral-800 py-3'>
          <div className='flex w-full justify-between items-center px-3'>
            <p className='text-sm font-semibold text-neutral-50'>Camera</p>
            <div
              data-testid='camera-chevron'
              onClick={() => handleFilter('camera')}
              className={`cursor-pointer ${filter.camera ? 'rotate-0' : 'rotate-180'}`}>
              <CevronUp />
            </div>
          </div>
          {filter.camera ? (
            <div className='w-full flex flex-col items-start justify-start px-3'>
              <div>
                <Canvas
                  data-testid='camera-canvas'
                  withScale
                  width={dummyCameras.width}
                  images={dummyCameras.images}
                  height={dummyCameras.height}
                  cameras={dummyCameras.cameras}
                  scale={188 / dummyCameras.width}
                />
              </div>
              <p className='text-xs text-neutral-200 mt-2'>Selected: All</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Date and Time */}
        <div className='w-full border-b-[1px] border-b-neutral-800 py-3'>
          <div className='flex w-full justify-between items-center px-3'>
            <p className='text-sm font-semibold text-neutral-50'>Date and time</p>
            <div
              data-testid='date-chevron'
              onClick={() => handleFilter('dateAndTime')}
              className={`cursor-pointer ${filter.dateAndTime ? 'rotate-0' : 'rotate-180'}`}>
              <CevronUp />
            </div>
          </div>
          {filter.dateAndTime ? (
            <div data-testid='date-component' className='px-[10px] mt-[10px]'>
              <p className='font-semibold text-neutral-200 mb-1'>Date</p>
              <div
                onClick={openModal}
                className='flex w-full border-[1px] border-neutral-800 rounded-md items-center justify-between px-4 py-3 cursor-pointer'>
                <p className='text-neutral-200 w-full truncate'>{`Until ${
                  dateRange.endDate === null ? 'Today' : dateToString(dateRange.endDate)
                }`}</p>
                <Calendar />
              </div>
              <p className='font-semibold text-neutral-200 mt-2 mb-1'>Time</p>
              <div className='flex w-full border-[1px] border-neutral-800 rounded-md items-center justify-between px-4 py-3'>
                <div className='flex items-center gap-[2px]'>
                  <input
                    type='text'
                    name='startClock'
                    onChange={changeTimeRange}
                    value={timeRange.startClock}
                    className='w-5 bg-transparent outline-none border-none text-neutral-200'
                  />
                  <p className='text-neutral-200'>:</p>
                  <input
                    type='text'
                    name='startMinute'
                    onChange={changeTimeRange}
                    value={timeRange.startMinute}
                    className='w-5 bg-transparent outline-none border-none text-neutral-200'
                  />
                  <p className='text-neutral-200'>-</p>
                  <input
                    type='text'
                    name='endClock'
                    onChange={changeTimeRange}
                    value={timeRange.endClock}
                    id=''
                    className='w-5 bg-transparent outline-none border-none text-neutral-200'
                  />
                  <p className='text-neutral-200'>:</p>
                  <input
                    type='text'
                    name='endMinute'
                    onChange={changeTimeRange}
                    value={timeRange.endMinute}
                    className='w-5 bg-transparent outline-none border-none text-neutral-200'
                  />
                </div>
                <Clock white />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Confidence */}
        <div className='w-full py-3'>
          <div className='flex w-full justify-between items-center px-3'>
            <div className='flex items-center gap-2'>
              <p className='text-sm font-semibold text-neutral-50'>Confidence level</p>
              <div onMouseEnter={openInfo} onMouseLeave={closeInfo} className='relative'>
                <Info />
                {confidenceInfo ? (
                  <div
                    data-testid='confidence-info'
                    className='absolute left-[-72px] top-[-54px] w-[145px] bg-[#334155] rounded px-2 py-1'>
                    <p className='text-start text-sm text-white'>
                      How well the <br /> match accuracy is.
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              data-testid='confidence-chevron'
              onClick={() => handleFilter('confidence')}
              className={`cursor-pointer ${filter.confidence ? 'rotate-0' : 'rotate-180'}`}>
              <CevronUp />
            </div>
          </div>
          {filter.confidence ? (
            <div className='px-[16px]'>
              <div className='w-full'>
                <MultiRangeSlider
                  data-testid='confidence-slider'
                  min={0}
                  max={100}
                  onChange={({ min, max }) => setConfidence({ min, max })}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
