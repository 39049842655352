import React from 'react'

import { SETTINGS } from '~/constant'

import {
  SettingMenu,
  AlertSetting,
  CameraSetting,
  SystemSetting,
  AccountSetting,
  CamSettingShimmer,
} from './components'
import { useSettings } from './hooks'
import { SystemShimmer } from './components/system/components'

const Setting = () => {
  const {
    datas: { tabValue, isLoading, accountLoading, settingMenus, alertsLoading, systemLoading },
    methods: { navToTab },
  } = useSettings()
  return (
    <div>
      <div className='flex flex-col mt-6 md:mt-10 md:max-w-7xl max-md:max-w-md mx-auto px-4 md:px-0'>
        {isLoading && tabValue === SETTINGS.AREA_SETTINGS ? (
          <CamSettingShimmer />
        ) : accountLoading && tabValue === SETTINGS.ACCOUNTS ? (
          <CamSettingShimmer />
        ) : alertsLoading && tabValue === SETTINGS.ALERTS ? (
          <CamSettingShimmer />
        ) : systemLoading && tabValue === SETTINGS.SYSTEM ? (
          <SystemShimmer />
        ) : (
          <>
            {' '}
            <p className='text-neutral-50 font-semibold text-2xl'>Settings</p>
            <div className='mt-6 flex flex-col md:flex-row gap-10'>
              <SettingMenu
                setSetting={navToTab}
                settingState={tabValue}
                settingMenus={settingMenus}
              />
              <div className='flex w-full pb-[10vh]'>
                {(() => {
                  switch (tabValue) {
                    case SETTINGS.ACCOUNTS:
                      return <AccountSetting />
                    case SETTINGS.ALERTS:
                      return <AlertSetting />
                    case SETTINGS.CAMERAS:
                      return <CameraSetting />
                      case SETTINGS.AREA_SETTINGS:
                        return <CameraSetting />
                    case SETTINGS.SYSTEM:
                      return <SystemSetting />
                    default:
                      return <></>
                  }
                })()}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Setting
