import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Tooltip,
  LineElement,
  Legend
);

import { Bar, Line } from "react-chartjs-2";

const BarChart = ({ timestamp, datasets }) => {
  const chartData = {
    labels: timestamp,
    datasets: datasets,
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        enabled: true,
        title: (context) => "Nice", // This will display the label of the data point in the tooltip
      },
      legend: {
        display: true,
        labels: {
          color: 'white'
        }
      },
    },
    scales: {
      x: {
        ticks: {
          fontWeight: 600,
          fontSize: 14,
          color: "#ECF4FD",
        },
        grid: {
          display: false, // Remove the vertical grid lines here
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 10,
        ticks: {
          fontWeight: 600,
          fontSize: 14,
          color: "#ECF4FD",
        },
        grid: {
          color: " #5A626B",
        },
        border: { dash: [4, 4] },
      },
    },
  };

  return <Line options={chartOptions} data={chartData} />;
};

export default BarChart;
