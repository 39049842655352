import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

export function useAnalyticsDetail() {
  const params = useParams()
  const navigate = useNavigate()

  const videoDetail = useSelector((state) => state.video.videoDetail)

  const { type, id } = params

  function backPreviousPage() {
    navigate(-1)
  }

  return { datas: { type, id, videoDetail }, methods: { backPreviousPage } }
}
