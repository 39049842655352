import React from 'react'

function WhatsAppIcon() {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='w-6 h-6'>
      <path
        d='M4.01953 52L7.46342 39.2062C4.68601 34.2223 3.8036 28.4066 4.97811 22.8262C6.15262 17.2458 9.30528 12.275 13.8576 8.82596C18.4098 5.37696 24.0564 3.68109 29.761 4.04955C35.4657 4.418 40.8458 6.82607 44.9141 10.8319C48.9824 14.8377 51.4661 20.1725 51.9093 25.8574C52.3524 31.5423 50.7255 37.196 47.3269 41.7809C43.9282 46.3658 38.986 49.5745 33.407 50.8182C27.828 52.0618 21.9865 51.257 16.9545 48.5513L4.01953 52ZM17.5782 43.7638L18.3781 44.2371C22.023 46.392 26.2801 47.2838 30.486 46.7734C34.692 46.263 38.6106 44.3791 41.6311 41.4154C44.6517 38.4517 46.6045 34.5746 47.1852 30.3882C47.766 26.2019 46.9421 21.9415 44.8419 18.2711C42.7417 14.6006 39.4832 11.7261 35.5742 10.0957C31.6652 8.46524 27.3253 8.17039 23.2308 9.25707C19.1363 10.3438 15.5171 12.7509 12.9374 16.1035C10.3576 19.456 8.96214 23.5656 8.96843 27.7918C8.965 31.296 9.9366 34.7324 11.7751 37.7185L12.2767 38.5435L10.3514 45.6842L17.5782 43.7638Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.9937 31.0769C36.5248 30.6996 35.9757 30.4341 35.3883 30.3005C34.8009 30.1669 34.1906 30.1688 33.6041 30.3061C32.7228 30.6712 32.1533 32.0507 31.5838 32.7404C31.4637 32.9057 31.2873 33.0217 31.0876 33.0665C30.8879 33.1113 30.6787 33.0819 30.4991 32.9838C27.2726 31.7233 24.5681 29.4118 22.825 26.4246C22.6763 26.2382 22.6059 26.0015 22.6287 25.7644C22.6514 25.5273 22.7655 25.3082 22.947 25.1534C23.5821 24.5262 24.0485 23.7494 24.3029 22.8948C24.3593 21.9522 24.1428 21.0133 23.6792 20.19C23.3207 19.0358 22.6385 18.008 21.7132 17.2282C21.2359 17.0141 20.7067 16.9423 20.1895 17.0215C19.6722 17.1007 19.189 17.3275 18.798 17.6745C18.1194 18.2585 17.5807 18.987 17.2217 19.8062C16.8627 20.6254 16.6925 21.5145 16.7236 22.408C16.7257 22.9097 16.7894 23.4093 16.9134 23.8956C17.2282 25.064 17.7124 26.1802 18.3506 27.209C18.8111 27.9971 19.3134 28.7601 19.8556 29.4946C21.6176 31.9073 23.8325 33.9558 26.3773 35.5264C27.6543 36.3245 29.0192 36.9734 30.4449 37.4603C31.9259 38.13 33.5611 38.387 35.1769 38.2041C36.0974 38.0652 36.9697 37.7026 37.7168 37.1484C38.4639 36.5942 39.063 35.8654 39.4614 35.026C39.6955 34.5189 39.7665 33.952 39.6648 33.4031C39.4207 32.2806 37.9157 31.6179 36.9937 31.0769Z'
        fill='white'
      />
    </svg>
  )
}

export default WhatsAppIcon
