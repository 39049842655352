import React from 'react'

import { LoadingCircle } from '~/svg'

import './index.css'

const ButtonLoading = () => {
  return (
    <div className='rotating'>
      <LoadingCircle />
    </div>
  )
}

export default ButtonLoading
