import React, { useEffect } from "react";

import { IconX } from "~/svg";
import { Button, Toast } from "~/components";

import { useSetting } from "./hooks";
import { AddCameraModal, CameraSection, SetCameraModal, TableSection } from "./components";

const CameraSetting = ({ site }) => {
  const {
    datas: {
      floors,
      cameras,
      addModal,
      cameraId,
      isLoading,
      showToast,
      floorsCams,
      cameraForm,
      choosenCamera,
      cameraModal,
      errAddCamera,
      showPassword,
      formTimeout,
    },
    methods: {
      hideToast,
      openModal,
      closeModal,
      selectBrand,
      selectFloor,
      handleChange,
      handleUpdate,
      addCoordinate,
      handlePassword,
      openCameraModal,
      closeCameraModal,
      handleBlurFormTimeout,
      handleChangeFormTimeout,
    },
  } = useSetting();

  return (
    <div className="flex flex-col w-full pb-16 md:pb-0 px-1 md:px-0">
      <CameraSection
        floors={floors}
        openModal={openModal}
        floorsCams={floorsCams}
        selectFloor={selectFloor}
        formTimeout={formTimeout}
        handleBlurFormTimeout={handleBlurFormTimeout}
        handleChangeFormTimeout={handleChangeFormTimeout}
      />

      <TableSection cameras={cameras} floors={floors} openCameraModal={openCameraModal} />

      {/* fixxed bottom button in mobile view */}
      <div className="block md:hidden fixed bottom-0 left-0 p-4 pb-10 w-full bg-[#1C1C1C] border-t-[1px] border-t-neutral-800">
        <Button onClick={openModal} type={"primary"}>
          <p>Add Camera</p>
        </Button>
      </div>

      {/* set camera modal */}
      <SetCameraModal
        choosenCamera={choosenCamera}
        state={cameraModal}
        closeModal={closeCameraModal}
      />

      {/* success toast */}
      <Toast show={showToast} type={"success"}>
        <div className="flex items-center gap-3">
          <p>New camera added</p>
          <div onClick={hideToast} className="cursor-pointer">
            <IconX />
          </div>
        </div>
      </Toast>
    </div>
  );
};

export default CameraSetting;
