import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { PATH } from "~/constant";
import { Navbar } from "~/components";
import {
  Alerts,
  Analytics,
  AnalyticsDetail,
  Home,
  Login,
  Search,
  Setting,
  UploadedVideos,
  Video,
  Sites,
} from "~/views";

const Router = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path={PATH.WATCH} element={<Home />} />
        <Route path={PATH.LOGIN} element={<Login />} />
        <Route path={PATH.ALERT} element={<Alerts />} />
        <Route path={PATH.SETTING} element={<Setting />} />
        <Route path={PATH.HOME} element={<Sites />} />
        <Route path={PATH.ANALYTICS} element={<Analytics />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
