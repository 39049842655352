import React from 'react'

const Chip = ({ children, disable, selected, onClick }) => {
  return (
    <>
      <button
        disabled={disable}
        onClick={onClick}
        className={`w-auto rounded-[4px] border-[1.5px] px-4 py-2 text-center ${
          selected
            ? 'border-primary-600 bg-primary-900 hover:bg-primary-800 focus:border-primary-400'
            : 'border-neutral-600 hover:bg-neutral-800 focus:border-primary-600'
        }}`}>
        {children}
      </button>
    </>
  )
}

export default Chip
