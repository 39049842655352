import React from "react";

import {
  NoAlert,
  ListAlerts,
  ReportModal,
  AlertShimmer,
  DesktopContent,
  AnonymousAlert,
  ReportBottomSheet,
  MobileAlerts,
} from "./components";
import { useAlert } from "./hooks";

const Alerts = () => {
  const {
    datas: {
      page,
      limit,
      notes,
      keyword,
      isMobile,
      incident,
      isNoAlert,
      isLoading,
      total_data,
      historyLog,
      userSelect,
      isAnonymous,
      reportModal,
      todayAlerts,
      alertVideoRef,
      pendingAlerts,
      selectedAlert,
      personLastSeen,
      yesterdayAlerts,
      reportBotomSheet,
      pending_alerts,
      non_pending_alerts,
      dummmyisNoAlert,
      dateRange,
      showDatePicker,
      displayDate,
      lastest_camera,
      isLoadingPersonLastSeen,
      countAlertRead,
    },
    methods: {
      toLogin,
      typeToStr,
      linkToVMS,
      selectUser,
      closeModal,
      backToList,
      selectAlert,
      toAddAlerts,
      timeToStatus,
      searchAlerts,
      dateToStatus,
      changeKeyword,
      openReportModal,
      fetchChangePage,
      getDifferentTimes,
      handleChangeNotes,
      setDateRange,
      setShowDatePicker,
      fetchPersonLastSeen,
    },
  } = useAlert();

  return (
    <div className="w-full h-[88.2vh] no-scrollbar">
      {isLoading ? <AlertShimmer /> : <></>}
      {dummmyisNoAlert ? (
        <NoAlert toAddAlerts={toAddAlerts} />
      ) : pending_alerts.length + non_pending_alerts.length < 1 ? (
        <NoAlert
          isNoIncidents
          toAddAlerts={toAddAlerts}
          dateRange={dateRange}
          setDateRange={setDateRange}
          showDatePicker={showDatePicker}
          setShowDatePicker={setShowDatePicker}
          displayDate={displayDate}
          searchAlerts={searchAlerts}
          keyword={keyword}
          changeKeyword={changeKeyword}
        />
      ) : (
        <div className="w-full h-full flex flex-col md:flex-row">
          {/* {isMobile && selectedAlert !== undefined ? (
            <MobileAlerts
              notes={notes}
              typeToStr={typeToStr}
              backToList={backToList}
              historyLog={historyLog}
              dateToStatus={dateToStatus}
              selectedAlert={selectedAlert}
              alertVideoRef={alertVideoRef}
              openReportModal={openReportModal}
              getDifferentTimes={getDifferentTimes}
              handleChangeNotes={handleChangeNotes}
            />
          ) : ( */}
          <>
            <ListAlerts
              page={page}
              limit={limit}
              keyword={keyword}
              total_data={total_data}
              todayAlerts={todayAlerts}
              selectAlert={selectAlert}
              searchAlerts={searchAlerts}
              changeKeyword={changeKeyword}
              pendingAlerts={pending_alerts}
              nonPendingAlerts={non_pending_alerts}
              fetchChangePage={fetchChangePage}
              yesterdayAlerts={yesterdayAlerts}
              dateRange={dateRange}
              setDateRange={setDateRange}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
              displayDate={displayDate}
              selectedAlert={selectedAlert}
              countAlertRead={countAlertRead}
            />
            <DesktopContent
              notes={notes}
              linkToVMS={linkToVMS}
              typeToStr={typeToStr}
              historyLog={historyLog}
              dateToStatus={dateToStatus}
              selectedAlert={selectedAlert}
              alertVideoRef={alertVideoRef}
              openReportModal={openReportModal}
              handleChangeNotes={handleChangeNotes}
              getDifferentTimes={getDifferentTimes}
              personLastSeen={lastest_camera}
              fetchPersonLastSeen={fetchPersonLastSeen}
              isLoadingPersonLastSeen={isLoadingPersonLastSeen}
            />
          </>
          {/* )} */}
        </div>
      )}
    </div>
  );
};

export default Alerts;
