import { Shimmer } from '~/components'

const SearchSimmer = () => {
  return (
    <div className='w-full flex justify-between items-center gap-1'>
      <div className='w-full'>
        <Shimmer height={24} />
      </div>
      <div className='w-20'>
        <Shimmer height={18} />
      </div>
    </div>
  )
}

export default SearchSimmer
