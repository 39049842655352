import { useEffect, useRef } from 'react'

export function useCanvasAdd({ cameras, addCoordinate }) {
  // init canvas ref
  const canvasRef = useRef()
  const canvas2Ref = useRef()

  // create camera image
  function renderCamImg() {
    const image = new Image()
    image.src = '/cam.svg'

    return image
  }

  function addCameras(canvas, ctx, e) {
    const image = renderCamImg()

    // get just inside canvas coordinate
    const rect = canvas.getBoundingClientRect()

    image.onload = () => {
      // set x and y of camera images
      const x = e.clientX - rect.left - image.width / 2
      const y = e.clientY - rect.top - image.height / 2

      // draw new camera in position / center with cursor
      ctx.drawImage(image, x, y)

      // save camera coordinate
      addCoordinate({ x, y })
    }
  }

  function renderCanvas(canvas, ctx) {
    const image = renderCamImg()

    // clear canvas to empty state when change level floor
    clearCanvas(ctx)

    // set opacity of existing camera canvas
    ctx.globalAlpha = 0.5

    // looping existing camera position
    image.onload = () => {
      cameras.forEach((it) => {
        ctx.drawImage(image, it.x, it.y)
      })
    }
  }

  function clearCanvas(ctx) {
    ctx.save()
    ctx.globalCompositeOperation = 'copy'
    ctx.strokeStyle = 'transparent'
    ctx.beginPath()
    ctx.lineTo(0, 0)
    ctx.stroke()
    ctx.restore()
  }

  // check canvas has drawn or not
  function isCanvasEmpty(canvas, ctx) {
    // get the image data of the canvas
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)

    // loop through the image data and check if any pixel is non-transparent
    for (let i = 0; i < imageData.data.length; i += 4) {
      if (imageData.data[i + 3] !== 0) {
        return false
      }
    }

    return true
  }

  function initCanvas() {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d', { willReadFrequently: true })

    const canvas2 = canvas2Ref.current
    const ctx2 = canvas2.getContext('2d')

    return {
      ctx,
      ctx2,
      canvas,
      canvas2,
    }
  }

  useEffect(() => {
    // init canvas context
    const { ctx, ctx2, canvas, canvas2 } = initCanvas()

    // render existing camera canvas
    renderCanvas(canvas2, ctx2)

    // add event listener click in canvas
    canvas.addEventListener('mousedown', function (e) {
      const isEmpty = isCanvasEmpty(canvas, ctx)
      // handle if has image in canvas (just can add 1 image)
      if (!isEmpty) {
        clearCanvas(ctx)
      }
      addCameras(canvas, ctx, e)
    })
  }, [])

  useEffect(() => {
    const { ctx2, canvas2 } = initCanvas()

    // render existing camera canvas
    renderCanvas(canvas2, ctx2)
  }, [cameras])

  return {
    canvasRef,
    canvas2Ref,
  }
}
