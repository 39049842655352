import React from 'react'

function Play() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <g clipPath='url(#clip0_705_6352)'>
        <path
          fill='#fff'
          d='M9.537 5.978A1 1 0 008 6.822v10.356a1 1 0 001.537.844l8.137-5.178a1 1 0 000-1.688L9.537 5.978z'></path>
      </g>
      <defs>
        <clipPath id='clip0_705_6352'>
          <path fill='#fff' d='M0 0H24V24H0z'></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Play
