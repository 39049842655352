import React, { useEffect, useState } from "react";
import { Dropdown, SearchBarHome, Datepicker, Button, Toast } from "~/components";
import { Tooltip } from "react-tooltip";

import {
  AnalyticsBarChart,
  AnalyticsPieChart,
  DetectionAlerts,
  DropdownExport,
  ExportModal,
} from "./components";

import { getSearchAsync } from "~/store/slice/camera";
import { useAnalytics } from "./hooks";
import { EXPORT_TYPE } from "~/constant";
import { IconX } from "~/svg";

const Analytics = () => {
  const {
    datas: {
      showToast,
      showToastPeople,
      dateRange,
      modalExport,
      people_summary_total,
      displayDate,
      selectedSite,
      selectedFloor,
      selectedCamera,
      floor_settings,
      showDatePicker,
      camera_settings,
      people_analytics,
      people_summary,
      incident_fire_analytics,
      incident_fall_analytics,
      incident_loitering_analytics,
      latest_fall_incidents,
      latest_fire_incidents,
      latest_loitering_incidents,
      incident_summary_data,
      incident_summary_total,
    },
    methods: {
      hideToast,
      jumpToSite,
      setSelectedSite,
      setSelectedCamera,
      setSelectedFloor,
      setDateRange,
      toggleDatePicker,
      openModalExport,
      closeModalExport,
    },
  } = useAnalytics();
  const fire_locations = [];
  const fire_alerts_timestamps = [];

  const filtered_labels = selectedFloor == "All Floors" ? floor_settings : [selectedFloor];

  return (
    <div className={`w-full min-h-screen px-8`}>
      <div className="w-full flex flex-row items-center justify-between mt-4">
        <div className="flex flex-row gap-8 items-center">
          <div>
            <h1 className="text-white text-5xl font-bold">Analytics</h1>
          </div>
          <div className="flex flex-cols gap-4 items-center">
            <Dropdown
              clickOptions={(value) => {
                setSelectedSite(value);
                jumpToSite(value);
              }}
              options={["Boon Keng", "Eunos", "Kallang", "Marymount", "Ang Mo Kio", "Little Road"]}
              selectedOptions={selectedSite}
            />
            <Dropdown
              clickOptions={(value) => {
                setSelectedFloor(value);
              }}
              options={["All Floors", ...floor_settings]}
              selectedOptions={selectedFloor}
            />
            <Dropdown
              clickOptions={(value) => {
                setSelectedCamera(value);
              }}
              options={["All Cameras", ...camera_settings]}
              selectedOptions={selectedCamera}
            />
            <div className="w-48">
              <button
                data-testid="dropdown-button"
                className="w-full px-4 py-2 flex flex-row justify-between items-center bg-grey-800 border border-grey-600 rounded-lg"
                onClick={toggleDatePicker}
              >
                <p className="leading-loose text-neutral-50">{displayDate}</p>
              </button>
              {showDatePicker ? (
                <div className="absolute">
                  <Datepicker
                    state={dateRange}
                    show={showDatePicker}
                    setState={setDateRange}
                    closeModal={toggleDatePicker}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div>
          <DropdownExport onSelectExport={openModalExport} />
        </div>
      </div>
      <div className="text-white w-full border border-grey-600 rounded-lg my-4">
        <table className="w-full table-fixed">
          <thead>
            <tr>
              {["", ...floor_settings, "Total"].map((floor, idx) => (
                <th
                  key={idx}
                  className="border border-grey-600 font-bold truncate hover:text-clip"
                  title={floor}
                >
                  {floor}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="border border-grey-600">People count</th>
              {[...floor_settings].map((floor, idx) => (
                <th key={idx} className="border border-grey-600 truncate hover:text-clip">
                  {floor in people_summary ? people_summary[floor] : 0}
                </th>
              ))}
              <th className="border border-grey-600">{people_summary_total}</th>
            </tr>
            <tr>
              <th className="border border-grey-600">Fire/Smoke</th>
              {[...floor_settings].map((floor, idx) => (
                <th key={idx} className="border border-grey-600 truncate hover:text-clip">
                  {incident_summary_data["Fire/Smoke"] === undefined ||
                  !(floor in incident_summary_data["Fire/Smoke"])
                    ? 0
                    : incident_summary_data["Fire/Smoke"][floor]}
                </th>
              ))}
              <th className="border border-grey-600">
                {incident_summary_total["Fire/Smoke"] === undefined
                  ? 0
                  : incident_summary_total["Fire/Smoke"]}
              </th>
            </tr>
            <tr>
              <th className="border border-grey-600">Fall</th>
              {[...floor_settings].map((floor, idx) => (
                <th key={idx} className="border border-grey-600 truncate hover:text-clip">
                  {incident_summary_data["Fall"] === undefined ||
                  !(floor in incident_summary_data["Fall"])
                    ? 0
                    : incident_summary_data["Fall"][floor]}
                </th>
              ))}
              <th className="border border-grey-600">
                {incident_summary_total["Fall"] === undefined ? 0 : incident_summary_total["Fall"]}
              </th>
            </tr>
            <tr>
              <th className="border border-grey-600">People loitering</th>
              {[...floor_settings].map((floor, idx) => (
                <th key={idx} className="border border-grey-600 truncate hover:text-clip">
                  {incident_summary_data["loitering"] === undefined ||
                  !(floor in incident_summary_data["loitering"])
                    ? 0
                    : incident_summary_data["loitering"][floor]}
                </th>
              ))}
              <th className="border border-grey-600">
                {incident_summary_total["loitering"] === undefined
                  ? 0
                  : incident_summary_total["loitering"]}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="grid grid-cols-2 mx-auto gap-10 mt-14">
        {/* Crowd Density */}
        <div className="grid grid-cols-1 gap-x-10">
          <div className="w-full">
            <AnalyticsBarChart title={"Crowd Density"} data={people_analytics} />
          </div>
        </div>
        {/* Fire/Smoke Density */}
        <div className="grid grid-cols-1 gap-x-10">
          <div className="w-full">
            <AnalyticsBarChart
              title={"Fire/smoke"}
              data={incident_fire_analytics}
              postfix="incident"
            />
          </div>
          {/* <div className="w-3/4">
            <DetectionAlerts latestData={latest_fire_incidents} />
          </div> */}
        </div>
        {/* Fall Detection */}
        <div className="grid grid-cols-1 gap-x-10">
          <div className="w-full">
            <AnalyticsBarChart
              title={"Fall detection"}
              data={incident_fall_analytics}
              postfix="incident"
            />
          </div>
          {/* <div className="w-3/4">
            <DetectionAlerts latestData={latest_fall_incidents} />
          </div> */}
        </div>
        {/* People Loitering */}
        <div className="grid grid-cols-1 gap-x-10">
          <div className="w-full">
            <AnalyticsBarChart
              title={"People loitering"}
              data={incident_loitering_analytics}
              postfix="incident"
            />
          </div>
          {/* <div className="w-3/4">
            <DetectionAlerts latestData={latest_loitering_incidents} />
          </div> */}
        </div>
      </div>
      <ExportModal
        exportModal={modalExport.length > 0}
        exportType={modalExport}
        closeModalExport={closeModalExport}
      />
      {/* success toast */}
      <Toast show={showToast || showToastPeople} type={"info"}>
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-row w-full justify-between">
            <p className="font-bold text-lg">Fetching Data</p>
            <div onClick={hideToast} className="cursor-pointer">
              <IconX />
            </div>
          </div>
          <p>Please do not close nor refresh this page.</p>
        </div>
      </Toast>
    </div>
  );
};

export default Analytics;
