import React, { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const Modal = ({ isOpen, closeModal, children, notOverflow }) => {
  const focusTrap = useRef()

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog initialFocus={focusTrap} as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            leaveTo='opacity-0'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leaveFrom='opacity-100'
            leave='ease-in duration-100'
            enter='ease-out duration-100'>
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto hide-scollbar'>
            <div className='flex min-h-full items-end md:items-center justify-center p-0 md:p-4 text-center'>
              <Transition.Child
                as={Fragment}
                leaveTo='opacity-0 scale-95'
                leave='ease-in duration-100'
                enter='ease-out duration-100'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leaveFrom='opacity-100 scale-100'>
                <Dialog.Panel
                  className={`w-full md:w-auto bg-neutral-900 transform ${
                    notOverflow ? '' : 'overflow-hidden'
                  } rounded-t-lg md:rounded-lg p-0 text-left align-middle transition-all`}>
                  <div className='w-full flex flex-col justify-start items-center'>{children}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Modal
