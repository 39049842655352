import React from 'react'

import { Clock } from '~/svg'

const Recent = ({ dummyHistory, setSuggestionKeyword }) => {
  return (
    <div className='w-full rounded-lg border-[1px] border-neutral-200 bg-neutral-900 px-4'>
      {dummyHistory &&
        dummyHistory.map((it, idx) => (
          <div key={idx} className='flex justify-between items-center'>
            <div
              onClick={() => setSuggestionKeyword(it.keyword)}
              className='flex items-center justify-start py-3 gap-3 cursor-pointer'>
              <Clock />
              <div className='flex items-end gap-3'>
                <p className='text-base text-neutral-200 truncate max-w-[70%] md:max-w-[100%]'>
                  {it.keyword}
                </p>
                {it.includes ? (
                  <p className='text-sm text-neutral-400 truncate max-w-[30%] md:max-w-[50%] lg:max-w-[100%]'>
                    {it.includes}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {idx === 2 ? (
              <p className='cursor-pointer text-sm font-semibold text-neutral-50'>1 new</p>
            ) : (
              <></>
            )}
          </div>
        ))}
    </div>
  )
}

export default Recent
