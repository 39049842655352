import React from 'react';
import moment from 'moment';
import { PieChart } from '~/components'

const AnalyticsPieChart = ({title, data}) => {
    // get percentage
    const sum = data[0] + data[1] + data[2];
    const men_percentage = data[0]/sum * 100;
    const women_percentage = data[1]/sum * 100;
    const unknown_percentage = data[2]/sum * 100;

    return (
        <div className='w-full h-full'>
            <h1 className='text-2xl font-semibold text-white mb-4'>{title}</h1>
            <div className='grid grid-cols-2 h-full'>
                <div className='w-full'>
                    <PieChart data={data}/>
                </div>
                <div>
                    <div className='grid grid-cols-2 text-white text-lg'>
                        <p>Men</p>
                        <p><b>{men_percentage.toFixed(0)}</b>% <b>{data[0]}</b> person(s)</p>
                    </div>
                    <div className='grid grid-cols-2 text-white text-lg'>
                        <p>Women</p>
                        <p><b>{women_percentage.toFixed(0)}</b>% <b>{data[1]}</b> person(s)</p>
                    </div>
                    <div className='grid grid-cols-2 text-white text-lg'>
                        <p>Unknown</p>
                        <p><b>{unknown_percentage.toFixed(0)}</b>% <b>{data[2]}</b> person(s)</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsPieChart;
