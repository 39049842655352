import React from "react";
import { useSetCameraModal } from "./hooks";
import CanvasImage from "../canvas-image";
import { Modal } from "~/components";

const SetCameraModal = ({ state, closeModal, choosenCamera }) => {
  const {
    datas: { canvasImage, boxesData },
    methods: { handleAnchorPoints, applyBoundingBoxes },
  } = useSetCameraModal({ state, choosenCamera });

  const applyThenClose = () => {
    applyBoundingBoxes();
    closeModal();
  }

  return (
    <Modal isOpen={state} closeModal={closeModal}>
      <div className="rounded-2xl bg-[#141618] shadow-xl border border-slate-200 flex-col max-w-3xl transform transition-all align-middle inline-block justify-center items-center px-8 py-3">
        <div className="justify-between items-center inline-flex w-full">
          <p className="text-slate-50 text-xl font-semibold leading-7">Set Camera</p>
          <button
            className="w-9 px-2 py-1.5 rounded-lg justify-center items-center gap-2 inline-flex"
            onClick={closeModal}
          >
            X
          </button>
        </div>
        <div className="flex flex-row text-[#DEEAF5] text-left mt-5 mb-4">
          <div className="flex flex-col justify-between max-w-96">
            <div className="flex flex-col gap-2">
              <p>
                Add and connect anchor points around the region of interest to allow for tracking.
              </p>
              <p className="font-bold text-sm">
                Last updated:{" "}
                {boxesData.updated_at
                  ? new Intl.DateTimeFormat("id-ID", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })
                      .format(new Date(boxesData.updated_at))
                      .replaceAll(".", ":")
                  : "-"}
              </p>
            </div>
            <p className="font-normal text-sm">Pro tip: click on a point to delete it.</p>
          </div>
          <div className="flex flex-col gap-2">
            <p>Example:</p>
            <img src="public/camera-example.png" alt="example-camera" width={240} />
          </div>
        </div>
        {canvasImage.length !== 0 ? (
          <>
          {canvasImage.map((imageData, idx) => (
            <div className="mb-4">
              <CanvasImage
                key={idx}
                imageData={imageData}
                handleAnchorPoints={handleAnchorPoints}
              />
            </div>))}
          </>
        ) : (
          <></>
        )}
        <div className="w-full mt-4 justify-end items-center gap-2 inline-flex">
          <div className="w-[100px] h-10 px-4 py-2.5 bg-slate-100 rounded-lg justify-center items-center gap-2 flex">
            <button onClick={closeModal} className="text-slate-900 text-base font-semibold">
              Cancel
            </button>
          </div>
          <div className="w-[161px] px-4 py-2.5 bg-sky-600 rounded-lg justify-center items-center gap-2 flex">
            <button onClick={applyThenClose} className="text-white text-base font-semibold ">
              Apply Changes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SetCameraModal;
