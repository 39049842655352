import React from 'react'
import moment from 'moment'

import { AccuracyBadges } from '~/components'

const VideoItems = ({ it, onClick }) => {
  return (
    <div data-testid='video-items' onClick={() => onClick(it)} className={'w-full relative'}>
      <img
        src={it.frames}
        alt='thumbnail'
        className={'h-[155px] w-full cursor-pointer object-cover rounded-[4px]'}
      />
      <div className={'absolute w-full top-0 left-0 p-2 flex justify-between'}>
        <div className='font-roboto font-normal text-neutral-50 text-[10px] p-1'>
          <p>{moment(new Date(it.times)).format('DD-MM-YYYY ddd h:mm:ss')}</p>
          <p>{it.locations}</p>
        </div>
        <AccuracyBadges accuracy={it.probabilities * 100} />
      </div>
    </div>
  )
}

export default VideoItems
