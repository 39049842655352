import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { Shimmer } from '~/components'
import { useStreamItem } from './hooks'
import { socket } from '~/utils/socket'
import { event, imageBast64String } from '~/constant'
import { b64toBlob } from '~/utils'

// same component as saved-video-items. if there are more list like this move it to component
const StreamItems = ({ item }) => {
  const {
    methods: { clickUploadVideo },
  } = useStreamItem({ item })

  return item !== undefined ? (
    <div className={'w-[292px] relative'}>
      <div data-testid='thumbnail' onClick={clickUploadVideo} className='cursor-pointer'>
        <div className='h-[155px]'>
          <ImageReceiver streamLink={item.rtspStream} />
        </div>
      </div>
      <div className='absolute w-[276px] top-0 left-0 p-2 flex justify-between'>
        <div className='font-roboto font-normal text-neutral-50 text-[10px] p-1'>
          <p>{moment(new Date(item.createdAt)).format('DD-MM-YYYY ddd h:mm:ss')}</p>
          <p>{item.locations}</p>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

const ImageReceiver = ({ streamLink }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [frameStream, setFrameStream] = useState('')

  useEffect(() => {
    if (frameStream.length) {
      return
    }
    setIsLoaded(false)
    // Listen for 'image' event and handle received frames

    socket.emit(event.STREAMFRAME, streamLink, (response) => {
      setFrameStream(URL.createObjectURL(b64toBlob(response, imageBast64String)))
      setIsLoaded(true)
    })
  }, [])

  return (
    <>
      <img src={frameStream} className={`${isLoaded ? 'h-full' : 'h-0'}`} alt='frame-stream' />
      <Shimmer height={isLoaded ? 0 : 150} />
    </>
  )
}

export default StreamItems
