import { store } from '~/store'
import { KeyConstant } from '~/constant'
import { getIpAddressAsync } from '~/store/slice/auth'

/**
 * Set Data to Session Storage
 * @param key - Key Name in Session Storage
 * @param value - Value
 */
export const setSessionStorage = (key, value) => {
  if (value && typeof value === 'string') {
    sessionStorage.setItem(key, value)
  } else {
    sessionStorage.setItem(key, JSON.stringify(value))
  }
}

/**
 * Get Data from Session Storage
 * @param key - Key Name in Session Storage
 */
export const getSessionStorage = (key) => {
  const data = sessionStorage.getItem(key)
  const nullToken = key === KeyConstant.Token && data === null

  if (nullToken) {
    const {
      auth: { token },
    } = store.getState()

    const isNull = token === undefined || token === null || token === ''

    store.dispatch(getIpAddressAsync())

    setSessionStorage(key, token)
    return isNull ? null : token
  }

  try {
    return JSON.parse(data)
  } catch (e) {
    return data
  }
}

/**
 * Clear Specify Key from Session Storage
 * @param key - Key Name in Session Storage
 */
export const clearSessionStorage = (key) => sessionStorage.removeItem(key)

/**
 * Clear All Session Storage
 */
export const clearAllSessionStorage = () => sessionStorage.clear()
