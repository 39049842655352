import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { axios } from "~/utils";
import { store } from "..";

const initialState = {
  timestamps: [],
  people_data: [],
  people_analytics: { average: 0, total: 0, mostFloor: 0, mostHour: 0, chartData: [] },
  people_summary: {},
  people_summary_total: 0,
  isLoadingPersonLastSeen: false,
  personLastSeen: {},
  showLoadingToastPeople: false,
};

const baseUrl = process.env.BASE_URL;

export const getPeople = createAsyncThunk("people/getPeople", async (args, { rejectWithValue }) => {
  const { location, floor, camera, site, after, before } = args;

  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/people/getPeople?location=${location}&camera=${camera}&floor=${floor}&site=${site}&after=${after}&before=${before}`,
      headers: {},
    };

    const response = await axios.request(config);
    return response.data;
  } catch (err) {
    const message = { ...err.response.data.message, ...args };
    return rejectWithValue(message);
  }
});

export const getPeopleAnalytics = createAsyncThunk(
  "people/getAnalytics",
  async (args, { rejectWithValue }) => {
    const { floor, camera, site, after, before } = args;

    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/people/getAnalytics?floor=${floor}&camera=${camera}&site=${site}&after=${after.toISOString()}&before=${before.toISOString()}`,
        headers: {},
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getPeopleSummary = createAsyncThunk(
  "people/getPeopleSummary",
  async (args, { rejectWithValue }) => {
    const { site } = args;

    const before = new Date();

    const after = new Date(before.getTime() - 10 * 60 * 1000);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/people/getPeopleSummary?site=${site}&after=${after.toISOString()}&before=${before.toISOString()}`,
      headers: {},
    };

    const response = await axios.request(config);

    try {
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);


export const exportPeople = createAsyncThunk("people/export", async (args, { rejectWithValue }) => {
  const { site, after, before, fileName } = args;

  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/people/export?site=${site}&after=${after.toISOString()}&before=${before.toISOString()}`,
      timeout: 60 * 10 * 1000
    };

    const response = await axios.request(config);

    const blob = new Blob([response.data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (err) {
    const message = { ...err.response.data.message, ...args };
    return rejectWithValue(message);
  }
});

export const getPersonLastSeen = createAsyncThunk("people/getPersonLastSeen", async (args, { rejectWithValue }) => {
  const { location, floor, camera, site } = args;
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/people/getPersonLastSeen?location=${location}&floor=${floor}&camera=${camera}&site=${site}`,
      headers: {},
    };

    const response = await axios.request(config);
    return response.data;
  } catch (err) {
    const message = { ...err.response.data.message, ...args };
    return rejectWithValue(message);
  }
});


export const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    resetpeopleStore: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPeople.pending, (state) => { })
      .addCase(getPeople.fulfilled, (state, action) => {
        state.people_data = [];
        state.timestamps = [];

        for (let i = 0; i < action.payload.data.length; i++) {
          const people_info = action.payload.data[i];
          state.timestamps.push(Date.parse(people_info.updated_at));
          state.people_data.push({ data: 1, label: people_info.floor, id: people_info.person_id, last_seen: people_info.last_seen });
        }
      })
      .addCase(getPeople.rejected, (state) => { });

    builder
      .addCase(getPeopleAnalytics.pending, (state) => { })
      .addCase(getPeopleAnalytics.fulfilled, (state, action) => {
        state.people_analytics = action.payload.analytics;
      })
      .addCase(getPeopleAnalytics.rejected, (state) => { });

    builder
      .addCase(getPeopleSummary.pending, (state) => { })
      .addCase(getPeopleSummary.fulfilled, (state, action) => {
        state.people_summary_total = action.payload.total;
        state.people_summary = action.payload.data.reduce((acc, current) => {
          acc[current.floor] = parseInt(current.count, 10);
          return acc;
        }, {});
      })
      .addCase(getPeopleSummary.rejected, (state) => { });

    builder
      .addCase(getPersonLastSeen.pending, (state) => {
        state.isLoadingPersonLastSeen = true;
      })
      .addCase(getPersonLastSeen.fulfilled, (state, action) => {
        state.isLoadingPersonLastSeen = false;
        const res = action.payload.data;
        state.personLastSeen = {
          camera_name: res.camera ? res.camera.name : action.meta.arg.camera,
          rtsp: res.camera ? res.camera.proxyStream2 : action.meta.arg.rtsp,
        };
      })
      .addCase(getPersonLastSeen.rejected, (state) => {
        state.isLoadingPersonLastSeen = false;
      });

    builder
      .addCase(exportPeople.pending, (state) => {
        state.showLoadingToastPeople = true;
      })
      .addCase(exportPeople.fulfilled, (state) => {
        state.showLoadingToastPeople = false;
      })
      .addCase(exportPeople.rejected, (state) => {
        state.showLoadingToastPeople = false;
      });
  },
});

export const { resetpeopleStore } = peopleSlice.actions;
export default peopleSlice.reducer;
