import React from 'react'

import { SearchBarHome } from '~/components'
import { getSearchAsync } from '~/store/slice/camera'
import { Filter, Info, Sort } from '~/svg'

const TopSection = ({
  openInfo,
  saveInfo,
  closeInfo,
  saveSearch,
  handleOpenSort,
  openBottomSheetFilter,
}) => {
  return (
    <div className='w-full'>
      <SearchBarHome dispatchSearch={getSearchAsync} />
      <div className='mt-5 flex items-center px-2 gap-8'>
        <div onClick={openBottomSheetFilter} className='flex items-center gap-1 cursor-pointer'>
          <Filter />
          <p className='text-sm font-semibold text-neutral-300'>Filter</p>
        </div>
        <div onClick={handleOpenSort} className='flex items-center gap-1 cursor-pointer'>
          <Sort />
          <p className='text-sm font-semibold text-neutral-300'>Sort</p>
        </div>
      </div>

      <div className='w-full h-[1px] bg-neutral-800 mt-2' />

      <div className='mt-6 flex items-center gap-[3px] text-sm'>
        <p className='text-neutral-400'>4 videos match •</p>
        <p onClick={saveSearch} className='text-primary-50 font-semibold underline cursor-pointer'>
          Save Search
        </p>
        <div
          data-testid='info-icon'
          onMouseEnter={() => openInfo(true)}
          onMouseLeave={() => closeInfo(true)}
          className='relative'>
          <Info />
          {saveInfo ? (
            <div
              data-testid='save-info'
              className='absolute left-[-87px] top-[-54px] w-[180px] bg-[#334155] rounded px-3 py-1'>
              <p className='text-start text-sm text-white'>
                Save search on Search <br /> to revisit anytime.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default TopSection
