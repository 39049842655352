import React from 'react'

import { useAccSetting } from './hooks'
import {
  AccountInformation,
  EditUserModal,
  LogoutBottomSheet,
  LogoutModal,
  PasswordModal,
  SessionTable,
} from './components'

const AccountSetting = () => {
  const {
    datas: {
      formEdit,
      editModal,
      hideState,
      tableHeader,
      dataProfile,
      userSessions,
      formPassword,
      dataVerified,
      actionVerify,
      ipAddressUser,
      passwordModal,
      tableHeadMobile,
      logoutModal,
      logoutBottomSheet,
    },
    methods: {
      handleKeyUp,
      setEditModal,
      setPassModal,
      submitPassword,
      handleChangePass,
      handleChangeEdit,
      submitEndSession,
      handleVerification,
      submitEditUser,
      submitLogout,
      handleLogout,
      setLogoutModal,
      setLogoutBottomSheet,
    },
  } = useAccSetting()
  return (
    <div className='flex flex-col px-1 md:px-0 md:w-3/4 w-full'>
      <AccountInformation
        hideState={hideState}
        dataProfile={dataProfile}
        dataVerified={dataVerified}
        handleVerification={handleVerification}
      />
      <SessionTable
        tableHeader={tableHeader}
        ipAddressUser={ipAddressUser}
        userSessions={userSessions}
        tableHeadMobile={tableHeadMobile}
        submitEndSession={submitEndSession}
        handleLogout={handleLogout}
      />
      <EditUserModal
        formEdit={formEdit}
        editModal={editModal}
        dataProfile={dataProfile}
        setEditModal={setEditModal}
        handleChangeEdit={handleChangeEdit}
        handleVerification={handleVerification}
        submitEditUser={submitEditUser}
      />
      <PasswordModal
        handleKeyUp={handleKeyUp}
        formPassword={formPassword}
        setPassModal={setPassModal}
        actionVerify={actionVerify}
        passwordModal={passwordModal}
        submitPassword={submitPassword}
        handleChangePass={handleChangePass}
      />

      <LogoutModal
        ipAddressUser={ipAddressUser}
        userSessions={userSessions}
        setLogoutModal={setLogoutModal}
        logoutModal={logoutModal}
        submitLogout={submitLogout}
      />

      <LogoutBottomSheet
        ipAddressUser={ipAddressUser}
        userSessions={userSessions}
        setLogoutBottomSheet={setLogoutBottomSheet}
        logoutBottomSheet={logoutBottomSheet}
        submitLogout={submitLogout}
      />
    </div>
  )
}

export default AccountSetting
