import React, { useState, useEffect, useRef } from 'react';

import { IconX } from '~/svg';
import { socket } from '~/utils/socket';
import { event, imageBast64String } from '~/constant';
import { Button, Modal, VideoPlayer, Shimmer } from '~/components';
import { b64toBlob } from '~/utils';

const Preview = ({
  isOpen,
  closeModal,
  name,
  rtsp
}) => {
  const videoPlayerRef = useRef();

  useEffect(() => {
    if(!isOpen){
      socket.emit(event.STOPSTREAM, () => {});
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className='hidden md:block'>
        <div className='overflow-hidden border-[1px] border-neutral-200 rounded-2xl pb-4 w-[844px]'>
          <div className='flex w-full justify-between items-center py-[10px] px-6 mb-2'>
            <p className='ml-2 text-xl font-semibold text-neutral-50'>{name}</p>
            <div onClick={closeModal} className='cursor-pointer'>
              <IconX />
            </div>
          </div>

          <div className='w-full'>
            <ImageReceiver streamLink={rtsp}/>
          </div>
          <div className='flex w-full justify-end px-8 py-[14px] mt-5'>
            <Button small type={'primary'} onClick={closeModal}>
              <p>Close</p>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ImageReceiver = ({ streamLink }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [frameStream, setFrameStream] = useState('');

  useEffect(() => {
    if (frameStream.length) {
      return;
    }
    setIsLoaded(false);
    // Listen for 'image' event and handle received frames
    if(streamLink !== ""){
      socket.emit(event.REQSTREAM, streamLink);
    }

    socket.on(event.IMAGERTSP, (response) => {
      setFrameStream(URL.createObjectURL(b64toBlob(response, imageBast64String)));
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      <img src={frameStream} className={`${isLoaded ? 'h-full' : 'h-0'}`} alt='frame-stream' />
      <Shimmer height={isLoaded ? 0 : 150} />
    </>
  );
};

export default Preview;
