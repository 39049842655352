import React from 'react'
import { CevronLeft } from '~/svg'
import { Button } from '~/components'
import { useAnalyticsDetail } from './hooks'
import { CameraAnalyticDetail, VideoAnalyticDetail } from './components'

const AnalyticsDetail = () => {
  const {
    datas: { type, id, videoDetail },
    methods: { backPreviousPage },
  } = useAnalyticsDetail()

  return (
    <div className='w-full mx-auto pt-10'>
      <div className='flex flex-col text-neutral-200 gap-2 md:w-3/4 mx-auto px-4'>
        <div className='flex flex-row gap-1 items-center'>
          <div className='cursor-pointer' onClick={backPreviousPage}>
            <CevronLeft />
          </div>
          <p className='font-semibold text-xl md:text-2xl'>
            Search from {videoDetail.label || 'StreamName'}
          </p>
        </div>
      </div>
      {type === 'camera' ? <CameraAnalyticDetail /> : <VideoAnalyticDetail videoID={id} />}
      <div className='fixed bottom-0 left-0 z-50 bg-basic-black w-full border-solid border-neutral-800 border-t-[2px] mt-5'>
        <div className='pt-4 flex md:flex-row flex-col-reverse md:justify-end w-full gap-2 md:w-2/3 mx-auto px-4 pb-5'>
          <div className='w-full md:w-fit'>
            <Button type={'primary'} onClick={backPreviousPage}>
              Back to Analytics
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnalyticsDetail
