import React from 'react'

function NoAlertToShow() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='231'
      height='222'
      fill='none'
      viewBox='0 0 231 222'>
      <path
        fill='#393F44'
        stroke='#393F44'
        d='M172.143 58.366c9.743 12.623 19.145 26.977 24.639 41.709 5.495 14.734 7.064 29.801 1.207 43.888-5.856 14.084-22.058 28.335-41.532 37.792-19.466 9.454-42.064 14.048-60.63 8.971-18.628-5.093-34.24-15.604-45.674-27.078-11.446-11.485-18.66-23.887-20.553-32.723-.927-4.328.304-10.151 2.978-16.814 2.666-6.642 6.731-14.036 11.366-21.466 8.586-13.764 19.093-27.6 26.193-36.951l1.63-2.147c13.97-18.432 36.519-27.306 57.45-23.5 10.433 1.896 21.156 8.95 29.307 15.58a140.07 140.07 0 019.87 8.862 119.957 119.957 0 012.78 2.833 69.205 69.205 0 01.822.882l.097.107.047.051.003.004z'
        opacity='0.8'></path>
      <path
        stroke='#7B8691'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M43.94 153.404H189.01'></path>
      <path
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M43.941 153.894H189.01'></path>
      <path fill='#9AA7B4' stroke='#393F44' d='M85.752 153.26v-49.351h64.055v49.351H85.752z'></path>
      <path
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.5'
        d='M150.31 103.409h-14.937v50.351h14.937v-50.351z'></path>
      <path
        fill='#B6C3D1'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='0.5'
        d='M146 104h-6v49h6v-49z'></path>
      <path
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M150.307 103.409H85.252v50.351h65.055v-50.351z'></path>
    </svg>
  )
}

export default NoAlertToShow
