import { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  getSavedVideoAsync,
  initVideoLoading,
  postVideoAlert,
  uploadVideoSearch,
} from '~/store/slice/video'
import { checkIsVideo, getFileExtension } from '~/utils'
import { useDispatch, useSelector } from 'react-redux'

export function useUploadedVideos() {
  const dispatch = useDispatch()

  const savedVideos = useSelector((state) => state.video.savedVideos) || []
  const isLoadingVideo = useSelector((state) => state.video.isLoading)
  const submitLoadingVideo = useSelector((state) => state.video.submitLoading)
  const progressUploaded = useSelector((state) => state.video.progressUpload) || 0

  const [searchUploaded, setSearchUploaded] = useState('')

  useEffect(() => {
    dispatch(initVideoLoading())
    dispatch(getSavedVideoAsync({ keyword: searchUploaded }))
  }, [])

  function handleChangeUploadedSearch(e) {
    setSearchUploaded(e.target.value)
  }

  function handleSubmitSearchUpload(e) {
    dispatch(getSavedVideoAsync({ keyword: searchUploaded }))
    return e
  }

  // upload files
  const onDrop = useCallback((acceptedFiles) => {
    const ext = getFileExtension(acceptedFiles[0].name)

    if (acceptedFiles.length > 1) {
      alert('Upload just 1 Video File')
      return
    }

    if (!checkIsVideo(ext)) {
      alert('Upload just 1 Video File')
      return
    }

    dispatch(
      uploadVideoSearch({
        files: acceptedFiles[0],
      }),
    )

    // instantly submit upload
    dispatch(postVideoAlert())
  }, [])
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true })

  useEffect(() => {
    if (!submitLoadingVideo) {
      dispatch(getSavedVideoAsync())
    }
  }, [submitLoadingVideo])

  return {
    datas: {
      savedVideos,
      searchUploaded,
      isLoadingVideo,
      progressUploaded,
      submitLoadingVideo,
    },
    methods: {
      open,
      getRootProps,
      getInputProps,
      handleSubmitSearchUpload,
      handleChangeUploadedSearch,
    },
  }
}
