import React from 'react'

function ExclamationCircle() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        fill='#F95151'
        fillRule='evenodd'
        d='M14.4 8A6.4 6.4 0 111.6 8a6.4 6.4 0 0112.8 0zm-5.6 3.2a.8.8 0 11-1.6 0 .8.8 0 011.6 0zM8 4a.8.8 0 00-.8.8V8a.8.8 0 101.6 0V4.8A.8.8 0 008 4z'
        clipRule='evenodd'></path>
    </svg>
  )
}

export default ExclamationCircle
