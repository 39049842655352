import React from 'react'

import { FireOutline } from '~/svg'

const DangerAlert = ({ alertData }) => {
  return (
    <div
      className='flex w-[154px] gap-[6px] rounded-lg px-2 py-[2px]'
      style={{ backgroundColor: alertData.color }}>
      <FireOutline />
      <div className='text-neutral-50 text-xs'>
        <p className='font-semibold'>{alertData.name}</p>
        <p>{alertData.location}</p>
      </div>
    </div>
  )
}

export default DangerAlert
