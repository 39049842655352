import React, { useEffect, useRef, useState } from "react";
import { Shimmer } from "~/components";
import { socket } from "~/utils/socket";
import { event, imageBast64String } from "~/constant";
import { b64toBlob } from "~/utils";

const RSTPVideoStream = ({ name, url }) => {
  const videoPlayerRef = useRef();

  useEffect(() => {
    return () => {
      socket.emit(`stop_${url}`, () => {});
    };
  }, []);

  return (
    <div className="w-full">
      <div className="relative w-full">
        <p className="absolute top-0 left-0 m-2 text-base text-neutral-400">{name}</p>
      </div>
      <ImageReceiver streamLink={url} />
    </div>
  );
};

const ImageReceiver = ({ streamLink }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [frameStream, setFrameStream] = useState("");

  useEffect(() => {
    if (frameStream.length) {
      return;
    }
    setIsLoaded(false);
    // Listen for 'image' event and handle received frames
    if (streamLink !== "") {
      socket.emit(event.REQSTREAM, streamLink);
    }

    socket.on(`stream_${streamLink}`, (response) => {
      setFrameStream(URL.createObjectURL(b64toBlob(response, imageBast64String)));
      setIsLoaded(true);
    });
  }, []);

  return (
    <>
      <img src={frameStream} className={`${isLoaded ? "h-full" : "h-0"}`} alt="frame-stream" />
      <Shimmer height={isLoaded ? 0 : 150} />
    </>
  );
};

export default RSTPVideoStream;
