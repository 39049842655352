import React from "react";
import { PENDING_ALERTS } from "~/constant";
import {
  CevronRight,
  MajorFire,
  WallCrack,
  FallDetection,
  PeopleLoitering,
} from "~/svg";

import StatusBadges from "../status-badges";

const PendingAlerts = ({
  type,
  site,
  status,
  date,
  location,
  onClick,
  selected,
}) => {
  return (
    <div
      onClick={onClick}
      className={`w-full p-4 border-[1px] ${
        selected
          ? "border-[#D81E1E] bg-[#A31212] bg-opacity-30"
          : "border-neutral-700"
      } rounded-lg flex justify-between items-center cursor-pointer`}
    >
      <div className="flex items-start gap-2">
        {type === PENDING_ALERTS.MAJOR_FIRE ? (
          <MajorFire />
        ) : type === PENDING_ALERTS.WALL_CRACK ? (
          <WallCrack />
        ) : type === PENDING_ALERTS.FALL_DETECTION ? (
          <FallDetection />
        ) : type === PENDING_ALERTS.PEOPLE_LOITERING ? (
          <PeopleLoitering />
        ) : type === PENDING_ALERTS.UNGUARDED ? (
          <PeopleLoitering />
        ) : (
          <></>
        )}
        <div>
          <div className="flex items-center gap-2">
            {type === PENDING_ALERTS.MAJOR_FIRE ? (
              <p className="font-semibold text-sm text-danger-200">
                Fire/smoke
              </p>
            ) : type === PENDING_ALERTS.WALL_CRACK ? (
              <p className="font-semibold text-sm text-neutral-50">
                Wall Crack
              </p>
            ) : type === PENDING_ALERTS.FALL_DETECTION ? (
              <p className="font-semibold text-sm text-neutral-50">
                Falling person
              </p>
            ) : type === PENDING_ALERTS.PEOPLE_LOITERING ? (
              <p className="font-semibold text-sm text-neutral-50">Loitering</p>
            ) : type === PENDING_ALERTS.UNGUARDED ? (
              <p className="font-semibold text-sm text-neutral-50">Un-guarded</p>
            ) : (
              <></>
            )}
            <p className="text-sm font-semibold text-neutral-200">at {site}</p>
            <StatusBadges status={status} />
          </div>
          <div className="flex items-center gap-4 mt-2">
            <p className="text-sm font-normal text-neutral-200">{date}</p>
            <p className="text-sm font-normal text-neutral-200">{location}</p>
          </div>
        </div>
      </div>
      <CevronRight />
    </div>
  );
};

export default PendingAlerts;
