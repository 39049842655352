import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Search } from "~/svg";
import { PATH, STATUS } from "~/constant";
import { Pagination, PendingAlerts, Datepicker } from "~/components";

const ListAlerts = ({
  page,
  limit,
  keyword,
  total_data,
  selectAlert,
  todayAlerts,
  searchAlerts,
  changeKeyword,
  selectedAlert,
  pendingAlerts,
  nonPendingAlerts,
  dateRange,
  setDateRange,
  showDatePicker,
  setShowDatePicker,
  fetchChangePage,
  yesterdayAlerts,
  countAlertRead,
  displayDate = { displayDate },
}) => {

  const filterAlerts = (it, keyword) => {
    return it.type.toLowerCase().replace("_", " ").includes(keyword.toLowerCase()) ||
    it.camera_name.toLowerCase().includes(keyword.toLowerCase()) ||
    it.floor.toLowerCase().includes(keyword.toLowerCase());
  };

                  
  const countAlerts = pendingAlerts.length + nonPendingAlerts.length;
  return (
    <div className="w-full md:w-[407px] md:min-w-96 h-full md:border-r-[1px] md:border-r-neutral-800 px-4 md:px-8 pt-6 md:pt-9 md:overflow-y-scroll">
      <div className="w-full flex items-center justify-between">
        <p className="text-neutral-200 font-semibold text-xl md:text-2xl">Incident Alerts</p>
        <div className="w-48">
          <button
            data-testid="dropdown-button"
            className="w-full px-4 py-2 flex flex-row justify-between items-center bg-grey-800 border border-grey-600 rounded-lg"
            onClick={() => {
              setShowDatePicker(!showDatePicker);
            }}
          >
            <p className="leading-loose text-neutral-50">{displayDate}</p>
          </button>
          {showDatePicker ? (
            <div className="absolute">
              <Datepicker
                state={dateRange}
                show={showDatePicker}
                setState={setDateRange}
                closeModal={() => {
                  setShowDatePicker(!showDatePicker);
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <form onSubmit={searchAlerts}>
        <div className="w-full flex justify-center h-12 rounded-lg border-[1px] border-neutral-600 mt-8">
          <div className="w-full h-fulll">
            <input
              type="text"
              id="search"
              name="search"
              value={keyword}
              onChange={changeKeyword}
              placeholder="Incident Type"
              className="h-full w-full text-base text-neutral-50 placeholder:text-neutral-400 bg-transparent outline-none border-none px-4 truncate"
            />
          </div>
          <button
            type="submit"
            className="w-[60px] flex justify-center items-center cursor-pointer border-l-[2px] border-l-neutral-600"
          >
            <Search />
          </button>
        </div>
      </form>
      {pendingAlerts.length > 0 && pendingAlerts.length <= countAlerts && (
        <div className="mt-6">
          <p className="text-neutral-50 font-semibold text-xl">
            Pending Alerts ({keyword === "" ? pendingAlerts?.length : pendingAlerts.filter(it => filterAlerts(it, keyword))?.length})
          </p>
          {pendingAlerts.length > 0 ? (
            <div className="grid mt-2 w-full grid-cols-1 gap-2">
              {pendingAlerts &&
                pendingAlerts.map((it, idx) =>
                  keyword === "" ||
                  it.type.toLowerCase().replace("_", " ").includes(keyword.toLowerCase()) ||
                  it.camera_name.toLowerCase().includes(keyword.toLowerCase()) ||
                  it.floor.toLowerCase().includes(keyword.toLowerCase()) ? (
                    <PendingAlerts
                      key={idx}
                      type={it.type}
                      site={it.site_name}
                      location={it.floor}
                      status={it.seen ? STATUS.RESOLVED : STATUS.PENDING}
                      date={moment(new Date(it.start_time)).format("DD MMM YYYY, HH:mm")}
                      onClick={() => selectAlert(it)}
                      selected={selectedAlert?.id === it.id}
                    />
                  ) : (
                    <></>
                  )
                )}
            </div>
          ) : (
            <p className="text-neutral-200 mt-2">No incidents detected.</p>
          )}
        </div>
      )}

      {nonPendingAlerts.length > 0 && nonPendingAlerts.length <= countAlerts && (
        <div className="mt-6">
          <p className="text-neutral-50 font-semibold text-xl">Alerts ({countAlertRead})</p>
          {nonPendingAlerts.length > 0 ? (
            <div className="grid mt-2 w-full grid-cols-1 gap-2">
              {nonPendingAlerts &&
                nonPendingAlerts.map((it, idx) => (
                  <PendingAlerts
                    key={idx}
                    type={it.type}
                    site={it.site_name}
                    location={it.floor}
                    status={STATUS.RESOLVED}
                    date={moment(new Date(it.start_time)).format("DD MMM YYYY, HH:mm")}
                    onClick={() => selectAlert(it)}
                    selected={selectedAlert?.id === it.id}
                  />
                ))}
            </div>
          ) : (
            <p className="text-neutral-200 mt-2">No incidents detected.</p>
          )}
        </div>
      )}
      <div className="flex justify-center">
        <Pagination
          page={page}
          fetchChangePage={fetchChangePage}
          limit={limit}
          total_data={countAlertRead}
        />
      </div>
    </div>
  );
};

export default ListAlerts;
