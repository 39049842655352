import React from 'react'

function Pencil() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Icon/Outline/pencil'>
        <path
          id='Icon'
          d='M10.1548 3.48816L12.5118 5.84518M11.1548 2.48816C11.8057 1.83728 12.8609 1.83728 13.5118 2.48816C14.1627 3.13903 14.1627 4.1943 13.5118 4.84518L4.33333 14.0237H2V11.6429L11.1548 2.48816Z'
          stroke='#DEEAF5'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  )
}

export default Pencil
