import React, { useState } from 'react'
import { SearchTypes } from '~/constant'

import { IconX, Info, Upload } from '~/svg'

const Dropzone = ({
  open,
  closeUpload,
  getRootProps,
  getInputProps,
  selectSearchType,
  selectedSearchType,
}) => {
  const [infoDropZone, setInfoDropZone] = useState(false)

  return (
    <div className='w-full rounded-lg mt-1 bg-neutral-900 border border-neutral-700 p-2'>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='w-full bg-neutral-900 border-[2px] border-neutral-700 border-dashed rounded-xl flex flex-col justify-center items-center py-12 gap-4 relative'>
          <div onClick={open} className='cursor-pointer'>
            <Upload />
          </div>
          <p className='text-normal text-neutral-200 text-sm'>
            <span onClick={open} className='text-primary-200 font-semibold cursor-pointer'>
              Upload
            </span>{' '}
            an image
          </p>
          <p className='text-xs text-neutral-200'>Accepts .jpg, .jpeg, and .png</p>
          <div onClick={closeUpload} className='absolute top-5 right-5 cursor-pointer'>
            <IconX />
          </div>
        </div>
      </div>
      <div className='text-neutral-200 pt-2 flex flex-row gap-4 items-center'>
        <p>I&apos;m searching for a</p>
        <div className='flex flex-row text-neutral-200 font-semibold mt-1'>
          <button
            data-testid='description-button'
            className={`border ${
              selectedSearchType === SearchTypes.FACE
                ? 'bg-primary-900 border-primary-600'
                : 'border-neutral-600'
            }  rounded-l-lg px-3 py-[6px]`}
            onClick={() => selectSearchType(SearchTypes.FACE)}>
            {SearchTypes.FACE}
          </button>
          <button
            data-testid='both-button'
            className={`border ${
              selectedSearchType === SearchTypes.PERSON
                ? 'bg-primary-900 border-primary-600'
                : 'border-neutral-600'
            }  rounded-r-lg px-3 py-[6px]`}
            onClick={() => selectSearchType(SearchTypes.PERSON)}>
            {SearchTypes.PERSON}
          </button>
        </div>
        <div
          className='relative'
          onMouseEnter={() => setInfoDropZone(true)}
          onMouseLeave={() => setInfoDropZone(false)}>
          <Info />
          {infoDropZone ? (
            <div className='absolute left-[-121px] top-[-138px] w-[242px] bg-[#334155] rounded px-2 py-1'>
              <p className='text-start text-sm text-white'>
                System will use a better model based on what you search.
                <br />
                <br /> For person, we recommend uploading multiple full body shots.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default Dropzone
