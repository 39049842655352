import React from 'react'

function LockedLogo() {
  return (
    <svg
      width='93'
      height='104'
      viewBox='0 0 93 104'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M46.2615 1C39.2648 7.65527 21.3686 14.3788 0.499268 28.6401C0.499268 28.6401 1.80196 76.8291 46.2615 103.172V1Z'
        fill='#9AA7B4'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M46.261 1C53.2524 7.65527 71.1539 14.3788 92.0232 28.6401C92.0232 28.6401 90.7205 76.8291 46.261 103.172V1Z'
        fill='#9AA7B4'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M46.2607 5.10254C37.8195 11.0855 23.1905 16.79 3.85498 30.0007C3.85498 30.0007 5.06313 74.6494 46.2607 99.0695C87.4583 74.6599 88.6664 30.0007 88.6664 30.0007C69.3256 16.7952 54.7649 11.0855 46.2607 5.10254Z'
        fill='#9AA7B4'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M62.3239 50.1239H30.6969C30.0004 50.1239 29.3324 49.8472 28.8398 49.3546C28.3473 48.8621 28.0706 48.194 28.0706 47.4975V32.6111C28.1522 27.7772 30.1297 23.1689 33.577 19.7793C37.0243 16.3897 41.6654 14.4902 46.4999 14.4902C51.3345 14.4902 55.9756 16.3897 59.4229 19.7793C62.8702 23.1689 64.8477 27.7772 64.9293 32.6111V47.4975C64.9293 48.1904 64.6555 48.8553 64.1675 49.3472C63.6795 49.8392 63.0168 50.1183 62.3239 50.1239ZM33.3443 44.8711H59.6975V32.6111C59.6287 29.162 58.2102 25.8774 55.7466 23.4625C53.283 21.0476 49.9707 19.695 46.521 19.695C43.0712 19.695 39.7589 21.0476 37.2953 23.4625C34.8317 25.8774 33.4132 29.162 33.3443 32.6111V44.8711Z'
        fill='#7B8691'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M66.3791 42.9487H26.1428V76.95H66.3791V42.9487Z'
        fill='#7B8691'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g clipPath='url(#clip0_1573_28185)'>
        <path
          d='M45.9993 70.125C50.7627 70.125 54.6243 66.2635 54.6243 61.5C54.6243 56.7365 50.7627 52.875 45.9993 52.875C41.2358 52.875 37.3743 56.7365 37.3743 61.5C37.3743 66.2635 41.2358 70.125 45.9993 70.125Z'
          fill='#5A626B'
          stroke='#393F44'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M42.4055 62.2188L44.5618 64.375L49.593 59.3438'
          stroke='#CCDAE7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1573_28185'>
          <rect width='23' height='23' fill='white' transform='translate(34.4993 50)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LockedLogo
