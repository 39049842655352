import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import { storage } from "./storage";
import authSlice from "./slice/auth";
import alertSlice from "./slice/alert";
import notifSlice from "./slice/notif";
import cameraSlice from "./slice/camera";
import accountSlice from "./slice/account";
import counterSlice from "./slice/counter";
import videoSlice from "./slice/video";
import peopleSlice from "./slice/people";
import incidentSlice from "./slice/incident";
import settingSlice from "./slice/setting";
import machineSlice from "./slice/machine";

// BlackList Search Keyword From Persist
const cameraConfig = {
  key: "camera",
  storage,
  blacklist: ["searchResult", "savedsearch"],
};

const accountConfig = {
  key: "account",
  storage,
};

const alertConfig = {
  key: "alert",
  storage,
  blacklist: ["selectedAlert"],
};

const notifConfig = {
  key: "notification",
  storage,
};

const videoConfig = {
  key: "video",
  storage,
};

const peopleConfig = {
  key: "people",
  storage,
};

const incidentConfig = {
  key: "incident",
  storage,
};

const settingConfig = {
  key: "incident",
  storage,
};

const machineConfig = {
  key: "machine",
  storage,
};

// All Reducer will be import in here
export const rootReducer = combineReducers({
  auth: authSlice,
  counter: counterSlice,
  alert: persistReducer(alertConfig, alertSlice),
  video: persistReducer(videoConfig, videoSlice),
  camera: persistReducer(cameraConfig, cameraSlice),
  account: persistReducer(accountConfig, accountSlice),
  notification: persistReducer(notifConfig, notifSlice),
  people: persistReducer(peopleConfig, peopleSlice),
  incident: persistReducer(incidentConfig, incidentSlice),
  settings: persistReducer(settingConfig, settingSlice),
  machine: persistReducer(machineConfig, machineSlice),
});
