import React from 'react'

import { Shimmer } from '~/components'

const SearchShimmer = () => {
  const items = [1, 2, 3, 4, 5, 6]
  const mobileItems = [1, 2, 3, 4]
  return (
    <>
      <div className='hidden md:block w-full'>
        <div className='flex w-full mt-10 gap-8 pb-[5vh]'>
          <div className='w-60 pl-8'>
            <div className='w-10'>
              <Shimmer height={16} />
            </div>
            <div className='w-full mt-7'>
              <Shimmer height={700} />
            </div>
          </div>
          <div className='w-full pr-32'>
            <div className='flex w-full justify-between'>
              <div className='w-60'>
                <Shimmer height={20} />
              </div>
              <div className='w-60'>
                <Shimmer height={20} />
              </div>
            </div>
            <div className='w-full grid grid-cols-3 gap-6 mt-6'>
              {items &&
                items.map((it) => (
                  <div key={it} className='w-full'>
                    <Shimmer height={155} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className='block md:hidden w-full'>
        <div className='w-fulll mx-4'>
          <Shimmer height={24} />
        </div>
        <div className='mt-[18px] flex gap-10 ml-8'>
          <div className='w-12'>
            <Shimmer height={24} />
          </div>
          <div className='w-12'>
            <Shimmer height={24} />
          </div>
        </div>
        <div className='w-full h-[1px] bg-neutral-800 mt-6' />
        <div className='mt-6 w-60 ml-4'>
          <Shimmer height={24} />
        </div>
        <div className='grid w-full grid-cols-1 p-4 gap-4'>
          {mobileItems &&
            mobileItems.map((it) => (
              <div key={it} className='w-full'>
                <Shimmer height={155} />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default SearchShimmer
