import React from 'react'

import { CevronDown, IconX, Trash, Upload } from '~/svg'
import { Button, Input, Modal } from '~/components'
import { ALERTMODALTYPE, ALERTNOTIFICATION, ALERTTYPE, SEARCH_INCIDENT } from '~/constant'

const ModalIncident = ({
  open,
  loopRef,
  chooseLoop,
  loopOptions,
  changeSearch,
  formIncident,
  getRootProps,
  incidentModal,
  getInputProps,
  setDropdownLoop,
  openModalPassword,
  addNotifications,
  setincidentModal,
  IconNotification,
  setOverrideAlert,
  dropdownLoopState,
  handleChangeIncident,
  handleSubmitIncident,
}) => {
  function isDescription(type) {
    return type === SEARCH_INCIDENT.DESCRIPTION
  }
  function isImage(type) {
    return type === SEARCH_INCIDENT.IMAGE
  }
  function isBoth(type) {
    return type === SEARCH_INCIDENT.BOTH
  }
  return (
    <Modal isOpen={incidentModal} closeModal={() => setincidentModal(false)} notOverflow>
      <div className='flex flex-col py-2 px-8 w-[600px]'>
        <div className='flex flex-row w-full justify-between max-md:border-b max-md:border-neutral-800 mt-3'>
          <p className='w-full text-neutral-50 font-semibold text-sm md:text-xl text-center md:text-left '>
            New Incident Alert
          </p>
          <div
            data-testid='close-modal'
            className='flex max-md:hidden cursor-pointer items-center'
            onClick={() => setincidentModal(false)}>
            <IconX />
          </div>
        </div>
        <div className='flex flex-row gap-5 mt-5'>
          <div className='flex flex-col gap-1 w-full'>
            <p className='font-semibold text-neutral-200'>Incident name</p>
            <Input
              name={'name'}
              type={'text'}
              value={formIncident.name}
              onChange={handleChangeIncident}
            />
          </div>
        </div>
        <p className='font-semibold mt-6 text-neutral-200'>Search by</p>
        <p className='font-semibold mt-1 text-neutral-400'>
          These will help the system detect and alert the incident.
        </p>
        <div className='flex flex-row text-neutral-200 font-semibold mt-1'>
          <div
            data-testid='description-button'
            className={`border ${
              isDescription(formIncident.keyword_type) ? 'bg-primary-900' : ''
            } border-neutral-600 rounded-l-lg p-3 cursor-pointer`}
            onClick={() => changeSearch(SEARCH_INCIDENT.DESCRIPTION)}>
            {SEARCH_INCIDENT.DESCRIPTION}
          </div>
          <div
            data-testid='image-button'
            className={`border ${
              isImage(formIncident.keyword_type) ? 'bg-primary-900' : ''
            } border-neutral-600 p-3 cursor-pointer`}
            onClick={() => changeSearch(SEARCH_INCIDENT.IMAGE)}>
            {SEARCH_INCIDENT.IMAGE}
          </div>
          <div
            data-testid='both-button'
            className={`border ${
              isBoth(formIncident.keyword_type) ? 'bg-primary-900' : ''
            } border-neutral-600 rounded-r-lg p-3 cursor-pointer`}
            onClick={() => changeSearch(SEARCH_INCIDENT.BOTH)}>
            {SEARCH_INCIDENT.BOTH}
          </div>
        </div>
        {isDescription(formIncident.keyword_type) || isBoth(formIncident.keyword_type) ? (
          <div>
            <div className='w-full flex flex-row justify-between mt-5 py-3'>
              <p className='font-semibold text-neutral-200 text-lg'>Description</p>
              <p className='font-semibold text-neutral-400 text-lg'>
                {formIncident.description.length} / 200
              </p>
            </div>{' '}
            <textarea
              maxLength={200}
              rows={3}
              name={'description'}
              value={formIncident.description}
              onChange={handleChangeIncident}
              className='w-full border border-neutral-600 text-neutral-400 px-4 py-3 bg-neutral-900 rounded-lg resize-none'
            />
          </div>
        ) : (
          <></>
        )}
        {isImage(formIncident.keyword_type) || isBoth(formIncident.keyword_type) ? (
          <div className='w-full bg-neutral-900 border-[2px] border-neutral-700 border-dashed rounded-2xl mt-6 p-2'>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className='w-full  flex flex-col justify-center items-center py-12 gap-4 relative'>
                <div onClick={open} className='cursor-pointer'>
                  <Upload />
                </div>
                <p className='text-normal text-neutral-200 text-sm'>
                  <span onClick={open} className='text-primary-200 font-semibold cursor-pointer'>
                    Upload
                  </span>{' '}
                  an image
                </p>
                <p className='text-xs text-neutral-200'>Accepts .jpg, .jpeg, and .png</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {!formIncident.is_override_alert ? (
          <div className='flex flex-row gap-2 pt-6'>
            {formIncident.apps_notification.map((a, akey) => {
              return <IconNotification n={a} key={akey} />
            })}
            <p className='text-sm text-neutral-200'>every {formIncident.loop} mins (default)</p>
            <p
              data-testid='override-button'
              className='text-sm text-primary-50 underline font-semibold cursor-pointer'
              onClick={() => setOverrideAlert(true)}>
              Override
            </p>
          </div>
        ) : (
          <div>
            <p className='font-semibold text-neutral-200 text-lg mt-6'>Notification</p>
            <div className='flex flex-row gap-2'>
              {Object.keys(ALERTNOTIFICATION).map((app) => {
                return (
                  <div
                    data-testid='notification-button'
                    className={`flex flex-row px-3 py-1 w-fit border border-neutral-600 rounded-lg cursor-pointer gap-2 items-center ${
                      formIncident.apps_notification.includes(ALERTNOTIFICATION[app])
                        ? 'bg-primary-900 border-primary-600'
                        : 'bg-neutral-900 border-neutral-600'
                    }`}
                    key={app}
                    onClick={() => addNotifications(ALERTNOTIFICATION[app], ALERTTYPE.INCIDENT)}>
                    <IconNotification n={ALERTNOTIFICATION[app]} />
                    <p className='text-neutral-200 font-medium text-sm'>{ALERTNOTIFICATION[app]}</p>
                  </div>
                )
              })}
            </div>
            <div className='flex flex-row justify-between items-center mt-6 gap-4'>
              <p className='font-semibold text-neutral-200 text-base w-full'>
                Loop until status change
              </p>
              <div className='flex flex-col gap-1 w-full' ref={loopRef}>
                <div className='relative inline-block text-left'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-between gap-x-1.5 rounded-md px-3 py-4 text-sm font-semibold text-neutral-400 shadow-sm ring-1 ring-neutral-600 items-center'
                    id='menu-button'
                    aria-expanded='true'
                    aria-haspopup='true'
                    onClick={() => setDropdownLoop(!dropdownLoopState)}>
                    {formIncident.loop == 0 ? 'No looping' : `${formIncident.loop} mins`}
                    <CevronDown customSize={'w-2'} />
                  </button>
                </div>
                {dropdownLoopState ? (
                  <div
                    className='absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-neutral-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                    role='menu'
                    aria-orientation='vertical'
                    aria-labelledby='menu-button'
                    tabIndex='-1'>
                    <div className='py-1 rounded-md' role='none'>
                      <div
                        data-testid='no-loop-button'
                        className='flex flex-row gap-3 py-3 items-center border-b border-neutral-800 px-4 cursor-pointer'
                        onClick={() => chooseLoop(0, ALERTTYPE.INCIDENT)}>
                        <p className='text-neutral-200'>No looping</p>
                      </div>
                      {loopOptions.map((l, lkey) => {
                        return (
                          <div
                            data-testid='loop-button'
                            key={lkey}
                            className='flex flex-row gap-3 items-center py-3 px-4 cursor-pointer'
                            onClick={() => chooseLoop(l, ALERTTYPE.INCIDENT)}>
                            <p className='text-neutral-200 h-fit text-sm'>{l} mins</p>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        )}
        <div className='flex md:flex-row flex-col justify-between mt-8 items-center'>
          <div className='flex flex-row gap-2'>
            {formIncident.incident_id.length ? (
              <div
                data-testid='delete-button'
                className='flex flex-row gap-2 cursor-pointer'
                onClick={() =>
                  openModalPassword(
                    ALERTMODALTYPE.DELETE,
                    ALERTTYPE.INCIDENT,
                    formIncident.name,
                    formIncident,
                  )
                }>
                <Trash className='stroke-danger-200' />
                <p className='text-danger-200 font-semibold'>Delete Alert</p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className='flex md:flex-row flex-col md:justify-end gap-2'>
            <div className='w-full md:w-fit'>
              <Button
                data-testid='cancel'
                type={'secondary'}
                onClick={() => setincidentModal(false)}>
                Cancel
              </Button>
            </div>
            <div className='w-full md:w-fit'>
              <Button data-testid='save' type={'primary'} onClick={handleSubmitIncident}>
                Set Alert
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalIncident
