import React from "react";

function CheckBox() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5259_1700)">
        <rect width="20" height="20" rx="6" fill="#20EBFF" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.061 5.42238C15.5182 5.73229 15.6375 6.35411 15.3276 6.81124L10.243 14.3112C10.077 14.5562 9.81098 14.715 9.51659 14.745C9.2222 14.7749 8.92965 14.673 8.71765 14.4665L4.80221 10.6536C4.40654 10.2683 4.39814 9.6352 4.78345 9.23953C5.16876 8.84386 5.80187 8.83546 6.19754 9.22077L9.25759 12.2007L13.6722 5.68895C13.9821 5.23182 14.6039 5.11247 15.061 5.42238Z"
          fill="#1C1C1C"
        />
      </g>
      <defs>
        <clipPath id="clip0_5259_1700">
          <rect width="20" height="20" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CheckBox;
