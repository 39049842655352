import React from 'react'

function CheckedAvatar() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <rect width='24' height='24' fill='#105F9C' rx='12'></rect>
      <path
        stroke='#DEEAF5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M6.167 12.833L9.5 16.166l8.333-8.333'></path>
    </svg>
  )
}

export default CheckedAvatar
