import React from 'react'

import { Button, ButtonLoading, Input } from '~/components'

import ErrToast from '../toast'

const ForgotPassword = ({
  form,
  errToast,
  errCount,
  isLoading,
  closeToast,
  handleChange,
  checkValidEmail,
  backToLogin,
  handleSendOTP,
}) => {
  return (
    <div className='relative w-full md:w-[431px]'>
      {errToast ? (
        <div className='absolute top-[-140px] w-full left-0 px-8 md:px-0'>
          <ErrToast errCount={errCount} closeToast={closeToast} />
        </div>
      ) : (
        <></>
      )}

      <div className='md:border-[1px] md:border-neutral-700 px-9 py-10 mx-0 w-full rounded-lg rounded-tl-none'>
        <p className='mt-2 mb-2 leading-7 text-xl font-semibold font-sans text-neutral-200 text-center'>
          Forgot Password
        </p>
        <p className='mt-2 mb-2 leading-5 text-sm font-normal font-sans text-neutral-200'>
          Enter your email to get an OTP code to reset your account password.
        </p>
        <form className='w-full'>
          <p className='leading-6 font-semibold font-sans text-base text-neutral-200 mb-1'>Email</p>
          <Input
            type={'text'}
            name={'email'}
            value={form.email}
            error={form.errEmail}
            empty={form.emptyEmail}
            onChange={handleChange}
            onBlur={checkValidEmail}
            invalidEmail={form.invalidEmail}
          />
          <div className='mt-10'>
            <Button
              type={'primary'}
              onClick={() => {
                handleSendOTP()
              }}>
              <p>Send Email</p>
              {isLoading ? <ButtonLoading /> : <></>}
            </Button>
          </div>
        </form>

        <p className='mt-2 text-center text-neutral-200'>
          <span className='text-sm font-normal'>Already remember? </span>
          <span
            className='cursor-pointer text-sm font-semibold text-primary-200 underline'
            onClick={() => backToLogin()}>
            Login
          </span>
        </p>
      </div>
    </div>
  )
}

export default ForgotPassword
