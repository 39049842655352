export function getFileExtension(filename) {
  const ext = /^.+\.([^.]+)$/.exec(filename)
  return ext == null ? '' : ext[1]
}

export function checkIsImage(ext) {
  return ext.toLowerCase() === 'jpg' || ext.toLowerCase() === 'png' || ext.toLowerCase() === 'jpeg'
}

export function checkIsVideo(ext) {
  return ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv' || ext.toLowerCase() === 'mov'
}
