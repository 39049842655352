import React from 'react'

import { Button } from '~/components'
import { LockedLogo } from '~/svg'

const NewPasswordSuccess = ({ backToLogin }) => {
  return (
    <div className='relative w-full md:w-full'>
      <div className='px-9 py-10 mx-0 w-full rounded-lg rounded-tl-none'>
        <div className='flex items-center justify-center'>
          <LockedLogo />
        </div>
        <p className='mt-2 mb-2 leading-7 text-xl font-semibold font-sans text-neutral-200 text-center'>
          New Password Set
        </p>
        <p className='mt-2 mb-2 leading-5 text-sm font-normal font-sans text-neutral-200 text-center'>
          You may log into your account using your new password.
        </p>
        <div className='w-full md:w-1/5 mt-10 md:mx-auto'>
          <Button
            type={'primary'}
            onClick={() => {
              backToLogin()
            }}>
            <p>Go to Log In</p>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NewPasswordSuccess
