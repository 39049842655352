import React from "react";

import { Button, Canvas, Input } from "~/components";

const CameraSection = ({
  floorsCams,
  floors,
  selectFloor,
  openModal,
  formTimeout,
  handleBlurFormTimeout,
  handleChangeFormTimeout,
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="w-full md:w-[687px] hidden md:flex justify-between items-center">
        <p className="text-neutral-50 font-semibold text-2xl">Area Setting</p>
      </div>
      <div className="w-full md:w-[667px] mt-8 relative">
        <div className="mt-3">
          <p className="text-neutral-200 font-semibold  md:text-base mb-1">Loitering time</p>
          <div className="w-1/4">
            <Input
              type={"text"}
              name={"timeout"}
              value={formTimeout}
              wrong={formTimeout < 11}
              onChange={handleChangeFormTimeout}
              onBlur={handleBlurFormTimeout}
            />
          </div>
          <p className="text-neutral-400 mt-2">Minimum 10 mins.</p>
        </div>
        {/* <div className="w-full hidden md:block">
          <Canvas
            dynamic
            withScale
            width={343}
            height={228.67}
            scale={687 / 343}
            cameras={floorsCams}
            images={"/floor_plan.png"}
          />
        </div>
        <div className="w-full block md:hidden overflow-x-scroll no-scrollbar">
          <Canvas
            dynamic
            width={343}
            height={228.67}
            cameras={floorsCams}
            images={"/floor_plan.png"}
          />
        </div> */}
      </div>
    </div>
  );
};

export default CameraSection;
