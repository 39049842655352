import thunk from 'redux-thunk'
import CryptoJS from 'crypto-js'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, createTransform } from 'redux-persist'

import { storage } from './storage'
import { rootReducer } from './store'

const SecretKey = process.env.SECRET_KEY || 'super-secret'

// Encrypt the Persist to save data in localStorage
const encrypt = createTransform(
  // eslint-disable-next-line no-unused-vars
  (inboundState, key) => {
    if (!inboundState) return inboundState
    const cryptedText = CryptoJS.AES.encrypt(JSON.stringify(inboundState), SecretKey)

    return cryptedText.toString()
  },
  // eslint-disable-next-line no-unused-vars
  (outboundState, key) => {
    if (!outboundState) return outboundState
    const bytes = CryptoJS.AES.decrypt(outboundState, SecretKey)
    const decrypted = bytes.toString(CryptoJS.enc.Utf8)

    return JSON.parse(decrypted)
  },
)

// Persistore Configuration
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  timeout: null,
  blacklist: ['camera', 'alert'],
  transforms: [encrypt],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk],
})

export const persistor = persistStore(store)
