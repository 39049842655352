import React, { useEffect, useState } from 'react';
import { useVms } from './hooks';
import { Datepicker, SearchBarFilter } from '~/components';

import './index.css';
import { ReplayVideoStream, Sidebar } from './components';
import { Tooltip } from 'react-tooltip'

const Vms = () => {
  const {
    datas: {
      isNew,
      dateRange,
      timestamp,
      displayTime,
      linkPlayer,
      playheadRef,
      selectedAlert,
      playheadPosition,
      floorList,
      cameraList,
      searchCamera,
      selectedFloors,
      isPlaying,
      selectedCamera,
    },
    methods: {
      setLinkPlayer,
      setDateRange,
      setTimestamp,
      changeSeconds,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      handleFilter,
      handleChangeSearchCamera,
      handlePlayVms,
      handlePauseVms,
      handleFastFoward,
      secondsPassedToday,
      handleJumpToTime,
      formatTime
    },
  } = useVms();
  
  const [timestampInput, setTimestampInput] = useState("00:00:00");

  const handleChangeTimestampInput = (e) => {
    setTimestampInput(e.target.value)
  }

  return (
    <div className="w-full flex flex-row px-8 py-4 h-full gap-8">
      <div className="flex flex-col justify-between h-full gap-4 w-[30.0vw] xl:w-[20.0vw] 2xl:w-[18.5vw]">
        <Sidebar
          filter={{
            floors: true,
            cameras: true,
          }}
          floors={floorList}
          cameras={cameraList}
          handleFilter={handleFilter}
          selectedFloors={selectedFloors}
          selectedCamera={selectedCamera}
          handleChangeSearchCamera={handleChangeSearchCamera}
        />
        <Datepicker
          minDate={new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)} // disable before this month
          state={dateRange}
          setState={setDateRange}
          isSingleDate
          isBlock
        />
        <div className="w-full flex justify-between items-center h-10">
          <div className="w-full h-full flex items-center justify-between px-5 rounded-lg border-[1px] gap-2 border-neutral-600">
            <div className="flex w-full items-center gap-2">
              <input
                type="text"
                id="timeinput"
                name="timeinput"
                value={timestampInput}
                autoComplete="off"
                onChange={handleChangeTimestampInput}
                onKeyUp={(e) => handleJumpToTime(e, timestampInput)}
                className="w-full bg-transparent outline-none border-none text-base font-normal placeholder:text-neutral-400 text-neutral-50 truncate"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col h-full gap-4 items-center">
        <ReplayVideoStream
          name={selectedAlert?.camera_name}
          url={linkPlayer}
          isNew={isNew}
          isPlaying={isPlaying}
          selectedCamera={selectedCamera}
          setTimestamp={setTimestamp}
          handlePlayVms={handlePlayVms}
          handlePause={handlePauseVms}
          fastFoward={handleFastFoward}
        />
        <div className="w-full relative mt-auto text-white">
          {/* Slider Container */}
          <a
            data-tooltip-id="timestamp"
            data-tooltip-content={formatTime(timestamp)}
          >
            <div
              className="slider-container relative"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              {/* Slider */}
              <input
                type="range"
                min={0}
                max={86399}
                value={secondsPassedToday(timestamp)}
                onChange={changeSeconds}
                className="timestamp-slider"
              />

              {/* Marker */}
              <div
                style={{
                  left: `${(secondsPassedToday(timestamp) / 86399) * 100}%`,
                  transform: 'translateX(-50%)', // Center the marker
                }}
                className="absolute cursor-pointer w-0 h-0 top-0 border-solid border-b-8 border-b-transparent border-t-8 border-t-[#1AD9EC] border-x-8 border-x-transparent"
              ></div>
            </div>
          </a>
          <Tooltip id="timestamp" className="example" />
          {/* Tick Marks */}
          <div className="absolute bottom-2 left-0 flex w-full justify-between text-xs text-white pointer-events-none">
            {Array.from({ length: 25 }).map((_, i) => (
              <div key={i} className="relative w-1">
                <span className="absolute -top-4 -translate-x-1/2">{`${i
                  .toString()
                  .padStart(2, '0')}:00`}</span>
                <div className="w-px h-4 bg-white mx-auto"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vms;
