import React, { useRef, useState } from "react";
import { CevronDown, Checked } from "~/svg";
import { useOutsideClick } from "~/utils";

const Dropdown = ({ options, clickOptions, selectedOptions, lock = false }) => {
  const dropdownRef = useRef();

  const [dropdownState, setDropdown] = useState(false);

  function toggleDropdown() {
    setDropdown(!dropdownState);
  }

  useOutsideClick({ ref: dropdownRef, action: () => setDropdown(false) });

  return (
    <div className="flex flex-col">
      <label className="relative gap-1 w-48" ref={dropdownRef}>
        <button
          data-testid="dropdown-button"
          className="w-full px-4 py-2 flex flex-row justify-between items-center bg-grey-800 border border-grey-600 rounded-lg"
          onClick={toggleDropdown}
        >
          <p className="leading-loose text-neutral-50">{selectedOptions}</p>
          {lock ? <></> : <CevronDown />}
        </button>
        {lock ? (
          <></>
        ) : (
          <>
            {dropdownState && (
              <div
                data-testid="dropdown-options"
                className="absolute rounded border border-grey-600 w-56 max-h-64 overflow-auto"
              >
                {options.map((option, index) => {
                  return (
                    <div
                      className={`px-4 py-3 ${
                        selectedOptions === option ? "bg-information-600" : "text-neutral-200"
                      }  bg-black hover:bg-neutral-800 text-neutral-50 cursor-pointer`}
                      key={index}
                      onClick={() => (lock ? {} : clickOptions(option))}
                    >
                      <p className="text-neutral-200 leading-tight text-sm text-start flex flex-row gap-3">
                        {selectedOptions === option ? <Checked /> : <></>}
                        {option}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </label>
    </div>
  );
};

export default Dropdown;
