import React, { useState, useEffect } from "react";
import moment from "moment";
import { BarChart } from "~/components";
import { timestamp_interval } from "~/utils";

const AnalyticsBarChart = ({
  title,
  data,
  precision = "hours",
  postfix = "person",
}) => {

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(data.chartData);
  }, [data]);

  return (
    <div>
      <h1 className="text-2xl font-semibold text-white mb-4">{title}</h1>
      <div className="grid grid-cols-2 gap-x-8">
        <div className="grid grid-cols-2">
          <p className="text-base text-white">On Average (per floor)</p>
          <p className="text-base font-semibold text-white text-right">
            {data.average.toFixed(0)} {postfix}(s)
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="text-base text-white">Most frequent floor</p>
          <p className="text-base font-semibold text-white text-right">{data.mostFloor}</p>
        </div>
        <div className="grid grid-cols-2">
          <p className="text-base text-white">Total people (est.)</p>
          <p className="text-base font-semibold text-white text-right">
            {data.total} {postfix}(s)
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="text-base text-white">Most frequent day</p>
          <p className="text-base font-semibold text-white text-right">{data.mostHour}</p>
        </div>
      </div>
      <BarChart timestamp={timestamp_interval} datasets={chartData} />
    </div>
  );
};

export default AnalyticsBarChart;
