import React from "react";
import RSTPVideoStream from "../video-stream";
const Main = ({ streamData }) => {
  return (
    <main className="w-full pt-9">
      <div className="grid grid-cols-2 gap-4 p-4">
        {streamData &&
          streamData.map((it, idx) => (
            <div className="w-full">
              <RSTPVideoStream
                key={it.name}
                name={it.name}
                url={it.proxyStream2}
                className="aspect-w-16 aspect-h-9 w-full"
              />
            </div>
          ))}
      </div>
    </main>
  );
};
export default Main;
