import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getVideoDetails, initVideoLoading } from '~/store/slice/video'

export function useVideoDetail({ videoID }) {
  const dispatch = useDispatch()
  const videoDetail = useSelector((state) => state.video.videoDetail)

  const [startVideo, setStartVideo] = useState('00:00')
  const [endVideo, setEndVideo] = useState('00:00')

  const handleChange = (event, inputType) => {
    const time = event.target.value
    const timeRegex = /^\d+:\d{0,2}$/

    if (!timeRegex.test(time)) {
      return
    }
    let inputTime = ''

    let [minutes, seconds] = time.split(':').map(Number)
    if (seconds >= 59) {
      seconds = 59
    }

    inputTime = `${minutes}:${seconds}`

    if (inputType === 'start') {
      setStartVideo(inputTime)
    } else if (inputType === 'end') {
      setEndVideo(inputTime)
    }
  }

  useEffect(() => {
    dispatch(initVideoLoading())
    dispatch(getVideoDetails({ videoId: videoID }))
  }, [])

  return {
    datas: { startVideo, endVideo, videoDetail },
    methods: { handleChange },
  }
}
