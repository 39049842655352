import React from "react";

import LevelItems from "../level-item";

const TableSection = ({ floors, cameras, openCameraModal }) => {
  return (
    <div className="w-full lg:w-[774px] mt-6 rounded-lg overflow-hidden border-[1px] border-neutral-600">
      <div className="flex px-7 py-[17px] text-neutral-200">
        <div className="w-[33%] md:w-1/4 px-2">
          <p className="w-full truncate">Location</p>
        </div>
        <div className="w-[33%] md:w-1/4 px-2">
          <p className="w-full truncate">RTSP Stream</p>
        </div>
        <div className="w-[33%] md:w-1/2 px-2">
          <p className="w-full truncate">Status</p>
        </div>
      </div>
      {floors &&
        floors.map((it, idx) => (
          <LevelItems
            key={idx}
            idx={idx}
            cameras={cameras}
            it={it}
            openCameraModal={openCameraModal}
          />
        ))}
    </div>
  );
};

export default TableSection;
