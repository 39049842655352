import React from 'react'
import { EyeIcon } from '~/svg'

const AccountInformation = ({ dataProfile, handleVerification, dataVerified, hideState }) => {
  return (
    <div>
      <p className='font-semibold text-2xl text-white'>Account</p>
      <div className='flex justify-between md:w-1/2'>
        <p className='font-semibold text-xl text-white pt-8'>Account Information</p>
        <p
          id='edit'
          className='font-semibold text-primary-200 underline pt-8 cursor-pointer'
          onClick={handleVerification}>
          Edit
        </p>
      </div>
      <div className='flex flex-row md:w-1/2 pt-6 gap-5'>
        <div className='h-8 w-8 rounded-full bg-neutral-800 cursor-pointer ml-2 md:ml-0'></div>
        <div className='w-full'>
          <p className='text-neutral-200 font-semibold text-lg'>{dataProfile.fullname}</p>
          <div className='flex flex-row justify-between w-full mt-5'>
            <p data-testid='test-email' className='text-neutral-400'>
              Email: {hideState.email ? dataProfile.email : dataVerified.email}
            </p>
            <div id='email' className='cursor-pointer' onClick={handleVerification}>
              <EyeIcon />
            </div>
          </div>
          <div className='flex flex-row justify-between w-full mt-5'>
            <p data-testid='test-phone' className='text-neutral-400'>
              Phone number:
              {` ${dataProfile.country_code}${
                hideState.phone ? dataProfile.phone_number : dataVerified.phone
              }`}
            </p>
            <div id='phone' className='cursor-pointer' onClick={handleVerification}>
              <EyeIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountInformation
