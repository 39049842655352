import React from 'react'

function ActivePhotograph() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.66675 4.00008C2.66675 3.2637 3.2637 2.66675 4.00008 2.66675H12.0001C12.7365 2.66675 13.3334 3.2637 13.3334 4.00008V12.0001C13.3334 12.7365 12.7365 13.3334 12.0001 13.3334H4.00008C3.2637 13.3334 2.66675 12.7365 2.66675 12.0001V4.00008Z'
        fill='#105F9C'
      />
      <path
        d='M2.66675 10.6667L5.72394 7.60956C6.24464 7.08886 7.08886 7.08886 7.60956 7.60956L10.6667 10.6667M9.33341 9.33341L10.3906 8.27622C10.9113 7.75552 11.7555 7.75552 12.2762 8.27622L13.3334 9.33341M9.33341 5.33341H9.34008M4.00008 13.3334H12.0001C12.7365 13.3334 13.3334 12.7365 13.3334 12.0001V4.00008C13.3334 3.2637 12.7365 2.66675 12.0001 2.66675H4.00008C3.2637 2.66675 2.66675 3.2637 2.66675 4.00008V12.0001C2.66675 12.7365 3.2637 13.3334 4.00008 13.3334Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ActivePhotograph
