import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { START_DATE } from '@datepicker-react/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useRef, useState } from 'react'

import { PATH, SORT, SearchTypes } from '~/constant'
import { useOutsideClick } from '~/utils'
import {
  resetToast,
  saveSearchAsync,
  getSearchAsync,
  getSearchDetailAsync,
  resetPreviewImage,
} from '~/store/slice/camera'
import { getEstimeteSavedVideoAsync } from '~/store/slice/video'

export function useSearch() {
  const data = 1 + 1
  const isNotFound = data === 1

  const sorts = Object.values(SORT)
  const floors = ['B1', 'L1', 'L2', 'L3', 'L4']
  const sources = [
    {
      label: 'CCTV',
      type: 'cctv',
    },
    {
      label: 'Uploaded Videos',
      type: 'video',
    },
  ]

  const dummyCameras = {
    width: 343,
    height: 228.67,
    images: 'floor_plan.png',
    cameras: [
      {
        x: 87,
        y: 50,
      },
      {
        x: 155,
        y: 50,
      },
      {
        x: 223,
        y: 50,
      },
      {
        x: 87,
        y: 135,
      },
      {
        x: 155,
        y: 135,
      },
      {
        x: 223,
        y: 135,
      },
    ],
  }

  const sortRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // TO-DO : subscription hanlder
  const isSubscibed = true

  const isShowToast = useSelector((state) => state.camera.isShowToast)
  const searchLoading = useSelector((state) => state.camera.searchLoading)
  const searchResult = useSelector((state) => state.camera.searchResult) || []

  const counter = useSelector((state) => state.video.counter) || {}
  const searchTypeStore = useSelector((state) => state.camera.searchType)
  const previewData = useSelector((state) => state.camera.previewData)
  const uploadedFiles = useSelector((state) => state.camera.uploadedFiles) || []

  const [filter, setFilter] = useState({
    source: true,
    level: true,
    camera: true,
    confidence: true,
    dateAndTime: true,
  })
  const [dateRange, setDateRange] = useState({
    endDate: null,
    startDate: null,
    focusedInput: START_DATE,
  })
  const [toastView, setToastView] = useState({
    show: false,
    type: 'info',
  })
  const [selectedSource, setSelectedSource] = useState(
    sources.map((it) => {
      return { source: it, selected: false }
    }),
  )
  const [selectedFloors, setSelectedFloors] = useState(
    floors.map((it) => {
      return { name: it, selected: false }
    }),
  )
  const [timeRange, setTimeRange] = useState({
    startClock: '00',
    startMinute: '00',
    endClock: '23',
    endMinute: '59',
  })
  const [openSort, setOpenSort] = useState(false)
  const [saveInfo, setSaveInfo] = useState(false)
  const [showDatas, setShowDatas] = useState([])
  const [previewModal, setPreviewModal] = useState(false)
  const [selectedSort, setSelectedSort] = useState(sorts[0])
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [confidenceInfo, setConfidenceInfo] = useState(false)
  const [expandedFilter, setExpandedFilter] = useState(false)
  const [bottomSheetSort, setBottomSheetSort] = useState(false)
  const [selectedSearch, setSelectedSearch] = useState(undefined)
  const [bottomSheetFilter, setBottomSheetFilter] = useState(false)
  const [confidence, setConfidence] = useState({ min: 0, max: 100 })
  const [searchType, setSearchType] = useState(SearchTypes.IMAGE)

  function openModal() {
    setShowDatePicker(true)
  }

  function openPreview(it) {
    dispatch(getEstimeteSavedVideoAsync({ id: it.id }))
    setPreviewModal(true)
    setSelectedSearch(it)
    dispatch(getSearchDetailAsync({ videoPath: it.source, videoType: it.source_type }))
  }

  function closeModal() {
    setPreviewModal(false)
    setShowDatePicker(false)
    dispatch(resetPreviewImage())
  }

  function closeFiter() {
    setExpandedFilter(false)
    setBottomSheetSort(false)
    setBottomSheetFilter(false)
  }

  function openInfo(isSave) {
    if (isSave === true) {
      setSaveInfo(true)
      return
    }
    setConfidenceInfo(true)
  }

  function closeInfo(isSave) {
    if (isSave === true) {
      setSaveInfo(false)
      return
    }
    setConfidenceInfo(false)
  }

  function toHomePage() {
    navigate(PATH.HOME)
  }

  function openBottomSheetFilter() {
    setExpandedFilter(false)
    setBottomSheetFilter(true)
  }

  function handleOpenSort() {
    if (window.innerWidth > 768) {
      setOpenSort(true)
      return
    }
    setBottomSheetSort(true)
  }

  function handleSort(it) {
    setSelectedSort(it)
    setOpenSort(false)
  }

  function openExpanded() {
    setExpandedFilter(!expandedFilter)
  }

  function dateToString(date, isStart) {
    if (date === null) {
      return isStart ? 'Start' : 'Today'
    }
    const arrDate = date.toString().split(' ')
    return `${arrDate[2]} ${arrDate[1].substring(0, 3)} ${arrDate[3]}`
  }

  function showToastView(type) {
    setToastView({
      type,
      show: true,
    })
    setTimeout(function () {
      setToastView({
        type,
        show: false,
      })
    }, 5000)
  }

  function handleFilter(it) {
    switch (it) {
      case 'source':
        setFilter((prev) => ({
          ...prev,
          source: !filter.source,
        }))
        break
      case 'level':
        setFilter((prev) => ({
          ...prev,
          level: !filter.level,
        }))
        break
      case 'camera':
        setFilter((prev) => ({
          ...prev,
          camera: !filter.camera,
        }))
        break
      case 'confidence':
        setFilter((prev) => ({
          ...prev,
          confidence: !filter.confidence,
        }))
        break
      default:
        setFilter((prev) => ({
          ...prev,
          dateAndTime: !filter.dateAndTime,
        }))
        break
    }
  }

  function sortResult(data) {
    switch (selectedSort) {
      case SORT.RECENT:
        return data.sort(function (a, b) {
          const c = new Date(a.times)
          const d = new Date(b.times)
          return d - c
        })
      case SORT.OLDEST:
        return data.sort(function (a, b) {
          const c = new Date(a.times)
          const d = new Date(b.times)
          return c - d
        })
      case SORT.LEAST_MATCH:
        return data.sort(function (a, b) {
          const c = a.probabilities * 100
          const d = b.probabilities * 100
          return c - d
        })
      default:
        return data.sort(function (a, b) {
          const c = a.probabilities * 100
          const d = b.probabilities * 100
          return d - c
        })
    }
  }

  function selectSource(args) {
    setSelectedSource(
      selectedSource.map((it) => {
        if (args === it.source.type) {
          return { source: it.source, selected: !it.selected }
        }
        return it
      }),
    )
  }

  function selectFloors(args) {
    setSelectedFloors(
      selectedFloors.map((it) => {
        if (args === it.name) {
          return { name: it.name, selected: !it.selected }
        }
        return it
      }),
    )
  }

  // Filter Time
  function fiterByTime(data) {
    const searchHours = new Date(data.times).getHours()
    const searchMinutes = new Date(data.times).getMinutes()

    const beforeEndClock = parseInt(timeRange.endClock) > searchHours
    const afterStartClock = parseInt(timeRange.startClock) < searchHours
    const isValidTime = beforeEndClock && afterStartClock
    const sameEndHours = parseInt(timeRange.endClock) === searchHours
    const sameStartHours = parseInt(timeRange.startClock) === searchHours
    const isBeforeEndMinute = parseInt(timeRange.endMinute) >= searchMinutes
    const isAfterStartMinute = parseInt(timeRange.startMinute) <= searchMinutes

    if (isValidTime) {
      return true
    }

    if (sameStartHours || sameEndHours) {
      if (sameStartHours && isAfterStartMinute && beforeEndClock) {
        return true
      }
      if (sameEndHours && isBeforeEndMinute && afterStartClock) {
        return true
      }
      if (sameStartHours && sameEndHours && isBeforeEndMinute && isAfterStartMinute) {
        return true
      }
    }

    return false
  }

  function changeTimeRange(e) {
    const { name, value } = e.target

    const regExp = /^\d+$/
    const isNumber = regExp.test(value)
    const isClock = name.toLowerCase().includes('clock')
    const isMinute = name.toLowerCase().includes('minute')

    if (value.length < 1) {
      setTimeRange((prevState) => ({
        ...prevState,
        [name]: '',
      }))
      return
    }

    const invalidNumber = !isNumber || value.length > 2
    const invalidClock = isClock && value.length < 2 && parseInt(value) > 2
    const invalidMinute = isMinute && value.length < 2 && parseInt(value) > 5
    const invalidClock2 = value[0] === '2' && isClock && value.length > 1 && parseInt(value[1]) > 3

    if (invalidClock || invalidNumber || invalidClock2 || invalidMinute) {
      return
    }

    setTimeRange((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function resetFilter() {
    setShowDatas(searchResult)
    setConfidence({ min: 0, max: 100 })
    setDateRange({ endDate: null, startDate: null, focusedInput: START_DATE })
    setSelectedFloors(
      floors.map((it) => {
        return { name: it, selected: false }
      }),
    )
    setTimeRange({
      startClock: '00',
      startMinute: '00',
      endClock: '23',
      endMinute: '59',
    })
    closeFiter()
  }

  const implementFilter = useCallback(() => {
    let result = []

    searchResult.forEach((r) => {
      // Filter Source
      if (selectedSource.some((s) => s.selected)) {
        let isSourceMatch = false
        selectedSource.forEach((it) => {
          if (it.selected && r.source_type === it.source.type) {
            isSourceMatch = true
          }
        })
        if (!isSourceMatch) return
      }

      // Filter Date
      const isSelectDate =
        dateRange.endDate !== null && dateRange.startDate !== null && !showDatePicker
      if (isSelectDate) {
        const isBetweenDate =
          moment(new Date(r.times)).isBefore(dateRange.endDate) &&
          moment(new Date(r.times)).isAfter(dateRange.startDate)
        if (!isBetweenDate) return
      }

      // Filter Probability
      const isBetweenProb =
        r.probabilities * 100 <= confidence.max && r.probabilities * 100 >= confidence.min
      if (!isBetweenProb) return

      // Filter Time (00 - 24)
      const isFilterTime = fiterByTime(r)
      if (!isFilterTime) return

      result.push(r)
    })
    result = sortResult(result)

    closeFiter()
    setShowDatas(result)
  }, [
    selectedFloors,
    dateRange,
    showDatePicker,
    confidence,
    selectedSort,
    timeRange,
    selectedSource,
  ])

  function saveSearch() {
    if (searchResult.length < 1) {
      return
    }
    dispatch(saveSearchAsync())
  }

  useOutsideClick({ ref: sortRef, action: () => setOpenSort(false) })

  useEffect(() => {
    dispatch(getSearchAsync())
    setSearchType(searchTypeStore)
  }, [])

  useEffect(() => {
    if (window.innerWidth > 768) {
      implementFilter()
    }
  }, [
    selectedSource,
    selectedFloors,
    dateRange,
    showDatePicker,
    confidence,
    selectedSort,
    timeRange,
  ])

  useEffect(() => {
    if (isShowToast) {
      showToastView('success')
      dispatch(resetToast())
    }
  }, [isShowToast])

  useEffect(() => {
    setShowDatas(searchResult)
  }, [searchResult])

  return {
    datas: {
      sorts,
      filter,
      counter,
      sortRef,
      saveInfo,
      openSort,
      dateRange,
      timeRange,
      toastView,
      showDatas,
      searchType,
      isNotFound,
      isSubscibed,
      previewData,
      selectedSort,
      previewModal,
      dummyCameras,
      searchLoading,
      uploadedFiles,
      selectedSearch,
      expandedFilter,
      confidenceInfo,
      showDatePicker,
      bottomSheetSort,
      bottomSheetFilter,
      floors: selectedFloors,
      sources: selectedSource,
    },
    methods: {
      openInfo,
      closeInfo,
      openModal,
      closeFiter,
      closeModal,
      toHomePage,
      handleSort,
      saveSearch,
      resetFilter,
      openPreview,
      openExpanded,
      handleFilter,
      dateToString,
      selectFloors,
      selectSource,
      setDateRange,
      setConfidence,
      handleOpenSort,
      implementFilter,
      changeTimeRange,
      openBottomSheetFilter,
    },
  }
}
