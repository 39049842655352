import React from 'react'

import { Shimmer } from '~/components'

const AnonyousAlertLoading = () => {
  return (
    <>
      <div className='hidden md:block'>
        <div className='mt-10 w-full'>
          <div className='w-full px-[10%]'>
            <div className='w-20'>
              <Shimmer height={24} />
            </div>
            <div className='flex mt-8 gap-6'>
              <div className='w-[640px]'>
                <Shimmer height={405} />
              </div>
              <div>
                <div className='w-20'>
                  <Shimmer height={20} />
                </div>
                <div className='w-20 mt-2'>
                  <Shimmer height={24} />
                </div>
                <div className='w-20 mt-8'>
                  <Shimmer height={20} />
                </div>
                <div className='flex gap-4 items-center mt-2'>
                  <div className='w-20'>
                    <Shimmer height={24} />
                  </div>
                  <div className='w-8'>
                    <Shimmer height={16} />
                  </div>
                  <div className='w-20'>
                    <Shimmer height={24} />
                  </div>
                </div>
                <div className='w-20 mt-2'>
                  <Shimmer height={24} />
                </div>
                <div className='w-20 mt-8'>
                  <Shimmer height={20} />
                </div>
                <div className='flex gap-4 items-center mt-2'>
                  <div className='w-20'>
                    <Shimmer height={24} />
                  </div>
                  <div className='w-20'>
                    <Shimmer height={24} />
                  </div>
                  <div className='w-20'>
                    <Shimmer height={24} />
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-6'>
              <div className='w-20'>
                <Shimmer height={24} />
              </div>
              <div className='w-[105px] mt-2'>
                <Shimmer height={16} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='block md:hidden'>
        <div className='mt-6 w-20 mx-4'>
          <Shimmer height={24} />
        </div>
        <div className='mt-4 px-7 w-full'>
          <Shimmer height={202} />
        </div>
        <div className='mt-4 px-4 flex gap-4'>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
        </div>
        <div className='mt-4 px-4 flex gap-4 items-center'>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
          <div className='w-8'>
            <Shimmer height={16} />
          </div>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
        </div>
        <div className='mt-4 pl-[112px] flex gap-4 items-center'>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
        </div>
        <div className='mt-4 px-4 flex gap-4 items-center'>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
        </div>
        <div className='mt-4 px-4 flex gap-4 items-center'>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
        </div>
        <div className='mt-4 px-4 flex gap-4 items-center'>
          <div className='w-20'>
            <Shimmer height={20} />
          </div>
        </div>
        <div className='mt-4 px-4 flex gap-4 items-center'>
          <div className='w-[105px]'>
            <Shimmer height={16} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AnonyousAlertLoading
