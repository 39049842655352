import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useOutsideClick } from "~/utils";
import { NOTIF_STATUS, NOTIF_TYPE, PATH, SETTINGS, event } from "~/constant";
import {
  getCountNotifAsync,
  getNotificationAsync,
  markNotificationAsync,
  pushNotifAlert,
} from "~/store/slice/notif";
import { setSelectedAlert } from "~/store/slice/alert";
import { socket } from "~/utils/socket";
import { getProfileAsync } from "~/store/slice/account";

export function useNavbar() {
  const dummyEmergency = [
    {
      type: NOTIF_TYPE.MAJOR_FIRE,
      status: NOTIF_STATUS.UNREAD,
      location: "B1 East Wing",
      reportedTo: "Retail Manager",
      time: "Today at 9:42 AM",
    },
  ];

  const notifStatus = [NOTIF_STATUS.ALL, NOTIF_STATUS.SEEN, NOTIF_STATUS.UNREAD];

  const notifRef = useRef(null);
  const statusRef = useRef(null);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const listPath = Object.values(PATH);

  const queryParams = new URLSearchParams(location.search);
  const fromValue = queryParams.get("from");

  const login = location.pathname !== checkLogin();
  const search = location.pathname === PATH.SEARCH;
  const noPath = !listPath.includes(location.pathname);

  const isLoading = useSelector((state) => state.notification.isLoading);
  const profilePhotos = useSelector((state) => state.auth.profilePhotos);
  const alertLoading = useSelector((state) => state.notification.isLoading);
  const dangerAlert = useSelector((state) => state.notification.dangerAlert);
  const countNotifData = useSelector((state) => state.notification.countData, shallowEqual);
  const notifLoading = useSelector((state) => state.notification.notifLoading);
  const isHaveupdate = useSelector((state) => state.notification.isHaveupdate);
  const searchLoading = useSelector((state) => state.notification.searchLoading);
  const notifCountLoading = useSelector((state) => state.notification.notifCountLoading);
  const dataProfile = useSelector((state) => state.account.dataProfile);
  const admin = useSelector((state) => state.account.admin);
  const dataNotif = useSelector((state) => {
    return state.notification.notifData;
  }, shallowEqual);

  const [hasLogin, setHasLogin] = useState(login);
  const [notFound, setNotFound] = useState(noPath);
  const [isSearch, setIsSearch] = useState(search);
  const [showNotif, setShowNotif] = useState(false);
  const [showNotifStatus, setShowNotifStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(NOTIF_STATUS.ALL);
  const [siteNumber, setSiteNumber] = useState(0);
  const [siteName, setSiteName] = useState("");

  const [toggleLogout, setToggleLogout] = useState(false);

  useEffect(() => {
    if (location.state !== null) {
      setSiteNumber(location.state.site);
      switch (location.state.site) {
        case 1:
          setSiteName("Boon Keng");
          break;
        case 2:
          setSiteName("Eunos");
          break;
        case 3:
          setSiteName("Kallang");
          break;
        case 4:
          setSiteName("Marymount");
          break;
        case 5:
          setSiteName("Ang Mo Kio");
          break;
        case 6:
          setSiteName("Little Road");
          break;            
        default:
          setSiteName("");
          break;
      }
    } else {
      setSiteName("");
    }
    socket.emit(event.HEALTH, { test: "test" });

    socket.on(event.ALERT, (msg) => {
      dispatch(pushNotifAlert(msg));
    });

    socket.on(event.DISCONNECT, () => {
      if (dataProfile) {
        socket.emit("health", { test: "test RECONNECT" });
      }
    });
  }, [location.state]);

  function handleProfileClick() {
    setToggleLogout(!toggleLogout);
  }

  function logout() {
    setToggleLogout(false);
    navigate("/login");
  }

  useOutsideClick({ ref: dropdownRef, action: () => setToggleLogout(false) });
  function openStatus() {
    setShowNotifStatus(true);
  }

  function handleShowNotif() {
    setShowNotif(!showNotif);
  }

  function handeMarkAsRead() {
    const req = {
      notif_ids: [],
    };
    dataNotif.forEach((n) => {
      if (n.is_read == false) {
        req.notif_ids.push(n.notif_id);
        n.is_read;
      }
    });

    dispatch(markNotificationAsync(req));
  }

  function getFilter(filter_read) {
    switch (filter_read) {
      case NOTIF_STATUS.SEEN:
        return (filter_read = true);
      case NOTIF_STATUS.UNREAD:
        return (filter_read = false);
      default:
        filter_read = "";
        return filter_read;
    }
  }

  function handleNotifStatus(it) {
    setSelectedStatus(it);
    let filter_read = getFilter(it);
    dispatch(getNotificationAsync(filter_read));
    setShowNotifStatus(false);
  }

  function checkLogin() {
    if (location.pathname === PATH.ALERT && fromValue !== null) {
      return false;
    }
    return location.pathname !== PATH.LOGIN;
  }

  const navBarMenus = [
    {
      menu: "Alerts",
      link: PATH.ALERT,
    },
    {
      menu: "Analytics",
      link: PATH.ANALYTICS,
    },
    {
      menu: "Watch",
      link: PATH.WATCH,
    },
  ];

  const getLocation = useCallback(() => {
    const login = checkLogin();
    const search = location.pathname === PATH.SEARCH;
    const noPath = !listPath.includes(location.pathname);

    setHasLogin(login);
    setNotFound(noPath);
    setIsSearch(search);

    if (location.pathname !== PATH.ALERT) {
      dispatch(setSelectedAlert(undefined));
    }
  }, [location]);

  function openSettings() {
    if (location.pathname != PATH.SETTING) {
      navigate(`${PATH.SETTING}?tab=${SETTINGS.AREA_SETTINGS}`, {
        state: { site: location.state?.site },
      });
    }
  }

  function openAlert() {
    if (location.pathname != PATH.ALERT) {
      navigate(`${PATH.ALERT}`);
    }
  }

  useOutsideClick({ ref: notifRef, action: () => setShowNotif(false) });
  useOutsideClick({ ref: statusRef, action: () => setShowNotifStatus(false) });

  useEffect(() => {
    getLocation();
  }, [location]);

  useEffect(() => {
    if (location.pathname !== PATH.LOGIN) {
      dispatch(getCountNotifAsync());
      dispatch(getNotificationAsync(""));
      setSelectedStatus(NOTIF_STATUS.ALL);
    }
  }, [location]);

  return {
    datas: {
      admin,
      siteName,
      dropdownRef,
      notifRef,
      hasLogin,
      notFound,
      isSearch,
      statusRef,
      showNotif,
      isLoading,
      dataNotif,
      notifStatus,
      navBarMenus,
      dangerAlert,
      alertLoading,
      notifLoading,
      isHaveupdate,
      searchLoading,
      selectedStatus,
      profilePhotos,
      dummyEmergency,
      showNotifStatus,
      countNotifData,
      notifCountLoading,
      siteNumber,
      toggleLogout,
    },
    methods: {
      openStatus,
      handleShowNotif,
      handleNotifStatus,
      handeMarkAsRead,
      openSettings,
      openAlert,
      handleProfileClick,
      logout,
    },
  };
}
