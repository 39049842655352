import Axios from "axios";

import { KeyConstant, PATH } from "~/constant";

import { clearAllSessionStorage, getSessionStorage } from "./storage";

const baseUrl = "/";

//
const axios = Axios.create({
  baseUrl,
  timeout: 30000,
});

axios.interceptors.request.use(
  (request) => {
    request.headers["Authorization"] = `Bearer ${getSessionStorage(KeyConstant.Token)}`;
    request.headers["address"] = getSessionStorage(KeyConstant.Ip);
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Auto Log Out when No Valid Token
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      clearAllSessionStorage();
      window.location.assign(PATH.LOGIN);
    }
    return Promise.reject(error);
  }
);

const pureAxios = Axios.create({
  timeout: 10000,
});

export { axios, pureAxios };
