import { useNavigate } from 'react-router-dom'
import { PATH } from '~/constant'

export function useStreamItem({ item }) {
  const navigate = useNavigate()

  function clickUploadVideo() {
    navigate(`${PATH.ANALYTICS}/camera/${item.cameraId}`)
  }

  return {
    methods: {
      clickUploadVideo,
    },
  }
}
