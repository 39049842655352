import React, { useEffect, useState } from "react";

import { CheckboxLabel, Chip, SearchBarFilter } from "~/components";
import { CevronUp } from "~/svg";

const Sidebar = ({
  filter,
  sites,
  floors,
  cameras,
  handleFilter,
  selectedSites,
  selectedFloors,
  selectedCameras,
  handleChangeSearchCamera,
}) => {
  const [filterSites, setFilterSites] = useState(filter.sites);
  const [filterFloors, setFilterFloors] = useState(filter.floors);
  const [filterCameras, setFilterCameras] = useState(filter.cameras);
  const [showAllCameras, setShowAllCameras] = useState(false);
  return (
    <aside className="sticky top-0 w-60 mt-10">
      <div className="flex w-full justify-between items-center">
        <p className="text-xl font-semibold text-neutral-200">Filter</p>
      </div>
      <div className="w-[208px] bg-neutral-900 rounded-r-lg mt-5 py-3">
        {/* Sites */}
        {/* <div className="w-full border-b-[1px] border-b-neutral-800 pb-3">
          <div className="flex w-full justify-between items-center px-3">
            <p className="text-sm font-semibold text-neutral-50">Site</p>
            <div
              data-testid="sites-chevron"
              onClick={() => setFilterSites(!filterSites)}
              className={`cursor-pointer ${
                filterSites ? "rotate-0" : "rotate-180"
              }`}
            >
              <CevronUp />
            </div>
          </div>
          {filterSites ? (
            <div className="flex flex-row flex-wrap px-3 mt-3 gap-2">
              {sites &&
                sites.map((it, idx) => (
                  <Chip
                    data-testid="sites-chip"
                    key={idx}
                    onClick={() => handleFilter("sites", it._id)}
                    selected={selectedSites.includes(it._id)}
                  >
                    <p className="text-sm font-medium text-neutral-50">
                      {it.siteName}
                    </p>
                  </Chip>
                ))}
            </div>
          ) : (
            <></>
          )}
        </div> */}
        {/* Floors */}
        <div className="w-full border-b-[1px] border-b-neutral-800 py-3">
          <div className="flex w-full justify-between items-center px-3">
            <p className="text-sm font-semibold text-neutral-50">Floor</p>
            <div
              data-testid="floors-chevron"
              onClick={() => setFilterFloors(!filterFloors)}
              className={`cursor-pointer ${
                filterFloors ? "rotate-0" : "rotate-180"
              }`}
            >
              <CevronUp />
            </div>
          </div>
          {filterFloors ? (
            <div className="flex flex-wrap px-3 mt-[10px] gap-2 max-h-[240px] overflow-y-auto">
              {floors &&
                floors.map((it, idx) => (
                  <Chip
                    data-testid="floors-chip"
                    key={idx}
                    onClick={() => handleFilter("floors", it._id)}
                    selected={selectedFloors.includes(it._id)}
                  >
                    <p className="text-sm font-medium text-neutral-50 leading-tight tracking-tight">
                      {it.name}
                    </p>
                  </Chip>
                ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Cameras */}
        <div className="w-full pt-3">
          <div className="flex w-full justify-between items-center px-3">
            <p className="text-sm font-semibold text-neutral-50">Camera</p>
            <div
              data-testid="camera-chevron"
              onClick={() => setFilterCameras(!filterCameras)}
              className={`cursor-pointer ${
                filterCameras ? "rotate-0" : "rotate-180"
              }`}
            >
              <CevronUp />
            </div>
          </div>
          {filterCameras ? (
            <div className="w-full items-start justify-start px-3 text-neutral-200 mt-[10px]">
              <div className="pb-3">
                <SearchBarFilter
                  placeholder={"Search camera"}
                  handleChange={handleChangeSearchCamera}
                />
              </div>
              <div className="flex flex-col max-h-[240px] overflow-y-auto">
                {cameras &&
                  (showAllCameras ? cameras : cameras.slice(0, 8)).map(
                    (it, idx) => (
                      <CheckboxLabel
                        label={it.name}
                        onClick={() => handleFilter("cameras", it._id)}
                        isSelected={selectedCameras.includes(it._id)}
                      />
                    )
                  )}
              </div>
              <div
                className="text-sm text-neutral-200 mt-4 text-center cursor-pointer"
                onClick={() => setShowAllCameras(!showAllCameras)}
              >
                {showAllCameras ? "Hide camera" : `See all (${cameras.length})`}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
