import React from 'react'
import { Button } from '~/components'

const SessionTable = ({
  tableHeader,
  userSessions,
  ipAddressUser,
  tableHeadMobile,
  submitEndSession,
  handleLogout,
}) => {
  return (
    <div className='w-full'>
      <p className='font-semibold text-2xl text-white mt-8 p-2'>Session Management</p>
      <div className='rounded-xl text-neutral-200 mt-6 border w-full hidden md:table'>
        <table className='md:min-w-fit w-full'>
          <thead>
            <tr>
              {tableHeader.map((h, key) => {
                return (
                  <th key={key} className='px-7 py-4'>
                    <p className='text-left'>{h}</p>
                  </th>
                )
              })}
            </tr>
          </thead>
          {userSessions.map((s, key) => {
            return (
              <tbody key={key} className='border-t border-neutral-800 truncate overflow-hidden'>
                <tr>
                  <td className='px-7 py-4'>{s.ip_address}</td>
                  <td className='px-7 py-4 hidden md:flex'>{s.device}</td>
                  <td className='px-7 py-4'>
                    {s.city}, {s.country}
                  </td>
                  <td className='px-7 py-4 hidden md:flex'>
                    {new Date(s.last_active).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </td>
                  <td className='px-7 py-4 w-1/5'>
                    {s.is_active && ipAddressUser != s.ip_address ? (
                      <div
                        data-testid='button-session'
                        className='cursor-pointer'
                        onClick={() => submitEndSession(s.id)}>
                        <p className='text-danger-200 font-semibold'>End Session</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </tbody>
            )
          })}
        </table>
      </div>
      <div className='rounded-xl text-neutral-200 mt-6 border w-full md:hidden flex'>
        <table className='w-full'>
          <thead>
            <tr>
              {tableHeadMobile.map((h, key) => {
                return (
                  <th key={key} className='px-4 py-4'>
                    <p className='text-left '>{h}</p>
                  </th>
                )
              })}
            </tr>
          </thead>
          {userSessions.map((s, key) => {
            return (
              <tbody key={key} className='border-t border-neutral-800'>
                <tr>
                  <td className='px-4 py-4'>{s.ip_address}</td>
                  <td className='px-4 py-4'>
                    {s.city}, {s.country}
                  </td>
                  <td className='px-4 py-4 w-full'>
                    {s.is_active && ipAddressUser != s.ip_address ? (
                      <div
                        data-testid='button-session'
                        className='cursor-pointer'
                        onClick={() => submitEndSession(s.id)}>
                        <p className='text-danger-200 font-semibold'>End Session</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </tbody>
            )
          })}
        </table>
      </div>
      <div className='font-semibold text-2xl text-white mt-5 p-2'>Danger Zone</div>
      <div className='text-white p-2 w-full max-w-md'>
        This will end your current session on this device. You will need to enter your password
        again to log back in.
      </div>
      <div className='relative inline-block text-left p-2 font-semibold'>
        <Button type={'danger'} onClick={() => handleLogout()}>
          <p>Log Out</p>
        </Button>
      </div>
    </div>
  )
}

export default SessionTable
