import React from 'react'

function IconX() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke='#DEEAF5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M4 12l8-8M4 4l8 8'></path>
    </svg>
  )
}

export default IconX
