import React from 'react'

import { UpdateIcon } from '~/svg'

const UpdateAlert = () => {
  return (
    <div className='flex w-[154px] gap-[6px] rounded-lg border-[1px] border-neutral-800 bg-neutral-900 px-2 py-[2px]'>
      <UpdateIcon />
      <div className='text-neutral-50 text-xs'>
        <p className='font-semibold'>Update Available</p>
        <p>Click to update</p>
      </div>
    </div>
  )
}

export default UpdateAlert
