import React, { useEffect, useRef, useState } from 'react'

import { Button, ButtonLoading } from '~/components'
import { WrongOutline } from '~/svg'

import ErrToast from '../toast'

const ForgotPasswordOTP = ({
  errToast,
  errCount,
  isLoading,
  closeToast,
  handleSendOTP,
  otp,
  setOTP,
  handleVerifyOTP,
  isInvalidOtp,
  isErrGeneral,
}) => {
  const [countdown, setCountdown] = useState(60) // Countdown timer in seconds
  const [isResendOTP, setIsResendOTP] = useState(false) // Countdown timer in seconds
  const inputRefs = useRef([])

  useEffect(() => {
    // Calculate the difference between now and the target time
    const targetTime = new Date() // Replace this with your target time
    targetTime.setSeconds(targetTime.getSeconds() + 60) // Add 60 seconds to the target time
    const timeDifference = Math.floor((targetTime - new Date()) / 1000)

    function n(n) {
      return n > 9 ? '' + n : '0' + n
    }
    if (timeDifference > 0) {
      setCountdown(timeDifference)
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => (prevCountdown > 0 ? n(prevCountdown - 1) : '00'))
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [])

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOTP = [...otp]
      newOTP[index] = value
      setOTP(newOTP)

      if (value !== '' && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus()
      }
    }
  }

  useEffect(() => {
    if (otp[5] !== '') {
      handleVerifyOTP()
    }
  }, [otp])

  const handleInputBackspace = (index, e) => {
    const newOTP = [...otp]
    if (e.key === 'Backspace' && index > 0) {
      newOTP[index] = ''
      setOTP(newOTP)
      inputRefs.current[index - 1].focus()
    } else if (e.key === 'Backspace' && index === 0) {
      inputRefs.current[index].focus()
    }
  }

  return (
    <div className='relative w-full md:w-[431px]'>
      {errToast ? (
        <div className='absolute top-[-140px] w-full left-0 px-8 md:px-0'>
          <ErrToast errCount={errCount} closeToast={closeToast} />
        </div>
      ) : (
        <></>
      )}

      <div className='md:border-[1px] md:border-neutral-700 px-9 py-10 mx-0 w-full rounded-lg rounded-tl-none'>
        <p className='mt-2 mb-2 leading-7 text-xl font-semibold font-sans text-neutral-200 text-center'>
          Forgot Password
        </p>
        <p className='mt-2 mb-2 leading-5 text-sm font-normal font-sans text-neutral-200'>
          Enter the OTP (one-time password) we just sent to your email to make a new password.
        </p>
        <form className='w-full content-center'>
          {otp.map((digit, index) => (
            <input
              key={index}
              className={`w-12 h-10 bg-transparent text-center border rounded-lg focus:outline-none focus:shadow-outline text-neutral-600 px-3 py-4 m-1 ${
                isInvalidOtp || isErrGeneral ? 'border-danger-200' : 'border-neutral-600'
              }`}
              type='text'
              maxLength='1'
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleInputBackspace(index, e)}
              ref={(ref) => (inputRefs.current[index] = ref)}
            />
          ))}
          {isInvalidOtp && (
            <p className='mt-2 mb-2 leading-5 text-sm font-normal font-sans text-danger-100 text-left'>
              <div className='flex'>
                <WrongOutline /> <span className='pl-2'>Please enter the right code.</span>
              </div>
            </p>
          )}
          {isErrGeneral && (
            <p className='mt-2 mb-2 leading-5 text-sm font-normal font-sans text-danger-100 text-left'>
              Sorry, there seems to be a problem with the server. Please try again later.
            </p>
          )}
          <p className='mt-2 mb-2 leading-5 text-sm font-normal font-sans text-neutral-200 text-center'>
            {isResendOTP
              ? `00:${countdown} We've just resent another to you. `
              : `00:${countdown} Didn't get a code?. `}
            {countdown > 0 ? (
              <a className='text-neutral-700'>Resend</a>
            ) : (
              <a
                className='text-primary-200 underline-offset-1 cursor-pointer'
                onClick={() => {
                  handleSendOTP()
                  setIsResendOTP(true)
                }}>
                Resend
              </a>
            )}
          </p>
          <div className='mt-10'>
            {!isInvalidOtp && !isErrGeneral && (
              <Button
                type={'primary'}
                onClick={() => {
                  handleVerifyOTP()
                }}>
                <p>Make New Password</p>
                {isLoading ? <ButtonLoading /> : <></>}
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPasswordOTP
