import React from 'react'

function CheckOutline({ dark }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke={dark ? '#FFFFFFA6' : '#fff'}
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M14 8c0-3.313-2.688-6-6-6-3.313 0-6 2.688-6 6 0 3.313 2.688 6 6 6 3.313 0 6-2.688 6-6z'></path>
      <path
        stroke={dark ? '#FFFFFFA6' : '#fff'}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M11 5.5l-4.2 5-1.8-2'></path>
    </svg>
  )
}

export default CheckOutline
