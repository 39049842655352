import React from 'react'

import { Button } from '~/components'
import { AccountLocked } from '~/svg'

const Locked = ({ backToLogin }) => {
  return (
    <div className='px-9 py-10 mx-0 w-full md:w-[431px] rounded-lg rounded-tl-none flex flex-col justify-center items-center'>
      <AccountLocked />
      <p className='text-xl font-semibold text-neutral-50'>Access Denied</p>
      <p className='text-sm font-normal text-neutral-200 mt-4 text-center max-w-[400px]'>
        Your account has been locked due to an excessive amount of unsuccessful log in attempts. You
        may regain access by contacting admin.
      </p>
      <div className='flex flex-col md:flex-row justify-center items-center gap-2 mt-4 w-full'>
        <Button onClick={backToLogin} type={'secondary'} small>
          <p>Back to Log In</p>
        </Button>
        <Button type={'primary'} small>
          <p>Contact Admin</p>
        </Button>
      </div>
    </div>
  )
}

export default Locked
