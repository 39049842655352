import React from 'react';
import { CevronLeft, CevronRight } from "~/svg";
import { usePagination } from "./hooks";

const Pagination = ({ page, fetchChangePage, limit, total_data }) => {
  const {
    datas: { paginationResult },
    methods: { handlePagination },
  } = usePagination({ page, fetchChangePage, limit, total_data });

  return (
    <>
      {total_data > 8 ? (
        <div className="mt-12">
          <div className="flex justify-center items-center">
            {page !== 1 ? (
              <button
                onClick={() => handlePagination(page - 1)}
                className="cursor-pointer mr-4"
              >
                <CevronLeft />
              </button>
            ) : (
              <></>
            )}
            {paginationResult.map((id, idx) => (
              <button
                className={`py-3 select-none cursor-pointer rounded-md font-medium text-neutral-50 ${
                  id === page
                    ? "bg-primary-600 px-5 mx-[2px]"
                    : "bg-transparent px-3 mx-[1px]"
                }`}
                onClick={() => handlePagination(id)}
                key={idx}
              >
                <p>{id}</p>
              </button>
            ))}
            {page !== Math.ceil(total_data / limit) ? (
              <button
                onClick={() => handlePagination(page + 1)}
                className="cursor-pointer ml-4"
              >
                <CevronRight />
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Pagination;
