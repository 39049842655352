import React from "react";
import { useMonth } from "@datepicker-react/hooks";

import Day from "../day";
import { CevronLeft, CevronRight } from "~/svg";

function Month({
  year,
  month,
  firstDayOfWeek,
  index,
  isEndDate,
  isStartDate,
  goToNextMonthsByOneMonth,
  goToPreviousMonthsByOneMonth,
}) {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  function getMonthString() {
    let arrString = monthLabel.split(" ");
    arrString[0] = arrString[0].substring(0, 3);
    return arrString.join(" ");
  }

  return (
    <div>
      {/* Label Of Day : [M,S,W,T,F,S,S] (Just Show in First Month | index = 0) */}
      {index < 1 ? (
        <div className="grid grid-cols-7 px-3 py-2 bg-neutral-800">
          {weekdayLabels.map((dayLabel) => (
            <div className="text-center" key={dayLabel}>
              <p className="text-sm text-neutral-400">{dayLabel.charAt(0)}</p>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}

      <div className="px-4">
        {/* Month and year : SEP 2023 */}
        <div className="py-2 flex flex-row justify-between items-center">
          <div onClick={goToPreviousMonthsByOneMonth}>
            <CevronLeft />
          </div>
          <p className="text-sm text-neutral-400">{getMonthString().toUpperCase()}</p>
          <div onClick={goToNextMonthsByOneMonth}>
            <CevronRight />
          </div>
        </div>


        {/* Mapping Days */}
        <div className="grid grid-cols-7 justify-center gap-y-[2px]">
          {days.map((day, index) => {
            if (typeof day === "object") {
              return (
                <Day
                  date={day.date}
                  isEndDate={isEndDate}
                  dayLabel={day.dayLabel}
                  isStartDate={isStartDate}
                  key={day.date.toString()}
                />
              );
            }
            return <div key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Month;
