import React from 'react'

import { Shimmer } from '~/components'

const SystemShimmer = () => {
  return (
    <>
      {/* mobile */}
      <div className='block md:hidden w-full py-6 px-4'>
        <div className='w-20'>
          <Shimmer height={24} />
        </div>
        <div className='w-full mt-4'>
          <Shimmer height={44} />
        </div>
        <div className='w-20 mt-4'>
          <Shimmer height={24} />
        </div>
        <div className='w-full mt-2'>
          <Shimmer height={24} />
        </div>
        <div className='w-24 mt-6 p-4'>
          <Shimmer height={24} />
        </div>
        <div className='w-20 mt-8'>
          <Shimmer height={24} />
        </div>
        <div className='flex flex-col gap-2 mt-6'>
          <div className='flex flex-row justify-between'>
            <div className='w-20'>
              <Shimmer height={24} />
            </div>{' '}
            <div className='w-20'>
              <Shimmer height={24} />
            </div>
          </div>
          <div className='flex flex-row justify-between'>
            <div className='w-20'>
              <Shimmer height={24} />
            </div>{' '}
            <div className='w-20'>
              <Shimmer height={24} />
            </div>
          </div>
          <div className='flex flex-row justify-between'>
            <div className='w-20'>
              <Shimmer height={24} />
            </div>{' '}
            <div className='w-20'>
              <Shimmer height={24} />
            </div>
          </div>
        </div>
      </div>
      {/* desktop */}
      <div className='hidden md:block w-full px-8 py-11'>
        <div className='w-20'>
          <Shimmer height={24} />
        </div>
        <div className='flex w-full gap-11 mt-7'>
          <div className='w-[193px]'>
            <Shimmer height={176} />
          </div>
          <div className='w-[667px]'>
            <div className='w-20 mt-4'>
              <Shimmer height={24} />
            </div>
            <div className='w-full mt-2'>
              <Shimmer height={24} />
            </div>
            <div className='w-24 mt-6 p-4'>
              <Shimmer height={24} />
            </div>
            <div className='w-20 mt-8'>
              <Shimmer height={24} />
            </div>
            <div className='flex flex-col gap-2 mt-6'>
              <div className='flex flex-row justify-between'>
                <div className='w-20'>
                  <Shimmer height={24} />
                </div>{' '}
                <div className='w-20'>
                  <Shimmer height={24} />
                </div>
              </div>
              <div className='flex flex-row justify-between'>
                <div className='w-20'>
                  <Shimmer height={24} />
                </div>{' '}
                <div className='w-20'>
                  <Shimmer height={24} />
                </div>
              </div>
              <div className='flex flex-row justify-between'>
                <div className='w-20'>
                  <Shimmer height={24} />
                </div>{' '}
                <div className='w-20'>
                  <Shimmer height={24} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SystemShimmer
