import React from 'react'

import { Button, Modal } from '~/components'

const LogoutBottomSheet = ({
  userSessions,
  ipAddressUser,
  logoutBottomSheet,
  submitLogout,
  setLogoutBottomSheet,
}) => {
  return (
    <Modal isOpen={logoutBottomSheet} closeModal={() => setLogoutBottomSheet(false)}>
      <div className='w-full flex flex-col items-center pt-2 pb-2 drop-shadow-sm'>
        <div className='w-11 h-1 rounded bg-neutral-800' />
        <p className='mt-6 text-neutral-50 font-semibold text-sm'>Log Out from This Device</p>
        <div className='w-full h-[1px] bg-neutral-800 mt-5' />
        <div className='w-full'>
          <p className='text-neutral-200 p-6'>
            This will end your current session on this device. You will need to enter your password
            again to log back in.
          </p>
        </div>
        <div className='w-full drop-shadow-[0px_-0.75px_0px_0px_#1D1D1D] p-2'>
          <div className='w-full pt-2 px-4 py-4 pb-2 font-semibold'>
            <Button
              data-testid='button-cancel'
              type={'secondary'}
              onClick={() => setLogoutBottomSheet(false)}>
              Cancel
            </Button>
          </div>
          <div className='w-full pt-0 px-4 py-4 pb-4 font-semibold'>
            <Button
              data-testid='button-logout'
              type={'danger'}
              onClick={() => {
                const userLogin = userSessions.filter(
                  (s) => s.is_active && ipAddressUser == s.ip_address,
                )
                submitLogout(userLogin[0].id)
              }}>
              Log Out
            </Button>
          </div>
        </div>
        <div className='w-1/3 h-1 rounded bg-neutral-800' />
      </div>
    </Modal>
  )
}

export default LogoutBottomSheet
