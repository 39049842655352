import React from "react";
import { Link } from "react-router-dom";

import { PATH } from "~/constant";
import { Button, Datepicker } from "~/components";
import { NoAlerts, NoIncidents, Search } from "~/svg";

const NoAlert = ({
  isNoIncidents,
  toAddAlerts,
  dateRange,
  setDateRange,
  showDatePicker,
  setShowDatePicker,
  yesterdayAlerts,
  searchAlerts,
  keyword,
  changeKeyword,
  displayDate = { displayDate },
}) => {
  return (
    <div className="w-full flex flex-col md:flex-row h-full">
      <div className="w-full md:w-[407px] h-full md:border-r-[2px] md:border-r-neutral-800 px-4 md:px-8 pt-6 md:pt-9">
        <div className="w-full flex items-center justify-between">
          <p className="text-neutral-200 font-semibold text-xl md:text-2xl">Incident Alerts</p>
          <div className="w-48">
            <button
              data-testid="dropdown-button"
              className="w-full px-4 py-2 flex flex-row justify-between items-center bg-grey-800 border border-grey-600 rounded-lg"
              onClick={() => {
                setShowDatePicker(!showDatePicker);
              }}
            >
              <p className="leading-loose text-neutral-50">{displayDate}</p>
            </button>
            {showDatePicker ? (
              <div className="absolute">
                <Datepicker
                  state={dateRange}
                  show={showDatePicker}
                  setState={setDateRange}
                  closeModal={() => {
                    setShowDatePicker(!showDatePicker);
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <form onSubmit={searchAlerts}>
          <div className="w-full flex justify-center h-12 rounded-lg border-[1px] border-neutral-600 mt-8">
            <div className="w-full h-fulll">
              <input
                type="text"
                id="search"
                name="search"
                value={keyword}
                onChange={changeKeyword}
                placeholder="Incident Type"
                className="h-full w-full text-base text-neutral-50 placeholder:text-neutral-400 bg-transparent outline-none border-none px-4 truncate"
              />
            </div>
            <button
              type="submit"
              className="w-[60px] flex justify-center items-center cursor-pointer border-l-[2px] border-l-neutral-600"
            >
              <Search />
            </button>
          </div>
        </form>
        <div className="flex flex-col w-full items-center mt-12 px-5 text-center">
          {isNoIncidents ? <NoIncidents /> : <NoAlerts />}
          <p className="font-semibold text-xl text-neutral-50">No Incidents Alert</p>
          <p className="text-sm text-neutral-200 my-4">
            Alerts will show here when an incident is detected.
          </p>
          {/* <Button small type={'primary'} onClick={toAddAlerts}>
            <p>{isNoIncidents ? 'See All Alerts' : 'Add New Alert'}</p>
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default NoAlert;
