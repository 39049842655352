import React from 'react'

function LoadingCircle() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M10 16.667a6.667 6.667 0 100-13.334 6.667 6.667 0 000 13.334zm0 1.666a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z'
        clipRule='evenodd'
        opacity='0.2'></path>
      <path
        fill='url(#paint0_linear_243_452)'
        fillRule='evenodd'
        d='M10 3.333a6.667 6.667 0 100 13.334.833.833 0 010 1.666A8.333 8.333 0 1118.333 10a.833.833 0 11-1.666 0A6.667 6.667 0 0010 3.333z'
        clipRule='evenodd'></path>
      <defs>
        <linearGradient
          id='paint0_linear_243_452'
          x1='10'
          x2='10'
          y1='10'
          y2='16.667'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#fff'></stop>
          <stop offset='1' stopColor='#fff' stopOpacity='0'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LoadingCircle
