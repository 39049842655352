import React from "react";

import { Calendar, Clock, WrongOutline } from "~/svg";

const Input = ({
  type,
  name,
  empty,
  wrong,
  error,
  value,
  onBlur,
  success,
  onKeyUp,
  onFocus,
  onChange,
  onKeyDown,
  placeholder,
  invalidEmail,
  customMessage = "",
}) => {
  return (
    <>
      <div className="relative">
        <input
          name={name}
          type={type}
          value={value}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          autoComplete="off"
          onChange={onChange}
          placeholder={placeholder}
          className={`w-full bg-transparent text-neutral-200 px-4 py-3 outline-none border-[1px] rounded-lg focus:border-primary-200 ${
            empty || error || invalidEmail || wrong
              ? "border-danger-200"
              : success
              ? "border-success-200"
              : "border-neutral-700"
          }`}
        />
        {type === "time" && (
          <div className="absolute right-4 bottom-4 w-[18px] h-[18px]">
            <Clock />
          </div>
        )}
      </div>
      {customMessage !== "" ? (
        <div className="flex items-center gap-1 mt-[1px]">
          <WrongOutline />
          <p className="text-base font-normal text-danger-100">{customMessage}</p>
        </div>
      ) : empty ? (
        <div className="flex items-center gap-1 mt-[1px]">
          <WrongOutline />
          <p className="text-base font-normal text-danger-100">Please fill this field</p>
        </div>
      ) : invalidEmail ? (
        <div className="flex items-center gap-1 mt-[1px]">
          <WrongOutline />
          <p className="text-base font-normal text-danger-100">Please enter a valid email</p>
        </div>
      ) : wrong ? (
        <div className="flex items-center gap-1 mt-[1px]">
          <WrongOutline />
          <p className="text-base font-normal text-danger-100">Please meet the minimum criterias</p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Input;
