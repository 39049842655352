export const SETTINGS = {
  ACCOUNTS: 'Account',
  ALERTS: 'Alerts',
  CAMERAS: 'Cameras',
  SYSTEM: 'System',
  LOITERING: 'Loitering',
  AREA_SETTINGS: 'Area Settings',
}

export const ALERTNOTIFICATION = {
  APP: 'In-app',
  TELEGRAM: 'Telegram',
  WHATSAPP: 'WhatsApp',
  SMS: 'SMS',
}

export const ALERTTYPE = {
  INCIDENT: 'Incident',
  PRIORITY: 'Priority',
}

export const ALERTMODALTYPE = {
  EDIT: 'Edit',
  DELETE: 'Delete',
}

export const STATUSSYSTEM = {
  ERROR: 'error',
  MAINTENANCE: 'maintenance',
  ONLINE: 'online',
}
