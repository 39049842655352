import React from "react";

function CevronDown({ customSize, height, width, customStroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "8"}
      height={height ? height : "8"}
      fill="none"
      viewBox="0 0 8 8"
      className={customSize}
    >
      <path
        stroke={customStroke ? customStroke : "#B6C3D1"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.75 2.875L4 5.125l2.25-2.25"
      ></path>
    </svg>
  );
}

export default CevronDown;
