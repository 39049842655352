import React from "react";
import moment from "moment";

import { STATUS } from "~/constant";
import { PendingAlerts } from "~/components";

const DetectionAlerts = ({ latestData }) => {
  return (
    <div>
      <h1 className="text-2xl text-white font-semibold mb-4">Recently Detected</h1>
      <div className="grid grid-cols-1 gap-y-4 h-full">
        {(latestData.length &&
          latestData.map((data, idx) => (
            <PendingAlerts
              key={idx}
              type={data.type}
              location={data.location}
              status={data.seen ? STATUS.RESOLVED : STATUS.PENDING}
              date={new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "short",
              }).format(new Date(data.created_at))}
            />
          ))) || <></>}
      </div>
    </div>
  );
};

export default DetectionAlerts;
