import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { axios } from "~/utils";
import { store } from "..";

const initialState = {
  floorSettings: [],
  cameraSettings: [],
  siteSettings: [],
  locationSettings: [],
  sites: [],
  floors: [],
  timeout: 0,
  errorTimeout: "",
};

const baseUrl = process.env.BASE_URL;

export const getSettings = createAsyncThunk(
  "settings/getSettings",
  async (args, { rejectWithValue }) => {
    const { site, floor } = args;

    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/setting/filter?site=${site}&floor=${floor}`,
        headers: {},
      };

      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getSites = createAsyncThunk("sites/getSync", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${baseUrl}/setting/sites`);
    return response.data;
  } catch (err) {
    const message = { ...err.response.data.message, ...args };
    return rejectWithValue(message);
  }
});

export const getFloors = createAsyncThunk("floors/getSync", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${baseUrl}/setting/floors`);
    return response.data;
  } catch (err) {
    const message = { ...err.response.data.message, ...args };
    return rejectWithValue(message);
  }
});

export const getTimeoutAsync = createAsyncThunk(
  "timeout/get",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/setting/timeout?site=${args.site}`);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message };
      return rejectWithValue(message);
    }
  }
);

export const postTimeoutAsync = createAsyncThunk(
  "timeout/post",
  async (args, { rejectWithValue }) => {
    try {
      const config = {
        method: "post",
        url: `${baseUrl}/setting/timeout`,
        headers: {
          "Content-Type": "application/json",
        },
        data: args,
      };
      const response = await axios.request(config);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetSettingStore: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {})
      .addCase(getSettings.fulfilled, (state, action) => {
        state.cameraSettings = action.payload.data.cameras.map(
          (camera_settings) => camera_settings.name
        );
        state.floorSettings = action.payload.data.floors.map((floor_setting) => floor_setting.name);
        state.locationSettings = action.payload.data.locations.map(
          (location_setting) => location_setting.name
        );
      })
      .addCase(getSettings.rejected, (state) => {});

    builder
      .addCase(getSites.pending, (state) => {})
      .addCase(getSites.fulfilled, (state, action) => {
        state.sites = action.payload.data;
      })
      .addCase(getSites.rejected, (state) => {});

    builder
      .addCase(getFloors.pending, (state) => {})
      .addCase(getFloors.fulfilled, (state, action) => {
        state.floors = action.payload.data;
      })
      .addCase(getFloors.rejected, (state) => {});

    builder
      .addCase(getTimeoutAsync.pending, (state) => {})
      .addCase(getTimeoutAsync.fulfilled, (state, action) => {
        state.timeout = action.payload.data.timeout;
      })
      .addCase(getTimeoutAsync.rejected, (state) => {
        state.errorTimeout = action.payload.message;
      });
  },
});

export const { resetSettingStore } = settingSlice.actions;
export default settingSlice.reducer;
