import React from 'react';
import moment from 'moment';
import { BarChart } from '~/components';

function hsvToRgb(h, s, v) {
    var r, g, b, i, f, p, q, t;
    if (arguments.length === 1) {
        s = h.s, v = h.v, h = h.h;
    }
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v, g = t, b = p; break;
        case 1: r = q, g = v, b = p; break;
        case 2: r = p, g = v, b = t; break;
        case 3: r = p, g = q, b = v; break;
        case 4: r = t, g = p, b = v; break;
        case 5: r = v, g = p, b = q; break;
    }

    const _r = Math.round(r * 255);
    const _g = Math.round(g * 255);
    const _b = Math.round(b * 255);
    return `rgba(${_r}, ${_g}, ${_b}, 1)`;
}

function generateDistinctColors(n) {
  const colors = [];
  const step = Math.floor(360 / n);

  for (let i = 0; i < n; i++) {
    const hue = i * step;
    const saturation = 255; // You can adjust this value to change the saturation of the colors
    const value = 255; // You can adjust this value to change the lightness of the colors

    const rgbColor = hsvToRgb(hue/360, saturation/255, value/255);
    colors.push(rgbColor);
  }

  return colors;
}

const AnalyticsBarChart = ({title, timestamp, data, all_labels, precision="hours", postfix="person"}) => {
    // Grouping the number in an interval (Always by 11 steps)
    const timestamp_labels = timestamp.map(function(date){
        if(precision === "hours"){
            const dateFormat = 'HH';
            const hours = moment(date).format(dateFormat);
          return parseInt(hours);
        } else {
            return date;
        }
    });

    const timestamp_interval = Array.from({ length: 24 }, (_, i) => {
        if(precision === "hours"){
            const hours = i;
            const period = hours >= 12 ? ' PM' : ' AM';
            const hours12 = hours % 12 || 12;
            return hours12 + period;
        } else {
            return i;
        }
    });

    const group_data = data.reduce(function(grouping, info, idx) {
      const group = timestamp_labels[idx];
      const label = info.label;
      if(!(label in grouping)){
        grouping[label] = {};
      }
      if(group in grouping[label]){
        grouping[label][group] += info.data;
      } else {
        grouping[label][group] = info.data;
      }
      return grouping;
    }, {});


  const colors = generateDistinctColors(all_labels.length);

  const plotting_data = all_labels.map((key, j) => {
    const _colors = timestamp_interval.map((_, i) => colors[j]);
    const count_data = timestamp_interval.map((_, i) => {
      if(key in group_data && i in group_data[key]){
        return group_data[key][i];
      } else {
        return 0;
      }
    });
    return {label: key, data: count_data, backgroundColor: _colors, borderColor: _colors, borderWidth: 1.2};
  });

  // Get the average
  const average = Object.keys(group_data).reduce(function (avg, floor, _, { length }) {
    const sum_floor = Object.keys(group_data[floor]).reduce((total, time) => {
      return total + group_data[floor][time];
    }, 0);
    return avg + sum_floor / length;
  }, 0);

  const sum = Object.keys(group_data).reduce(function (total, floor) {
    const sum_floor = Object.keys(group_data[floor]).reduce((total, time) => {
      return total + group_data[floor][time];
    }, 0);
    return total + sum_floor;
  }, 0);

  // most frequent
  const frequency = Object.keys(group_data).map(function (floor) {
    const sum_floor = Object.keys(group_data[floor]).reduce((total, time) => {
      return total + group_data[floor][time];
    }, 0);
    return sum_floor;
  });

  const floors = Object.keys(group_data);
  const most_frequent_floor = floors[frequency.indexOf(Math.max(...frequency))];

  const frequency_time = timestamp_interval.map((_, i) => {
    const sum_time = Object.keys(group_data).reduce(function (total, floor) {
      if(i in group_data[floor]){
        return total + group_data[floor][i];
      }else{
        return total;
      }
    }, 0);
    return sum_time;
  });
  const most_frequent_time = timestamp_interval[frequency_time.indexOf(Math.max(...frequency_time))];
    return (
        <div>
            <h1 className='text-2xl font-semibold text-white mb-4'>{title}</h1>
            <div className='grid grid-cols-2 gap-x-8'>
                <div className='grid grid-cols-2'>
                  <p className='text-base text-white'>On Average (per floor)</p>
                  <p className='text-base font-semibold text-white text-right'>{average.toFixed(0)} {postfix}(s)</p>
                </div>
                <div className='grid grid-cols-2'>
                    <p className='text-base text-white'>Most frequent floor</p>
                    <p className='text-base font-semibold text-white text-right'>{most_frequent_floor}</p>
                </div>
                <div className='grid grid-cols-2'>
                    <p className='text-base text-white'>Total people (est.)</p>
                    <p className='text-base font-semibold text-white text-right'>{sum} {postfix}(s)</p>
                </div>
                <div className='grid grid-cols-2'>
                    <p className='text-base text-white'>Most frequent day</p>
                    <p className='text-base font-semibold text-white text-right'>{most_frequent_time}</p>
                </div>
            </div>
          <BarChart timestamp={timestamp_interval} datasets={plotting_data} />
        </div>
    );
};

export default AnalyticsBarChart;
