import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { axios } from '~/utils'
import { store } from '..'

const initialState = {
  isLoading: true,
  alert: [],
  incident: [],
  allAlerts: {
    today: [],
    pending: [],
    yesterday: [],
  },
  todayAlerts: [],
  pendingAlerts: [],
  selectedAlert: undefined,
  isSettingLoading: true,
  settingAlert: [],
}

const baseUrl = process.env.BASE_URL

export const getPendingAlertsAsync = createAsyncThunk(
  'pending_alerts/get',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/alert/pending`)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const getTodayAlertsAsync = createAsyncThunk(
  'today_alerts/get',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/alert/today`)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const getAllAlertsAsync = createAsyncThunk(
  'all_alerts/get',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/alert/all`, {
        params: {
          limit: 10,
        },
      })
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const searchAlertsAsync = createAsyncThunk(
  'search_alerts/get',
  async (keyword, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/alert/search`, {
        params: {
          keyword,
        },
      })
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...keyword }
      return rejectWithValue(message)
    }
  },
)

export const getSettingAlerts = createAsyncThunk(
  'setting_alerts/get',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/setting/alerts`)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message }
      return rejectWithValue(message)
    }
  },
)

export const postAlertPriority = createAsyncThunk(
  'setting_alerts_priority/post',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/setting/alerts/priority`, args)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message }
      return rejectWithValue(message)
    }
  },
)

export const postAlertIncident = createAsyncThunk(
  'setting_alerts_incident/post',
  async (args, { rejectWithValue }) => {
    const {
      incident_id,
      name,
      image,
      priority_id,
      description,
      keyword_type,
      loop,
      apps_notification,
      is_override_alert,
    } = args
    try {
      const formData = new FormData()
      formData.append('priority_id', priority_id)
      formData.append('incident_id', incident_id)
      formData.append('name', name)
      formData.append('keyword_type', keyword_type)
      formData.append('description', description)
      formData.append('image', image)
      formData.append('loop', loop)
      formData.append('apps_notification', apps_notification)
      formData.append('is_override_alert', is_override_alert)

      const response = await axios.post(`${baseUrl}/setting/alerts/incident`, formData)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message }
      return rejectWithValue(message)
    }
  },
)

export const deleteSettingAlerts = createAsyncThunk(
  'setting_alerts_delete/post',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/setting/alerts/delete`, args)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message }
      return rejectWithValue(message)
    }
  },
)

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    initAlertLoading: (state) => {
      state.isLoading = true
      state.isSettingLoading = true
    },
    setSelectedAlert: (state, action) => {
      state.selectedAlert = action.payload
    },
    resetAlertStore: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getPendingAlertsAsync.fulfilled, (state, action) => {
      const { results } = action.payload

      state.pendingAlerts = results
    })

    builder.addCase(getTodayAlertsAsync.fulfilled, (state, action) => {
      const { results } = action.payload

      state.todayAlerts = results
    })

    builder
      .addCase(getAllAlertsAsync.fulfilled, (state, action) => {
        const { results } = action.payload

        state.todayAlerts = results.today
        state.pendingAlerts = results.pending
        if (results.today.length > 0) {
          state.incident = results.today
        }
        state.allAlerts = results
        state.isLoading = false
      })
      .addCase(getAllAlertsAsync.rejected, (state) => {
        state.isLoading = false
      })

    builder
      .addCase(searchAlertsAsync.pending, (state) => {
        state.isLoading = true
      })
      .addCase(searchAlertsAsync.fulfilled, (state, action) => {
        const { results } = action.payload

        state.allAlerts = results
        state.isLoading = false
        state.selectedAlert = undefined
      })
      .addCase(searchAlertsAsync.rejected, (state) => {
        state.isLoading = false
      })

    builder
      .addCase(getSettingAlerts.pending, (state) => {
        state.isSettingLoading = true
      })
      .addCase(getSettingAlerts.fulfilled, (state, action) => {
        const { data } = action.payload

        state.settingAlert = data
        state.isSettingLoading = false
      })
      .addCase(getSettingAlerts.rejected, (state) => {
        state.isSettingLoading = false
      })

    builder
      .addCase(postAlertPriority.pending, (state) => {
        state.isSettingLoading = true
      })
      .addCase(postAlertPriority.fulfilled, (state) => {
        setTimeout(() => {
          store.dispatch(getSettingAlerts())
        })
        state.isSettingLoading = false
      })
      .addCase(postAlertPriority.rejected, (state) => {
        state.isSettingLoading = false
      })

    builder
      .addCase(postAlertIncident.pending, (state) => {
        state.isSettingLoading = true
      })
      .addCase(postAlertIncident.fulfilled, (state) => {
        setTimeout(() => {
          store.dispatch(getSettingAlerts())
        })
        state.isSettingLoading = false
      })
      .addCase(postAlertIncident.rejected, (state) => {
        state.isSettingLoading = false
      })

    builder
      .addCase(deleteSettingAlerts.pending, (state) => {
        state.isSettingLoading = true
      })
      .addCase(deleteSettingAlerts.fulfilled, (state) => {
        setTimeout(() => {
          store.dispatch(getSettingAlerts())
        })
        state.isSettingLoading = false
      })
      .addCase(deleteSettingAlerts.rejected, (state) => {
        state.isSettingLoading = false
      })
  },
})

export const { initAlertLoading, resetAlertStore, setSelectedAlert } = alertSlice.actions
export default alertSlice.reducer
