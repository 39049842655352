import React from 'react'

function Camera() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M2 6c0-.736.597-1.333 1.333-1.333h.62c.446 0 .862-.223 1.11-.594l.541-.813c.247-.37.664-.593 1.11-.593h2.572c.446 0 .863.222 1.11.593l.541.813c.248.37.664.594 1.11.594h.62C13.403 4.667 14 5.264 14 6v6c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 012 12V6z'></path>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M10 8.667a2 2 0 11-4 0 2 2 0 014 0z'></path>
    </svg>
  )
}

export default Camera
