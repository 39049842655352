import React from 'react'

import { Calendar, Clock, Info } from '~/svg'
import { Button, Canvas, Chip, Modal, MultiRangeSlider } from '~/components'

const BottomSheetFilter = ({
  floors,
  source,
  openInfo,
  openModal,
  timeRange,
  dateRange,
  closeInfo,
  closeFiter,
  resetFilter,
  openExpanded,
  dummyCameras,
  dateToString,
  selectFloors,
  selectSource,
  setConfidence,
  expandedFilter,
  confidenceInfo,
  changeTimeRange,
  implementFilter,
  bottomSheetFilter,
}) => {
  return (
    <Modal isOpen={bottomSheetFilter} closeModal={closeFiter}>
      <div className='w-full flex flex-col items-center pt-2 pb-5'>
        <div onClick={openExpanded} className='w-11 h-1 rounded bg-neutral-800 cursor-pointer' />
        <p className='mt-6 text-neutral-50 font-semibold text-sm'>Filter</p>
        <div className='w-full h-[1px] bg-neutral-800 mt-5' />
        <div className='w-full px-9'>
          <p className='text-sm font-semibold text-neutral-50 mt-8'>Source</p>
          <div className='flex flex-row flex-wrap gap-2 mt-2'>
            {source &&
              source.map((it, idx) => (
                <Chip key={idx} onClick={() => selectSource(it.source.type)} selected={it.selected}>
                  <p className='text-sm font-medium text-neutral-50'>{it.source.label}</p>
                </Chip>
              ))}
          </div>
        </div>
        <div className='w-full px-6'>
          <div className='w-full h-[1px] bg-neutral-800 mt-4' />
        </div>
        <div className='w-full px-9'>
          <p className='text-sm font-semibold text-neutral-50 mt-8'>Level</p>
          <div className='flex flex-row flex-wrap gap-2 mt-2'>
            {floors &&
              floors.map((it, idx) => (
                <Chip key={idx} onClick={() => selectFloors(it.name)} selected={it.selected}>
                  <p className='text-sm font-medium text-neutral-50'>{it.name}</p>
                </Chip>
              ))}
          </div>
        </div>
        <div className='w-full px-6'>
          <div className='w-full h-[1px] bg-neutral-800 mt-4' />
        </div>
        <div className='w-full px-9'>
          <p className='text-sm font-semibold text-neutral-50 mt-[10px]'>Camera</p>
          <div className='flex flex-col justify-center items-center'>
            <Canvas
              withScale
              width={dummyCameras.width}
              images={dummyCameras.images}
              height={dummyCameras.height}
              cameras={dummyCameras.cameras}
              scale={
                expandedFilter
                  ? window.innerWidth / 1.1 / dummyCameras.width
                  : window.innerWidth / 2 / dummyCameras.width
              }
            />
            <div className={`w-full ${expandedFilter ? 'text-start' : 'text-center mt-2'}`}>
              <p className='text-xs text-neutral-200'>Selected: All</p>
            </div>
          </div>
        </div>

        {expandedFilter ? (
          <>
            <div className='w-full px-6'>
              <div className='w-full h-[1px] bg-neutral-800 mt-4' />
            </div>

            <div className='w-full px-9'>
              <p className='text-sm font-semibold text-neutral-50 mt-[10px]'>Date and time</p>
              <div className='mt-[10px]'>
                <p className='font-semibold text-neutral-200 mb-1'>Date</p>
                <div
                  onClick={openModal}
                  className='flex w-full border-[1px] border-neutral-800 rounded-md items-center justify-between px-4 py-3 cursor-pointer'>
                  <p className='text-neutral-200 w-full truncate'>{`Until ${
                    dateRange.endDate === null ? 'Today' : dateToString(dateRange.endDate)
                  }`}</p>
                  <Calendar />
                </div>
                <p className='font-semibold text-neutral-200 mt-2 mb-1'>Time</p>
                <div className='flex w-full border-[1px] border-neutral-800 rounded-md items-center justify-between px-4 py-3 cursor-pointer'>
                  <div className='flex items-center gap-[2px]'>
                    <input
                      type='text'
                      name='startClock'
                      onChange={changeTimeRange}
                      value={timeRange.startClock}
                      className='w-5 bg-transparent outline-none border-none text-neutral-200'
                    />
                    <p className='text-neutral-200'>:</p>
                    <input
                      type='text'
                      name='startMinute'
                      onChange={changeTimeRange}
                      value={timeRange.startMinute}
                      className='w-5 bg-transparent outline-none border-none text-neutral-200'
                    />
                    <p className='text-neutral-200'>-</p>
                    <input
                      type='text'
                      name='endClock'
                      onChange={changeTimeRange}
                      value={timeRange.endClock}
                      id=''
                      className='w-5 bg-transparent outline-none border-none text-neutral-200'
                    />
                    <p className='text-neutral-200'>:</p>
                    <input
                      type='text'
                      name='endMinute'
                      onChange={changeTimeRange}
                      value={timeRange.endMinute}
                      className='w-5 bg-transparent outline-none border-none text-neutral-200'
                    />
                  </div>
                  <Clock white />
                </div>
              </div>
            </div>

            <div className='w-full px-6'>
              <div className='w-full h-[1px] bg-neutral-800 mt-4' />
            </div>

            <div className='w-full px-9'>
              <div className='flex items-center gap-2 mt-[10px]'>
                <p className='text-sm font-semibold text-neutral-50'>Confidence level</p>
                <div onMouseEnter={openInfo} onMouseLeave={closeInfo} className='relative'>
                  <Info />
                  {confidenceInfo ? (
                    <div className='absolute left-[-72px] top-[-54px] w-[145px] bg-[#334155] rounded px-2 py-1'>
                      <p className='text-start text-sm text-white'>
                        How well the <br /> match accuracy is.
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div>
                <div className='w-full mx-auto'>
                  <MultiRangeSlider
                    min={0}
                    max={100}
                    onChange={({ min, max }) => setConfidence({ min, max })}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className={`w-full h-[1px] bg-[#1E1E1E] ${expandedFilter ? 'mt-10' : 'mt-1'}`} />
        <div className='w-full p-4'>
          <Button onClick={resetFilter} type={'secondary'}>
            <p>Reset All</p>
          </Button>
          <div className='mt-2' />
          <Button onClick={implementFilter} type={'primary'}>
            <p>Apply Filter</p>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default BottomSheetFilter
