import React, { useRef, useState } from "react";
import { Button } from "~/components";
import { EXPORT_TYPE } from "~/constant";
import { CevronDown, Checked } from "~/svg";
import { useOutsideClick } from "~/utils";

const exportOptions = [
  {
    label: "Export data as CSV",
    type: EXPORT_TYPE.CSV,
  },
];

const DropdownExport = ({ onSelectExport }) => {
  const dropdownRef = useRef();

  const [dropdownState, setDropdown] = useState(false);

  function toggleDropdown() {
    setDropdown(!dropdownState);
  }

  useOutsideClick({ ref: dropdownRef, action: () => setDropdown(false) });

  return (
    <div className="flex flex-col ">
      <label className="relative  inline-block gap-1 float-right" ref={dropdownRef}>
        <Button type={"primary"} small onClick={toggleDropdown}>
          <p className="leading-loose text-black">Export</p>
          <CevronDown customStroke={"#000"} />
        </Button>
        <>
          {dropdownState && (
            <div
              data-testid="dropdown-options"
              className="absolute right-0 rounded border min-w-48 border-grey-600"
            >
              {exportOptions.map((option, index) => {
                return (
                  <div
                    className="px-4 py-3 bg-black hover:bg-neutral-800 text-neutral-50 cursor-pointer"
                    key={index}
                    onClick={() => onSelectExport(option.type)}
                  >
                    <p className="text-neutral-200 leading-tight text-sm text-start flex flex-row gap-3">
                      {option.label}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </>
      </label>
    </div>
  );
};

export default DropdownExport;
