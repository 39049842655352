import React from 'react'
import moment from 'moment'

import { Trash } from '~/svg'
import { Shimmer } from '~/components'
import VideoThumbnail from 'react-video-thumbnail'
import { useVideoItems } from './hooks'
import ModalDeleteVideo from '../modal-delete-video'
const baseUrl = process.env.BASE_URL

// same component as saved-video-items. if there are more list like this move it to component
const UploadedVideoItems = ({ item }) => {
  const {
    datas: { thumbnail, inputRef, stateVideoName, stateSelected, stateDeleteModal },
    methods: {
      setVideoName,
      setThumbnail,
      setInputFocus,
      handleUpdateName,
      clickUploadVideo,
      setStateSelected,
      submitDeleteVideo,
      setStateDeleteModal,
    },
  } = useVideoItems({ item })

  return item !== undefined ? (
    <div className={'w-[292px] relative'}>
      <div
        data-testid='thumbnail'
        onClick={() => setStateSelected(!stateSelected)}
        className='cursor-pointer'>
        {!thumbnail ? <Shimmer height={155} /> : ''}
        <div className={`${!thumbnail ? 'hidden' : ''}`}>
          <VideoThumbnail
            videoUrl={`${baseUrl}/video/${item.path}`}
            renderThumbnail={true}
            thumbnailHandler={() => {
              setThumbnail(true)
            }}
            height={155}
          />
        </div>
      </div>
      <div className='absolute w-[276px] top-0 left-0 p-2 flex justify-between'>
        <div className='font-roboto font-normal text-neutral-50 text-[10px] p-1'>
          <p>{moment(new Date(item.created_at)).format('DD-MM-YYYY ddd h:mm:ss')}</p>
          <p>{item.locations}</p>
        </div>
      </div>
      <div className='mt-3 flex flex-col gap-1'>
        <input
          id='name'
          type='text'
          name='name'
          ref={inputRef}
          value={stateVideoName}
          onBlur={(e) => handleUpdateName(e)}
          onChange={(e) => setVideoName(e.target.value)}
          className='text-xs w-full text-neutral-200 placeholder:text-neutral-200 outline-none border-none bg-transparent truncate'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.target.blur()
            }
          }}
        />
        {stateSelected ? (
          <div className='w-full h-5 justify-between items-center inline-flex'>
            <button
              data-testid='trash'
              className='cursor-pointer'
              onClick={() => setStateDeleteModal(true)}>
              <Trash className={'stroke-danger-200'} />
            </button>
            <div className='justify-start items-center gap-2 flex'>
              <p
                className='text-primary-50 text-sm font-semibold underline leading-tight cursor-pointer'
                onClick={setInputFocus}>
                Rename
              </p>
              <p
                data-testid='search-video'
                className='text-primary-200 text-sm font-semibold underline leading-tight cursor-pointer'
                onClick={clickUploadVideo}>
                Search This Video
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <ModalDeleteVideo
        isOpen={stateDeleteModal}
        closeModal={() => setStateDeleteModal(false)}
        submitDeleteVideo={submitDeleteVideo}
        videoLabel={stateVideoName}
      />
    </div>
  ) : (
    <></>
  )
}

export default UploadedVideoItems
