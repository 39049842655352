import { useEffect, useState } from "react";
import { getCameraAsync, getCameraAsyncV2, resetcameraStore } from "~/store/slice/camera";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getFloors, getSites, resetSettingStore } from "~/store/slice/setting";
import { PATH } from "~/constant";

export function useHome() {
  const location = useLocation();
  const dispatch = useDispatch();

  const siteList = useSelector((state) => state.settings.sites);
  const [selectedSites, setSelectedSites] = useState([]);

  const floorData = useSelector((state) => state.settings.floors);
  const [floorList, setFloorList] = useState(floorData);
  const [selectedFloors, setSelectedFloors] = useState([]);

  const camerasV2 = useSelector((state) => state.camera.camerasV2);
  const allCameras = useSelector((state) => state.camera.allCameras);
  const id_allCameras = useSelector((state) => state.camera.id_allCameras);
  const [cameraList, setCameraList] = useState(allCameras);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [searchCamera, setSearchCamera] = useState("");
  const [streamData, setStreamData] = useState([]);
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    if (location.pathname == PATH.WATCH) {
      dispatch(getSites());
      dispatch(getFloors());
      dispatch(getCameraAsyncV2({ site: location?.state?.site }));
    }
  }, [location])

  useEffect(() => {
    setSelectedSites([siteList[location?.state?.site-1]?._id])
    const defaultFloor = floorData.filter(item => item.site_id === siteList[location?.state?.site-1]._id)
    setFloorList(defaultFloor);
    setSelectedFloors([defaultFloor[0]?._id])
    const defaultCamera = allCameras.filter(item => item.floor_id === floorList[0]?._id)
    setCameraList(defaultCamera);
    setTempData(defaultCamera);
    const firstFourData = defaultCamera.slice(0, 4).map(d => d._id);
    setSelectedCameras(firstFourData)
  }, [siteList, floorData, allCameras])


  function handleChangeSearchCamera(e) {
    if (e.target.value) {
      setCameraList(tempData.filter((item) => item.name.includes(e.target.value)));
    } else {
      setCameraList(tempData);
    }
  }

  function submitSearchCamera() {
    // dispatch submit search
  }

  function handleFilter(type, value) {
    switch (type) {
      case 'sites':
        if (selectedSites.includes(value)) {
          setSelectedSites(selectedSites.filter(item => item !== value));
        } else {
          setSelectedSites([...selectedSites, value]);
        }
        break;
      case 'floors':
        if (selectedFloors.includes(value)) {
          setSelectedFloors(selectedFloors.filter(item => item !== value));
        } else {
          setSelectedFloors([...selectedFloors, value]);
        }
        break;
      case 'cameras':
        if (selectedCameras.includes(value)) {
          setSelectedCameras(selectedCameras.filter(item => item !== value));
        } else {
          setSelectedCameras((prevState) => (prevState.length === 4 ? [...prevState.slice(0,prevState.length-1), value] : [...prevState, value]));
        }
        break;
    }
  }

  useEffect(() => {
    if (selectedSites) {
      setFloorList(floorData.filter((item) => selectedSites.includes(item.site_id)))
    }
    if (selectedFloors) {
      setCameraList(allCameras.filter((item) => selectedFloors.includes(item.floor_id)))
      setTempData(allCameras.filter((item) => selectedFloors.includes(item.floor_id)))
    }
  }, [selectedSites, selectedFloors]);

  useEffect(() => {
    const showData = selectedCameras.map((_id) => id_allCameras[_id]);
    setStreamData((prevState) => showData);
  }, [selectedCameras]);

  return {
    datas: {
      siteList,
      floorList,
      streamData,
      cameraList,
      searchCamera,
      selectedSites,
      selectedFloors,
      selectedCameras,
    },
    methods: {
      handleFilter,
      submitSearchCamera,
      handleChangeSearchCamera,
    },
  };
}
