import React, { useEffect, useRef } from "react";

const CanvasImage = ({ imageData, handleAnchorPoints }) => {
  const canvasRef = useRef(null);
  const points = useRef(imageData.polygon);
  const drawing = useRef(false);
  const anchorPoints = useRef(imageData.polygon);

  useEffect(() => {
    const canvas = canvasRef.current;

    const context = canvas.getContext("2d");
    const image = new Image();

    // Set up the image's load event
    image.onload = () => {
      // Set canvas width and height to match the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;
      // Draw the image onto the canvas when it's fully loaded
      context.drawImage(image, 0, 0);

      // Draw existing anchor points
      if (imageData.polygon.length > 0) {
        handleAnchorPoints(imageData, imageData.polygon);
        draw();
      }
    };

    // Set the source of the image to be drawn
    image.src = imageData.frame;

    const handleMouseDown = (e) => {
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const clickedPointIndex = points.current.findIndex((point) => {
        const dx = point.x - x;
        const dy = point.y - y;
        return Math.sqrt(dx * dx + dy * dy) < 10; // 10 is the radius of anchor points
      });
      if (clickedPointIndex !== -1) {
        points.current.splice(clickedPointIndex, 1);
        const updatedPoints = [...anchorPoints.current];
        updatedPoints.splice(clickedPointIndex, 1);
        anchorPoints.current = updatedPoints;
        handleAnchorPoints(imageData, updatedPoints);
        draw();
      } else {
        if (anchorPoints.current.length === 10) {
          anchorPoints.current = [];
          points.current = [];
        }

        const newPoints = [...anchorPoints.current, { x, y }];
        anchorPoints.current = newPoints;
        handleAnchorPoints(imageData, newPoints);
        points.current.push({ x, y });
        drawing.current = true;
        draw();
      }
    };

    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const isHoveringPoint = points.current.some((point) => {
        const dx = point.x - x;
        const dy = point.y - y;
        return Math.sqrt(dx * dx + dy * dy) < 10; // 10 is the radius of anchor points
      });

      if (!drawing.current) return;
      points.current[points.current.length - 1] = { x, y };
      draw();
    };

    const handleMouseUp = () => {
      drawing.current = false;
    };

    const draw = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.filter = "none";
      context.drawImage(image, 0, 0);

      context.lineJoin = "round";
      context.lineWidth = 5;

      const newPoints = groupArray(points.current, 10);
      newPoints.forEach((pointGroup) => {
        // Draw lines between points
        context.filter = "none";
        context.strokeStyle = "#CE1C1C"; // Change line color here
        context.beginPath();
        pointGroup.forEach((point, index) => {
          if (index === 0) {
            context.moveTo(point.x, point.y);
          } else {
            context.lineTo(point.x, point.y);
          }
        });
        if (pointGroup.length >= 3) {
          const firstPoint = pointGroup[0];
          context.lineTo(firstPoint.x, firstPoint.y);
        }
        context.stroke();
        context.closePath();

        // Draw anchor points
        context.fillStyle = "#FFFDF5"; // Change anchor point fill color here
        context.strokeStyle = "#CE1C1C"; // Change anchor point stroke color here
        points.current.forEach((point, index) => {
          const radius = 7;
          if (points.current.length - 1 === index && points.current.length < 4) {
            context.fillStyle = "#CE1C1C"; // Change anchor point fill color here
            context.strokeStyle = "#FFFDF5"; // Change anchor point stroke color here
          }

          context.beginPath();
          context.arc(point.x, point.y, radius, 0, 2 * Math.PI);
          context.fill();
          context.stroke();
          context.closePath();
        });

        // Fill shape with solid color and apply blur effect
        context.fillStyle = "#FEC8C8"; // Change solid color here
        context.filter = "opacity(42%)"; // Adjust blur intensity as needed
        context.beginPath();
        pointGroup.forEach((point, index) => {
          if (index === 0) {
            context.moveTo(point.x, point.y);
          } else {
            context.lineTo(point.x, point.y);
          }
        });
        context.fill();
        context.closePath();
      });
    };

    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseup", handleMouseUp);

    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseup", handleMouseUp);
    };
  }, [imageData]);

  return (
    <canvas className="w-[640] h-[360] cursor-default" width="640" height="360" ref={canvasRef} />
  );
};

const groupArray = (array, groupSize) => {
  var groupedArray = [];
  for (var i = 0; i < array.length; i += groupSize) {
    groupedArray.push(array.slice(i, i + groupSize));
  }
  return groupedArray;
};

export default CanvasImage;
