import { START_DATE } from "@datepicker-react/hooks";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ANALYTICS_TYPE, EXPORT_TYPE } from "~/constant";
import { exportIncident } from "~/store/slice/incident";
import { exportPeople } from "~/store/slice/people";
import { formatDate, useOutsideClick } from "~/utils";

export function useExportModal({ exportType }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const datePickerRef = useRef(null);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([
    {
      name: ANALYTICS_TYPE.CROWD_DENSITY,
      checked: false,
    },
    {
      name: ANALYTICS_TYPE.FIRE_SMOKE,
      checked: false,
    },
    {
      name: ANALYTICS_TYPE.FALL_DETECTION,
      checked: false,
    },
    {
      name: ANALYTICS_TYPE.PEOPLE_LOITERING,
      checked: false,
    },
  ]);
  const [selectedOption, setSelectedOption] = useState("today");

  // options: today, week, month, custom
  const [dateRange, setDateRange] = useState({
    endDate: null,
    startDate: null,
    focusedInput: START_DATE,
  });
  const [displayDate, setDisplayDate] = useState("Until Today");
  const [hourExportState, setHourExportState] = useState({
    startHour: "00:00",
    endHour: "23:59",
  });

  useOutsideClick({ ref: datePickerRef, action: () => setShowDatePicker(false) });

  const handleCheckboxChange = (event) => {
    const target = event.target;
    const checked = target.checked;
    const name = target.name;

    setSelectedCheckboxes((prevState) =>
      prevState.map((checkbox) => (checkbox.name === name ? { ...checkbox, checked } : checkbox))
    );
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  function toggleDatePicker() {
    setShowDatePicker(!showDatePicker);
  }

  const handleHourChange = (event) => {
    let { name, value } = event.target;

    if (name == "endHour" && value < hourExportState.startHour) {
      value = hourExportState.startHour;
    }

    setHourExportState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    let filtered_dates = "Until Today";
    if (dateRange.startDate !== null) {
      const startDate = formatDate(dateRange.startDate);
      filtered_dates = "start: " + startDate;
    }
    if (dateRange.endDate !== null) {
      const endDate = formatDate(dateRange.endDate);
      filtered_dates += "\n end: " + endDate;
    }
    setDisplayDate(filtered_dates);
  }, [dateRange]);

  function submitExport() {
    if (exportType == EXPORT_TYPE.CSV) {
      selectedCheckboxes.forEach((element) => {
        if (!element.checked) return;

        const paramExport = {
          site: location.state.site,
          after: 1,
          before: 1,
          type: "",
          fileName: "",
        };
        const startDate = new Date();
        const endDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 59);

        switch (selectedOption) {
          case "today":
            paramExport.after = startDate;
            paramExport.before = endDate;
            break;
          case "week":
            const startWeek = new Date(startDate);
            startWeek.setDate(startDate.getDate() - startDate.getDay() + 1);
            paramExport.after = startWeek;
            break;
          case "month":
            const startMonth = new Date(startDate);
            startMonth.setDate(1);
            paramExport.after = startMonth;
            break;
          case "custom":
            if(dateRange.startDate !== null){
              paramExport.after = dateRange.startDate;
            } else {
              const afterDateObj = new Date();
              afterDateObj.setHours(0);
              afterDateObj.setMinutes(0);
              afterDateObj.setSeconds(0);
              paramExport.after = afterDateObj;
            }
            if(dateRange.endDate !== null){
              paramExport.before = dateRange.endDate;
            } else {
              const beforeDateObj = new Date();
              beforeDateObj.setHours(23);
              beforeDateObj.setMinutes(59);
              beforeDateObj.setSeconds(59);
              paramExport.before = beforeDateObj;
            }

            paramExport.after.setHours(
              hourExportState.startHour.split(":")[0],
              hourExportState.startHour.split(":")[1]
            );
            paramExport.before.setHours(
              hourExportState.endHour.split(":")[0],
              hourExportState.endHour.split(":")[1]
            );
            break;
          default:
            break;
        }

        paramExport.fileName = `${element.name}
        _${selectedOption}
        _${paramExport.after.toISOString()}
        _${paramExport.before.toISOString()}.csv`;
        switch (element.name) {
          case ANALYTICS_TYPE.FIRE_SMOKE:
            paramExport.type = "Fire/Smoke";

            dispatch(exportIncident(paramExport));
            break;
          case ANALYTICS_TYPE.FALL_DETECTION:
            paramExport.type = "Fall";
            dispatch(exportIncident(paramExport));

            break;
          case ANALYTICS_TYPE.PEOPLE_LOITERING:
            paramExport.type = "loitering";
            dispatch(exportIncident(paramExport));

            break;
          default:
            // crowd density uses different API
            dispatch(exportPeople(paramExport));
            break;
        }
      });
    } else if (exportType == EXPORT_TYPE.IMAGE) {
      // TO DO: Implement export image
    }
  }

  return {
    datas: {
      dateRange,
      displayDate,
      datePickerRef,
      selectedOption,
      showDatePicker,
      hourExportState,
      selectedCheckboxes,
    },
    methods: {
      setDateRange,
      handleHourChange,
      toggleDatePicker,
      handleOptionChange,
      handleCheckboxChange,
      submitExport,
    },
  };
}
