import React from 'react'

import { IconX, WrongOutline } from '~/svg'
import { Button, Input, Modal } from '~/components'

const PasswordModal = ({
  handleKeyUp,
  formPassword,
  setPassModal,
  actionVerify,
  passwordModal,
  submitPassword,
  handleChangePass,
}) => {
  return (
    <Modal isOpen={passwordModal} closeModal={() => setPassModal(false)}>
      <div className='flex flex-col py-2 px-8 w-[600px]'>
        <div className='flex flex-row w-full justify-between max-md:border-b max-md:border-neutral-800 mt-3'>
          <p className='w-full text-neutral-50 font-semibold text-sm md:text-xl text-center md:text-left my-5'>
            Edit Account Information
          </p>
          <div
            data-testid='iconx'
            className='flex max-md:hidden cursor-pointer'
            onClick={() => setPassModal(false)}>
            <IconX />
          </div>
        </div>
        <p className='text-neutral-200 text-sm my-4 md:my-2'>
          To confirm your identity, please enter your password.
        </p>
        <Input
          name={'password'}
          type={'password'}
          onKeyUp={handleKeyUp}
          onChange={handleChangePass}
          value={formPassword.password}
          error={formPassword.errPassword}
          empty={formPassword.emptyPassword}
        />
        {formPassword.errPassword ? (
          <div data-testid='err-password' className='flex flex-row gap-1 items-center mt-1'>
            <WrongOutline />
            <p className='text-danger-100'>Wrong password, please try again</p>
          </div>
        ) : (
          ''
        )}
        <div className='mt-11 md:mt-4 flex md:flex-row flex-col justify-end w-full gap-2'>
          <div className='w-fit'>
            <Button
              data-testid='button-cancel'
              type={'secondary'}
              onClick={() => setPassModal(false)}>
              Cancel
            </Button>
          </div>
          <div className='w-fit'>
            <Button data-testid='button-verify' type={'primary'} onClick={submitPassword}>
              {(() => {
                switch (actionVerify) {
                  case 'edit':
                    return 'Edit Information'
                  case 'email':
                    return 'Reveal Email'
                  case 'phone':
                    return 'Reveal Phone Number'
                  default:
                    break
                }
              })()}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PasswordModal
