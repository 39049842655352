import React from 'react'

function WallCrack() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        fill='#1C1C1C'
        stroke='#DEEAF5'
        strokeWidth='1.5'
        d='M15.25 16v.75H16a.25.25 0 110 .5h-3a.25.25 0 01-.25-.25v-2A1.75 1.75 0 0011 13.25H9A1.75 1.75 0 007.25 15v2a.25.25 0 01-.25.25H4a.25.25 0 110-.5h.75V4c0-.69.56-1.25 1.25-1.25h8c.69 0 1.25.56 1.25 1.25v12zM7 4.25h-.75v3.5h3.5v-3.5H7zM9.75 9v-.75h-3.5v3.5h3.5V9zM11 4.25h-.75v3.5h3.5v-3.5H11zM13.75 9v-.75h-3.5v3.5h3.5V9z'></path>
      <path
        fill='#F7D149'
        fillRule='evenodd'
        d='M15.303 2.24a.8.8 0 011.394 0l2.232 3.968a.8.8 0 01-.697 1.192h-4.464a.8.8 0 01-.697-1.192l2.232-3.969zM16.4 6.2a.4.4 0 11-.8 0 .4.4 0 01.8 0zM16 3a.4.4 0 00-.4.4v1.2a.4.4 0 00.8 0V3.4A.4.4 0 0016 3z'
        clipRule='evenodd'></path>
    </svg>
  )
}

export default WallCrack
