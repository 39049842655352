import React from 'react'

import { Button } from '~/components'
import { NOTIF_STATUS, NOTIF_TYPE } from '~/constant'
import { CevronDown, CheckList, CheckOutline, FireNotifications, Search } from '~/svg'

import NotifShimmer from './shimmer'

const Notifications = ({
  statusRef,
  isLoading,
  dataNotif,
  openStatus,
  notifStatus,
  selectedStatus,
  showNotifStatus,
  handleNotifStatus,
  handeMarkAsRead,
}) => {
  return isLoading ? (
    <NotifShimmer />
  ) : (
    <div className='w-full rounded-lg pt-3 pb-0 bg-neutral-900 absolute z-50'>
      <div className='flex justify-between items-center px-4 mb-2'>
        <div className='flex items-center gap-2'>
          <p className='text-sm font-semibold text-neutral-200'>Notifications</p>
          <div className='flex items-center w-16 justify-between p-1 relative gap-1'>
            <div className='flex flex-row items-center gap-3 cursor-pointer' onClick={openStatus}>
              <p className='text-sm font-semibold text-neutral-200 w-full'>{selectedStatus}</p>
              <div onClick={openStatus}>
                <CevronDown />
              </div>
            </div>
            {showNotifStatus ? (
              <div
                ref={statusRef}
                className={`absolute left-0 ${
                  selectedStatus === NOTIF_STATUS.ALL
                    ? 'top-0'
                    : selectedStatus === NOTIF_STATUS.UNREAD
                    ? 'bottom-0'
                    : 'top-[-16px]'
                }`}>
                <ListNotif
                  notifStatus={notifStatus}
                  selectedStatus={selectedStatus}
                  handleNotifStatus={handleNotifStatus}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='flex items-center gap-1 cursor-pointer' onClick={handeMarkAsRead}>
          <p className='text-sm text-white'>Mark all as read</p>
          <CheckOutline />
        </div>
      </div>
      {/* {dummyEmergency && dummyEmergency.map((it, idx) => <EmergencyItems key={idx} it={it} />)} */}
      {dataNotif && dataNotif.map((it, idx) => <NotifItems key={idx} it={it} />)}
    </div>
  )
}

export default Notifications

const ListNotif = ({ notifStatus, selectedStatus, handleNotifStatus }) => {
  return (
    <div className='bg-neutral-800 rounded w-16 px-1 py-[2px]'>
      {notifStatus &&
        notifStatus.map((it) => (
          <div
            key={it}
            onClick={() => handleNotifStatus(it)}
            className='cursor-pointer flex items-center justify-between w-full'>
            <p className='font-medium text-xs text-neutral-400 leading-5'>{it}</p>
            {it === selectedStatus ? <CheckList dark /> : <></>}
          </div>
        ))}
    </div>
  )
}

const NotifItems = ({ it }) => {
  return (
    <div>
      <div>
        {it.type === NOTIF_TYPE.SEARCH ? (
          <div
            className={`w-full flex px-4 pt-2 pb-4 gap-4 border-t-[1px] border-t-neutral-800 ${
              !it.is_read ? 'bg-primary-900' : ''
            }`}>
            <div className='w-7'>
              <Search />
            </div>
            <div className='flex flex-col'>
              {' '}
              <p
                className={`text-sm font-normal ${
                  it.is_read ? 'text-neutral-500' : 'text-neutral-50'
                }`}>
                <span className='font-semibold'>{it.description}</span>
              </p>
              <div className='w-full flex mt-2'>
                <p className='text-sm font-medium text-neutral-400'>{it.created_at}</p>
              </div>
            </div>
          </div>
        ) : it.type === NOTIF_TYPE.REPORT ? (
          <div
            className={`w-full flex px-4 pt-2 pb-4 gap-4 border-t-[1px] border-t-neutral-800 ${
              !it.is_read ? 'bg-primary-900' : ''
            }`}>
            <div className='h-7 w-7 rounded-full bg-neutral-800 flex justify-center items-center'></div>
            <div className='flex flex-col'>
              <p
                className={`text-sm font-normal ${
                  it.is_read === NOTIF_STATUS.SEEN ? 'text-neutral-500' : 'text-neutral-50'
                }`}>
                <span className='font-semibold'>{it.description}</span>
              </p>
              <div className='w-full flex mt-2'>
                <p className='text-sm font-medium text-neutral-400'>{it.created_at}</p>
              </div>
            </div>
          </div>
        ) : it.type === NOTIF_TYPE.ALERTS ? (
          <div
            className={`w-full flex px-4 pt-2 pb-4 gap-4 ${
              !it.is_read ? 'bg-danger-900' : 'bg-primary-900'
            } border-t-[1px] border-t-neutral-800`}>
            <div className='w-7'>
              <FireNotifications />
            </div>
            <div className='flex flex-col'>
              <div>
                <p className='text-sm text-neutral-50'>
                  <span className='font-semibold'>{it.description}</span>
                </p>
                <div className='flex items-center mt-4 justify-start gap-2'>
                  <div className='w-[87px]'>
                    <Button small type={'primary'}>
                      <p>See Alert</p>
                    </Button>
                  </div>
                  <div className='w-[161px]'>
                    <Button small type={'secondary'}>
                      <p>Call Firefighters</p>
                    </Button>
                  </div>
                </div>
              </div>
              <div className='w-full flex mt-2'>
                <p className='text-sm font-medium text-neutral-400'>{it.created_at}</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
