import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { PATH, SETTINGS } from "~/constant";
import { getCameraAsync } from "~/store/slice/camera";
import { getProfile, getSession, getSystemUser } from "~/store/slice/account";
import { getSettingAlerts } from "~/store/slice/alert";
import { getTimeoutAsync } from "~/store/slice/setting";

export function useSettings() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get("tab");

  const isLoading = useSelector((state) => state.camera.isLoading);
  const accountLoading = useSelector((state) => state.account.isLoading);
  const alertsLoading = useSelector((state) => state.alert.isSettingLoading);
  const systemLoading = useSelector((state) => state.account.isSystemLoading);
  const admin = useSelector((state) => state.account.admin);

  const settingMenus = [
    // onyl camera is enabled for now
    SETTINGS.ACCOUNTS,
    SETTINGS.AREA_SETTINGS,
  ];

  function navToTab(it) {
    navigate({
      pathname: PATH.SETTING,
      search: createSearchParams({
        tab: it,
      }).toString(),
    });
  }

  useEffect(() => {
    if (tabValue === null || tabValue === undefined) {
      navToTab(SETTINGS.AREA_SETTINGS);
    }
  }, [tabValue]);

  useEffect(() => {
    const site = location.state.site;

    switch (tabValue) {
      case SETTINGS.ACCOUNTS:
        dispatch(getProfile());
        dispatch(getSession());
        break;
      case SETTINGS.AREA_SETTINGS:
        dispatch(getCameraAsync({ site }));
        dispatch(getTimeoutAsync({ site }));
        break;
      case SETTINGS.CAMERAS:
        dispatch(getCameraAsync({ site }));
        dispatch(getTimeoutAsync({ site }));
        break;
      case SETTINGS.ALERTS:
        dispatch(getSettingAlerts());
        break;
      case SETTINGS.SYSTEM:
        dispatch(getSystemUser());
        break;
      default:
        break;
    }
    if (tabValue === SETTINGS.AREA_SETTINGS) {
      dispatch(getCameraAsync());
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [tabValue]);

  return {
    datas: {
      tabValue,
      isLoading,
      settingMenus,
      alertsLoading,
      accountLoading,
      systemLoading,
    },
    methods: { navToTab },
  };
}
