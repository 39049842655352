import React from 'react'

import { Button, Modal } from '~/components'
import { IconX } from '~/svg'

const ModalDeleteVideo = ({ isOpen, closeModal, submitDeleteVideo, videoLabel }) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className='flex flex-col py-2 px-8 w-[600px]'>
        <div className='flex flex-row w-full justify-between max-md:border-b max-md:border-neutral-800 mt-3 items-center mb-2'>
          <p
            className='w-full text-neutral-50 font-semibold text-sm md:text-xl text-center md:text-left'
            data-testid='title'>
            Delete Video
          </p>
          <div className='flex max-md:hidden cursor-pointer' onClick={closeModal}>
            <IconX />
          </div>
        </div>
        <p className='text-neutral-200 text-sm my-4 md:my-2' data-testid='desc'>
          This will delete “{videoLabel}” from the storage. This action is irreversible. Are you
          sure to continue?
        </p>
        <div className='mt-11 md:mt-4 flex md:flex-row flex-col justify-end w-full gap-2'>
          <div className='w-full md:w-fit'>
            <Button type={'secondary'} onClick={closeModal}>
              Cancel
            </Button>
          </div>
          <div className='w-full md:w-fit'>
            <Button type={'danger'} onClick={submitDeleteVideo}>
              Delete Video
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalDeleteVideo
