import React from 'react'

function Sort() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M2.5 3.333h10.833M2.5 6.668H10M2.5 10h5m3.333 0l3.334-3.333m0 0L17.5 10m-3.333-3.333v10'></path>
    </svg>
  )
}

export default Sort
