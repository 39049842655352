import React from 'react'

function Exclamation() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        fill='#F7D149'
        fillRule='evenodd'
        d='M6.606 2.48a1.6 1.6 0 012.789 0l4.464 7.935a1.6 1.6 0 01-1.395 2.385H3.536a1.6 1.6 0 01-1.395-2.385L6.606 2.48zM8.8 10.4a.8.8 0 11-1.6 0 .8.8 0 011.6 0zM8 4a.8.8 0 00-.8.8v2.4a.8.8 0 101.6 0V4.8A.8.8 0 008 4z'
        clipRule='evenodd'></path>
    </svg>
  )
}

export default Exclamation
