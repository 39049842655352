import React from 'react';

function PeopleLoitering() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M12.8997 5.8335C12.8997 7.21421 11.7805 8.3335 10.3997 8.3335C9.01903 8.3335 7.89974 7.21421 7.89974 5.8335C7.89974 4.45278 9.01903 3.3335 10.3997 3.3335C11.7805 3.3335 12.8997 4.45278 12.8997 5.8335Z"
        fill="#1C1C1C"
      />
      <path
        d="M17.8997 8.3335C17.8997 9.25397 17.1535 10.0002 16.2331 10.0002C15.3126 10.0002 14.5664 9.25397 14.5664 8.3335C14.5664 7.41302 15.3126 6.66683 16.2331 6.66683C17.1535 6.66683 17.8997 7.41302 17.8997 8.3335Z"
        fill="#1C1C1C"
      />
      <path
        d="M6.23307 8.3335C6.23307 9.25397 5.48688 10.0002 4.56641 10.0002C3.64593 10.0002 2.89974 9.25397 2.89974 8.3335C2.89974 7.41302 3.64593 6.66683 4.56641 6.66683C5.48688 6.66683 6.23307 7.41302 6.23307 8.3335Z"
        fill="#1C1C1C"
      />
      <path
        d="M10.3997 10.8335C8.64542 10.8335 7.14433 11.9177 6.52994 13.4526C6.33843 13.931 6.23307 14.4533 6.23307 15.0002V16.6668H14.5664V15.0002C14.5664 14.4533 14.4611 13.931 14.2695 13.4526C13.6552 11.9177 12.1541 10.8335 10.3997 10.8335Z"
        fill="#1C1C1C"
      />
      <path
        d="M14.5664 16.6668H18.7331V15.0002C18.7331 13.6195 17.6138 12.5002 16.2331 12.5002C15.4367 12.5002 14.7274 12.8725 14.2695 13.4526M14.5664 16.6668H6.23307M14.5664 16.6668V15.0002C14.5664 14.4533 14.4611 13.931 14.2695 13.4526M6.23307 16.6668H2.06641V15.0002C2.06641 13.6195 3.18569 12.5002 4.56641 12.5002C5.36275 12.5002 6.07212 12.8725 6.52994 13.4526M6.23307 16.6668V15.0002C6.23307 14.4533 6.33843 13.931 6.52994 13.4526M6.52994 13.4526C7.14433 11.9177 8.64542 10.8335 10.3997 10.8335C12.1541 10.8335 13.6552 11.9177 14.2695 13.4526M12.8997 5.8335C12.8997 7.21421 11.7805 8.3335 10.3997 8.3335C9.01903 8.3335 7.89974 7.21421 7.89974 5.8335C7.89974 4.45278 9.01903 3.3335 10.3997 3.3335C11.7805 3.3335 12.8997 4.45278 12.8997 5.8335ZM17.8997 8.3335C17.8997 9.25397 17.1535 10.0002 16.2331 10.0002C15.3126 10.0002 14.5664 9.25397 14.5664 8.3335C14.5664 7.41302 15.3126 6.66683 16.2331 6.66683C17.1535 6.66683 17.8997 7.41302 17.8997 8.3335ZM6.23307 8.3335C6.23307 9.25397 5.48688 10.0002 4.56641 10.0002C3.64593 10.0002 2.89974 9.25397 2.89974 8.3335C2.89974 7.41302 3.64593 6.66683 4.56641 6.66683C5.48688 6.66683 6.23307 7.41302 6.23307 8.3335Z"
        stroke="#DEEAF5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7027 2.23933C16.0086 1.69561 16.7914 1.69561 17.0972 2.23933L19.3294 6.20755C19.6293 6.74083 19.244 7.39976 18.6321 7.39976H14.1679C13.556 7.39976 13.1706 6.74083 13.4706 6.20755L15.7027 2.23933ZM16.8 6.1998C16.8 6.42072 16.6209 6.5998 16.4 6.5998C16.179 6.5998 16 6.42072 16 6.1998C16 5.97889 16.179 5.7998 16.4 5.7998C16.6209 5.7998 16.8 5.97889 16.8 6.1998ZM16.4 2.9998C16.179 2.9998 16 3.17889 16 3.3998V4.5998C16 4.82072 16.179 4.9998 16.4 4.9998C16.6209 4.9998 16.8 4.82072 16.8 4.5998V3.3998C16.8 3.17889 16.6209 2.9998 16.4 2.9998Z"
        fill="#F7D149"
      />
    </svg>
  );
}

export default PeopleLoitering;
