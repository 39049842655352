import React from 'react'

import { Search } from '~/svg'

const SearchBar = ({ keyword, handleChange, submitSearch }) => {
  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      submitSearch()
    }
  }

  return (
    <div className='w-full flex justify-between items-center h-10'>
      <div className='w-full h-full flex items-center justify-between px-5 rounded-l-lg border-[1px] gap-2 border-primary-600'>
        <div className='flex w-full items-center gap-2'>
          <input
            type='text'
            id='search'
            name='search'
            value={keyword}
            autoComplete='off'
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder='Man in 20s wearing green hoodie'
            className='w-full bg-transparent outline-none border-none text-base font-normal placeholder:text-neutral-400 text-neutral-50 truncate'
          />
        </div>
      </div>
      <div
        onClick={submitSearch}
        className={`flex items-center cursor-pointer bg-primary-600 h-full px-5 ${'rounded-r-lg'}`}>
        <Search />
      </div>
    </div>
  )
}

export default SearchBar
