import React from 'react'

import { useCanvasAdd } from './hooks'

const CanvasAdd = ({ cameras, addCoordinate }) => {
  const { canvasRef, canvas2Ref } = useCanvasAdd({ cameras, addCoordinate })

  return (
    <div>
      <div className='relative select-none'>
        <img width={343} src='/floor_plan.png' alt='img' />
        {/* Existing camera canvas */}
        <canvas
          width={343}
          height={229}
          ref={canvas2Ref}
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }}
        />
        {/* Drawing Canvas to add new camera */}
        <canvas
          width={343}
          height={229}
          ref={canvasRef}
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 3 }}
        />
      </div>
    </div>
  )
}

export default CanvasAdd
