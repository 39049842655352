import { useCallback, useEffect, useState, useRef } from 'react'

import './index.css'

const MultiRangeSlider = ({ min, max, onChange }) => {
  const range = useRef(null)
  const minValRef = useRef(null)
  const maxValRef = useRef(null)

  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal)
      // Precede with '+' to convert the value from type string to type number
      const maxPercent = getPercent(+maxValRef.current.value)

      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [minVal])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value)
      const maxPercent = getPercent(maxVal)

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [maxVal])

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal })
  }, [minVal, maxVal])

  useEffect(() => {
    setMinVal(0)
  }, [])

  return (
    <div className='container'>
      <input
        min={min}
        max={max}
        type='range'
        value={minVal}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal - 1)
          setMinVal(value)
          event.target.value = value.toString()
        }}
        className={`w-[80%] md:w-[90%] thumb ${
          minVal > max - 100 ? 'thumb--zindex-5' : 'thumb--zindex-3'
        }`}
      />
      <input
        min={min}
        max={max}
        type='range'
        value={maxVal}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal + 1)
          setMaxVal(value)
          event.target.value = value.toString()
        }}
        className='w-[80%] md:w-[90%] thumb thumb--zindex-4'
      />

      <div className='w-[90%] md:w-full slider'>
        <div className='slider__track'></div>
        <div ref={range} className='slider__range'></div>
        <div className='slider__left-value'>{minVal}</div>
        <div className='slider__right-value'>{maxVal}</div>
      </div>
    </div>
  )
}

export default MultiRangeSlider
