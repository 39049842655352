import React from 'react';

function FallDetection() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3999 17.5C6.25777 17.5 2.8999 14.1421 2.8999 10C2.8999 5.85786 6.25777 2.5 10.3999 2.5C14.542 2.5 17.8999 5.85786 17.8999 10C17.8999 14.1421 14.542 17.5 10.3999 17.5Z"
        fill="#1C1C1C"
      />
      <path
        d="M12.7569 13.4764C11.4551 12.1746 9.34458 12.1746 8.04283 13.4764M12.8999 8.33333H12.8916M7.8999 8.33333H7.89157M2.8999 10C2.8999 14.1421 6.25777 17.5 10.3999 17.5C14.542 17.5 17.8999 14.1421 17.8999 10C17.8999 5.85786 14.542 2.5 10.3999 2.5C6.25777 2.5 2.8999 5.85786 2.8999 10Z"
        stroke="#DEEAF5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7027 2.23933C16.0086 1.69561 16.7914 1.69561 17.0972 2.23933L19.3294 6.20755C19.6293 6.74083 19.244 7.39976 18.6321 7.39976H14.1679C13.556 7.39976 13.1706 6.74083 13.4706 6.20755L15.7027 2.23933ZM16.8 6.1998C16.8 6.42072 16.6209 6.5998 16.4 6.5998C16.179 6.5998 16 6.42072 16 6.1998C16 5.97889 16.179 5.7998 16.4 5.7998C16.6209 5.7998 16.8 5.97889 16.8 6.1998ZM16.4 2.9998C16.179 2.9998 16 3.17889 16 3.3998V4.5998C16 4.82072 16.179 4.9998 16.4 4.9998C16.6209 4.9998 16.8 4.82072 16.8 4.5998V3.3998C16.8 3.17889 16.6209 2.9998 16.4 2.9998Z"
        fill="#F7D149"
      />
    </svg>
  );
}

export default FallDetection;
