import { useDropzone } from 'react-dropzone'
import { useCallback, useEffect, useRef, useState } from 'react'

import { checkIsImage, getFileExtension, useOutsideClick } from '~/utils'
import { useDispatch, useSelector } from 'react-redux'
import { ALERTMODALTYPE, ALERTTYPE, SEARCH_INCIDENT } from '~/constant'
import { postVerify } from '~/store/slice/account'
import { deleteSettingAlerts, postAlertIncident, postAlertPriority } from '~/store/slice/alert'

export function useAlertSetting() {
  const [formPassword, setFormPass] = useState({
    password: '',
    errPassword: false,
  })

  const colorRef = useRef(false)
  const loopRefPrio = useRef(false)
  const loopRefInci = useRef(false)

  const dispatch = useDispatch()

  const dataAlerts = useSelector((state) => state.alert.settingAlert) || []
  const isVerified = useSelector((state) => state.account.isVerified)
  const verifyLoading = useSelector((state) => state.account.verifyLoading)

  const defaultFormPriority = {
    priority_id: '',
    name: '',
    color: '',
    description: '',
    loop: 0,
    apps_notification: [],
  }
  const [formPriority, setFormPriority] = useState(defaultFormPriority)
  const defaultFormIncident = {
    incident_id: '',
    name: '',
    image: [],
    priority_id: '',
    description: '',
    keyword_type: SEARCH_INCIDENT.DESCRIPTION,
    loop: 0,
    apps_notification: [],
    is_override_alert: false,
  }
  const [formIncident, setFormIncident] = useState(defaultFormIncident)
  const [deleteModal, setDeleteModal] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [priorityModal, setPriorityModal] = useState(false)
  const [incidentModal, setincidentModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState({
    isOpen: false,
    modalType: ALERTMODALTYPE.EDIT,
    alertName: '',
    alertType: ALERTTYPE.PRIORITY,
    data: {},
  })
  const [dropdownLoopState, setDropdownLoop] = useState(false)
  const [dropdownColorState, setDropdownColor] = useState(false)

  const alertRef = useRef([])

  useEffect(() => {
    if (!formPassword.password.length) {
      return
    }
    if (!verifyLoading && isVerified) {
      setPasswordModal((prevState) => ({
        ...prevState,
        isOpen: false,
      }))
      if (passwordModal.modalType == ALERTMODALTYPE.DELETE) {
        const req = {
          alert_id: passwordModal.data.incident_id || passwordModal.data.priority_id,
          delete_type: passwordModal.alertType.toLowerCase(),
        }
        dispatch(deleteSettingAlerts(req))
      } else if (passwordModal.alertType == ALERTTYPE.PRIORITY) {
        openPriorityModal(passwordModal.data)
      } else if (passwordModal.alertType == ALERTTYPE.INCIDENT) {
        openIncidentModal(passwordModal.data, null)
      }
      setFormPass((prevState) => ({
        ...prevState,
        password: '',
      }))
    } else if (!verifyLoading && !isVerified) {
      setFormPass((prevState) => ({
        ...prevState,
        errPassword: true,
      }))
    }
  }, [verifyLoading])

  const onDrop = useCallback((acceptedFiles) => {
    const ext = getFileExtension(acceptedFiles[0].name)

    if (acceptedFiles.length > 1) {
      alert('Upload just 1 image File')
      return
    }

    if (!checkIsImage(ext)) {
      alert('Upload just 1 image File')
      return
    }

    setUploadedFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
    setFormIncident((prevState) => ({
      ...prevState,
      image: acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    }))
  }, [])

  function closePasswordModal() {
    setPasswordModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }))
  }

  function openModalPassword(modalType, alertType, alertName, data) {
    setPasswordModal({
      isOpen: true,
      modalType,
      alertName: alertName,
      alertType: alertType,
      data,
    })
  }

  function submitPassword() {
    const req = {
      password: formPassword.password,
    }

    dispatch(postVerify(req))
  }

  function handleKeyUp(event) {
    if (event.key === 'Enter') {
      submitPassword()
    }
  }

  function addNotifications(notif, formType) {
    if (formType == ALERTTYPE.PRIORITY) {
      const apps = formPriority.apps_notification
      const findIndex = apps.findIndex((e) => e == notif)
      if (findIndex != -1) {
        setFormPriority((prevState) => ({
          ...prevState,
          apps_notification: apps
            .slice(0, findIndex)
            .concat(apps.slice(findIndex + 1, apps.length)),
        }))
      } else {
        apps.push(notif)
        setFormPriority((prevState) => ({
          ...prevState,
          apps_notification: apps,
        }))
      }
    } else if (formType == ALERTTYPE.INCIDENT) {
      const apps = formIncident.apps_notification
      const findIndex = apps.findIndex((e) => e == notif)
      if (findIndex != -1) {
        setFormIncident((prevState) => ({
          ...prevState,
          apps_notification: apps
            .slice(0, findIndex)
            .concat(apps.slice(findIndex + 1, apps.length)),
        }))
      } else {
        apps.push(notif)
        setFormIncident((prevState) => ({
          ...prevState,
          apps_notification: apps,
        }))
      }
    }
  }

  function openPriorityModal(p) {
    if (p) {
      setFormPriority(p)
    } else {
      setFormPriority({
        priority_id: '',
        name: '',
        color: '',
        description: '',
        loop: 0,
        apps_notification: [],
      })
    }
    setPriorityModal(true)
  }

  function openIncidentModal(p, dataPriority) {
    if (p) {
      setFormIncident({
        ...p,
      })
    } else {
      setFormIncident({
        incident_id: '',
        name: '',
        image: [],
        priority_id: dataPriority.priority_id,
        description: '',
        keyword_type: SEARCH_INCIDENT.DESCRIPTION,
        loop: dataPriority.loop,
        apps_notification: dataPriority.apps_notification,
        is_override_alert: false,
      })
    }
    setincidentModal(true)
  }

  function handleChangePriority(e) {
    const { name, value } = e.target

    setFormPriority((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function handleCustomPriority(name, value) {
    setFormPriority((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function handleChangePass(e) {
    const { value } = e.target

    cleanError()

    setFormPass((prevState) => ({
      ...prevState,
      password: value,
    }))
  }

  function cleanError() {
    setFormPass((prevState) => ({
      ...prevState,
      errPassword: false,
    }))
  }

  function handleChangeIncident(e) {
    const { name, value } = e.target

    setFormIncident((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function handleCustomIncident(name, value) {
    setFormIncident((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function handleSubmitPriority() {
    dispatch(postAlertPriority(formPriority))
    setPriorityModal(false)
    setFormPriority(defaultFormPriority)
  }

  function handleSubmitIncident() {
    dispatch(postAlertIncident(formIncident))
    setincidentModal(false)
    setFormIncident(defaultFormIncident)
  }

  function setOverrideAlert(b) {
    setFormIncident((prevState) => ({
      ...prevState,
      is_override_alert: b,
    }))
  }

  function chooseLoop(l, formType) {
    if (formType == ALERTTYPE.PRIORITY) {
      setFormPriority((prevState) => ({
        ...prevState,
        loop: l,
      }))
    } else if (formType == ALERTTYPE.INCIDENT) {
      setFormIncident((prevState) => ({
        ...prevState,
        loop: l,
      }))
    }
    setDropdownLoop(false)
  }

  function changeSearch(keyword_type) {
    setFormIncident((prevState) => ({
      ...prevState,
      keyword_type,
    }))
  }

  function chooseColor(color) {
    setFormPriority((prevState) => ({
      ...prevState,
      color,
    }))
    setDropdownColor(false)
  }

  useOutsideClick({ ref: colorRef, action: () => setDropdownColor(false) })
  useOutsideClick({ ref: loopRefPrio, action: () => setDropdownLoop(false) })
  useOutsideClick({ ref: loopRefInci, action: () => setDropdownLoop(false) })

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true })

  const colorOptions = [
    {
      colorHex: '#FF0000',
      colorText: 'Red',
    },
    {
      colorHex: '#FF6108',
      colorText: 'Orange',
    },
    {
      colorHex: '#F7DC06',
      colorText: 'Yellow',
    },
    {
      colorHex: '#0DAF0B',
      colorText: 'Green',
    },
    {
      colorHex: '#467AFF',
      colorText: 'Blue',
    },
    {
      colorHex: '#901081',
      colorText: 'Purple',
    },
  ]

  const loopOptions = [2, 5, 10, 30, 60]

  return {
    datas: {
      loopRefInci,
      loopRefPrio,
      colorRef,
      alertRef,
      dataAlerts,
      deleteModal,
      loopOptions,
      formPassword,
      colorOptions,
      formPriority,
      formIncident,
      uploadedFiles,
      priorityModal,
      incidentModal,
      passwordModal,
      dropdownLoopState,
      dropdownColorState,
    },
    methods: {
      open,
      chooseLoop,
      setFormPass,
      chooseColor,
      handleKeyUp,
      getRootProps,
      changeSearch,
      getInputProps,
      setDeleteModal,
      submitPassword,
      setDropdownLoop,
      handleChangePass,
      setOverrideAlert,
      setDropdownColor,
      addNotifications,
      setPriorityModal,
      setincidentModal,
      openIncidentModal,
      openPriorityModal,
      openModalPassword,
      closePasswordModal,
      handleChangePriority,
      handleChangeIncident,
      handleCustomPriority,
      handleCustomIncident,
      handleSubmitIncident,
      handleSubmitPriority,
    },
  }
}
