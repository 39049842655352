import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { KeyConstant } from "~/constant";

import {
  addCameraAsync,
  getCamera,
  resetBoxPolygon,
  resetError,
  resetToast,
} from "~/store/slice/camera";
import { postTimeoutAsync } from "~/store/slice/setting";
import { getSessionStorage } from "~/utils";

export function useSetting() {
  const dispatch = useDispatch();
  const location = useLocation();
  const site = location.state.site;

  const floors = useSelector((state) => state.camera.floors);
  const cameras = useSelector((state) => state.camera.cameras);
  const showToast = useSelector((state) => state.camera.successAdd);
  const isLoading = useSelector((state) => state.camera.postLoading);
  const errAddCamera = useSelector((state) => state.camera.errAddCamera);
  const timeout = useSelector((state) => state.settings.timeout);
  const errorTimeout = useSelector((state) => state.settings.errorTimeout);

  const [addModal, setAddModal] = useState(false);
  const [cameraModal, setCameraModal] = useState(false);
  const [floorsCams, setFloorsCams] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [coordinate, setCoordinate] = useState(undefined);
  const [selectedFloor, setSelectedFloor] = useState(floors[0]);
  const [cameraForm, setCameraForm] = useState({
    userName: "",
    password: "",
    rtspStream: "",
    cameraBrand: "",
    emptyRtsp: false,
    emptyBrand: false,
  });
  const [cameraId, setCameraId] = useState("");
  const [choosenCamera, setChoosenCamera] = useState({
    floor: "",
    x: 0,
    y: 0,
    rtspStream: "",
    userName: "",
    created_at: "",
    name: "",
    floor_id: "",
    machine: 1,
    _id: "",
    proxyStream: "",
    proxyStream2: "",
    boxes: false,
  });
  const [formTimeout, setFormTimeout] = useState(0);

  const handleChangeFormTimeout = (e) => {
    if (e.target.value === "") {
      setFormTimeout(0);
      return;
    }
    const value = parseInt(e.target.value, 10);
    setFormTimeout(value);
  };

  const handleBlurFormTimeout = (e) => {
    let timeout = e.target.value;
    if (formTimeout < 10) {
      timeout = 10;
      setFormTimeout(timeout);
    }
    // dispatch lmaooo
    dispatch(postTimeoutAsync({ site, timeout }));
  };

  function selectFloor(it) {
    setSelectedFloor(it);
    if (cameras[it] === undefined) {
      setFloorsCams([]);
      return;
    }
    setFloorsCams(cameras[it]);
  }

  function openModal() {
    setAddModal(true);
  }

  function closeModal() {
    setAddModal(false);
    setShowPassword(false);
    setCoordinate(undefined);
    setCameraForm({
      userName: "",
      password: "",
      rtspStream: "",
      cameraBrand: "",
      emptyRtsp: false,
      emptyBrand: false,
    });
  }

  function addCoordinate({ x, y }) {
    setCoordinate({ x, y });
  }

  function handleChange(e) {
    const { name, value } = e.target;

    setCameraForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    handleError(name, false);
    if (name === "password" && errAddCamera) {
      dispatch(resetError());
    }
  }

  function handleError(name, value) {
    switch (name) {
      case "rtspStream":
        setCameraForm((prevState) => ({
          ...prevState,
          emptyRtsp: value,
        }));
        break;
      case "cameraBrand":
        setCameraForm((prevState) => ({
          ...prevState,
          emptyBrand: value,
        }));
        break;
      default:
        break;
    }
  }

  function selectBrand(it) {
    setCameraForm((prevState) => ({
      ...prevState,
      cameraBrand: it,
    }));
  }

  function checkError() {
    if (cameraForm.cameraBrand === "" && cameraForm.rtspStream === "") {
      handleError("cameraBrand", true);
      handleError("rtspStream", true);
    } else if (cameraForm.cameraBrand === "") {
      handleError("cameraBrand", true);
    } else {
      handleError("rtspStream", true);
    }
  }

  async function addNewCamera() {
    const location = getSessionStorage(KeyConstant.Location).split(",")[0];

    const args = {
      camera_id: cameraId,
      data: {
        location,
        x: coordinate.x,
        y: coordinate.y,
        floor: selectedFloor,
        password: cameraForm.password,
        userName: cameraForm.userName,
        rtspStream: cameraForm.rtspStream,
        cameraBrand: cameraForm.cameraBrand,
      },
    };

    dispatch(addCameraAsync(args));
  }

  function handlePassword() {
    setShowPassword(!showPassword);
  }

  function hideToast() {
    dispatch(resetToast());
  }

  function updateNewCamera() {
    dispatch(getCamera());
  }

  useEffect(() => {
    selectFloor(floors[0]);
  }, [cameras]);

  useEffect(() => {
    setFormTimeout(timeout);
  }, [timeout]);

  useEffect(() => {
    if (showToast) {
      closeModal();
      updateNewCamera();
      setTimeout(() => {
        hideToast();
      }, 5000);
    }
  }, [showToast]);

  function openCameraModal(it) {
    setChoosenCamera(it);
    setCameraModal(true);
  }

  function closeCameraModal() {
    setChoosenCamera({
      floor: "",
      x: 0,
      y: 0,
      rtspStream: "",
      userName: "",
      created_at: "",
      name: "",
      floor_id: "",
      machine: 1,
      _id: "",
      proxyStream: "",
      proxyStream2: "",
      boxes: false,
    });
    setCameraModal(false);
    dispatch(resetBoxPolygon());
  }

  function handleUpdate(it) {
    selectFloor(it.floor);
    addCoordinate(it);
    setCameraId(it.cameraId);
    setCameraForm({
      userName: it.userName,
      password: "",
      rtspStream: it.rtspStream,
      cameraBrand: it.cameraBrand,
      emptyRtsp: it.rtspStream !== "",
      emptyBrand: it.cameraBrand !== "",
      x: it.x,
      y: it.y,
      floor: it.floor,
    });
    for (const key in cameraForm) {
      handleError(key, false);
    }
    openModal();
  }

  return {
    datas: {
      floors,
      cameras,
      addModal,
      cameraId,
      isLoading,
      showToast,
      floorsCams,
      cameraForm,
      choosenCamera,
      cameraModal,
      errAddCamera,
      showPassword,
      formTimeout,
    },
    methods: {
      hideToast,
      openModal,
      closeModal,
      selectBrand,
      selectFloor,
      handleChange,
      handleUpdate,
      addCoordinate,
      handlePassword,
      openCameraModal,
      closeCameraModal,
      handleBlurFormTimeout,
      handleChangeFormTimeout,
    },
  };
}
