import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'
import { useEffect, useRef, useState } from 'react'
import { videoSlice } from '~/store/slice/video'
import { useDispatch, useSelector } from 'react-redux'

export function usevideoPlayer() {
  const playerRef = useRef()
  const player = playerRef.current
  const dispatch = useDispatch()

  const [videoState, setVideoState] = useState({
    url: null,
    played: 0,
    loaded: 0,
    pip: false,
    volume: 0.4,
    duration: 0,
    muted: false,
    playing: false,
  })
  const [showVolume, setShowVolume] = useState(false)
  const [showControls, setShowControls] = useState(false)

  const estimateData = useSelector((state) => state.video.estimateData) || []

  function handlePlay() {
    setVideoState((prev) => ({
      ...prev,
      playing: !videoState.playing,
    }))
  }

  function handleSeekMouseDown() {
    setVideoState((prev) => ({
      ...prev,
      seeking: true,
    }))
  }

  function handleMuted(boolean) {
    setVideoState((prev) => ({
      ...prev,
      muted: boolean,
    }))
  }

  function handleSeekChange(e) {
    setVideoState((prev) => ({
      ...prev,
      played: parseFloat(e.target.value),
    }))
  }

  function handleSeekMouseUp(e) {
    setVideoState((prev) => ({
      ...prev,
      seeking: false,
    }))
    player.seekTo(parseFloat(parseFloat(e.target.value)))
  }

  function handleClickFullscreen() {
    // eslint-disable-next-line react/no-find-dom-node
    screenfull.request(findDOMNode(player))
  }

  function handleProgress(state) {
    setVideoState((prev) => ({
      ...prev,
      played: state.played,
    }))
    if (state.played === 1) {
      setVideoState((prev) => ({
        ...prev,
        playing: false,
      }))
    }
  }

  function handleVolumeChange(e) {
    setVideoState((prev) => ({
      ...prev,
      volume: parseFloat(e.target.value),
      muted: false,
    }))
  }

  function handleDuration(duration) {
    setVideoState((prev) => ({
      ...prev,
      duration: duration,
    }))
  }

  function handleTogglePIP() {
    setVideoState((prev) => ({
      ...prev,
      pip: !videoState.pip,
    }))
  }

  function handleControls(args) {
    setShowControls(args)
  }

  useEffect(() => {
    if (estimateData.person) {
      const desiredData = estimateData?.person.find(
        (item) => item.second === Math.round(videoState.duration * videoState.played),
      )
      dispatch(
        videoSlice.actions.setCounter({
          headCount: desiredData?.count,
          estimation: desiredData?.estimate,
        }),
      )
    }
  }, [videoState])

  return {
    datas: {
      playerRef,
      videoState,
      showVolume,
      showControls,
    },
    methods: {
      handlePlay,
      handleMuted,
      setShowVolume,
      handleControls,
      handleDuration,
      handleProgress,
      handleTogglePIP,
      handleSeekChange,
      handleSeekMouseUp,
      handleVolumeChange,
      handleSeekMouseDown,
      handleClickFullscreen,
    },
  }
}
