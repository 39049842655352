import React from 'react'
import moment from 'moment'

import { STATUS } from '~/constant'
import { PendingAlerts } from '~/components'

const DetectionAlerts = ({types, locations, timestamps}) => {
  return (
    <div>
        <h1 className='text-2xl text-white font-semibold mb-4'>Recently Detected</h1>
        <div className='grid grid-cols-1 gap-y-4 h-full'>
          {types &&
            types.map((type, idx) => (
              <PendingAlerts
                key={idx}
                type={type}
                location={locations[idx]}
                status={STATUS.PENDING}
                onClick={() => {}}
                date={moment(timestamps[idx]).format('DD MMM YYYY, HH:mm')}
              />
            ))}
        </div>
    </div>

  )
}

export default DetectionAlerts
