import { START_DATE } from '@datepicker-react/hooks';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '~/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getIncidentAlerts, readIncident } from '~/store/slice/incident';
import { useLocation } from 'react-router-dom';
import { setSelectedAlert } from '~/store/slice/alert';
import { getFloors, getSites } from '~/store/slice/setting';
import { getCameraAsyncV2 } from '~/store/slice/camera';

export const useVms = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let limit = 7;
  // it will use end date for single date
  const defaultDate = {
    endDate: new Date().setHours(0, 0, 0, 0),
    startDate: null,
    focusedInput: START_DATE,
  };

  const siteList = useSelector((state) => state.settings.sites);
  const floorData = useSelector((state) => state.settings.floors);
  const [floorList, setFloorList] = useState(floorData);
  const [selectedFloors, setSelectedFloors] = useState([]);
  // const camerasV2 = useSelector((state) => state.camera.camerasV2);
  const allCameras = useSelector((state) => state.camera.allCameras);
  const id_allCameras = useSelector((state) => state.camera.id_allCameras);
  const [cameraList, setCameraList] = useState(allCameras);
  const [selectedCamera, setSelectedCamera] = useState('');
  const [searchCamera, setSearchCamera] = useState('');
  const [tempData, setTempData] = useState([]);

  const [page, setPage] = useState(1);
  const [timestamp, setTimestamp] = useState(0);
  // const [secondsOfTheDay, setSecondsOfTheDay] = useState(0);
  const [dateRange, setDateRange] = useState(defaultDate);
  const [initLoad, setInitLoad] = useState(true);
  const [linkPlayer, setLinkPlayer] = useState('');
  const [cameraURL, setCameraURL] = useState('');
  const [isPlaying, setPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const timeoutRef = useRef(null);

  // Show only floors and camera for each site
  useEffect(() => {
    const defaultFloor = floorData.filter(item => item.site_id === siteList[location?.state?.site-1]?._id)
    setFloorList(defaultFloor);
    setSelectedFloors([defaultFloor[0]?._id])
    const defaultCamera = allCameras.filter(item => item.floor_id === floorList[0]?._id)
    setCameraList(defaultCamera);
  }, [floorData, allCameras])

  useEffect(() => {
    if (location.state.time){
      setTimestamp(location.state.time);
    }

    if (location.state.camera_id) {
      const foundCamera = cameraList.find(
        (camera) => camera.id === location.state.camera_id
      );
      const foundFloor = floorList.find(
        (floor) => floor._id === foundCamera?.floor_id
      );
      // setSelectedCamera(foundCamera);
      setSelectedCamera(location.state.camera_id)
      setSelectedFloors([foundFloor]);
    }
  }, [location.state]);

  const secondsPassedToday = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    const startOfToday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return Math.floor((date - startOfToday) / 1000);
  }

  const offsetPassed = (unixTimestamp, offset) => {
    const date = new Date(unixTimestamp * 1000);
    const startOfToday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return Math.floor((startOfToday) / 1000) + offset;
  }

  const formatTime = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`
  }

  const handlePlayVms = () => {    
    // if timestamp is not set then set to be latest time +1h
    if (timestamp === 0){
      const now = Math.floor(Date.now()/1000);
      setLinkPlayer(cameraURL.concat(`/b${now}/e${now + 60}/replay`));
      setTimestamp(now);
    } else {
      const intTimeStamp = Math.floor(timestamp);
      setLinkPlayer(cameraURL.concat(`/b${intTimeStamp}/e${intTimeStamp + 60}/replay`));
    }
    
    setPlaying(true);
    setIsNew(false);
  };

  const handlePauseVms = () => {
    setPlaying(false);
  };

  const handleChangeTime = (time) => {
    const newTimestamp = offsetPassed(timestamp, time);
    setTimestamp(newTimestamp);
    handlePauseVms();
  };

  useEffect(() => {
    dispatch(getSites());
    dispatch(getFloors());
    dispatch(getCameraAsyncV2({ site: location?.state?.site }));
  }, []);

  useEffect(() => {
    const defaultCamera = allCameras.filter(
      (item) => item.floor_id === floorList[0]?._id
    );
    setCameraList(defaultCamera);
    setTempData(defaultCamera);
  }, [floorData, allCameras]);

  function handleChangeSearchCamera(e) {
    if (e.target.value) {
      setCameraList(
        tempData.filter((item) => item.name.includes(e.target.value))
      );
    } else {
      setCameraList(tempData);
    }
  }

  function handleFilter(type, value) {
    switch (type) {
      case 'floors':
        setSelectedFloors([value]);
        break;
      case 'cameras':
        setSelectedCamera(value);
        break;
    }
  }

  useEffect(() => {
    if (selectedFloors) {
      setCameraList(
        allCameras.filter((item) => selectedFloors.includes(item.floor_id))
      );
      setTempData(
        allCameras.filter((item) => selectedFloors.includes(item.floor_id))
      );
    }
  }, [selectedFloors]);

  useEffect(() => {
    if (!selectedCamera) {
      setCameraURL('');
      return;
    }
    const showData = id_allCameras[selectedCamera];
    setCameraURL(showData.replayStream);
  }, [selectedCamera]);

  const changeSeconds = async (e) => {
    const time = Number(e.target.value);
    handleChangeTime(time);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
    if (timestamp < 0 || timestamp > 86399) {
      setIsDragging(false);
    }
  };

  const handleJumpToTime = (e, timeString) => {
    if (e.key === "Enter") {
      // check if string is valid
      const regex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
      const match = timeString.match(regex);
    
      if (!match) {
        // Handle invalid format as needed.
        throw new Error("Invalid time format. Expected HH:MM");
      }

      // Extract hours and minutes from the match groups.
      const hours = parseInt(match[1], 10);
      const minutes = parseInt(match[2], 10);
      const seconds = parseInt(match[3], 10);

      if(hours > 23){
        // Handle invalid format as needed.
        throw new Error("Invalid hours");        
      }

      if(minutes > 59){
        throw new Error("Invalid minutes");        
      }

      if(seconds > 59){
        throw new Error("Invalid minutes");  
      }

      // Convert hours and minutes to seconds.
      const total_seconds = hours * 3600 + minutes * 60 + seconds;
      handlePauseVms();

      const newTimestamp = offsetPassed(timestamp, total_seconds)
      if(newTimestamp > 0){
        setTimestamp(newTimestamp);
      }
    }
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      e.preventDefault();
      const slider = e.target.closest('.slider-container');
      const sliderRect = slider.getBoundingClientRect();
      const offsetX = e.clientX - sliderRect.left;
      const offsetTime = Math.floor((offsetX / sliderRect.width) * 86399);
      const newTimestamp = offsetPassed(timestamp, offsetTime);

      // handleChangeTime(newTimestamp);
      if (newTimestamp < 0 || newTimestamp > 86399) {
        setIsDragging(false);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleFastFoward = (value) => {
    handlePauseVms();
    const newTimestamp = timestamp + value;
    setTimestamp(newTimestamp);
    // handleChangeTime(newTimestamp);
  };

  useEffect(() => {
    handlePauseVms();
  }, [selectedCamera]);

  useEffect(() => {
    handlePauseVms();
    
    if(dateRange && !initLoad){
      const newTimestamp = Math.floor(new Date(dateRange.endDate).getTime()/1000);

      setTimestamp(newTimestamp);
    } else if (dateRange && initLoad) {
      setInitLoad(false)
    }

    // const newTimestamp = timestamp + value;
  }, [dateRange])


  return {
    datas: {
      isNew,
      linkPlayer,
      dateRange,
      timestamp,
      floorList,
      isPlaying,
      cameraList,
      searchCamera,
      selectedFloors,
      selectedCamera,
    },
    methods: {
      setLinkPlayer,
      setTimestamp,
      setDateRange,
      changeSeconds,
      handleMouseDown,
      handleMouseMove,
      handleMouseUp,
      handleFilter,
      handleChangeSearchCamera,
      handlePlayVms,
      handlePauseVms,
      handleFastFoward,
      secondsPassedToday,
      handleJumpToTime,
      formatTime
    },
  };
};
