import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { axios } from '~/utils'
import { store } from '..'

const initialState = {
  data: [],
  keyword: '',
  countData: 0,
  isLoading: false,
  successAdd: false,
  postLoading: false,
  dangerAlert: null,
  notifLoading: false,
  errAddCamera: false,
  isHaveupdate: false,
  notifCountLoading: false,
}

const baseUrl = process.env.BASE_URL

export const getNotification = createAsyncThunk(
  'notification/getSync',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/notification?filter_read=${args}`)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const getNotificationAsync = createAsyncThunk(
  'notification/get',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/notification?filter_read=${args}`)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const markNotificationAsync = createAsyncThunk(
  'notification/mark',
  async (args, { rejectWithValue }) => {
    try {
      await axios.post(`${baseUrl}/notification/read`, args)
      return args
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const getCountNotifAsync = createAsyncThunk(
  'notification/count',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/notification/count`)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const pushNotifAlert = createAsyncThunk('notification/incident', async (args) => {
  return args
})

export const notifSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    initLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
      state.dangerAlert = false
      state.isHaveupdate = false
      state.notifLoading = false
    },
    initNotifLoading: (state) => {
      state.notifLoading = true
    },
    updateKeyword: (state, action) => {
      state.keyword = action.payload.keyword
      state.uploadedFiles = action.payload.files
    },
    resetError: (state) => {
      state.errMarkNotif = false
    },
    resetToast: (state) => {
      state.successAdd = false
    },
    resetcameraStore: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getNotificationAsync.pending, (state) => {
        state.notifLoading = true
      })
      .addCase(getNotificationAsync.fulfilled, (state, action) => {
        const { data } = action.payload

        state.notifData = data
        state.notifLoading = false
        state.errMarkNotif = false
      })
      .addCase(getNotificationAsync.rejected, (state) => {
        state.notifLoading = false
      })

    builder
      .addCase(getCountNotifAsync.pending, (state) => {
        state.notifCountLoading = true
      })
      .addCase(getCountNotifAsync.fulfilled, (state, action) => {
        const { count } = action.payload

        state.countData = count
        state.notifCountLoading = false
      })
      .addCase(getCountNotifAsync.rejected, (state) => {
        state.notifCountLoading = false
      })

    builder
      .addCase(markNotificationAsync.pending, (state) => {
        state.notifLoading = true
      })
      .addCase(markNotificationAsync.fulfilled, (state) => {
        setTimeout(() => {
          store.dispatch(getNotificationAsync(''))
          store.dispatch(getCountNotifAsync())
        })
        state.successAdd = true
      })
      .addCase(markNotificationAsync.rejected, (state) => {
        state.notifLoading = false
        state.successAdd = false
      })

    builder.addCase(pushNotifAlert.fulfilled, (state, action) => {
      state.dangerAlert = action.payload
    })
  },
})

export const {
  resetToast,
  resetError,
  initLoading,
  stopLoading,
  updateKeyword,
  resetcameraStore,
  initNotifLoading,
} = notifSlice.actions
export default notifSlice.reducer
