import React from 'react'

function AccountLocked() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='224'
      height='214'
      fill='none'
      viewBox='0 0 224 214'>
      <path
        fill='#7B8691'
        fillRule='evenodd'
        stroke='#393F44'
        d='M138.428 159.557c-12.839 8.309-22.354 23.698-37.691 22.966-15.613-.745-25.84-15.2-36.728-26.518-12.043-12.52-27.294-23.982-28.472-41.26-1.299-19.058 8.342-37.249 22.237-50.199 14.552-13.562 33.719-19.814 53.665-20.54 23.28-.847 49.888-1.333 65.413 16.207 15.663 17.693 17.114 45.571 8.648 67.459-7.121 18.41-30.531 21.182-47.072 31.885z'
        clipRule='evenodd'></path>
      <path
        fill='#141618'
        fillRule='evenodd'
        stroke='#393F44'
        d='M138.428 159.557c-12.839 8.309-22.354 23.698-37.691 22.966-15.613-.745-25.84-15.2-36.728-26.518-12.043-12.52-27.294-23.982-28.472-41.26-1.299-19.058 8.342-37.249 22.237-50.199 14.552-13.563 33.719-19.814 53.665-20.54 23.28-.847 49.888-1.333 65.413 16.206 15.663 17.694 17.114 45.572 8.648 67.46-7.121 18.41-30.531 21.181-47.072 31.885z'
        clipRule='evenodd'
        opacity='0.7'></path>
      <path
        fill='#141618'
        stroke='#393F44'
        d='M179.033 182.032a6.418 6.418 0 01-.719.218c-.872.22-2.179.436-3.889.644-3.414.416-8.364.791-14.495 1.107-12.257.631-29.197 1.022-47.913 1.022-18.716 0-35.656-.391-47.913-1.022-6.131-.316-11.082-.691-14.495-1.107-1.71-.208-3.017-.424-3.889-.644a6.437 6.437 0 01-.72-.218c.178-.069.416-.143.72-.219.872-.22 2.18-.436 3.889-.644 3.413-.416 8.364-.791 14.494-1.107 12.258-.631 29.198-1.022 47.914-1.022s35.656.391 47.913 1.022c6.131.316 11.081.691 14.495 1.107 1.71.208 3.017.424 3.889.644.304.076.541.15.719.219z'></path>
      <path
        fill='#9AA7B4'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M71.959 120.122H155.7a1.857 1.857 0 011.858 1.859v53.76H70.096v-53.76a1.852 1.852 0 01.546-1.316 1.864 1.864 0 011.317-.543z'></path>
      <path
        fill='#141618'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M76.451 124.342h74.758a1.863 1.863 0 011.314.544 1.867 1.867 0 01.544 1.314v50.291H74.588v-50.286a1.859 1.859 0 011.863-1.863z'></path>
      <path
        fill='#9AA7B4'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M156.81 180.234H66.955c-4.137 0-7.49-2.504-7.49-5.593H164.3c0 3.089-3.353 5.593-7.49 5.593zM111.814 68.91c-5.77 5.488-20.53 11.033-37.74 22.794 0 0 1.074 39.743 37.74 61.468V68.909zM111.814 68.91c5.766 5.488 20.53 11.033 37.741 22.794 0 0-1.074 39.743-37.741 61.468V68.909z'></path>
      <path
        fill='#9AA7B4'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M111.815 72.293c-6.962 4.934-19.026 9.638-34.973 20.533 0 0 .997 36.823 34.973 56.962 33.976-20.131 34.972-56.962 34.972-56.962-15.95-10.89-27.959-15.6-34.972-20.533z'></path>
      <path
        fill='#7B8691'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M125.062 109.422H98.978a2.166 2.166 0 01-2.165-2.166V94.98a15.2 15.2 0 0130.397 0v12.276a2.169 2.169 0 01-2.148 2.166zm-23.9-4.332h21.734V94.98a10.87 10.87 0 00-21.734 0v10.11z'></path>
      <path
        fill='#7B8691'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M128.406 103.505H95.223v28.041h33.183v-28.041z'></path>
      <path
        fill='#5A626B'
        stroke='#393F44'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M115.263 115.149a3.609 3.609 0 00-3.652-3.612 3.613 3.613 0 00-1.676 6.792l-.607 6.368h4.644l-.607-6.368a3.6 3.6 0 001.898-3.18z'></path>
    </svg>
  )
}

export default AccountLocked
