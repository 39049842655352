import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBoxesAsync, postBoxesAsync } from "~/store/slice/camera";
import { event, imageBast64String } from "~/constant";
import { socket } from "~/utils/socket";
import { b64toBlob } from "~/utils";

export function useSetCameraModal({ state, choosenCamera }) {
  const dispatch = useDispatch();

  const boxesData = useSelector((state) => state.camera.boxesData);
  const [canvasImage, setCanvasImage] = useState([]);
  const [anchorPoints, setAnchorPoints] = useState([]);
  const [urlBlob, setUrlBlob] = useState("");

  const fetchCameraFrames = useCallback(() => {
    if (!choosenCamera.proxyStream2 || !state || !choosenCamera._id) return;
    setCanvasImage([]);
    setAnchorPoints([]);
    dispatch(getBoxesAsync({ cameraId: choosenCamera._id }));
    socket.emit(event.STREAMFRAME, choosenCamera.proxyStream2, (response) => {
      const blob = b64toBlob(response, "data:image/jpg;base64,");
      setUrlBlob(URL.createObjectURL(blob));
    });
  }, [choosenCamera, state]);

  useEffect(() => {
    const box = boxesData.polygon && boxesData.polygon.map((box) => box);
    if (!choosenCamera.proxyStream2 || !urlBlob.length) return;
    setCanvasImage([{ frame: urlBlob, link: choosenCamera.proxyStream2, polygon: box }]);
    setUrlBlob("")
  }, [boxesData, choosenCamera.proxyStream2, urlBlob]);

  useEffect(() => {
    if (!state) return;
    fetchCameraFrames();
  }, [fetchCameraFrames, state]);

  function applyBoundingBoxes() {
    if (anchorPoints.length === 0) {
      alert("Invalid bounding boxes");
      return;
    }

    const data = {
      camera: choosenCamera._id,
      type: "default",
      polygon: anchorPoints,
    };
    // DISPATCH POST BOXES
    dispatch(postBoxesAsync(data));
  }

  function handleAnchorPoints(imageData, polygon) {
    setAnchorPoints(polygon);
  }

  const fetchBoundingBoxes = async (link) => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL + "/api/bounding_boxes", {
        params: { link: link },
      });
      return response.data.data[0];
    } catch (error) {
      console.error("Error fetching bounding boxes data:", error);
    }
  };

  return {
    datas: { canvasImage, boxesData },
    methods: { handleAnchorPoints, applyBoundingBoxes },
  };
}
