import React from 'react'
import { Input, VideoPlayer } from '~/components'
import { useVideoDetail } from './hooks'

const VideoAnalyticDetail = ({ videoID }) => {
  const {
    datas: { startVideo, endVideo, videoDetail },
    methods: { handleChange },
  } = useVideoDetail({ videoID })

  const mediaPath = process.env.MEDIA_PATH

  return (
    <div className='w-full mx-auto pt-10'>
      <div className='flex flex-col md:flex-row gap-10 mt-8 md:h-[600px] mx-auto md:w-2/3 mb-24'>
        <div className='flex flex-col px-2'>
          <VideoPlayer url={`${mediaPath}/video/${videoDetail.path}`} />
        </div>
        <div className='flex flex-col'>
          <div className='flex flex-row gap-6'>
            <label>
              <p className='font-semibold text-neutral-200'>From</p>
              <Input type='text' value={startVideo} onChange={(e) => handleChange(e, 'start')} />
            </label>
            <label>
              <p className='font-semibold text-neutral-200'>From</p>
              <Input type='text' value={endVideo} onChange={(e) => handleChange(e, 'end')} />
            </label>
          </div>
          <div className='justify-start items-center gap-1 inline-flex mt-2'>
            <p className='text-slate-300'>Head count:</p>
            <div className='text-slate-300  font-semibold'>6 person(s)</div>
          </div>
          <div className='justify-start items-center gap-1 inline-flex'>
            <p className='text-slate-300'>Estimation:</p>
            <p className='text-slate-300  font-semibold'>7 person(s)</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoAnalyticDetail
