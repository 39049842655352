import { useEffect, useState } from 'react'
import { event } from '~/constant'
import { socket } from '~/utils/socket'

export function useCameraDetail() {
  const [countPeople, setCountPeople] = useState({
    count: 0,
    estimate: 0,
  })

  useEffect(() => {
    setCountPeople({
      count: 6,
      estimate: 7,
    })

    socket.emit(event.REQSTREAM, 'rtsp://:8554/vegas')
  }, [])

  return {
    datas: { countPeople },
    methods: {},
  }
}
