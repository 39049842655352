import React from 'react';

function Refresh() {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33337 1.69466V5.86132H1.81797M14.6151 7.52799C14.205 4.2394 11.3997 1.69466 8.00004 1.69466C5.20223 1.69466 2.80695 3.41813 1.81797 5.86132M1.81797 5.86132H5.50004M14.6667 15.028V10.8613H14.1821M14.1821 10.8613C13.1931 13.3045 10.7979 15.028 8.00004 15.028C4.60036 15.028 1.79503 12.4832 1.38495 9.19466M14.1821 10.8613H10.5"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Refresh;
