import { useEffect, useState } from 'react'

export function usePagination({ page, fetchChangePage, limit, total_data }) {
  const [paginationResult, setpaginationResult] = useState([])

  useEffect(() => {
    if (total_data > 8) {
      const maxPage = Math.ceil(total_data / limit)
      let paginationArr = []
      for (let index = 1; index <= maxPage; index++) {
        if (index == 1 || index == maxPage) {
          paginationArr.push(index)
          continue
        }
        if (index > page + 1 || index < page - 1) {
          // if the pagination has not been truncated
          if (paginationArr[paginationArr.length - 1] == '...') {
            continue
          }
          paginationArr.push('...')
          continue
        }
        paginationArr.push(index)
      }

      setpaginationResult(paginationArr)
      return
    }
  }, [page, limit, total_data])

  function handlePagination(id) {
    const maxPage = Math.ceil(total_data / limit)
    const endOfPagination = maxPage < id

    if (endOfPagination) {
      return
    }

    if (id === '...' || id < 1 || id > maxPage) {
      return
    }

    fetchChangePage(id)
  }

  return {
    datas: {
      page,
      paginationResult,
    },
    methods: {
      handlePagination,
    },
  }
}
