export const event = {
  ALERT: 'alert',
  HEALTH: 'health',
  IMAGERTSP: 'imageRTSP',
  CONNECTION: 'connection',
  DISCONNECT: 'disconnect',
  REQSTREAM: 'reqRTSPStream',
  STOPSTREAM: 'stopRTSPStream',
  STREAMFRAME: 'reqFrameStream',
}
