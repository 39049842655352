import React, { useState, useEffect } from "react";

import { useHome } from "./hooks";
import { CheckCircle, Search } from "~/svg";
import { socket } from "~/utils/socket";
import { event, imageBast64String } from "~/constant";
import { Button, Dropdown, SearchBar, Shimmer } from "~/components";
import { b64toBlob } from "~/utils";
import Preview from "./components/preview";
import Sidebar from "./components/sidebar-filter";
import Main from "./components/main";

const Home = () => {
  const {
    datas: {
      siteList,
      floorList,
      streamData,
      cameraList,
      searchCamera,
      previewModal,
      selectedSites,
      selectedFloors,
      selectedCameras,
    },
    methods: { handleFilter, submitSearchCamera, handleChangeSearchCamera },
  } = useHome();

  return (
    <div className="w-full min-h-screen mt-10 px-8">
      <div className="flex flex-row justify-between mx-auto">
        <div className="flex flex-row items-center gap-4 w-full">
          <p className="text-4xl text-neutral-50 font-semibold">Cameras</p>
          <p className="flex flex-row items-center gap-1">
            <CheckCircle />
            All
          </p>
        </div>
      </div>

      <div className="w-full flex gap-8 pb-[10vh]">
        <Sidebar
          filter={{
            sites: true,
            floors: true,
            cameras: true,
          }}
          sites={siteList}
          floors={floorList}
          cameras={cameraList}
          handleFilter={handleFilter}
          selectedSites={selectedSites}
          selectedFloors={selectedFloors}
          selectedCameras={selectedCameras}
          handleChangeSearchCamera={handleChangeSearchCamera}
        />

        <Main streamData={streamData} />
      </div>
    </div>
  );
};

export default Home;
