import React from 'react'

function EyeInput() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M10 8a2 2 0 11-4 0 2 2 0 014 0z'></path>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M1.639 8a6.67 6.67 0 0112.723 0A6.67 6.67 0 011.639 8z'></path>
    </svg>
  )
}

export default EyeInput
