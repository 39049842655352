import React from 'react'

import { IconX } from '~/svg'
import { Button, Modal } from '~/components'

const LogoutModal = ({
  userSessions,
  ipAddressUser,
  logoutModal,
  submitLogout,
  setLogoutModal,
}) => {
  return (
    <Modal isOpen={logoutModal} closeModal={() => setLogoutModal(false)}>
      <div className='rounded-2xl flex flex-col py-2 px-8 w-[600px] border-neutral-200 border'>
        <div className='flex flex-row w-full justify-between max-md:border-b max-md:border-neutral-800 mt-3'>
          <p className='w-full text-neutral-50 font-semibold text-sm md:text-xl text-center md:text-left my-5'>
            Log Out from This Device
          </p>
          <div
            data-testid='iconx'
            className='flex max-md:hidden cursor-pointer'
            onClick={() => setLogoutModal(false)}>
            <IconX />
          </div>
        </div>
        <p className='text-neutral-200 text-sm my-4 md:my-2'>
          This will end your current session on this device. You will need to enter your password
          again to log back in.
        </p>
        <div className='font-semibold mt-11 md:mt-4 flex md:flex-row flex-col justify-end w-full gap-2'>
          <div className='w-fit'>
            <Button
              data-testid='button-cancel'
              type={'secondary'}
              onClick={() => setLogoutModal(false)}>
              Cancel
            </Button>
          </div>
          <div className='w-fit'>
            <Button
              data-testid='button-logout'
              type={'danger'}
              onClick={() => {
                const userLogin = userSessions.filter(
                  (s) => s.is_active && ipAddressUser == s.ip_address,
                )
                submitLogout(userLogin[0].id)
              }}>
              Log Out
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LogoutModal
