import React from 'react'

import { CheckedAvatar } from '~/svg'

const UserItems = ({ it, selectUser }) => {
  return (
    <div
      onClick={() => selectUser(it.name)}
      className={`border-[1px] rounded-[30px] flex gap-2 items-center p-1 pr-3 cursor-pointer ${
        it.selected ? 'border-primary-600 bg-primary-900' : 'border-neutral-600'
      }`}>
      {it.selected ? (
        <CheckedAvatar />
      ) : (
        <div className='h-6 w-6 flex justify-center items-center bg-neutral-800 rounded-full'>
          <p className='text-xs text-neutral-200'>{it.name.charAt(0).toUpperCase()}</p>
        </div>
      )}
      <p
        className={`text-sm truncate text-neutral-200 ${
          it.selected ? 'font-semibold' : 'font-medium'
        }`}>
        {it.name}
      </p>
    </div>
  )
}

export default UserItems
