import React from 'react'

function Info() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M8.667 10.667H8V8h-.667M8 5.333h.007M14 8A6 6 0 112 8a6 6 0 0112 0z'></path>
    </svg>
  )
}

export default Info
