import React, { useEffect, useRef, useState } from 'react';
import { Shimmer } from '~/components';
// import { socket } from '~/utils/socket';
import { io } from 'socket.io-client'

import { event, imageBast64String } from '~/constant';
import { b64toBlob } from '~/utils';
import { FastForward, PauseSolid, Play } from '~/svg';
import PlaySolid from '~/svg/play-solid';

const ReplayVideoStream = ({
  name,
  url,
  isPlaying,
  isNew,
  selectedCamera,
  setTimestamp,
  handlePlayVms,
  handlePause,
  fastFoward,
}) => {
  const videoPlayerRef = useRef();

  // useEffect(() => {
  //   // Cleanup on component unmount
  //   return () => {
  //     socket.emit(`stop_${url}`, () => {});
  //   };
  // }, [url]);

  return (
    <div className="relative w-full h-full aspect-video">
      <p className="absolute top-0 left-0 m-2 text-base text-neutral-400">
        {name}
      </p>
      <div className="absolute flex flex-row gap-6 fill-white text-white stroke-white bottom-0 left-1/2 transform -translate-x-1/2">
        <div className='grid grid-cols-1'>
          <p>-10s</p>
          <button className="items-center" onClick={() => fastFoward(-10)}>
            <FastForward size="size-12" rotate180 />
          </button>
        </div>
        <button onClick={isPlaying ? handlePause : handlePlayVms}>
          {isPlaying ? (
            <PauseSolid size="size-12" />
          ) : (
            <PlaySolid size="size-12" />
          )}
        </button>
        <div className='grid grid-cols-1'>
        <p>+10s</p>
        <button onClick={() => fastFoward(10)}>
          <FastForward size="size-12" />
        </button>
        </div>
      </div>
      <ImageReceiver selectedCamera={selectedCamera} streamLink={url} isNew={isNew} isPlaying={isPlaying} setTimestamp={setTimestamp} />
    </div>
  );
};

const ImageReceiver = ({ streamLink, selectedCamera, isNew, isPlaying, setTimestamp }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [frameStream, setFrameStream] = useState('');
  const [currentSocket, setCurrentSocket] = useState(null);

  const handleStream = (response) => {
    const blob = b64toBlob(response, imageBast64String);
    const objectURL = URL.createObjectURL(blob);
    setFrameStream(objectURL);
    setIsLoaded(true);
  };

  const handleTime = (response) => {
    setTimestamp((prevState) => prevState + 1/response);
  };

  useEffect(() => {
    if(currentSocket){
      currentSocket.emit(event.REQSTREAM, streamLink);
      currentSocket.on(`stream_${streamLink}`, handleStream);
      currentSocket.on(`fps_${streamLink}`, handleTime);
    }
    if(!isPlaying && currentSocket){
      currentSocket.disconnect()
      setCurrentSocket(null)
    }

  }, [currentSocket, isPlaying])

  // useEffect(() => {
  //   if(isNew){
  //     if(frameStream){
  //       URL.revokeObjectURL(frameStream)
  //     }
  //     setIsLoaded(false);
  //   }
  // }, [isNew])

  useEffect(() => {
    if(frameStream){
      URL.revokeObjectURL(frameStream)
    }
    setIsLoaded(false);
  }, [streamLink, selectedCamera]);

  useEffect(() => {
    if (streamLink !== '' && isPlaying) {
      if(currentSocket){
        console.log("clear all streams")
        currentSocket.disconnect()
      }
      // Listen for 'image' event and handle received frames
      const socket = io(process.env.SOCKET_URL);
      setCurrentSocket(socket);
    }
  }, [streamLink, isPlaying])

  return (
    <div className="h-full">
      <img
        src={frameStream}
        className={`${isLoaded ? 'h-full' : 'h-0'}`}
        alt="frame-stream"
      />
      <Shimmer height={isLoaded ? 0 : '100%'} />
    </div>
  );
};

export default ReplayVideoStream;
