import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useRef, useState } from 'react'

import { PATH, SearchTypes } from '~/constant'
import { updateKeywordVideo } from '~/store/slice/video'
import { updateKeywordCamera } from '~/store/slice/camera'
import { checkIsImage, getFileExtension, useOutsideClick } from '~/utils'

export function useSearchBar({ dispatchSearch }) {
  const dummyHistory = [
    {
      keyword: 'Orange cat',
      includes: 'B2, 19/04/23 - 25/04/23',
    },
    {
      keyword: 'Toddler, girl, pink top, piggytails',
    },
    {
      keyword: 'Man smoking in corridors',
    },
  ]

  const dispatch = useDispatch()
  const searchRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()

  const keywordStore = useSelector((state) => state.camera.keyword)
  const fileStore = useSelector((state) => state.camera.uploadedFiles) || []
  const typeStore = useSelector((state) => state.camera.searchType) || []
  const videoFrameLimit = useSelector((state) => state.video.videoFrameLimit)

  const [keyword, setKeyword] = useState('')
  const [showUpload, setShowUpload] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [activeSearch, setActiveSearch] = useState(false)
  const [selectedSearchType, setSelectedSearchType] = useState(SearchTypes.IMAGE)

  const showButton = (keyword !== '' && activeSearch) || (uploadedFiles.length > 0 && activeSearch)

  const onDrop = useCallback(
    (acceptedFiles) => {
      const ext = getFileExtension(acceptedFiles[0].name)

      setShowUpload(false)

      if (acceptedFiles.length > 3 || uploadedFiles.length >= 3) {
        alert('Upload just 3 image File')
        return
      }

      if (!checkIsImage(ext)) {
        alert('Please Input the right image format')
        return
      }

      setUploadedFiles((prevState) => [
        ...prevState,
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ])
    },
    [uploadedFiles],
  )

  function handleActive() {
    setActiveSearch(true)
  }

  function handleChange(e) {
    setKeyword(e.target.value)
  }

  function resetKeyword() {
    setKeyword('')
    setShowUpload(false)
    setUploadedFiles([])
    setActiveSearch(false)
    updateKeywordSearch('', [])
  }

  function updateKeywordSearch(keyword, files, type) {
    switch (location.pathname) {
      case PATH.VIDEO:
        dispatch(updateKeywordVideo({ keyword, files }))
        break

      default:
        dispatch(updateKeywordCamera({ keyword, files, type }))
        break
    }
  }

  function setSuggestionKeyword(text) {
    setKeyword(text)
    setActiveSearch(false)
  }

  function openUpload() {
    setShowUpload(true)
    setActiveSearch(false)
  }

  function closeUpload() {
    setShowUpload(false)
  }

  function closeAll() {
    setShowUpload(false)
    setActiveSearch(false)
  }

  function reseImage() {
    setUploadedFiles([])
  }

  function goToSearch() {
    navigate(PATH.SEARCH)
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      submitSearch()
    }
  }

  function submitSearch() {
    if (keyword === '' && uploadedFiles.length < 1) {
      return
    }
    updateKeywordSearch(keyword, uploadedFiles, selectedSearchType)
    if (location.pathname === PATH.HOME) {
      goToSearch()
      return
    }
    setActiveSearch(false)
    dispatch(dispatchSearch())
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: { 'image/png': ['.png', '.jpg', '.jpeg'] },
    maxFiles: 3,
    multiple: true,
  })

  useOutsideClick({ ref: searchRef, action: closeAll })

  useEffect(() => {
    if (location.pathname === PATH.SEARCH) {
      setKeyword(keywordStore)
      setUploadedFiles(fileStore)
      // dont update the type if only search by text
      if (fileStore.length) {
        setSelectedSearchType(typeStore)
      }
      return
    }
    resetKeyword()
  }, [])

  useEffect(() => {
    if (videoFrameLimit > 0) {
      submitSearch()
    }
  }, [videoFrameLimit])

  function selectSearchType(search) {
    if (selectedSearchType === search) {
      setSelectedSearchType(SearchTypes.IMAGE)
      return
    }

    setSelectedSearchType(search)
  }

  return {
    datas: {
      keyword,
      searchRef,
      showUpload,
      showButton,
      dummyHistory,
      activeSearch,
      uploadedFiles,
      selectedSearchType,
    },
    methods: {
      open,
      reseImage,
      openUpload,
      closeUpload,
      getRootProps,
      handleActive,
      submitSearch,
      handleChange,
      resetKeyword,
      handleKeyDown,
      getInputProps,
      selectSearchType,
      setSuggestionKeyword,
    },
  }
}
