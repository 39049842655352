import React from 'react'

function MajorFire() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        fill='#1C1C1C'
        fillRule='evenodd'
        stroke='#FC7676'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M11.757 2.034a1 1 0 01.638.519c.483.967.844 1.554 1.207 2.03.368.482.756.876 1.348 1.467A6.981 6.981 0 0117 11 7 7 0 115.05 6.05a1 1 0 011.707.707c0 1.12.07 1.973.398 2.654.18.374.46.74.945 1.067.116-1.061.328-2.354.614-3.58.225-.966.505-1.93.839-2.734.167-.403.356-.785.57-1.116.208-.322.477-.65.822-.88a1 1 0 01.812-.134zm.364 13.087A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z'
        clipRule='evenodd'></path>
      <path
        fill='#F7D149'
        fillRule='evenodd'
        d='M15.303 2.24a.8.8 0 011.394 0l2.233 3.968a.8.8 0 01-.698 1.192h-4.464a.8.8 0 01-.697-1.192l2.232-3.969zM16.4 6.2a.4.4 0 11-.8 0 .4.4 0 01.8 0zM16 3a.4.4 0 00-.4.4v1.2a.4.4 0 00.8 0V3.4A.4.4 0 0016 3z'
        clipRule='evenodd'></path>
    </svg>
  )
}

export default MajorFire
