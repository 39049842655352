import React from 'react'

import { STATUS } from '~/constant'

const StatusBadges = ({ big, status }) => {
  return (
    <div
      className={`${big ? 'px-1 md:px-2' : 'px-1'} py-[2px] font-medium rounded-sm ${
        status === STATUS.PENDING
          ? 'bg-[#EFC73452] text-warning-50'
          : status === STATUS.SCHEDULED
          ? 'bg-[#1676BF29] text-primary-100'
          : status === STATUS.RESOLVED
          ? 'bg-[#22B21429] text-success-100'
          : status === STATUS.NOTIFIED
          ? 'bg-[#1652C029] text-information-100'
          : ''
      }`}>
      <p
        className={
          big ? 'text-xs leading-3 md:text-[18px] md:leading-[18px]' : 'text-xs leading-3'
        }>
        {status === STATUS.PENDING
          ? 'Pending'
          : status === STATUS.SCHEDULED
          ? 'Scheduled'
          : status === STATUS.RESOLVED
          ? 'Resolved'
          : status === STATUS.NOTIFIED
          ? 'Notified'
          : ''}
      </p>
    </div>
  )
}

export default StatusBadges
