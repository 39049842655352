import React from 'react'

function TelegramIcon() {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='w-6 h-6'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52 28C52 41.2548 41.2548 52 28 52C14.7452 52 4 41.2548 4 28C4 14.7452 14.7452 4 28 4C41.2548 4 52 14.7452 52 28ZM28.8601 21.7179C26.5257 22.6888 21.8603 24.6984 14.8638 27.7466C13.7277 28.1984 13.1325 28.6404 13.0783 29.0726C12.9868 29.803 13.9014 30.0906 15.1469 30.4822C15.3164 30.5355 15.4919 30.5907 15.6719 30.6492C16.8973 31.0475 18.5457 31.5135 19.4026 31.5321C20.1799 31.5489 21.0475 31.2284 22.0053 30.5707C28.5423 26.158 31.9168 23.9276 32.1286 23.8795C32.2781 23.8456 32.4852 23.803 32.6255 23.9277C32.7659 24.0524 32.7521 24.2886 32.7372 24.352C32.6466 24.7383 29.0562 28.0762 27.1982 29.8036C26.619 30.3421 26.2081 30.724 26.1242 30.8113C25.936 31.0067 25.7443 31.1915 25.56 31.3692C24.4215 32.4667 23.5678 33.2896 25.6072 34.6336C26.5873 35.2794 27.3715 35.8135 28.1539 36.3463C29.0084 36.9282 29.8606 37.5085 30.9632 38.2313C31.2442 38.4155 31.5125 38.6068 31.7738 38.7931C32.7681 39.5019 33.6615 40.1388 34.7652 40.0373C35.4065 39.9782 36.0689 39.3752 36.4053 37.5767C37.2004 33.3263 38.7633 24.1169 39.1244 20.3219C39.1561 19.9895 39.1163 19.5639 39.0843 19.3771C39.0523 19.1904 38.9855 18.9242 38.7427 18.7272C38.4552 18.4939 38.0113 18.4447 37.8127 18.4482C36.91 18.4641 35.5251 18.9456 28.8601 21.7179Z'
        fill='white'
      />
    </svg>
  )
}

export default TelegramIcon
