import { useCallback, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { START_DATE } from "@datepicker-react/hooks";

import { peopleSlice, getPeopleAnalytics, getPeopleSummary } from "~/store/slice/people";
import {
  incidentSlice,
  getIncident,
  getIncidentAnalytics,
  getIncidentSummary,
  resetIncidentToast,
} from "~/store/slice/incident";
import { getSettings } from "~/store/slice/setting";
import { useDispatch, useSelector } from "react-redux";
import { INCIDENT_TYPE } from "~/constant";
import { formatDate } from "~/utils";

export function useAnalytics() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const people_analytics = useSelector((state) => state.people.people_analytics);
  const people_summary = useSelector((state) => state.people.people_summary);
  const people_summary_total = useSelector((state) => state.people.people_summary_total);
  const incident_fire_analytics = useSelector((state) => state.incident.incident_fire_analytics);
  const incident_summary_data = useSelector((state) => state.incident.incident_summary_data);
  const incident_summary_total = useSelector((state) => state.incident.incident_summary_total);
  const showToast = useSelector((state) => state.incident.showLoadingToast);
  const showToastPeople = useSelector((state) => state.people.showLoadingToastPeople);

  const incident_loitering_analytics = useSelector(
    (state) => state.incident.incident_loitering_analytics
  );
  const incident_fall_analytics = useSelector((state) => state.incident.incident_fall_analytics);
  const latest_fire_incidents = useSelector((state) => state.incident.latest_fire_incidents);
  const latest_loitering_incidents = useSelector(
    (state) => state.incident.latest_loitering_incidents
  );
  const latest_fall_incidents = useSelector((state) => state.incident.latest_fall_incidents);

  const floor_settings = useSelector((state) => state.settings.floorSettings);
  const camera_settings = useSelector((state) => state.settings.cameraSettings);

  const [selectedSite, setSelectedSite] = useState("Site 1");
  const [selectedCamera, setSelectedCamera] = useState("All Cameras");
  const [selectedFloor, setSelectedFloor] = useState("All Floors");

  const now = new Date();
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const defaultDate = {
    endDate: now,
    startDate: todayStart,
    focusedInput: START_DATE,
  };

  const [dateRange, setDateRange] = useState(defaultDate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [displayDate, setDisplayDate] = useState("All Dates");
  const [modalExport, setModalExport] = useState("");

  function toggleDatePicker() {
    setShowDatePicker(!showDatePicker);
  }

  function jumpToSite(site) {
    switch (site) {
      case "Boon Keng":
        navigate("/alert", { state: { site: 1 } });
        break;
      case "Eunos":
        navigate("/alert", { state: { site: 2 } });
        break;
      case "Kallang":
        navigate("/alert", { state: { site: 3 } });
        break;
      case "Marymount":
        navigate("/alert", { state: { site: 4 } });
        break;    
      case "Ang Mo Kio":
        navigate("/alert", { state: { site: 5 } });
        break;     
      case "Little Road":
        navigate("/alert", { state: { site: 6 } });
        break;     
    }
  }

  useEffect(() => {
    let filtered_dates = "All Dates";
    if (dateRange.startDate !== null) {
      const startDate = formatDate(dateRange.startDate);
      filtered_dates = "start: " + startDate;
    }
    if (dateRange.endDate !== null) {
      const endDate = formatDate(dateRange.endDate);
      filtered_dates += "\n end: " + endDate;
    }
    setDisplayDate(filtered_dates);
  }, [dateRange]);

  useEffect(() => {
    dispatch(peopleSlice.actions.resetpeopleStore());
    dispatch(incidentSlice.actions.resetincidentStore());
  }, []);

  useEffect(() => {
    switch (location.state.site) {
      case 1:
        setSelectedSite(`Boon Keng`);
        break;
      case 2:
        setSelectedSite(`Eunos`);
        break;
      case 3:
        setSelectedSite(`Kallang`);
        break;
      case 4:
        setSelectedSite(`Marymount`);
        break;        
      case 5:
        setSelectedSite(`Ang Mo Kio`);
        break;
      case 6:
        setSelectedSite(`Little Road`);
        break;
      default:
        setSelectedSite(`Boon Keng`);
        break;
    }
  }, [location.state]);

  function fetchAnalytics() {
    dispatch(getIncidentSummary({ type: "Fire/Smoke", site: location.state.site }));
    dispatch(getIncidentSummary({ type: "Fall", site: location.state.site }));
    dispatch(getIncidentSummary({ type: "loitering", site: location.state.site }));

    dispatch(getPeopleSummary({ site: location.state.site }));

    dispatch(
      getIncidentAnalytics({
        type: INCIDENT_TYPE.FIRE,
        camera: selectedCamera,
        floor: selectedFloor,
        site: location.state.site,
        after: dateRange.startDate,
        before: dateRange.endDate,
      })
    );
    dispatch(
      getIncidentAnalytics({
        type: INCIDENT_TYPE.FALL,
        camera: selectedCamera,
        floor: selectedFloor,
        site: location.state.site,
        after: dateRange.startDate,
        before: dateRange.endDate,
      })
    );
    dispatch(
      getIncidentAnalytics({
        type: INCIDENT_TYPE.LOITERING,
        camera: selectedCamera,
        floor: selectedFloor,
        site: location.state.site,
        after: dateRange.startDate,
        before: dateRange.endDate,
      })
    );
    dispatch(
      getPeopleAnalytics({
        camera: selectedCamera,
        floor: selectedFloor,
        site: location.state.site,
        after: dateRange.startDate,
        before: dateRange.endDate,
      })
    );
    dispatch(getSettings({ site: location.state.site, floor: selectedFloor }));
  }

  useEffect(() => {
    fetchAnalytics();

    // Set up the interval
    const intervalID = setInterval(() => {
      fetchAnalytics();
    }, 1000 * 60 * 5); // 5 minutes

    // Clean up the interval on unmount or when the effect runs again
    return () => {
      clearInterval(intervalID);
    };
  }, [selectedCamera, selectedFloor, dateRange]);

  function openModalExport(type) {
    setModalExport(type);
  }

  function closeModalExport(type) {
    setModalExport("");
  }

  function hideToast() {
    dispatch(resetIncidentToast());
  }

  return {
    datas: {
      showToast,
      showToastPeople,
      dateRange,
      people_summary_total,
      displayDate,
      modalExport,
      selectedSite,
      selectedFloor,
      selectedCamera,
      floor_settings,
      showDatePicker,
      camera_settings,
      people_analytics,
      people_summary,
      incident_fire_analytics,
      incident_fall_analytics,
      incident_loitering_analytics,
      latest_fall_incidents,
      latest_fire_incidents,
      latest_loitering_incidents,
      incident_summary_data,
      incident_summary_total,
    },
    methods: {
      hideToast,
      jumpToSite,
      setSelectedSite,
      setSelectedCamera,
      setSelectedFloor,
      setDateRange,
      toggleDatePicker,
      closeModalExport,
      openModalExport,
    },
  };
}
