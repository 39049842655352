import React from 'react'

import { ActivePhotograph, IconX, Photograph, Search } from '~/svg'

const SearchInput = ({
  keyword,
  isNavbar,
  reseImage,
  openUpload,
  showUpload,
  showButton,
  closeUpload,
  activeSearch,
  handleActive,
  handleChange,
  resetKeyword,
  submitSearch,
  uploadedFiles,
  handleKeyDown,
}) => {
  return (
    <div className={`w-full flex justify-between items-center ${isNavbar ? 'h-10' : 'h-12'}`}>
      <div
        className={`w-full h-full flex items-center justify-between px-5 rounded-l-lg border-[1px] gap-2 ${
          activeSearch ? 'border-primary-600' : 'border-neutral-700'
        }`}>
        <div className='flex w-full items-center gap-2'>
          {uploadedFiles.length > 0 && (
            <div className='flex justify-start items-center gap-[6px] bg-[#393F4466] rounded'>
              <img
                className='h-6 w-6 object-cover rounded'
                src={uploadedFiles[0][0].preview}
                alt='uploaded'
              />
              <p className='text-xs w-[59px] md:w-[140px] text-neutral-50 mt-[1px]'>
                Uploaded image ({uploadedFiles.length})
              </p>
              <div onClick={reseImage} className='cursor-pointer mr-1'>
                <IconX />
              </div>
            </div>
          )}
          <input
            type='text'
            id='search'
            name='search'
            value={keyword}
            autoComplete='off'
            onFocus={(e) => {
              e.target.placeholder = ''
              handleActive()
            }}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={(e) =>
              (e.target.placeholder = uploadedFiles ? '' : 'Man in 20s wearing green hoodie')
            }
            placeholder={uploadedFiles ? '' : 'Man in 20s wearing green hoodie'}
            className='w-full bg-transparent outline-none border-none text-base font-normal placeholder:text-neutral-400 text-neutral-50 truncate'
          />
        </div>
        <div className='flex items-center gap-5'>
          {showButton ? (
            <div className='cursor-pointer' onClick={resetKeyword}>
              <IconX />
            </div>
          ) : (
            <></>
          )}
          {uploadedFiles ? (
            <>
              {showUpload ? (
                <div onClick={closeUpload} className='cursor-pointer'>
                  <ActivePhotograph />
                </div>
              ) : (
                <div onClick={openUpload} className='cursor-pointer'>
                  <Photograph />
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        onClick={submitSearch}
        className={`flex items-center cursor-pointer bg-primary-600 h-full px-5 ${
          activeSearch ? 'rounded-tr-lg' : 'rounded-r-lg'
        }`}>
        <Search />
      </div>
    </div>
  )
}

export default SearchInput
