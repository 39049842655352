import React from 'react'

import { useLogin } from './hooks'
import { CountDown, Form, Locked } from './components'
import ForgotPassword from './components/forgot-password'
import ForgotPasswordOTP from './components/forgot-password-otp'
import NewPassword from './components/new-password'
import NewPasswordSuccess from './components/new-password-success'

const Login = () => {
  const {
    datas: {
      form,
      isLoading,
      errToast,
      errCount,
      suspendUntil,
      isDenied,
      isSuspend,
      isForgotPassword,
      otp,
      isVerifyOtp,
      isInvalidOtp,
      isErrGeneral,
      isNewPassword,
      newPasswordSuccess,
    },
    methods: {
      handleChange,
      handleSubmit,
      closeToast,
      checkValidEmail,
      backToLogin,
      handleForgotPassword,
      setOTP,
      handleSendOTP,
      handleVerifyOTP,
      handleNewPassword,
    },
  } = useLogin()

  return (
    <div className='w-full min-h-screen'>
      <div className='w-full h-[90vh] flex justify-center items-center'>
        {!isDenied &&
        !isSuspend &&
        !isForgotPassword &&
        !isVerifyOtp &&
        !isNewPassword &&
        !newPasswordSuccess ? (
          <Form
            form={form}
            errCount={errCount}
            errToast={errToast}
            isLoading={isLoading}
            closeToast={closeToast}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            checkValidEmail={checkValidEmail}
            handleForgotPassword={handleForgotPassword}
          />
        ) : isDenied ? (
          <Locked backToLogin={backToLogin} />
        ) : isSuspend ? (
          <CountDown countdownTimestampMs={suspendUntil} />
        ) : isForgotPassword ? (
          <ForgotPassword
            form={form}
            errCount={errCount}
            errToast={errToast}
            isLoading={isLoading}
            closeToast={closeToast}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            checkValidEmail={checkValidEmail}
            backToLogin={backToLogin}
            handleSendOTP={handleSendOTP}
          />
        ) : isVerifyOtp ? (
          <ForgotPasswordOTP
            form={form}
            errCount={errCount}
            errToast={errToast}
            isLoading={isLoading}
            otp={otp}
            isInvalidOtp={isInvalidOtp}
            isErrGeneral={isErrGeneral}
            closeToast={closeToast}
            setOTP={setOTP}
            handleSendOTP={handleSendOTP}
            handleVerifyOTP={handleVerifyOTP}
          />
        ) : isNewPassword ? (
          <NewPassword
            form={form}
            errCount={errCount}
            errToast={errToast}
            isLoading={isLoading}
            handleNewPassword={handleNewPassword}
            closeToast={closeToast}
          />
        ) : newPasswordSuccess ? (
          <NewPasswordSuccess backToLogin={backToLogin} />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default Login
