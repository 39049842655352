import React from 'react'

function Volume() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <g clipPath='url(#clip0_705_6355)'>
        <path
          fill='#fff'
          d='M4 9a1 1 0 00-1 1v4a1 1 0 001 1h3l3.293 3.293c.63.63 1.707.184 1.707-.707V6.414c0-.89-1.077-1.337-1.707-.707L7 9H4zm12.5 3c0-1.654-.891-3.09-2.216-3.875-.128-.076-.284.02-.284.169v7.404c0 .149.155.244.283.17A4.471 4.471 0 0016.5 12zm-1.302-8.408c-.6-.228-1.198.249-1.198.89v.062c0 .443.294.826.702.997A7.005 7.005 0 0119 12a7.004 7.004 0 01-4.298 6.46c-.408.17-.702.553-.702.996v.062c0 .641.598 1.118 1.198.89a8.995 8.995 0 000-16.816z'></path>
      </g>
      <defs>
        <clipPath id='clip0_705_6355'>
          <path fill='#fff' d='M0 0H24V24H0z'></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Volume
