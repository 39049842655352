import React, { useEffect, useState } from 'react'

import { Button } from '~/components'
import { getRemainingTimeUntilMsTimestamp } from '~/utils'

const defaultRemainingTime = {
  seconds: '00',
  minutes: '00',
  hours: '00',
  days: '00',
}

const CountDown = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime)

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [countdownTimestampMs])

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown))
  }

  return (
    <div className='px-9 py-10 mx-0 w-full md:w-[431px] rounded-lg rounded-tl-none flex flex-col justify-center items-center'>
      <div className='flex flex-col justify-center items-center text-center'>
        <div className='flex justify-center items-center'>
          <p className='font-medium text-[64px] md:text-[80px] text-neutral-50'>
            {remainingTime.hours}
          </p>
          <p className='text-sm font-normal text-neutral-400 mx-3'>:</p>
          <p className='font-medium text-[64px] md:text-[80px] text-neutral-50'>
            {remainingTime.minutes}
          </p>
          <p className='text-sm font-normal text-neutral-400 mx-3'>:</p>
          <p className='font-medium text-[64px] md:text-[80px] text-neutral-50 move'>
            {remainingTime.seconds}
          </p>
        </div>
        <p className='text-neutral-50 font-semibold text-[20px] mt-5'>
          Access Temporarily Suspended
        </p>
        <p className='text-neutral-200 font-normal text-sm mt-4 w-full md:w-[400px] max-w-[335px] md:max-w-[400px]'>
          For security reasons, you can try log into your account after the countdown is out.
          Alternatively, you may contact admin to help you.
        </p>
        <div className='flex flex-col md:flex-row justify-center items-center gap-2 mt-4 w-full'>
          <Button type={'primary'} small>
            <p>Contact Admin</p>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CountDown
