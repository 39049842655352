import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { axios } from "~/utils";
import { store } from "..";
import { socket } from "~/utils/socket";
import { useNavigate } from "react-router-dom";

const initialState = {
  dataSession: [],
  dataProfile: {},
  dataVerified: {
    email: "",
    password: "",
  },
  dataSystem: {
    path: "",
    storage: 0,
    status: "offline",
    version: "1.0.0",
  },
  isLoading: false,
  isVerified: false,
  notifLoading: false,
  revealedData: false,
  verifyLoading: false,
  editLoading: false,
  endSessionLoading: true,
  isSystemLoading: false,
  profileLoading: false,
  sessionLoading: false,
  super: null,
  admin: false,
  site_choosen: null,
  isAuthorized: null,
};
const baseUrl = process.env.BASE_URL;

export const getProfile = createAsyncThunk(
  "account/getProfile",
  async (args, { getState, rejectWithValue }) => {
    const store = getState();

    try {
      const response = await axios.get(`${baseUrl}/auth/me`);
      return { ...response.data, store };
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getProfileAsync = createAsyncThunk(
  "account/getProfileAsync",
  async (args, { getState, rejectWithValue }) => {
    const store = getState();

    const token = sessionStorage.getItem("authToken");
    try {
      const response = await axios.get(`${baseUrl}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { ...response.data, store, super: response.data.super };
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getSession = createAsyncThunk(
  "account/getSession",
  async (args, { getState, rejectWithValue }) => {
    const store = getState();

    try {
      const response = await axios.get(`${baseUrl}/setting/sessions`);
      return { ...response.data, store };
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const endSession = createAsyncThunk(
  "account/endSession",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/setting/sessions/end`, args);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const postVerify = createAsyncThunk(
  "account/verifyPassword",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/auth/password/verify`, args);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const postEditProfile = createAsyncThunk(
  "account/editProfile",
  async (args, { rejectWithValue }) => {
    try {
      await axios.post(`${baseUrl}/auth/me/edit`, args);
      return args;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

// ADDD SYSTEEEM
export const postEditPathStorage = createAsyncThunk(
  "account/editStorage",
  async (args, { rejectWithValue }) => {
    try {
      await axios.post(`${baseUrl}/setting/storage`, args);
      return args;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getSystemUser = createAsyncThunk(
  "account/getSysten",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/setting/system`);
      return response.data;
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    initLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.profileLoading = false;
      state.sessionLoading = false;
    },
    initProfileLoading: (state) => {
      state.profileLoading = true;
    },
    initSessionLoading: (state) => {
      state.profileLoading = true;
    },
    initNotifLoading: (state) => {
      state.notifLoading = true;
    },
    resetError: (state) => {
      state.errMarkNotif = false;
    },
    resetToast: (state) => {
      state.successAdd = false;
    },
    resetAccountStore: () => initialState,
    resetSessionState: (state) => {
      state.super = null;
      state.site_choosen = null;
      state.isAuthorized = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true;
        state.profileLoading = true;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        const { data, store } = action.payload;

        state.dataProfile = data;
        state.profileLoading = false;
        if (!store.account.sessionLoading) {
          state.isLoading = false;
        }
      })
      .addCase(getProfile.rejected, (state) => {
        state.isLoading = false;
        state.profileLoading = false;
      });

    builder
      .addCase(getProfileAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        const { data } = action.payload;
        socket.emit("health", { test: "TEST DARI LOGIN" });

        state.dataProfile = data;
        state.super = action.payload.super;
        state.site_choosen = action.payload.site;
        state.isLoading = !state.super;
        state.isAuthorized = true;
        state.admin = action.payload.admin === undefined ? false : action.payload.admin;
      })
      .addCase(getProfileAsync.rejected, (state) => {
        state.isAuthorized = false;
        state.isLoading = true;
      });

    builder
      .addCase(getSession.pending, (state) => {
        state.isLoading = true;
        state.sessionLoading = true;
      })
      .addCase(getSession.fulfilled, (state, action) => {
        const { data, store } = action.payload;

        state.dataSession = data;
        state.sessionLoading = false;
        if (!store.account.profileLoading) {
          state.isLoading = false;
        }
      })
      .addCase(getSession.rejected, (state) => {
        state.isLoading = false;
        state.sessionLoading = false;
      });

    builder
      .addCase(postVerify.pending, (state) => {
        state.isVerified = false;
        state.verifyLoading = true;
      })
      .addCase(postVerify.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.isVerified = true;
        state.dataVerified = data;
        state.verifyLoading = false;
      })
      .addCase(postVerify.rejected, (state) => {
        state.verifyLoading = false;
      });

    builder
      .addCase(endSession.pending, (state) => {
        state.endSessionLoading = true;
      })
      .addCase(endSession.fulfilled, (state) => {
        setTimeout(() => {
          store.dispatch(getSession());
        });

        state.endSessionLoading = false;
      })
      .addCase(endSession.rejected, (state) => {
        state.endSessionLoading = false;
      });

    builder
      .addCase(postEditProfile.pending, (state) => {
        state.editLoading = true;
      })
      .addCase(postEditProfile.fulfilled, (state) => {
        setTimeout(() => {
          store.dispatch(getProfile());
        });

        state.editLoading = false;
      })
      .addCase(postEditProfile.rejected, (state) => {
        state.editLoading = false;
      });

    builder
      .addCase(postEditPathStorage.pending, (state) => {
        state.isSystemLoading = true;
      })
      .addCase(postEditPathStorage.fulfilled, (state) => {
        setTimeout(() => {
          store.dispatch(getSystemUser());
        });

        state.isSystemLoading = false;
      })
      .addCase(postEditPathStorage.rejected, (state) => {
        state.isSystemLoading = false;
      });

    builder
      .addCase(getSystemUser.pending, (state) => {
        state.isSystemLoading = true;
      })
      .addCase(getSystemUser.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.dataSystem = data;
        state.isSystemLoading = false;
      })
      .addCase(getSystemUser.rejected, (state) => {
        state.isSystemLoading = false;
      });
  },
});

export const {
  resetError,
  resetToast,
  initLoading,
  stopLoading,
  initNotifLoading,
  resetAccountStore,
  initProfileLoading,
  initSessionLoading,
  resetSessionState,
} = accountSlice.actions;
export default accountSlice.reducer;
