import React from 'react'

import { Button, VideoPlayer } from '~/components'

const MobileView = ({ typeToStr, selectedAlert, toLogin, alertVideoRef }) => {
  return (
    <div className='w-full py-9'>
      <div className='flex justify-start items-center gap-2 px-7'>
        <p className='text-neutral-200 font-semibold text-[18px] md:text-2xl'>
          {typeToStr(selectedAlert.type)}
        </p>
      </div>
      <div className='mt-5 w-full px-7'>
        <VideoPlayer
          playerRef={alertVideoRef}
          isMobile
          url={
            'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4'
          }
          accuracy={80}
        />
      </div>
      <div className='mt-4 flex gap-4 px-4'>
        <div>
          <p className='text-neutral-200 text-sm'>Location</p>
          <p className='text-neutral-50 font-semibold mt-2'>L1 Lobby</p>
        </div>
        <div>
          <p className='text-neutral-200 text-sm'>Date, time</p>
          <div className='mt-2 flex items-center gap-4'>
            <div>
              <p className='text-neutral-50 font-semibold text-sm'>19 Sep 2023</p>
              <p className='text-neutral-50 font-semibold text-sm mt-[2px]'>23:18</p>
            </div>
            <p className='text-xs text-neutral-400 my-1'>until</p>
            <div>
              <p className='text-neutral-50 font-semibold text-sm'>20 Sep 2023</p>
              <p className='text-neutral-50 font-semibold text-sm mt-[2px]'>01:43</p>
            </div>
          </div>
          <p className='text-sm text-neutral-400 mt-1'>(2 hours, 25 mins)</p>
        </div>
      </div>
      <div className='mt-4 px-4'>
        <div className='mt-8 text-xs text-neutral-400'>
          <p className='text-neutral-200'>Emergency numbers</p>
          <div className='flex gap-4'>
            <p className='mt-2'>{`Police: ${selectedAlert.emergencies.police}`}</p>
            <p className='mt-2'>{`Ambulance: ${selectedAlert.emergencies.ambulance}`}</p>
            <p className='mt-2'>{`Firefighter: ${selectedAlert.emergencies.firefighter}`}</p>
          </div>
        </div>
      </div>

      <div className='mt-4 px-4 text-sm'>
        <p className='text-neutral-200'>History log</p>
        <p className='text-neutral-200 text-sm mt-2'>
          <span
            onClick={toLogin}
            className='font-semibold underline cursor-pointer text-primary-200'>
            Log into your account
          </span>{' '}
          to view this section.
        </p>
      </div>
      <div className='w-full border-t-[1px] border-t-neutral-800 pt-5 px-4 mt-16'>
        <Button type={'primary'}>
          <p>Login to Report</p>
        </Button>
      </div>
    </div>
  )
}

export default MobileView
