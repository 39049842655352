import { useCallback, useState, useEffect, useMemo } from "react";
import { peopleSlice, getPeople } from "~/store/slice/people";
import { incidentSlice, getIncident, getIncidentCount } from "~/store/slice/incident";
import { accountSlice } from "~/store/slice/account";
import { getSettings } from "~/store/slice/setting";
import { getProfileAsync } from "~/store/slice/account";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMachineStatus } from "~/store/slice/machine";

export function useSites() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const super_user = useSelector((state) => state.account.super);
  const site_choosen = useSelector((state) => state.account.site_choosen);
  const isLoading = useSelector((state) => state.account.isLoading);
  const isAuthorized = useSelector((state) => state.account.isAuthorized);
  const statusIndicator = useSelector((state) => state.machine.machineStatus) || [];

  const incident_count = useSelector((state) => state.incident.incident_count);

  const [superUser, setSuperUser] = useState(null);
  const [siteChoosen, setSiteChoosen] = useState(null);
  const [authorized, setAuthorized] = useState(null);
  const [countIncident, setCountIncident] = useState([0, 0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    dispatch(accountSlice.actions.resetSessionState());
    dispatch(peopleSlice.actions.resetpeopleStore());
    dispatch(incidentSlice.actions.resetincidentStore());
    dispatch(getIncidentCount());
    dispatch(getProfileAsync());
    dispatch(getMachineStatus());
    const intervalId = setInterval(() => dispatch(getMachineStatus()), 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setSuperUser(super_user);
    setSiteChoosen(site_choosen);
    setAuthorized(isAuthorized);
  }, [super_user, site_choosen, isAuthorized]);

  useEffect(() => {
    if (authorized !== null && !authorized) {
      navigate("/login");
    }
    if (superUser !== null && !superUser) {
      navigate("/analytics", { state: { site: siteChoosen } });
    }
  }, [superUser, superUser, authorized]);

  useEffect(() => {
    if (!incident_count && !incident_count.length) return;
    const incident_counts = [0, 0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < incident_count.length; i++) {
      incident_counts[incident_count[i].site - 1] = incident_count[i].count;
    }

    setCountIncident(incident_counts);
  }, [incident_count]);

  const goToChi1 = () => {
    navigate("/alert", { state: { site: 1 } });
  };

  const goToChi2 = () => {
    navigate("/alert", { state: { site: 2 } });
  };

  const goToChi3 = () => {
    navigate("/alert", { state: { site: 3 } });
  };

  const goToChi4 = () => {
    navigate("/alert", { state: { site: 4 } });
  };

  const goToChi5 = () => {
    navigate("/alert", { state: { site: 5 } });
  };

  const goToChi6 = () => {
    navigate("/alert", { state: { site: 6 } });
  };

  const goToChi7 = () => {
    navigate("/alert", { state: { site: 7 } });
  };

  return {
    datas: {
      isLoading,
      countIncident,
      statusIndicator,
    },
    methods: { goToChi1, goToChi2, goToChi3, goToChi4, goToChi5, goToChi6, goToChi7 },
  };
}
