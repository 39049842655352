import React, { useEffect, useState } from 'react'
import { CevronLeft, Info } from '~/svg'
import { useVideo } from './hooks'
import { Button, Dropdown, SearchBarHome, VideoPlayer } from '~/components'
import { getSearchVideoAsync } from '~/store/slice/video'

const Video = () => {
  const {
    datas: {
      saveInfo,
      isMobile,
      videoName,
      frameLimit,
      videoObject,
      stateFrames,
      isFrameView,
      uploadedFiles,
      stateMaxSearch,
      stateExpandSearch,
      isLoadingSearchVideo,
    },
    methods: {
      open,
      loadSearch,
      setSaveInfo,
      backToVideos,
      toVideoFrame,
      setFrameLimit,
      switchToFrames,
      setExpandSearch,
      backPreviousPage,
    },
  } = useVideo()
  const [startVideo, setStartVideo] = useState(0)

  const secondToMinute = (sec) => {
    return `${Math.floor(sec / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${Math.floor(sec % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`
  }

  useEffect(() => {
    setStartVideo(stateFrames.object.length > 0 ? stateFrames.object[0].timestamp[0].start : 0)
  }, [stateFrames])

  return (
    <div className='w-full mx-auto pt-10'>
      <div className='flex flex-col text-neutral-200 gap-2 md:w-3/4 mx-auto px-4'>
        <div className='flex flex-row gap-1 items-center'>
          <div className='cursor-pointer' onClick={backPreviousPage}>
            <CevronLeft />
          </div>
          <p className='font-semibold text-xl md:text-2xl'>Search from {videoName}</p>
          <div className="text-slate-300 text-sm font-normal font-['Inter'] leading-tight">•</div>
          <div className='justify-start items-center gap-2.5 flex'>
            <div className="text-blue-50 text-sm font-semibold font-['Inter'] underline leading-tight">
              Save Search
            </div>
          </div>
          <div
            onMouseEnter={() => setSaveInfo(true)}
            onMouseLeave={() => setSaveInfo(false)}
            className='relative'>
            <Info />
            {saveInfo ? (
              <div className='absolute left-[-87px] top-[-115px] w-[180px] bg-[#334155] rounded px-3 py-1'>
                <p className='text-start text-sm text-white'>
                  This will save the search history of this video. Saved search can be found on
                  Search.
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='flex flex-row'>
          <p className='flex md:hidden font-semibold text-sm underline text-right justify-end w-full text-primary-50'>
            <button data-testid='button-switch-mobile' onClick={() => switchToFrames(!isFrameView)}>
              {isFrameView ? 'Switch to Video' : 'Switch to Frames'}
            </button>
          </p>
        </div>
      </div>
      <div className='flex flex-col-reverse md:flex-row gap-10 mt-8 md:h-[600px] mx-auto md:w-2/3 mb-24'>
        <div className='flex flex-col md:w-1/3'>
          <div className='w-full px-4 md:px-0'>
            <SearchBarHome
              dispatchSearch={getSearchVideoAsync}
              previousHistory={stateFrames.searchHistory}
            />
          </div>
          <div className='h-full overflow-auto mt-8'>
            {stateFrames.object?.map((s, sidx) => (
              <div key={sidx}>
                {sidx < stateMaxSearch ? (
                  <div className='flex flex-col justify-between border-0 border-b-2 border-b-neutral-800 '>
                    <div
                      className='flex flex-row justify-between px-4 md:px-2 py-4 items-center cursor-pointer'
                      onClick={() => setExpandSearch(sidx)}>
                      <div className='flex flex-row gap-2 items-center'>
                        <p className='font-semibold text-neutral-50'>{s.name}</p>
                      </div>
                      <p className='font-semibold text-neutral-200 '>{s.timestamp?.length} found</p>
                    </div>{' '}
                    {sidx === stateExpandSearch && !isLoadingSearchVideo ? (
                      <div className='flex flex-wrap px-2 py-4 auto-cols-auto justify-between'>
                        {s.timestamp?.slice(0, 4).map((t, tidx) => (
                          <div
                            key={tidx}
                            onClick={() => toVideoFrame(isFrameView, t.start)}
                            className='cursor-pointer w-1/2 px-2'>
                            <video src={`${videoObject}#t=${t.start}`} className=''></video>
                            <p className='underline w-fit text-primary-50 justify-center text-center mx-auto'>
                              {secondToMinute(t.start)} - {secondToMinute(t.end)}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
            {stateMaxSearch < stateFrames.object?.length ? (
              <div className='flex flex-row justify-between px-2 py-4 border-0 border-b-2 border-b-neutral-800 items-center cursor-pointer'>
                <p
                  className='font-semibold text-neutral-200 w-full text-center'
                  onClick={loadSearch}>
                  Load More
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='flex flex-col md:w-2/3 px-2'>
          <div className='w-full px-2 pb-6 flex items-center justify-between'>
            {isFrameView && (
              <div className='flex flex-row items-center gap-2 justify-start font-semibold text-neutral-400'>
                <p>
                  {secondToMinute(
                    stateFrames.object.length > 0 ? stateFrames.object[0].timestamp[0].start : 0,
                  )}{' '}
                  -{' '}
                  {secondToMinute(
                    stateFrames.object.length > 0
                      ? stateFrames.object[0].timestamp[stateFrames.object[0].timestamp.length - 1]
                          .end
                      : 0,
                  )}
                </p>
                <p>•</p>
                <div className='flex flex-row gap-2 items-center'>
                  Show{' '}
                  {/* <Dropdown
                    value={stateFrames.object.length > 0 ? stateFrames.object.length : 0}
                    onChange={setFrameLimit}
                  /> */}
                  frames
                </div>
              </div>
            )}
            <p className='hidden md:flex font-semibold text-sm underline justify-end text-primary-50 ml-auto'>
              <button data-testid='button-switch' onClick={() => switchToFrames(!isFrameView)}>
                {isFrameView ? 'Switch to Video' : 'Switch to Frames'}
              </button>
            </p>
          </div>
          {isFrameView ? (
            <div
              data-testid='frame-view'
              className='flex flex-wrap px-2 py-4 auto-cols-auto justify-between md:h-[400px]'>
              {stateFrames.object.slice(0, frameLimit).map((t, tidx) => (
                <div
                  key={tidx}
                  onClick={() => toVideoFrame(!isFrameView, t.timestamp[0].start)}
                  className='cursor-pointer md:w-1/2 px-2 gap-2 pb-4'>
                  <video src={`${videoObject}#t=${t.timestamp[0].start}`}></video>
                </div>
              ))}
            </div>
          ) : (
            <VideoPlayer
              url={uploadedFiles.length > 0 ? uploadedFiles : stateFrames.url}
              isMobile={isMobile}
              start={startVideo}
            />
          )}
        </div>
      </div>
      <div className='fixed bottom-0 left-0 z-50 bg-basic-black w-full border-solid border-neutral-800 border-t-[2px] mt-5'>
        <div className='pt-4 flex md:flex-row flex-col-reverse md:justify-end w-full gap-2 md:w-2/3 mx-auto px-4 pb-5'>
          <div className='w-full md:w-fit'>
            <Button type={'transparent'} onClick={open}>
              Upload Another
            </Button>
          </div>
          <div className='w-full md:w-fit'>
            <Button type={'primary'} onClick={backToVideos}>
              Back to Videos
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Video
