import React, { useState } from 'react'
import ReactPlayer from 'react-player'

import { AccuracyBadges } from '~/components'
import { FullScreen, Pause, Pip, Play, Volume, VolumeOff } from '~/svg'

import './index.css'
import Duration from './duration'
import { usevideoPlayer } from './hooks'

const VideoPlayer = ({ url, accuracy, isMobile, start = 0, incident_name='Incident name' }) => {
  const {
    datas: { videoState, showVolume, showControls, playerRef },
    methods: {
      handlePlay,
      handleMuted,
      setShowVolume,
      handleControls,
      handleDuration,
      handleProgress,
      handleTogglePIP,
      handleSeekChange,
      handleSeekMouseUp,
      handleVolumeChange,
      handleSeekMouseDown,
      handleClickFullscreen,
    },
  } = usevideoPlayer();
  const [firstLoad, setFirstLoad] = useState(true);
  const handleReady = () => {
    if (firstLoad) {
      playerRef.current.seekTo(start);
      setFirstLoad(false);
    }
  };
  return (
    <div
      onMouseEnter={() => handleControls(true)}
      onMouseLeave={() => handleControls(false)}
      className='relative flex justify-center w-full items-center md:h-[400px]'>
      {/* Top Section */}
      {showControls || !videoState.playing ? (
        <div className='absolute top-0 rounded-t-lg w-full flex justify-between items-center px-4 py-3 bg-gradient-to-b from-black to-transparent'>
          <p className='flex items-center font-semibold text-neutral-50 text-[8px] text-base'>
            {incident_name}
            {accuracy ? (
              <span className='font-normal text-neutral-400 text-[8px] md:text-sm'>
                • 18-09-2023 Mon 22:33:23 B1 East Wing
              </span>
            ) : (
              <></>
            )}
          </p>
          {accuracy ? <AccuracyBadges accuracy={accuracy} /> : <></>}
        </div>
      ) : (
        <></>
      )}
      <div className='rounded-lg overflow-hidden mt-0' onClick={handlePlay}>
        <ReactPlayer
          url={url}
          ref={playerRef}
          height={'auto'}
          pip={videoState.pip}
          volume={videoState.volume}
          muted={videoState.muted}
          onDuration={handleDuration}
          onProgress={handleProgress}
          playing={videoState.playing}
          width={isMobile ? window.innerWidth - 48 : undefined}
          onReady={handleReady}
        />
      </div>
      {/* Bottom Section */}
      {showControls || !videoState.playing ? (
        <div className='absolute bottom-0 w-full py-3 rounded-b-lg bg-gradient-to-t from-[#1C1C1C] to-transparent'>
          <input
            min={0}
            step='any'
            type='range'
            max={0.999999}
            value={videoState.played}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
            onMouseDown={handleSeekMouseDown}
            className='w-full h-[6px] overflow-hidden appearance-none bg-[#9AA7B433] cursor-pointer player'
          />
          <div className='flex items-center justify-between px-4 mt-1'>
            <div className='flex items-center justify-start gap-4'>
              <div onClick={handlePlay} className='cursor-pointer'>
                {videoState.playing ? <Pause /> : <Play />}
              </div>
              {videoState.muted ? (
                // Volume is Muted
                <div
                  onMouseEnter={() => setShowVolume(true)}
                  onMouseLeave={() => setShowVolume(false)}
                  className='cursor-pointer flex justify-start items-center gap-2'>
                  <div onClick={() => handleMuted(false)}>
                    <VolumeOff />
                  </div>{' '}
                  {showVolume ? (
                    <input
                      min={0}
                      max={1}
                      step='any'
                      type='range'
                      value={0}
                      onChange={handleVolumeChange}
                      className='w-16 h-[6px] overflow-hidden appearance-none bg-[#9AA7B433] cursor-pointer player'
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                // Volume is Not Muted
                <div
                  onMouseEnter={() => setShowVolume(true)}
                  onMouseLeave={() => setShowVolume(false)}
                  className='cursor-pointer flex justify-start items-center gap-2'>
                  <div onClick={() => handleMuted(true)}>
                    <Volume />
                  </div>
                  {showVolume ? (
                    <input
                      min={0}
                      max={1}
                      step='any'
                      type='range'
                      value={videoState.volume}
                      onChange={handleVolumeChange}
                      className='w-16 h-[6px] overflow-hidden appearance-none bg-[#9AA7B433] cursor-pointer player'
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}
              <p className='text-[8px] md:text-sm text-white'>
                <Duration seconds={videoState.duration * videoState.played} /> /{' '}
                <Duration seconds={videoState.duration} />
              </p>
            </div>
            <div className='flex justify-end items-center gap-5'>
              <div onClick={handleTogglePIP} className='cursor-pointer'>
                <Pip />
              </div>
              <div onClick={handleClickFullscreen} className='cursor-pointer'>
                <FullScreen />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default VideoPlayer
