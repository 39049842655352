import React from 'react'

function Checked() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.33337 8.6665L6.00004 11.3332L12.6667 4.6665'
        stroke='#80F975'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Checked
