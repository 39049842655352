import React from 'react'

import { Button, Modal } from '~/components'

const BottomSheetSort = ({
  sorts,
  closeFiter,
  handleSort,
  selectedSort,
  bottomSheetSort,
  implementFilter,
}) => {
  return (
    <Modal isOpen={bottomSheetSort} closeModal={closeFiter}>
      <div className='w-full flex flex-col items-center pt-2 pb-5'>
        <div className='w-11 h-1 rounded bg-neutral-800' />
        <p className='mt-6 text-neutral-50 font-semibold text-sm'>Sort</p>
        <div className='w-full h-[1px] bg-neutral-800 mt-5' />
        <div className='w-full'>
          {sorts &&
            sorts
              .filter((it) => it === selectedSort)
              .map((it) => (
                <div
                  data-testid='sort-selected'
                  onClick={() => handleSort(it)}
                  className='w-full py-3 px-4 cursor-pointer bg-[#0B4470]'
                  key={it}>
                  <p className='text-sm leading-3 text-neutral-200'>{it}</p>
                </div>
              ))}
          {sorts &&
            sorts
              .filter((it) => it !== selectedSort)
              .map((it) => (
                <div
                  data-testid='sort-options'
                  onClick={() => handleSort(it)}
                  className='w-full py-3 px-4 cursor-pointer bg-neutral-900'
                  key={it}>
                  <p className='text-sm leading-3 text-neutral-200'>{it}</p>
                </div>
              ))}
        </div>
        <div className='w-full h-[1px] bg-[#1E1E1E] mt-1' />
        <div className='w-full p-4'>
          <Button onClick={implementFilter} type={'primary'}>
            <p>Apply Sort</p>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default BottomSheetSort
