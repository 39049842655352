export const PATH = {
  HOME: '/',
  ALERT: '/alert',
  LOGIN: '/login',
  VIDEO: '/video',
  WATCH: '/watch',
  SEARCH: '/search',
  SETTING: '/setting',
  ANALYTICS: '/analytics',
  UPLOADED: '/uploaded-videos',
  DETAIL: '/detail',
}
