import React from 'react'

import { Button, ButtonLoading, Input } from '~/components'

import ErrToast from '../toast'

const Form = ({
  form,
  errToast,
  errCount,
  isLoading,
  closeToast,
  handleChange,
  handleSubmit,
  checkValidEmail,
  handleForgotPassword,
}) => {
  return (
    <div className='relative w-full md:w-[431px]'>
      {errToast ? (
        <div className='absolute top-[-140px] w-full left-0 px-8 md:px-0'>
          <ErrToast errCount={errCount} closeToast={closeToast} />
        </div>
      ) : (
        <></>
      )}

      <div className='md:border-[1px] md:border-neutral-700 px-9 py-10 mx-0 w-full rounded-lg rounded-tl-none'>
        <form className='w-full' onSubmit={handleSubmit}>
          <p className='font-semibold text-base text-neutral-200 mb-1'>Email</p>
          <Input
            type={'text'}
            name={'email'}
            value={form.email}
            error={form.errEmail}
            empty={form.emptyEmail}
            onChange={handleChange}
            onBlur={checkValidEmail}
            invalidEmail={form.invalidEmail}
          />
          <p className='font-semibold text-base text-neutral-200 mt-6 mb-1'>Password</p>
          <Input
            name={'password'}
            type={'password'}
            value={form.password}
            onChange={handleChange}
            error={form.errPassword}
            empty={form.emptyPassword}
          />
          {/* <p className='text-end'> */}
          {/*   <span */}
          {/*     className='cursor-pointer text-xs font-semibold text-primary-200 underline' */}
          {/*     onClick={() => handleForgotPassword(true)}> */}
          {/*     Forgot Password? */}
          {/*   </span> */}
          {/* </p> */}
          <div className='mt-10'>
            <Button type={'primary'} submit>
              <p>Log In</p>
              {isLoading ? <ButtonLoading /> : <></>}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Form
