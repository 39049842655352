import React from 'react'

function OutlineCloud() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
      <path
        fill='#CCDAE7'
        d='M6.2 10.4a1.4 1.4 0 01-.148-2.792A1.603 1.603 0 017.6 5.6a1.6 1.6 0 011.554 1.217A1.8 1.8 0 119.4 10.4H6.2z'></path>
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M8 13.333A5.333 5.333 0 108 2.667a5.333 5.333 0 000 10.666zm0 1.334A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334z'
        clipRule='evenodd'
        opacity='0.2'></path>
      <path
        fill='url(#paint0_linear_165_13359)'
        fillRule='evenodd'
        d='M8 2.667a5.333 5.333 0 100 10.666.667.667 0 010 1.334A6.667 6.667 0 1114.667 8a.667.667 0 11-1.334 0A5.333 5.333 0 008 2.667z'
        clipRule='evenodd'></path>
      <defs>
        <linearGradient
          id='paint0_linear_165_13359'
          x1='8'
          x2='8'
          y1='8'
          y2='13.333'
          gradientUnits='userSpaceOnUse'>
          <stop stopColor='#fff'></stop>
          <stop offset='1' stopColor='#fff' stopOpacity='0'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default OutlineCloud
