import React from 'react'

import { BellIcon, TelegramIcon, WhatsAppIcon, ChatBubbleIcon } from '~/svg'
import { ALERTNOTIFICATION } from '~/constant'

import { useAlertSetting } from './hooks'
import { AlertSettingPage, IncidentModal, PasswordModal, PriorityModal } from './components'

const AlertsSetting = () => {
  const {
    datas: {
      loopRefInci,
      loopRefPrio,
      colorRef,
      dataAlerts,
      loopOptions,
      formPassword,
      colorOptions,
      formPriority,
      formIncident,
      priorityModal,
      incidentModal,
      passwordModal,
      dropdownLoopState,
      dropdownColorState,
    },
    methods: {
      open,
      chooseLoop,
      chooseColor,
      handleKeyUp,
      getRootProps,
      changeSearch,
      getInputProps,
      submitPassword,
      setDropdownLoop,
      handleChangePass,
      setOverrideAlert,
      setDropdownColor,
      setPriorityModal,
      setincidentModal,
      addNotifications,
      openIncidentModal,
      openPriorityModal,
      openModalPassword,
      closePasswordModal,
      handleChangePriority,
      handleChangeIncident,
      handleSubmitIncident,
      handleSubmitPriority,
    },
  } = useAlertSetting()
  return (
    <div className='flex flex-col w-full md:w-3/4 px-1 md:px-0'>
      <p className='font-semibold text-2xl text-white'>Alerts</p>
      <div className='flex flex-col gap-6 pt-4'>
        {dataAlerts.map((a, akey) => {
          return (
            <AlertSettingPage
              key={akey}
              dataAlerts={a}
              IconNotification={IconNotification}
              openPriorityModal={openPriorityModal}
              openIncidentModal={openIncidentModal}
              openModalPassword={openModalPassword}
            />
          )
        })}
      </div>
      <div
        className='border border-neutral-800 bg-neutral-900 rounded-xl mt-6'
        onClick={() => openPriorityModal(undefined)}>
        <p className='text-neutral-300 cursor-pointer font-semibold py-10 text-center w-full'>
          Add Priority Level
        </p>
      </div>
      <PriorityModal
        loopRef={loopRefPrio}
        colorRef={colorRef}
        chooseLoop={chooseLoop}
        chooseColor={chooseColor}
        loopOptions={loopOptions}
        formPriority={formPriority}
        colorOptions={colorOptions}
        priorityModal={priorityModal}
        setDropdownLoop={setDropdownLoop}
        setPriorityModal={setPriorityModal}
        IconNotification={IconNotification}
        setDropdownColor={setDropdownColor}
        addNotifications={addNotifications}
        dropdownLoopState={dropdownLoopState}
        dropdownColorState={dropdownColorState}
        handleChangePriority={handleChangePriority}
        handleSubmitPriority={handleSubmitPriority}
      />
      <IncidentModal
        open={open}
        loopRef={loopRefInci}
        chooseLoop={chooseLoop}
        loopOptions={loopOptions}
        formIncident={formIncident}
        getRootProps={getRootProps}
        changeSearch={changeSearch}
        incidentModal={incidentModal}
        getInputProps={getInputProps}
        setDropdownLoop={setDropdownLoop}
        setincidentModal={setincidentModal}
        IconNotification={IconNotification}
        setOverrideAlert={setOverrideAlert}
        addNotifications={addNotifications}
        dropdownLoopState={dropdownLoopState}
        openModalPassword={openModalPassword}
        handleChangeIncident={handleChangeIncident}
        handleSubmitIncident={handleSubmitIncident}
      />
      <PasswordModal
        handleKeyUp={handleKeyUp}
        formPassword={formPassword}
        passwordModal={passwordModal}
        submitPassword={submitPassword}
        handleChangePass={handleChangePass}
        closePasswordModal={closePasswordModal}
      />
    </div>
  )
}

const IconNotification = ({ n }) => {
  return (
    <>
      {(() => {
        switch (n) {
          case ALERTNOTIFICATION.APP:
            return <BellIcon />
          case ALERTNOTIFICATION.TELEGRAM:
            return <TelegramIcon />
          case ALERTNOTIFICATION.WHATSAPP:
            return <WhatsAppIcon />
          case ALERTNOTIFICATION.SMS:
            return <ChatBubbleIcon />
          default:
            return
        }
      })()}
    </>
  )
}

export default AlertsSetting
