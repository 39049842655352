import React from 'react'

import { Datepicker, Modal, Toast } from '~/components'

import {
  Preview,
  Sidebar,
  NotFound,
  TopSection,
  VideoItems,
  SaveAndSort,
  SearchShimmer,
  BottomSheetSort,
  BottomSheetFilter,
} from './components'
import { useSearch } from './hooks'

const Search = () => {
  const {
    datas: {
      sorts,
      floors,
      filter,
      counter,
      sources,
      sortRef,
      saveInfo,
      openSort,
      toastView,
      showDatas,
      dateRange,
      timeRange,
      searchType,
      isNotFound,
      isSubscibed,
      previewData,
      previewModal,
      dummyCameras,
      selectedSort,
      searchLoading,
      uploadedFiles,
      showDatePicker,
      expandedFilter,
      confidenceInfo,
      selectedSearch,
      bottomSheetSort,
      bottomSheetFilter,
    },
    methods: {
      openInfo,
      openModal,
      closeInfo,
      closeModal,
      toHomePage,
      handleSort,
      saveSearch,
      closeFiter,
      resetFilter,
      openPreview,
      openExpanded,
      handleFilter,
      selectFloors,
      selectSource,
      setDateRange,
      dateToString,
      setConfidence,
      handleOpenSort,
      implementFilter,
      changeTimeRange,
      openBottomSheetFilter,
    },
  } = useSearch()

  return searchLoading ? (
    <SearchShimmer />
  ) : isNotFound ? (
    <NotFound toHomePage={toHomePage} />
  ) : (
    <>
      <div className='hidden md:block w-full'>
        <div className='w-full flex gap-8 pb-[10vh]'>
          <Sidebar
            filter={filter}
            floors={floors}
            source={sources}
            openInfo={openInfo}
            closeInfo={closeInfo}
            openModal={openModal}
            dateRange={dateRange}
            timeRange={timeRange}
            resetFilter={resetFilter}
            selectFloors={selectFloors}
            selectSource={selectSource}
            dateToString={dateToString}
            dummyCameras={dummyCameras}
            handleFilter={handleFilter}
            setConfidence={setConfidence}
            confidenceInfo={confidenceInfo}
            changeTimeRange={changeTimeRange}
          />
          <main className='w-full pr-[126px] pt-9'>
            <SaveAndSort
              sorts={sorts}
              sortRef={sortRef}
              openInfo={openInfo}
              saveInfo={saveInfo}
              openSort={openSort}
              closeInfo={closeInfo}
              showDatas={showDatas}
              handleSort={handleSort}
              saveSearch={saveSearch}
              selectedSort={selectedSort}
              handleOpenSort={handleOpenSort}
            />
            {/* Video Result */}
            <div className='w-full grid grid-cols-3 gap-6 mt-6'>
              {showDatas &&
                showDatas.map((it, idx) => <VideoItems key={idx} it={it} onClick={openPreview} />)}
            </div>
          </main>
        </div>
      </div>

      {/* Mobile */}
      <div className='block md:hidden w-full pb-[5vh]'>
        <div className='px-4'>
          <TopSection
            openInfo={openInfo}
            saveInfo={saveInfo}
            closeInfo={closeInfo}
            saveSearch={saveSearch}
            handleOpenSort={handleOpenSort}
            openBottomSheetFilter={openBottomSheetFilter}
          />
          {/* Video Result */}
          <div className='w-full grid grid-cols-1 gap-6 mt-6'>
            {showDatas &&
              showDatas.map((it, idx) => <VideoItems key={idx} it={it} onClick={openPreview} />)}
          </div>
        </div>
        {bottomSheetSort ? (
          <BottomSheetSort
            sorts={sorts}
            closeFiter={closeFiter}
            handleSort={handleSort}
            selectedSort={selectedSort}
            implementFilter={implementFilter}
            bottomSheetSort={bottomSheetSort}
          />
        ) : (
          <></>
        )}

        {bottomSheetFilter ? (
          <BottomSheetFilter
            floors={floors}
            source={sources}
            openInfo={openInfo}
            closeInfo={closeInfo}
            dateRange={dateRange}
            openModal={openModal}
            timeRange={timeRange}
            closeFiter={closeFiter}
            resetFilter={resetFilter}
            selectFloors={selectFloors}
            selectSource={selectSource}
            openExpanded={openExpanded}
            dateToString={dateToString}
            dummyCameras={dummyCameras}
            setConfidence={setConfidence}
            expandedFilter={expandedFilter}
            confidenceInfo={confidenceInfo}
            implementFilter={implementFilter}
            changeTimeRange={changeTimeRange}
            bottomSheetFilter={bottomSheetFilter}
          />
        ) : (
          <></>
        )}
      </div>

      {showDatePicker ? (
        <Modal isOpen={showDatePicker} closeModal={closeModal}>
          <Datepicker
            state={dateRange}
            show={showDatePicker}
            setState={setDateRange}
            closeModal={closeModal}
          />
        </Modal>
      ) : (
        <></>
      )}
      <Toast show={toastView.show} type={toastView.type}>
        <div>
          <p className='text-base font-semibold'>Search is successfully saved</p>
          <p className='mt-1 text-base'>You can revisit this search anytime from Home.</p>
        </div>
      </Toast>

      {previewModal ? (
        <Preview
          isOpen={previewModal}
          closeModal={closeModal}
          data={selectedSearch}
          counter={counter}
          isSubscibed={isSubscibed}
          searchType={searchType}
          previewData={previewData}
          uploadedFiles={uploadedFiles}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default Search
