import React from 'react'

function Notification() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        stroke='#EBF6FF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M12.5 14.167h4.167l-1.171-1.171A1.693 1.693 0 0115 11.799V9.167a5.002 5.002 0 00-3.333-4.716v-.284a1.667 1.667 0 10-3.334 0v.284A5.002 5.002 0 005 9.167v2.632c0 .449-.178.88-.496 1.197l-1.17 1.17H7.5m5 0V15a2.5 2.5 0 01-5 0v-.833m5 0h-5'></path>
    </svg>
  )
}

export default Notification
