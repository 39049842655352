import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PATH } from '~/constant'
import { deleteVideoById, editVideoById } from '~/store/slice/video'

export function useVideoItems({ item }) {
  // need to set state seperately for each video item
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus()
    }

    return [htmlElRef, setFocus]
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [thumbnail, setThumbnail] = useState(false)
  const [inputRef, setInputFocus] = useFocus()
  const [stateVideoName, setVideoName] = useState(item.label)
  const [stateSelected, setStateSelected] = useState(false)
  const [stateDeleteModal, setStateDeleteModal] = useState(false)

  function handleUpdateName(e) {
    setStateSelected(false)
    if (e.target.value === '') {
      setVideoName(item.label)
      return
    }

    if (e.target.value === item.label) {
      return
    }

    dispatch(editVideoById({ id: item._id, label: e.target.value }))
  }

  function submitDeleteVideo() {
    dispatch(deleteVideoById({ id: item._id }))
  }

  function clickUploadVideo() {
    // need to restate name because edit name doesnt get name again from backend
    const videoItem = { ...item, label: stateVideoName }
    navigate(PATH.VIDEO, {
      state: videoItem,
    })
  }

  return {
    datas: {
      inputRef,
      thumbnail,
      stateSelected,
      stateVideoName,
      stateDeleteModal,
    },
    methods: {
      setVideoName,
      setThumbnail,
      setInputFocus,
      handleUpdateName,
      setStateSelected,
      clickUploadVideo,
      submitDeleteVideo,
      setStateDeleteModal,
    },
  }
}
