export function checkSuspend(it) {
  return it === 3 || it === 5 || it === 7 || it === 10
}

export function checkTryLeft(it) {
  if (it < 3) {
    return 3 - it
  }
  if (it < 5) {
    return 5 - it
  }
  if (it < 7) {
    return 7 - it
  }
  if (it < 10) {
    return 10 - it
  }
}
