import React from 'react'
import moment from 'moment'

import { CevronRight } from '~/svg'
import { Button, StatusBadges, VideoPlayer } from '~/components'

import HistoryItems from '../history-items'

const MobileAlerts = ({
  notes,
  typeToStr,
  backToList,
  historyLog,
  alertVideoRef,
  selectedAlert,
  openReportModal,
  handleChangeNotes,
  getDifferentTimes,
}) => {
  return (
    <div className='w-full py-9'>
      <div className='flex justify-start items-center gap-2 px-7'>
        <div onClick={backToList} className='rotate-180 cursor-pointer mr-4'>
          <CevronRight height={'20'} />
        </div>
        <p className='text-neutral-200 font-semibold text-[18px] md:text-2xl'>
          {typeToStr(selectedAlert.type)}
        </p>
        <StatusBadges big status={selectedAlert.status} />
      </div>
      <div className='mt-5 w-full px-7'>
        <VideoPlayer
          playerRef={alertVideoRef}
          isMobile
          url={
            'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4'
          }
          accuracy={80}
        />
      </div>
      <div className='mt-4 flex gap-4 px-4'>
        <div>
          <p className='text-neutral-200 text-sm'>Location</p>
          <p className='text-neutral-50 font-semibold mt-2'>{selectedAlert.location}</p>
        </div>
        <div>
          <p className='text-neutral-200 text-sm'>Date, time</p>
          <div className='mt-2 flex items-center gap-4'>
            <div>
              <p className='text-neutral-50 font-semibold text-sm'>
                {moment(new Date(selectedAlert.start_time)).format('DD MMM YYYY')}
              </p>
              <p className='text-neutral-50 font-semibold text-sm mt-[2px]'>
                {moment(new Date(selectedAlert.start_time)).format('HH: mm')}
              </p>
            </div>
            <p className='text-xs text-neutral-400 my-1'>until</p>
            <div>
              <p className='text-neutral-50 font-semibold text-sm'>
                {moment(new Date(selectedAlert.end_time)).format('DD MMM YYYY')}
              </p>
              <p className='text-neutral-50 font-semibold text-sm mt-[2px]'>
                {moment(new Date(selectedAlert.end_time)).format('HH: mm')}
              </p>
            </div>
          </div>
          <p className='text-sm text-neutral-400 mt-1'>{`(${getDifferentTimes(selectedAlert)})`}</p>
        </div>
      </div>
      <div className='mt-4 px-4'>
        <div className='mt-8 text-xs text-neutral-400'>
          <p className='text-neutral-200'>Emergency numbers</p>
          <div className='flex gap-4'>
            <p className='mt-2'>{`Police: ${selectedAlert.emergencies.police}`}</p>
            <p className='mt-2'>{`Ambulance: ${selectedAlert.emergencies.ambulance}`}</p>
            <p className='mt-2'>{`Firefighter: ${selectedAlert.emergencies.firefighter}`}</p>
          </div>
        </div>
      </div>
      <div className='mt-4 px-4'>
        <div className='w-full flex justify-between'>
          <p className='font-semibold text-neutral-200'>Additional notes (optional)</p>
          <p className='text-neutral-400'>{notes.length + '/200'}</p>
        </div>
        <textarea
          id='notes'
          name='notes'
          value={notes}
          onChange={handleChangeNotes}
          placeholder='Describe about the incident, actions taken, and more'
          className='w-full rounded outline-none border-[1px] border-neutral-600 bg-transparent p-3 h-36 text-neutral-200 placeholder:text-neutral-400 mt-1'
        />
      </div>
      <div className='mt-4 px-4'>
        <p className='text-neutral-200'>History log</p>
        <div>{historyLog && historyLog.map((it, idx) => <HistoryItems key={idx} it={it} />)}</div>
      </div>
      <div className='w-full border-t-[1px] border-t-neutral-800 pt-6 px-4 mt-6'>
        <div className='flex justify-center pb-5'>
          <p className='font-semibold text-neutral-300 cursor-pointer'>Track Person</p>
        </div>

        <Button onClick={openReportModal} type={'primary'}>
          <p>Report Incident</p>
        </Button>
      </div>
    </div>
  )
}

export default MobileAlerts
