import React from 'react';

function SearchNotFound() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="230"
      height="214"
      fill="none"
      viewBox="0 0 230 214"
    >
      <path
        fill="#393F44"
        d="M35.949 118.04a42.009 42.009 0 012.238-24.927C42.65 82.076 52.242 74.234 62.986 69.66c15.617-6.648 30.948-6.775 43.64-19.807 3.914-4.019 7.199-8.622 11.207-12.556 11.795-11.594 29.058-10.199 41.951-.997 26.89 19.203 36.563 57.24 35.068 88.682-.669 14.11-7.409 27.242-18.072 36.502-24.115 20.938-65.061 17.418-92.751 6.485-20.672-8.135-43.23-27.165-48.08-49.928z"
        opacity="0.7"
      ></path>
      <path
        fill="#393F44"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M47.57 176.043c-.504-.1-.942-.187-1.31-.262l85.473-12.22h21.787l-6.096 16.537c-20.734 4.297-46.335 3.156-66.785.932a413.706 413.706 0 01-25.572-3.605 342.99 342.99 0 01-7.497-1.382z"
      ></path>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M147.61 180.315l6.273-17.016h-22.168l-86.904 12.437"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M42.275 174.389c-1.415-1.261-2.878-3.189-3.191-5.917l75.42-5.623h16.965v.246l-86.412 13.14a10.427 10.427 0 01-.807-.42 12.132 12.132 0 01-1.975-1.426z"
      ></path>
      <path
        fill="#141618"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M42.275 174.389c-1.415-1.261-2.878-3.189-3.191-5.917l75.42-5.623h16.965v.246l-86.412 13.14a10.427 10.427 0 01-.807-.42 12.132 12.132 0 01-1.975-1.426z"
        opacity="0.1"
      ></path>
      <path
        fill="#B6C3D1"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M131.315 163.117l-.002.001-1.254.193h-.002l-.547.081h-.001l-1.391.212h0l-.611.093h0l-1.546.236-.684.104.038.247-.038-.247-1.746.267h-.001l-.772.116h0l-1.979.301h-.001l-.873.136h0l-2.26.344h0l-.997.15h0l-2.6.387h-.002l-1.159.178h0l-3.03.459h0l-1.356.209h-.001l-3.574.545h0l-1.607.243h-.001l-4.274.65h0l-1.932.297h0l-5.206.793-2.388.363-6.481.985h0l-3.01.46h-.001l-8.29 1.26h0l-3.907.595h0l-10.967 1.67h0l-5.287.804h0l-6.51.991-.022-.007c-.051-.018-.125-.044-.216-.078a11.775 11.775 0 01-.702-.291c-.553-.253-1.146-.588-1.451-.944v-.001c-.541-.63-.747-1.014-.822-1.223a.8.8 0 01-.012-.037l4.338-.531h0l8.174-.997h0l9.634-1.179h0l5.963-.731h0l7.184-.865h.002l4.54-.576h0l5.603-.684h0l3.587-.437h0l4.487-.549h0l2.89-.355h0l3.668-.448v-.001l2.381-.289h.001l3.063-.387h.001l1.993-.243h.001l2.589-.317h0l1.692-.209h.001l2.218-.27-.03-.249.03.249 1.457-.178 1.932-.236 1.268-.154h0l1.685-.205h0l1.109-.136h0l1.488-.185h0l.981-.12h0l1.325-.162h.001l.873-.104.001-.001 1.19-.146v-.001l.771-.096h.002l.249-.029 1.283.01v.247l-.15.022z"
        opacity="0.7"
      ></path>
      <g opacity="0.7">
        <path
          fill="#5A626B"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M56.625 174.474l-4.79.728-.09-2.117-.009-.23-.23-.009-2.298-.095 5.096-.621 2.237.233.084 2.111zM71.503 172.209l-3.41.52-.054-1.77-.007-.226-.225-.016-1.25-.087 3.34-.409 1.559.225.047 1.763zM82.804 170.49l-2.513.383-.032-1.504-.005-.222-.22-.022-.651-.064 2.265-.277 1.126.205.03 1.501zM91.675 169.14l-1.89.288-.019-1.298-.003-.217-.215-.028-.26-.033 1.546-.188.823.177.018 1.299zM98.826 168.05l-1.445.222-.009-1.134-.002-.212-.21-.033-.014-.002 1.061-.131.61.157.009 1.133zM104.709 167.157l-1.108.168-.003-.997-.001-.207-.06-.011.714-.087.455.134.003 1zM109.641 166.404l-.857.132v-1.068l.521-.063.336.111v.888zM113.83 165.766l-.659.101.003-.789.001-.148.408-.05.25.092-.003.794zM117.429 165.22l-.497.076.003-.71v-.115l.318-.039.181.074-.005.714zM120.564 164.743l-.372.057.005-.637.001-.09.247-.03.124.055-.005.645zM123.312 164.324l-.268.041.005-.573v-.066l.187-.023.08.039-.004.582zM125.743 163.953l-.182.028.007-.516.001-.051.131-.016.05.027-.007.528zM127.908 163.624l-.109.017.007-.47v-.029l.089-.011.02.011-.007.482zM129.848 163.33l-.047.007.007-.429v-.013l.047-.005-.007.44z"
        ></path>
        <mask id="path-21-inside-1_300_17120" fill="#fff">
          <path d="M131.715 162.599v.7l-.364.054.012-.68-.162-.074h.514z"></path>
        </mask>
        <path
          fill="#5A626B"
          d="M131.715 162.599v.7l-.364.054.012-.68-.162-.074h.514z"
        ></path>
        <path
          fill="#393F44"
          d="M131.715 162.599h.5v-.5h-.5v.5zm0 .7l.073.494.427-.063v-.431h-.5zm-.364.054l-.5-.009-.01.59.584-.087-.074-.494zm.012-.68l.5.008.006-.329-.3-.135-.206.456zm-.162-.074v-.5l-.206.956.206-.456zm.014 0v.7h1v-.7h-1zm.426.205l-.363.054.147.989.363-.054-.147-.989zm.21.557l.012-.68-1-.017-.012.68 1 .017zm-.282-1.144l-.162-.073-.412.911.162.073.412-.911zm-.368.882h.514v-1h-.514v1z"
          mask="url(#path-21-inside-1_300_17120)"
        ></path>
      </g>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M193.75 173.666l.291.147c-16.796 7.285-37.342 8.121-46.647 8.079-2.42 0-4.08-.074-4.72-.108l.151-.242m50.925-7.876c.1-.042.199-.086.298-.129l-22.743-11.486m22.445 11.615c-16.71 7.187-37.091 8.018-46.355 7.976h-.001a95.02 95.02 0 01-4.569-.1m50.925-7.876l-22.505-11.365m.06-.25h-16.649m16.649 0v.25h-.06m.06-.25l-.113.223.053.027m-16.589-.25l-.074.12-.131.208m.205-.328l.213.13-.073.12m-.14-.25v.25h.14m-.345.078l-.271.433m.271-.433l.212.133.131-.208h0l.002-.003h0m-.345.078l.212.133-.269.431-.214-.131m0 0l-.158.259-.332.53m.49-.789l.212.133-.157.257-.001.002h0l-.331.527-.213-.13m0 0l-.197.32-.418.669m.615-.989l.211.132-.195.319-.001.002-.418.668-.212-.132m0 0l-.255.41m.255-.41l.212.132-.255.41-.212-.132m0 0l-.541.869m.541-.869l.212.132-.541.869-.212-.132m0 0l-.336.541-.734 1.179m1.07-1.72l.212.132-.336.541h0l-.734 1.179-.212-.132m0 0l-.464.746m.464-.746l.212.132-.463.745-.213-.131m0 0l-1.04 1.685-.684 1.097m1.724-2.782l.212.132-1.039 1.684-.001.001-.684 1.097-.212-.132m0 0l-1.615 2.593m1.615-2.593l.212.132-1.615 2.593-.212-.132m0 0l-1.109 1.786-2.81 4.514-.572.916-.375.601.285.015m4.581-7.832l.212.133-1.109 1.785h0l-2.81 4.514h0l-.572.916-.212-.132.212.132-.302.484m28.42-19.241h-16.449"
      ></path>
      <path
        fill="#141618"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M193.75 173.666l.291.147c-16.796 7.285-37.342 8.121-46.647 8.079-2.42 0-4.08-.074-4.72-.108l.151-.242m50.925-7.876c.1-.042.199-.086.298-.129l-22.743-11.486m22.445 11.615c-16.71 7.187-37.091 8.018-46.355 7.976h-.001a95.02 95.02 0 01-4.569-.1m50.925-7.876l-22.505-11.365m.06-.25h-16.649m16.649 0v.25h-.06m.06-.25l-.113.223.053.027m-16.589-.25l-.074.12-.131.208m.205-.328l.213.13-.073.12m-.14-.25v.25h.14m-.345.078l-.271.433m.271-.433l.212.133.131-.208h0l.002-.003h0m-.345.078l.212.133-.269.431-.214-.131m0 0l-.158.259-.332.53m.49-.789l.212.133-.157.257-.001.002h0l-.331.527-.213-.13m0 0l-.197.32-.418.669m.615-.989l.211.132-.195.319-.001.002-.418.668-.212-.132m0 0l-.255.41m.255-.41l.212.132-.255.41-.212-.132m0 0l-.541.869m.541-.869l.212.132-.541.869-.212-.132m0 0l-.336.541-.734 1.179m1.07-1.72l.212.132-.336.541h0l-.734 1.179-.212-.132m0 0l-.464.746m.464-.746l.212.132-.463.745-.213-.131m0 0l-1.04 1.685-.684 1.097m1.724-2.782l.212.132-1.039 1.684-.001.001-.684 1.097-.212-.132m0 0l-1.615 2.593m1.615-2.593l.212.132-1.615 2.593-.212-.132m0 0l-1.109 1.786-2.81 4.514-.572.916-.375.601.285.015m4.581-7.832l.212.133-1.109 1.785h0l-2.81 4.514h0l-.572.916-.212-.132.212.132-.302.484m28.42-19.241h-16.449"
        opacity="0.1"
      ></path>
      <path
        fill="#B6C3D1"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M154.795 162.301l1.052.003-.183.41h0l-.001.003-.111.256-.001.003-.235.527h0l-.143.32h0l-.297.669h-.001l-.181.41h0l-.387.869h0l-.235.53h0l-.526 1.178v.001l-.328.745-.001.001-.749 1.68v.001l-.487 1.097h0l-1.159 2.593h-.001l-.792 1.786h0l-2.005 4.513-.001.001-.779 1.733a93.066 93.066 0 01-4.409-.1l.868-1.4h0l2.81-4.514h0l1.109-1.785h0l1.615-2.593h0l.684-1.098h.001l1.035-1.673v-.001l.464-.745h0l.734-1.179h0l.336-.541h0l.541-.869h0l.255-.41h0l.417-.668.001-.002.196-.319.002-.002.331-.527h0l.001-.002.157-.257.002-.002.268-.431h.001l.131-.208h0l.001-.003h0z"
        opacity="0.7"
      ></path>
      <path
        fill="#5A626B"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M146.427 175.746h3.434l-1.783 4.014h-4.149l2.498-4.014zM149.152 171.367h2.653l-.93 2.093h-3.027l1.304-2.093zM150.883 168.589h2.158l-.526 1.181h-2.367l.735-1.181zM152.082 166.664h1.814l-.303.679h-1.934l.423-.679zM152.959 165.253h1.563l-.164.37h-1.629l.23-.37zM153.631 164.175h1.37l-.075.168h-1.4l.105-.168zM154.16 163.325h1.219l-.013.03h-1.225l.019-.03z"
        opacity="0.7"
      ></path>
      <g opacity="0.7">
        <mask id="path-33-inside-2_300_17120" fill="#fff">
          <path d="M156.07 162.383l-.193.433h-1.697l.271-.433h1.619z"></path>
        </mask>
        <path
          fill="#5A626B"
          d="M156.07 162.383l-.193.433h-1.697l.271-.433h1.619z"
        ></path>
        <path
          fill="#393F44"
          d="M156.07 162.383l.457.204.314-.704h-.771v.5zm-.193.433v.5h.324l.132-.296-.456-.204zm-1.697 0l-.424-.265-.478.765h.902v-.5zm.271-.433v-.5h-.277l-.147.235.424.265zm1.162-.204l-.193.433.913.408.194-.433-.914-.408zm.264.137h-1.697v1h1.697v-1zm-1.273.765l.271-.433-.848-.53-.271.433.848.53zm-.153-.198h1.619v-1h-1.619v1z"
          mask="url(#path-33-inside-2_300_17120)"
        ></path>
      </g>
      <g opacity="0.7">
        <mask id="path-35-inside-3_300_17120" fill="#fff">
          <path d="M156.217 162.054l-.054.12h-1.581l.073-.12h1.562z"></path>
        </mask>
        <path
          fill="#5A626B"
          d="M156.217 162.054l-.054.12h-1.581l.073-.12h1.562z"
        ></path>
        <path
          fill="#393F44"
          d="M156.217 162.054l.455.206.319-.706h-.774v.5zm-.054.12v.5h.323l.132-.294-.455-.206zm-1.581 0l-.426-.261-.467.761h.893v-.5zm.073-.12v-.5h-.28l-.146.239.426.261zm1.106-.206l-.054.12.911.412.054-.12-.911-.412zm.402-.174h-1.581v1h1.581v-1zm-1.155.761l.074-.12-.853-.522-.073.12.852.522zm-.353.119h1.562v-1h-1.562v1z"
          mask="url(#path-35-inside-3_300_17120)"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M156.163 162.175l-8.398 18.906M194.338 173.683l-23.034-11.629h-16.649l-12.274 19.714M38.81 168.242l75.68-5.643h17.225v.7l-86.692 13.194"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M50.364 119.601l.455 40.975H49.26l.45-40.975h.653z"
      ></path>
      <mask
        id="mask0_300_17120"
        style={{ maskType: 'luminance' }}
        width="3"
        height="42"
        x="49"
        y="119"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M50.364 119.601l.455 40.975H49.26l.45-40.975h.653z"
        ></path>
      </mask>
      <g mask="url(#mask0_300_17120)">
        <path
          fill="#141618"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M50.364 119.601l.455 40.975H49.26l.45-40.975h.653z"
          opacity="0.2"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M51.072 160.826h-2.064l.456-41.475h1.148l.46 41.475z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M49.158 159.005h1.763a1.07 1.07 0 011.07 1.071v10.995h-3.907v-10.995a1.073 1.073 0 011.074-1.071z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M51.386 114.665l-.6 4.713H49.29l-.598-4.713h2.694z"
      ></path>
      <mask
        id="mask1_300_17120"
        style={{ maskType: 'luminance' }}
        width="4"
        height="6"
        x="48"
        y="114"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M51.386 114.665l-.6 4.713H49.29l-.598-4.713h2.694z"
        ></path>
      </mask>
      <g mask="url(#mask1_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M51.386 114.665l-.6 4.713H49.29l-.598-4.713h2.694z"
          opacity="0.5"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M51.005 119.628H49.07l-.66-5.213h3.261l-.665 5.213z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M50.221 112.966h-.363l-2.064 1.449h4.487l-2.06-1.449zM90.379 158.978H88.84l.344-30.933h.854l.34 30.933z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M89.227 157.872H90a.824.824 0 01.82.821v7.68h-2.414v-7.68a.822.822 0 01.82-.821z"
      ></path>
      <mask
        id="mask2_300_17120"
        style={{ maskType: 'luminance' }}
        width="4"
        height="10"
        x="88"
        y="157"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M89.227 157.872H90a.824.824 0 01.82.821v7.68h-2.414v-7.68a.822.822 0 01.82-.821z"
        ></path>
      </mask>
      <g mask="url(#mask2_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M89.227 157.872H90a.824.824 0 01.82.821v7.68h-2.414v-7.68a.822.822 0 01.82-.821z"
          opacity="0.2"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M89.227 157.622H90a1.07 1.07 0 011.07 1.071v7.93h-2.914v-7.93a1.069 1.069 0 011.07-1.071z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M90.543 124.612l-.43 3.388H89.11l-.43-3.388h1.862z"
      ></path>
      <mask
        id="mask3_300_17120"
        style={{ maskType: 'luminance' }}
        width="3"
        height="5"
        x="88"
        y="124"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M90.543 124.612l-.43 3.388H89.11l-.43-3.388h1.862z"
        ></path>
      </mask>
      <g mask="url(#mask3_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M90.543 124.612l-.43 3.388H89.11l-.43-3.388h1.862z"
          opacity="0.6"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M90.332 128.25h-1.441l-.495-3.888h2.431l-.495 3.888z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M88.73 124.112l.823-.578h.113l.825.578h-1.76z"
      ></path>
      <mask
        id="mask4_300_17120"
        style={{ maskType: 'luminance' }}
        width="5"
        height="2"
        x="87"
        y="123"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M88.73 124.112l.823-.578h.113l.825.578h-1.76z"
        ></path>
      </mask>
      <g mask="url(#mask4_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M88.73 124.112l.823-.578h.113l.825.578h-1.76z"
          opacity="0.2"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M89.745 123.284h-.271l-1.534 1.078h3.343l-1.538-1.078z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M111.928 135.816l.242 22.085h-.615l.242-22.085h.131z"
      ></path>
      <mask
        id="mask5_300_17120"
        style={{ maskType: 'luminance' }}
        width="2"
        height="24"
        x="111"
        y="135"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M111.928 135.816l.242 22.085h-.615l.242-22.085h.131z"
        ></path>
      </mask>
      <g mask="url(#mask5_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M111.928 135.816l.242 22.085h-.615l.242-22.085h.131z"
          opacity="0.2"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M112.423 158.151h-1.121l.247-22.585h.626l.248 22.585z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M111.287 157.646a.813.813 0 011.388.574v5.261h-1.626v-5.261c0-.215.086-.422.238-.574z"
      ></path>
      <mask
        id="mask6_300_17120"
        style={{ maskType: 'luminance' }}
        width="3"
        height="7"
        x="110"
        y="157"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M111.287 157.646a.813.813 0 011.388.574v5.261h-1.626v-5.261c0-.215.086-.422.238-.574z"
        ></path>
      </mask>
      <g mask="url(#mask6_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M111.287 157.646a.813.813 0 011.388.574v5.261h-1.626v-5.261c0-.215.086-.422.238-.574z"
          opacity="0.5"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M111.862 157.158a1.062 1.062 0 00-1.063 1.062v5.511h2.126v-5.511a1.064 1.064 0 00-1.063-1.062z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M112.467 133.126l-.296 2.341h-.615l-.299-2.341h1.21z"
      ></path>
      <mask
        id="mask7_300_17120"
        style={{ maskType: 'luminance' }}
        width="3"
        height="4"
        x="110"
        y="132"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M112.467 133.126l-.296 2.341h-.615l-.299-2.341h1.21z"
        ></path>
      </mask>
      <g mask="url(#mask7_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M112.467 133.126l-.296 2.341h-.615l-.299-2.341h1.21z"
          opacity="0.8"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M112.391 135.717h-1.055l-.363-2.841h1.778l-.36 2.841z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M111.431 132.626l.41-.288h.042l.41.288h-.862z"
      ></path>
      <mask
        id="mask8_300_17120"
        style={{ maskType: 'luminance' }}
        width="4"
        height="1"
        x="110"
        y="132"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M111.431 132.626l.41-.288h.042l.41.288h-.862z"
        ></path>
      </mask>
      <g mask="url(#mask8_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M111.431 132.626l.41-.288h.042l.41.288h-.862z"
          opacity="0.5"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M111.963 132.088h-.201l-1.121.788h2.442l-1.12-.788z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M125.092 157.285l.002.145.127.071a.579.579 0 01.3.504v4.349h-1.258v-4.349s0 0 0 0a.58.58 0 01.079-.293l-.216-.126.216.126a.578.578 0 01.215-.213l.125-.071.002-.143.197-17.847.003-.253h-.21l-.27-2.131-.005-.036.477-.336h.005l.487.342-.003.03-.27 2.131h-.207l.003.253.201 17.847z"
      ></path>
      <mask
        id="mask9_300_17120"
        style={{ maskType: 'luminance' }}
        width="3"
        height="27"
        x="123"
        y="136"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M125.092 157.285l.002.145.127.071a.579.579 0 01.3.504v4.349h-1.258v-4.349s0 0 0 0a.58.58 0 01.079-.293l-.216-.126.216.126a.578.578 0 01.215-.213l.125-.071.002-.143.197-17.847.003-.253h-.21l-.27-2.131-.005-.036.477-.336h.005l.487.342-.003.03-.27 2.131h-.207l.003.253.201 17.847z"
        ></path>
      </mask>
      <g mask="url(#mask9_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M125.092 157.285l.002.145.127.071a.579.579 0 01.3.504v4.349h-1.258v-4.349s0 0 0 0a.58.58 0 01.079-.293l-.216-.126.216.126a.578.578 0 01.215-.213l.125-.071.002-.143.197-17.847.003-.253h-.21l-.27-2.131-.005-.036.477-.336h.005l.487.342-.003.03-.27 2.131h-.207l.003.253.201 17.847z"
          opacity="0.6"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M125.342 157.282l-.201-17.847h.174l.298-2.35h.278l-.932-.653h-.162l-.927.653h.286l.297 2.35h.178l-.197 17.847a.824.824 0 00-.421.723v4.599h1.758v-4.599a.837.837 0 00-.429-.723zM41.556 173.436l88.628-10.837"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M159.043 153.266l.003.241.241.006a.816.816 0 01.799.829v12.232h-3.89v-12.24a.826.826 0 01.813-.821l.243-.004.003-.243.498-44.251.003-.252h-.473l-.718-5.645-.028-.218h-.116l1.606-1.127h.252l1.606 1.127h-.117l-.028.219-.714 5.644h-.473l.003.252.487 44.251z"
      ></path>
      <mask
        id="mask10_300_17120"
        style={{ maskType: 'luminance' }}
        width="6"
        height="66"
        x="155"
        y="101"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M159.043 153.266l.003.241.241.006a.816.816 0 01.799.829v12.232h-3.89v-12.24a.826.826 0 01.813-.821l.243-.004.003-.243.498-44.251.003-.252h-.473l-.718-5.645-.028-.218h-.116l1.606-1.127h.252l1.606 1.127h-.117l-.028.219-.714 5.644h-.473l.003.252.487 44.251z"
        ></path>
      </mask>
      <g mask="url(#mask10_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M159.043 153.266l.003.241.241.006a.816.816 0 01.799.829v12.232h-3.89v-12.24a.826.826 0 01.813-.821l.243-.004.003-.243.498-44.251.003-.252h-.473l-.718-5.645-.028-.218h-.116l1.606-1.127h.252l1.606 1.127h-.117l-.028.219-.714 5.644h-.473l.003.252.487 44.251z"
          opacity="0.4"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M159.293 153.263l-.487-44.25h.44l.742-5.863h.688l-2.318-1.627h-.41l-2.319 1.627h.688l.746 5.863h.441l-.499 44.25a1.074 1.074 0 00-1.059 1.07v12.491h4.39v-12.479a1.068 1.068 0 00-1.043-1.082z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M158.234 156.838h-3.049l.676-61.267h1.693l.68 61.267z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M154.895 154.397h3.625a.82.82 0 01.58.241l.177-.177-.177.177a.82.82 0 01.241.58v16.503h-5.267v-16.503a.826.826 0 01.507-.758.826.826 0 01.314-.063z"
      ></path>
      <mask
        id="mask11_300_17120"
        style={{ maskType: 'luminance' }}
        width="7"
        height="18"
        x="153"
        y="154"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M154.895 154.397h3.625a.82.82 0 01.58.241l.177-.177-.177.177a.82.82 0 01.241.58v16.503h-5.267v-16.503a.826.826 0 01.507-.758.826.826 0 01.314-.063z"
        ></path>
      </mask>
      <g mask="url(#mask11_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M154.895 154.397h3.625a.82.82 0 01.58.241l.177-.177-.177.177a.82.82 0 01.241.58v16.503h-5.267v-16.503a.826.826 0 01.507-.758.826.826 0 01.314-.063z"
          opacity="0.2"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M154.895 154.147h3.625a1.07 1.07 0 011.071 1.071v16.753h-5.767v-16.753a1.068 1.068 0 01.661-.989c.13-.054.269-.082.41-.082z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M158.831 88.525l-.914 7.202h-2.419l-.914-7.202h4.247z"
      ></path>
      <mask
        id="mask12_300_17120"
        style={{ maskType: 'luminance' }}
        width="6"
        height="8"
        x="154"
        y="88"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M158.831 88.525l-.914 7.202h-2.419l-.914-7.202h4.247z"
        ></path>
      </mask>
      <g mask="url(#mask12_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M158.831 88.525l-.914 7.202h-2.419l-.914-7.202h4.247z"
          opacity="0.6"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M158.137 95.977h-2.859l-.978-7.702h4.815l-.978 7.702z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M154.187 88.024l2.333-1.637h.379l2.33 1.637h-5.042z"
      ></path>
      <mask
        id="mask13_300_17120"
        style={{ maskType: 'luminance' }}
        width="8"
        height="3"
        x="153"
        y="86"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M154.187 88.024l2.333-1.637h.379l2.33 1.637h-5.042z"
        ></path>
      </mask>
      <g mask="url(#mask13_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M154.187 88.024l2.333-1.637h.379l2.33 1.637h-5.042z"
          opacity="0.2"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M156.978 86.137h-.537l-3.045 2.137h6.624l-3.042-2.137z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M155.556 77.12l.91 82.443h-3.622l.911-82.443h1.801z"
      ></path>
      <mask
        id="mask14_300_17120"
        style={{ maskType: 'luminance' }}
        width="5"
        height="84"
        x="152"
        y="76"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M155.556 77.12l.91 82.443h-3.622l.911-82.443h1.801z"
        ></path>
      </mask>
      <g mask="url(#mask14_300_17120)">
        <path
          fill="#141618"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M155.556 77.12l.91 82.443h-3.622l.911-82.443h1.801z"
          opacity="0.2"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M156.719 159.813h-4.127l.915-82.943h2.296l.916 82.943z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M151.822 156.172h5.666a1.07 1.07 0 011.071 1.071v23.072h-7.807v-23.068a1.07 1.07 0 011.07-1.075z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M157.634 67.242l-1.262 9.93h-3.432l-1.262-9.93h5.956z"
      ></path>
      <mask
        id="mask15_300_17120"
        style={{ maskType: 'luminance' }}
        width="7"
        height="12"
        x="151"
        y="66"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
          d="M157.634 67.242l-1.262 9.93h-3.432l-1.262-9.93h5.956z"
        ></path>
      </mask>
      <g mask="url(#mask15_300_17120)">
        <path
          fill="#B6C3D1"
          stroke="#393F44"
          strokeWidth="0.5"
          d="M157.634 67.242l-1.262 9.93h-3.432l-1.262-9.93h5.956z"
          opacity="0.5"
        ></path>
      </g>
      <path
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M156.592 77.423h-3.873l-1.325-10.43h6.523l-1.325 10.43z"
      ></path>
      <path
        fill="#7B8691"
        stroke="#393F44"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M155.019 64.097h-.727l-4.124 2.895h8.97l-4.119-2.895z"
      ></path>
      <g opacity="0.7">
        <mask id="path-111-inside-4_300_17120" fill="#fff">
          <path d="M118.668 168.423l-3.85 1.009h1.407l3.594-1.009h-1.151z"></path>
        </mask>
        <path
          fill="#B6C3D1"
          d="M118.668 168.423l-3.85 1.009h1.407l3.594-1.009h-1.151z"
        ></path>
        <path
          fill="#393F44"
          d="M118.668 168.423v-.5h-.065l-.062.017.127.483zm-3.85 1.009l-.126-.484.126.984v-.5zm1.407 0v.5h.069l.066-.019-.135-.481zm3.594-1.009l.135.482-.135-.982v.5zm-1.278-.483l-3.849 1.008.253.968 3.849-1.009-.253-.967zm-3.723 1.992h1.407v-1h-1.407v1zm1.542-.019l3.594-1.008-.27-.963-3.594 1.009.27.962zm3.459-1.99h-1.151v1h1.151v-1z"
          mask="url(#path-111-inside-4_300_17120)"
        ></path>
      </g>
      <path
        fill="#B6C3D1"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M105.455 172.193h-.179l2.738-.716-2.559.716zM73.404 181.184l-3.627 1.017-1.674-.265 2.876-.752h2.425zM95.358 175.027l-6.351 1.78h-1.33l6.792-1.78h.89z"
        opacity="0.7"
      ></path>
      <g opacity="0.7">
        <mask id="path-116-inside-5_300_17120" fill="#fff">
          <path d="M125.729 166.572l-2.822.742h.878l2.635-.742h-.691z"></path>
        </mask>
        <path
          fill="#B6C3D1"
          d="M125.729 166.572l-2.822.742h.878l2.635-.742h-.691z"
        ></path>
        <path
          fill="#393F44"
          d="M125.729 166.572v-.5h-.065l-.063.017.128.483zm-2.822.742l-.127-.483.127.983v-.5zm.878 0v.5h.069l.066-.018-.135-.482zm2.635-.742l.136.482-.136-.982v.5zm-.819-.483l-2.821.742.255.967 2.821-.742-.255-.967zm-2.694 1.725h.878v-1h-.878v1zm1.013-.018l2.636-.742-.271-.963-2.636.742.271.963zm2.5-1.724h-.691v1h.691v-1z"
          mask="url(#path-116-inside-5_300_17120)"
        ></path>
      </g>
      <g opacity="0.7">
        <mask id="path-118-inside-6_300_17120" fill="#fff">
          <path d="M135.247 164.08l-1.704.444h.181l1.593-.444h-.07z"></path>
        </mask>
        <path
          fill="#B6C3D1"
          d="M135.247 164.08l-1.704.444h.181l1.593-.444h-.07z"
        ></path>
        <path
          fill="#393F44"
          d="M135.247 164.08v-.5h-.064l-.062.016.126.484zm-1.704.444l-.126-.484.126.984v-.5zm.181 0v.5h.069l.066-.018-.135-.482zm1.593-.444l.134.481-.134-.981v.5zm-.196-.484l-1.704.444.252.968 1.704-.445-.252-.967zm-1.578 1.428h.181v-1h-.181v1zm.316-.018l1.592-.445-.269-.963-1.592.444.269.964zm1.458-1.426h-.07v1h.07v-1z"
          mask="url(#path-118-inside-6_300_17120)"
        ></path>
      </g>
      <g opacity="0.7">
        <mask id="path-120-inside-7_300_17120" fill="#fff">
          <path d="M131.07 165.173l-2.157.565h.483l2.014-.565h-.34z"></path>
        </mask>
        <path
          fill="#B6C3D1"
          d="M131.07 165.173l-2.157.565h.483l2.014-.565h-.34z"
        ></path>
        <path
          fill="#393F44"
          d="M131.07 165.173v-.5h-.065l-.062.017.127.483zm-2.157.565l-.126-.484.126.984v-.5zm.483 0v.5h.069l.066-.019-.135-.481zm2.014-.565l.135.482-.135-.982v.5zm-.467-.483l-2.156.564.253.967 2.156-.564-.253-.967zm-2.03 1.548h.483v-1h-.483v1zm.618-.019l2.014-.564-.27-.963-2.014.564.27.963zm1.879-1.546h-.34v1h.34v-1z"
          mask="url(#path-120-inside-7_300_17120)"
        ></path>
      </g>
      <path
        fill="#B6C3D1"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M133.314 171.24l-1.49.953h-.39l1.651-.953h.229zM127.397 175.027l-2.785 1.78h-1.148l3.077-1.78h.856z"
        opacity="0.7"
      ></path>
      <g opacity="0.7">
        <mask id="path-124-inside-8_300_17120" fill="#fff">
          <path d="M137.446 168.423l-1.743 1.009h.901l1.576-1.009h-.734z"></path>
        </mask>
        <path
          fill="#B6C3D1"
          d="M137.446 168.423l-1.743 1.009h.901l1.576-1.009h-.734z"
        ></path>
        <path
          fill="#393F44"
          d="M137.446 168.423v-.5h-.134l-.116.068.25.432zm-1.743 1.009l-.25-.433-1.612.933h1.862v-.5zm.901 0v.5h.146l.123-.079-.269-.421zm1.576-1.009l.27.422 1.44-.922h-1.71v.5zm-.984-.432l-1.743 1.008.501.866 1.743-1.009-.501-.865zm-1.493 1.941h.901v-1h-.901v1zm1.17-.079l1.577-1.008-.539-.843-1.577 1.009.539.842zm1.307-1.93h-.734v1h.734v-1z"
          mask="url(#path-124-inside-8_300_17120)"
        ></path>
      </g>
      <path
        fill="#B6C3D1"
        stroke="#393F44"
        strokeWidth="0.5"
        d="M117.77 181.184l-5.599 3.577h-2.45l6.185-3.577h1.864z"
        opacity="0.7"
      ></path>
      <g opacity="0.7">
        <mask id="path-127-inside-9_300_17120" fill="#fff">
          <path d="M140.642 166.572l-1.275.742h.549l1.159-.742h-.433z"></path>
        </mask>
        <path
          fill="#B6C3D1"
          d="M140.642 166.572l-1.275.742h.549l1.159-.742h-.433z"
        ></path>
        <path
          fill="#393F44"
          d="M140.642 166.572v-.5h-.135l-.116.068.251.432zm-1.275.742l-.252-.432-1.602.932h1.854v-.5zm.549 0v.5h.146l.123-.079-.269-.421zm1.159-.742l.27.421 1.439-.921h-1.709v.5zm-.684-.432l-1.276.742.503.864 1.276-.742-.503-.864zm-1.024 1.674h.549v-1h-.549v1zm.818-.079l1.16-.742-.539-.842-1.16.742.539.842zm.89-1.663h-.433v1h.433v-1z"
          mask="url(#path-127-inside-9_300_17120)"
        ></path>
      </g>
      <g opacity="0.7">
        <mask id="path-129-inside-10_300_17120" fill="#fff">
          <path d="M144.955 164.08l-.773.444h.093l.699-.444h-.019z"></path>
        </mask>
        <path
          fill="#B6C3D1"
          d="M144.955 164.08l-.773.444h.093l.699-.444h-.019z"
        ></path>
        <path
          fill="#393F44"
          d="M144.955 164.08v-.5h-.133l-.116.066.249.434zm-.773.444l-.249-.433-1.623.933h1.872v-.5zm.093 0v.5h.145l.123-.078-.268-.422zm.699-.444l.269.422 1.451-.922h-1.72v.5zm-.268-.434l-.773.445.498.866.773-.444-.498-.867zm-.524 1.378h.093v-1h-.093v1zm.361-.078l.7-.444-.537-.844-.699.444.536.844zm.431-1.366h-.019v1h.019v-1z"
          mask="url(#path-129-inside-10_300_17120)"
        ></path>
      </g>
      <g opacity="0.7">
        <mask id="path-131-inside-11_300_17120" fill="#fff">
          <path d="M143.062 165.173l-.978.565h.294l.885-.565h-.201z"></path>
        </mask>
        <path
          fill="#B6C3D1"
          d="M143.062 165.173l-.978.565h.294l.885-.565h-.201z"
        ></path>
        <path
          fill="#393F44"
          d="M143.062 165.173v-.5h-.134l-.116.067.25.433zm-.978.565l-.25-.433-1.617.933h1.867v-.5zm.294 0v.5h.146l.122-.079-.268-.421zm.885-.565l.268.422 1.446-.922h-1.714v.5zm-.451-.433l-.978.565.5.866.978-.565-.5-.866zm-.728 1.498h.294v-1h-.294v1zm.562-.079l.885-.564-.537-.843-.885.564.537.843zm.617-1.486h-.201v1h.201v-1z"
          mask="url(#path-131-inside-11_300_17120)"
        ></path>
      </g>
      <path
        stroke="#141618"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M149.496 57.547s-.093.336-.279.924"
      ></path>
      <path
        stroke="#141618"
        strokeDasharray="5.1 5.1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M148.588 60.334c-2.288 6.334-8.955 21.02-20.699 17.8-14.883-4.077-.41-12.637 3.864-4.688 4.054 7.532-16.231 13.963-27.547 10.118"
      ></path>
      <path
        stroke="#141618"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
        d="M103.286 83.22a21.01 21.01 0 01-.881-.386"
      ></path>
      <path
        fill="#7B8691"
        stroke="#141618"
        strokeWidth="0.5"
        d="M99.904 82.277h-.001 0s0 0 0 0zm-.13.018a1.498 1.498 0 01-.913-.229c-.506-.32-1.104-1.115-1.191-3.102a2.406 2.406 0 011.819.583h0l.007.006c.458.373.547 1.045.485 1.707a5.296 5.296 0 01-.208 1.035zM121.101 90.989v-.001h0zm-.054-.12a1.49 1.49 0 01-.044-.938c.161-.575.751-1.374 2.633-2.028a2.386 2.386 0 01-.037 1.907h0l-.003.007c-.227.545-.845.823-1.498.954a5.308 5.308 0 01-1.051.098zM144.537 64.148h0zm.015.13a1.494 1.494 0 01-.249.907c-.33.498-1.138 1.077-3.127 1.118a2.38 2.38 0 01.624-1.804h0l.006-.007c.384-.448 1.058-.522 1.717-.445a5.3 5.3 0 011.029.231z"
      ></path>
    </svg>
  );
}

export default SearchNotFound;