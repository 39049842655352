import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { clearAllSessionStorage, history, trimStr } from '~/utils'
import { initLoading, resetcameraStore } from '~/store/slice/camera'
import {
  loginAsync,
  resetToken,
  resetDisable,
  closeErrToast,
  getIpAddressAsync,
  setIsForgotPassword,
  sendOTPAsync,
  verifyOTPAsync,
  setNewPasswordAsync,
} from '~/store/slice/auth'

export function useLogin() {
  history.navigate = useNavigate()
  history.location = useLocation()

  const dispatch = useDispatch()

  const errCount = useSelector((state) => state.auth.errCount)
  const errToast = useSelector((state) => state.auth.errToast)
  const isDenied = useSelector((state) => state.auth.isDenied)
  const isLoading = useSelector((state) => state.auth.isLoading)
  const isSuspend = useSelector((state) => state.auth.isSuspend)
  const suspendUntil = useSelector((state) => state.auth.suspendUntil)
  const isForgotPassword = useSelector((state) => state.auth.isForgotPassword)
  const isVerifyOtp = useSelector((state) => state.auth.isVerifyOtp)
  const isInvalidOtp = useSelector((state) => state.auth.isInvalidOtp)
  const isErrGeneral = useSelector((state) => state.auth.isErrGeneral)
  const isNewPassword = useSelector((state) => state.auth.isNewPassword)
  const newPasswordSuccess = useSelector((state) => state.auth.newPasswordSuccess)

  const [otp, setOTP] = useState(['', '', '', '', '', ''])

  const [form, setForm] = useState({
    email: '',
    password: '',
    errEmail: false,
    emptyEmail: false,
    errPassword: false,
    invalidEmail: false,
    emptyPassword: false,
  })

  function checkError(value) {
    const regExp = /[a-zA-Z]/g
    return value === '' || !regExp.test(value)
  }

  function checkValidEmail() {
    if (form.email === '') {
      return
    }
    // eslint-disable-next-line no-useless-escape
    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const isValid = regExp.test(form.email)
    setForm((prevState) => ({
      ...prevState,
      invalidEmail: !isValid,
    }))
  }

  function cleanError(name) {
    switch (name) {
      case 'password':
        setForm((prevState) => ({
          ...prevState,
          errPassword: false,
          emptyPassword: false,
        }))
        break
      default:
        setForm((prevState) => ({
          ...prevState,
          errEmail: false,
          emptyEmail: false,
          invalidEmail: false,
        }))
        break
    }
  }

  function emptyMessage(name) {
    switch (name) {
      case 'password':
        setForm((prevState) => ({
          ...prevState,
          emptyPassword: true,
        }))
        break
      default:
        setForm((prevState) => ({
          ...prevState,
          emptyEmail: true,
        }))
        break
    }
  }

  function handleChange(e) {
    const { name, value } = e.target
    const regExp = /[a-zA-Z]/g

    cleanError(name)

    setForm((prevState) => ({
      ...prevState,
      [name]:
        regExp.test(value) && value.charAt(0) === ' ' && name !== 'password'
          ? trimStr(value)
          : value,
    }))

    if (value === '') {
      emptyMessage(name)
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    setForm((prevState) => ({
      ...prevState,
      emptyEmail: form.email === '',
      errEmail: checkError(form.email),
      emptyPassword: form.password === '',
    }))

    if (form.email === '' || form.password === '' || checkError(form.email)) {
      return
    }

    dispatch(loginAsync({ email: form.email, password: form.password }))
    dispatch(initLoading())
  }

  function closeToast() {
    dispatch(closeErrToast())
  }

  function backToLogin() {
    dispatch(resetDisable())
  }

  function handleForgotPassword() {
    dispatch(setIsForgotPassword(true))
  }

  function handleSendOTP() {
    setOTP(['', '', '', '', '', ''])
    dispatch(sendOTPAsync({ email: form.email }))
  }

  function handleVerifyOTP() {
    dispatch(verifyOTPAsync({ email: form.email, otp: otp.join('') }))
  }

  function handleNewPassword(password) {
    dispatch(setNewPasswordAsync({ email: form.email, otp: otp.join(''), password: password }))
  }

  useEffect(() => {
    if (errToast) {
      setForm((prevState) => ({
        ...prevState,
        errEmail: true,
        errPassword: true,
      }))
    }
  }, [errToast])

  useEffect(() => {
    if (form.email === '') {
      backToLogin()
    }
    clearAllSessionStorage()
    dispatch(resetcameraStore())
    dispatch(getIpAddressAsync())
    dispatch(resetToken())
  }, [])

  useEffect(() => {
    if (errToast) {
      setTimeout(() => {
        closeToast()
      }, 5000)
    }
  }, [errToast])

  return {
    datas: { form, isLoading, errToast, errCount, suspendUntil, isDenied, isSuspend, isForgotPassword, otp, isVerifyOtp, isNewPassword, isInvalidOtp, isErrGeneral, newPasswordSuccess },
    methods: { handleChange, handleSubmit, closeToast, checkValidEmail, backToLogin, handleForgotPassword, setOTP, handleSendOTP, handleVerifyOTP, handleNewPassword },
  }
}
