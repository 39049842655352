import React from 'react'

import Checklist from './check'

const Toast = ({ type, children, show }) => {
  return (
    <div className={`fixed bottom-0 left-0 bg-transparent w-full z-50 ${show ? '' : 'hidden'}`}>
      <div className='relative w-full flex justify-center'>
        <div
          className={`rounded-md absolute bottom-[80px] w-fit px-4 py-2 border-[2px] flex gap-2 items-start justify-start select-none backdrop-blur-md ${
            type === 'info'
              ? 'border-[#105F9C] bg-[#105F9C66] text-[#EBF6FF]'
              : type === 'success'
              ? 'border-[#18890E] bg-[#18890E66] text-[#EAFCE8]'
              : 'bg-[#FFFFFF] border-neut-300 text-neut-900'
          }`}>
          <div>
            <Checklist
              color={type === 'info' ? '#ABDAFE' : type === 'success' ? '#B0FCA9' : '#FFFFFF'}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Toast
