import React from 'react'

import { Upload } from '~/svg'

const UploadVideo = ({
  uploadedFiles,
  open,
  removeFile,
  getRootProps,
  getInputProps,
  submitVideo,
}) => {
  return (
    <div className='w-full'>
      {uploadedFiles.length <= 0 ? (
        <div className='w-full md:w-1/2 mx-auto rounded-lg mt-1 bg-neutral-800 p-2'>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className='w-full bg-neutral-900 border-[2px] border-neutral-700 border-dashed rounded-xl flex flex-col justify-center items-center py-12 gap-4 relative'>
              <div onClick={open} className='cursor-pointer'>
                <Upload />
              </div>
              <p className='text-normal text-neutral-200 text-sm'>
                <span onClick={open} className='text-primary-200 font-semibold cursor-pointer'>
                  Upload
                </span>{' '}
                a video to search
              </p>
              <p className='text-xs text-neutral-200'>Accepts .mp4, .mkv, and .mov</p>
            </div>
          </div>
        </div>
      ) : (
        <div className='w-full md:w-1/2 mx-auto text-center'>
          <video className='w-full h-full' controls>
            <source src={uploadedFiles} type='video/mp4' />
            Sorry Your Browser Is Not Supported
          </video>
          <p className='items-center text-neutral-200 pt-6'>
            <span
              className='underline text-primary-200 font-semibold cursor-pointer'
              onClick={submitVideo}>
              Confirm Video
            </span>{' '}
            or{' '}
            <span
              className='underline text-primary-50 font-semibold cursor-pointer'
              onClick={removeFile}>
              Delete
            </span>
          </p>
        </div>
      )}
    </div>
  )
}

export default UploadVideo
