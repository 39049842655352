import React from 'react'

import { SETTINGS } from '~/constant'
import { BellIcon, CevronDown, CogIcon, UserIcon, VideoCameraIcon } from '~/svg'

import { useMenuSetting } from './hooks'

const SettingMenu = ({ setSetting, settingMenus, settingState }) => {
  const {
    datas: { dropdownSetting, dropdownRef },
    methods: { setDropdown },
  } = useMenuSetting()
  return (
    <>
      <div
        data-testid='dropdown-setting'
        className='w-full h-fit rounded-xl flex border px-6 py-3 border-neutral-800 flex-row md:hidden justify-between text-neutral-200 cursor-pointer relative'
        onClick={() => setDropdown(!dropdownSetting)}>
        <div className='flex flex-row gap-3'>
          <IconSettings isSelected={false} s={settingState} />
          {settingState}
        </div>
        <CevronDown customSize={'h-6 w-6'} />

        <div
          ref={dropdownRef}
          className='absolute block md:hidden top-14 left-0 z-20 text-primary-50 w-full rounded-lg overflow-hidden'>
          {dropdownSetting ? (
            settingMenus.map((s, idx) => {
              const isSelected = settingState == s
              return (
                <div
                  data-testid='setting-menu-mobile'
                  className={`p-2 gap-2 flex flex-row cursor-pointer ${
                    isSelected ? 'bg-[#0B4470] font-semibold' : ' bg-basic-black hover:bg-[#393F44]'
                  }`}
                  key={idx}
                  onClick={() => setSetting(s)}>
                  <IconSettings isSelected={isSelected} s={s} key={idx} />
                  {s}
                </div>
              )
            })
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='hidden md:flex-col md:flex md:border-2 border-neutral-800 text-primary-50 md:rounded-lg w-48 overflow-clip h-fit'>
        {settingMenus.map((s, idx) => {
          const isSelected = settingState == s
          return (
            <div
              data-testid='setting-menu'
              className={`p-2 gap-2 flex flex-row cursor-pointer ${
                isSelected ? 'bg-[#0B4470] font-semibold' : (s === SETTINGS.ACCOUNTS ? ' bg-basic-black hover:bg-[#393F44] text-gray-500' : ' bg-basic-black hover:bg-[#393F44]')
              }`}
              key={idx}
              onClick={() => {s === SETTINGS.ACCOUNTS ? false : setSetting(s)}}>
              <IconSettings isSelected={isSelected} s={s} key={idx} />
              {s}
            </div>
          )
        })}
      </div>
    </>
  )
}

const IconSettings = ({ s, isSelected }) => {
  return (
    <>
      {(() => {
        switch (s) {
          case SETTINGS.ACCOUNTS:
            return <UserIcon isSelected={isSelected} />
          case SETTINGS.ALERTS:
            return <BellIcon isSelected={isSelected} />
          case SETTINGS.CAMERAS:
            return <VideoCameraIcon isSelected={isSelected} />
            case SETTINGS.AREA_SETTINGS:
              return <VideoCameraIcon isSelected={isSelected} />
          case SETTINGS.SYSTEM:
            return <CogIcon isSelected={isSelected} />
          case SETTINGS.LOITERING:
              return <CogIcon isSelected={isSelected} />
          default:
            return
        }
      })()}
    </>
  )
}

export default SettingMenu
