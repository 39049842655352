import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { axios } from '~/utils'
import { store } from '..'
import { defaultPagination } from '~/constant/default'

const initialState = {
  keyword: '',
  videoId: '',
  videoDetail: {},
  videoPath: '',
  videoType: '',
  savedVideos: [],
  isLoading: false,
  estimateData: [],
  uploadedFiles: [],
  progressUpload: 0,
  videoFrameLimit: 0,
  fileUploadSearch: [],
  submitLoading: false,
  videoSearchResult: [],
  isLoadingSearchVideo: false,
  savedVideoPagination: defaultPagination,
  counter: {
    headCount: 0,
    estimation: 0,
  },
}

const baseUrl = process.env.BASE_URL

export const getVideoSearchHistory = createAsyncThunk(
  'getVideoSearchHistory/get',
  async (args, { getState, rejectWithValue }) => {
    const {
      video: { videoId },
    } = getState()

    try {
      const response = await axios.get(`${baseUrl}/video/history/${videoId}`)

      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const postVideoAlert = createAsyncThunk(
  'submit_video/post',
  async (args, { getState, rejectWithValue, dispatch }) => {
    const {
      video: { fileUploadSearch, savedVideos },
    } = getState()

    try {
      const formData = new FormData()
      formData.append('file', fileUploadSearch)

      const response = await axios.post(`${baseUrl}/video`, formData, {
        timeout: 6000000,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.ceil((progressEvent.loaded / progressEvent.total) * 100)

          dispatch(videoSlice.actions.uploadProgressVideo({ progress }))
        },
      })
      return { data: response.data, savedVideos }
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const getSearchVideoAsync = createAsyncThunk(
  'search/get',
  async (args, { getState, rejectWithValue }) => {
    const {
      video: { keyword, uploadedFiles, videoPath, videoType, videoId, videoFrameLimit },
    } = getState()

    try {
      const formData = new FormData()
      formData.append('keyword', keyword)
      formData.append('image', uploadedFiles[0])
      formData.append('videoPath', videoPath)
      formData.append('videoType', videoType)
      formData.append('videoId', videoId)
      formData.append('frameLimit', videoFrameLimit)

      const response = await axios.post(`${baseUrl}/searchVideo`, formData)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const getSavedVideoAsync = createAsyncThunk(
  'saved_video/get',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/video?keyword=${args.keyword}&page=${args.page}&limit=${args.limit}`,
      )
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const editVideoById = createAsyncThunk(
  'edit_video/post',
  async (args, { rejectWithValue }) => {
    const { id, label } = args
    try {
      const response = await axios.post(`${baseUrl}/video/${id}`, { label })
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message }
      return rejectWithValue(message)
    }
  },
)

export const deleteVideoById = createAsyncThunk(
  'delete_video/post',
  async (args, { rejectWithValue }) => {
    const { id } = args
    try {
      const response = await axios.post(`${baseUrl}/video/delete/${id}`)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message }
      return rejectWithValue(message)
    }
  },
)

export const getEstimeteSavedVideoAsync = createAsyncThunk(
  'estimate_saved_video/get',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/video/${args.id}/estimate`)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const getVideoDetails = createAsyncThunk(
  'video_detail/get',
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/video/${args.videoId}`)
      return response.data
    } catch (err) {
      const message = { ...err.response.data.message, ...args }
      return rejectWithValue(message)
    }
  },
)

export const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    initVideoLoading: (state) => {
      state.savedVideos = []
      state.videoDetail = {}
      state.isLoading = true
      state.progressUpload = 0
      state.savedVideoPagination = defaultPagination
    },
    uploadVideoSearch: (state, action) => {
      state.fileUploadSearch = action.payload.files
    },
    resetVideoStore: () => initialState,
    updateKeywordVideo: (state, action) => {
      state.keyword = action.payload.keyword
      state.uploadedFiles = action.payload.files
    },
    updateVideoInfo: (state, action) => {
      state.videoPath = action.payload.videoPath
      state.videoType = action.payload.videoType
      state.videoId = action.payload.videoId
      state.videoFrameLimit = action.payload.videoFrameLimit
    },
    uploadProgressVideo: (state, action) => {
      state.progressUpload = action.payload.progress
    },
    setCounter: (state, action) => {
      state.counter = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(postVideoAlert.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(postVideoAlert.fulfilled, (state) => {
        setTimeout(() => {
          store.dispatch(getSavedVideoAsync({ keyword: '' }))
        })
        state.progressUpload = 0
        state.submitLoading = false
      })
      .addCase(postVideoAlert.rejected, (state) => {
        state.submitLoading = false
      })

    builder
      .addCase(getVideoSearchHistory.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getVideoSearchHistory.fulfilled, (state, action) => {
        state.videoSearchResult = action.payload.data
        state.isLoading = false
      })
      .addCase(getVideoSearchHistory.rejected, (state) => {
        state.isLoading = false
      })

    builder
      .addCase(getSearchVideoAsync.pending, (state) => {
        state.isLoadingSearchVideo = true
      })
      .addCase(getSearchVideoAsync.fulfilled, (state, action) => {
        state.isLoadingSearchVideo = false
        state.videoSearchResult = [action.payload.data, ...state.videoSearchResult]
      })
      .addCase(getSearchVideoAsync.rejected, (state) => {
        state.isLoadingSearchVideo = false
      })

    builder
      .addCase(getSavedVideoAsync.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getSavedVideoAsync.fulfilled, (state, action) => {
        state.isLoading = false
        state.savedVideos = action.payload.results
        state.savedVideoPagination = action.payload.pagination
      })
      .addCase(getSavedVideoAsync.rejected, (state) => {
        state.isLoading = false
      })

    builder
      .addCase(deleteVideoById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteVideoById.fulfilled, (state) => {
        setTimeout(() => {
          store.dispatch(getSavedVideoAsync({ keyword: '' }))
        })
        state.isLoading = false
      })
      .addCase(deleteVideoById.rejected, (state) => {
        state.isLoading = false
      })

    builder
      .addCase(getEstimeteSavedVideoAsync.pending, (state) => {
        state.isLoadingSearchVideo = true
      })
      .addCase(getEstimeteSavedVideoAsync.fulfilled, (state, action) => {
        state.isLoadingSearchVideo = false
        state.estimateData = action.payload.data
      })
      .addCase(getEstimeteSavedVideoAsync.rejected, (state) => {
        state.isLoadingSearchVideo = false
      })

    builder
      .addCase(getVideoDetails.pending, (state) => {
        state.isLoadingVideo = true
      })
      .addCase(getVideoDetails.fulfilled, (state, action) => {
        state.isLoadingVideo = false
        state.videoDetail = action.payload.saved_search[0]
      })
      .addCase(getVideoDetails.rejected, (state) => {
        state.isLoadingVideo = false
      })
  },
})

export const {
  uploadVideoSearch,
  initVideoLoading,
  resetVideoStore,
  updateKeywordVideo,
  updateVideoInfo,
  setCounter,
} = videoSlice.actions
export default videoSlice.reducer
