import React from 'react'
import { Switch } from '@headlessui/react'

const SwitchToggle = ({ checked, onChange }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={`${
        checked ? 'bg-neutral-100' : 'bg-neutral-900'
      } border border-neutral-700 relative inline-flex h-8 w-[52px] items-center rounded-full`}>
      <span
        className={`${
          checked ? 'translate-x-6 bg-neutral-900' : 'translate-x-1 bg-neutral-50'
        } inline-block h-6 w-6 transform rounded-full  transition`}
      />
    </Switch>
  )
}

export default SwitchToggle
