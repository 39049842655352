import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { KeyConstant, PATH } from "~/constant";
import { axios, checkSuspend, getOS, history, pureAxios, setSessionStorage } from "~/utils";
import { store } from "..";
import { getProfileAsync } from "./account";

const initialState = {
  token: "",
  errCount: 0,
  location: "",
  ipAddress: "",
  suspendUntil: 0,
  isDenied: false,
  errToast: false,
  isLoading: false,
  isSuspend: false,
  isVerifyOtp: false,
  isInvalidOtp: false,
  isErrGeneral: false,
  isNewPassword: false,
  isErrCredentials: false,
  isForgotPassword: false,
  newPasswordSuccess: false,
};

const baseUrl = process.env.BASE_URL;
const ipUrl = process.env.IP_ADRESS_URL;

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (args, { rejectWithValue, getState }) => {
    const {
      auth: { ipAddress, location },
    } = getState();

    try {
      const response = await axios.post(`${baseUrl}/auth/login`, {
        device: getOS(),
        address: ipAddress,
        username: args.email,
        password: args.password,
        city: location.split(",")[0],
        country: location.split(", ")[1],
      });
      return { ipAddress, ...response.data };
    } catch (err) {
      const message = { ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const getIpAddressAsync = createAsyncThunk(
  "auth/ip_adress",
  async (args, { rejectWithValue, getState }) => {
    const {
      auth: { ipAddress },
    } = getState();

    try {
      const res = await pureAxios.get(ipUrl);
      return { ipAddress, ...res.data };
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const sendOTPAsync = createAsyncThunk("auth/send_otp", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${baseUrl}/auth/send-otp`, {
      email: args.email,
    });
    return response.data;
  } catch (err) {
    const message = { status: err.response.status, ...err.response.data.message, ...args };
    return rejectWithValue(message);
  }
});

export const verifyOTPAsync = createAsyncThunk(
  "auth/verify_otp",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/auth/verify-otp`, {
        email: args.email,
        otp: args.otp,
      });
      return response.data;
    } catch (err) {
      const message = { status: err.response.status, ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const setNewPasswordAsync = createAsyncThunk(
  "auth/new_password",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/auth/new-password`, {
        email: args.email,
        otp: args.otp,
        password: args.password,
      });
      return response.data;
    } catch (err) {
      const message = { status: err.response.status, ...err.response.data.message, ...args };
      return rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    closeErrToast: (state) => {
      state.errToast = false;
    },
    resetDisable: (state) => {
      state.isDenied = false;
      state.errToast = false;
      state.isSuspend = false;
      state.isForgotPassword = false;
      state.isVerifyOtp = false;
      state.isInvalidOtp = false;
      state.isErrGeneral = false;
      state.isNewPassword = false;
      state.newPasswordSuccess = false;
    },
    resetSuspend: (state) => {
      state.errToast = false;
      state.isSuspend = false;
    },
    resetToken: (state) => {
      state.token = "";
    },
    setIsForgotPassword: (state, action) => {
      state.isForgotPassword = action.payload;
    },
    resetAuthStore: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.errToast = false;
        state.isLoading = true;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.errCount = 0;
        state.suspendUntil = 0;
        state.isDenied = false;
        state.errToast = false;
        state.isLoading = false;
        state.isSuspend = false;
        state.token = action.payload.token;

        setSessionStorage(KeyConstant.Token, action.payload.token);
        setTimeout(() => {
        });
        history.navigate(PATH.HOME);
      })
      .addCase(loginAsync.rejected, (state, action) => {
        const { attempts, time_suspended } = action.payload;
        state.isLoading = false;
        if (attempts) {
        } else {
          state.attempts += 1;
        }
      });

    builder.addCase(getIpAddressAsync.fulfilled, (state, action) => {
      const { ipAddress, ip } = action.payload;
      const locations = `${action.payload.city}, ${action.payload.country_code}`;

      const ipIsNull = ipAddress === null || ipAddress === undefined || ipAddress === "";
      const userIpAddress = ipIsNull ? ip : ipAddress;

      state.location = locations;
      state.ipAddress = userIpAddress;
      state.errToast = false;

      setSessionStorage(KeyConstant.Ip, userIpAddress);
      setSessionStorage(KeyConstant.Location, locations);
    });

    builder
      .addCase(sendOTPAsync.pending, (state) => {
        state.isForgotPassword = true;
        state.isVerifyOtp = false;
      })
      .addCase(sendOTPAsync.fulfilled, (state) => {
        state.isForgotPassword = false;
        state.isVerifyOtp = true;
      })
      .addCase(sendOTPAsync.rejected, (state) => {
        state.isForgotPassword = true;
        state.isVerifyOtp = true;
      });

    builder
      .addCase(verifyOTPAsync.pending, (state) => {
        state.isForgotPassword = false;
        state.isVerifyOtp = true;
        state.isNewPassword = false;
      })
      .addCase(verifyOTPAsync.fulfilled, (state) => {
        state.isForgotPassword = false;
        state.isVerifyOtp = false;
        state.isNewPassword = true;
      })
      .addCase(verifyOTPAsync.rejected, (state, action) => {
        state.isForgotPassword = false;
        state.isVerifyOtp = true;
        state.isNewPassword = false;

        const { status } = action.payload;
        if (status >= 500) {
          state.isErrGeneral = true;
          state.isInvalidOtp = false;
        } else {
          state.isErrGeneral = false;
          state.isInvalidOtp = true;
        }
      });

    builder
      .addCase(setNewPasswordAsync.pending, (state) => {
        state.isNewPassword = true;
        state.newPasswordSuccess = false;
      })
      .addCase(setNewPasswordAsync.fulfilled, (state) => {
        state.isNewPassword = false;
        state.newPasswordSuccess = true;
      })
      .addCase(setNewPasswordAsync.rejected, (state) => {
        state.isNewPassword = true;
        state.newPasswordSuccess = false;
      });
  },
});

export const {
  resetAuthStore,
  closeErrToast,
  resetSuspend,
  resetDisable,
  resetToken,
  setIsForgotPassword,
} = authSlice.actions;
export default authSlice.reducer;
