import React, { useEffect, useRef, useState } from 'react'
import { Shimmer } from '~/components'
import { useCameraDetail } from './hooks'
import { event } from '~/constant'
import { socket } from '~/utils/socket'

const CameraAnalyticDetail = () => {
  const {
    datas: { countPeople },
  } = useCameraDetail()

  return (
    <div className='w-full mx-auto pt-10'>
      <div className='flex flex-col md:flex-row gap-10 mt-8 md:h-[600px] mx-auto md:w-2/3 mb-24'>
        <div className='h-[405px]'>
          <ImageReceiver />
        </div>
        <div className='flex flex-col'>
          <div className='justify-start items-center gap-1 inline-flex mt-2'>
            <p className='text-slate-300'>Head count:</p>
            <div className='text-slate-300  font-semibold'>{countPeople.count} person(s)</div>
          </div>
          <div className='justify-start items-center gap-1 inline-flex'>
            <p className='text-slate-300'>Estimation:</p>
            <p className='text-slate-300  font-semibold'>{countPeople.estimate} person(s)</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ImageReceiver = () => {
  const canvasRef = useRef(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    // Listen for 'image' event and handle received frames

    socket.on(event.IMAGERTSP, (data) => {
      // Create an Image object and set its source to the received data
      const img = new Image()
      img.src = 'data:image/jpeg;base64,' + data

      // Once the image is loaded, render it on the canvas
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img, 0, 0, 276 /* width, make it same as the modal width*/, 150)
        setIsLoaded(true)
      }
    })

    return () => {
      setIsLoaded(false)
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    }
  }, [])

  return (
    <>
      <canvas ref={canvasRef} className={`${isLoaded ? 'h-[405px]' : 'h-0'}`} />
      <Shimmer height={isLoaded ? 0 : 405} />
    </>
  )
}

export default CameraAnalyticDetail
