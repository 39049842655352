import React from 'react'
import ContentLoader from 'react-content-loader'

const Shimmer = ({ rounded, height = 0 }) => {
  return (
    <div className='w-full'>
      <ContentLoader
        speed={1}
        width={'100%'}
        height={height.toString()}
        backgroundColor='#2E2E2E'
        foregroundColor='#3F3F3F'>
        <rect
          x='0'
          y='0'
          rx={rounded ? '50%' : '6'}
          ry={rounded ? '50%' : '6'}
          width='100%'
          height={height.toString()}
        />
      </ContentLoader>
    </div>
  )
}

export default Shimmer
