import React, { useRef } from 'react'

import { IconX } from '~/svg'
import { Button, Modal, VideoPlayer } from '~/components'

const Preview = ({
  isOpen,
  closeModal,
  data,
  counter,
  isSubscibed,
  previewData,
  uploadedFiles,
}) => {
  const mediaPath = process.env.MEDIA_PATH

  const videoPlayerRef = useRef()

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className='hidden md:block'>
        <div className='overflow-hidden border-[1px] border-neutral-200 rounded-2xl pb-4 w-[844px]'>
          <div className='flex w-full justify-between items-center py-[10px] px-6 mb-2'>
            <p className='ml-2 text-xl font-semibold text-neutral-50'>Footage Preview</p>
            <div onClick={closeModal} className='cursor-pointer'>
              <IconX />
            </div>
          </div>

          <div className='w-full'>
            <VideoPlayer
              playerRef={videoPlayerRef}
              accuracy={data.probabilities * 100}
              url={`${mediaPath}/video/media/${data.source}`}
              start={data.timestamp}
            />
          </div>

          <div className='w-full grid grid-cols-2 px-8 mt-5 gap-5'>
            <div className='grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex'>
              <div className='flex-col justify-center items-start gap-1 flex'>
                {!isSubscibed ? (
                  <div className='px-8 justify-start items-start gap-10 inline-flex'>
                    <div className='grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex'>
                      <div className='flex-col justify-center items-start gap-1 flex'>
                        <div className='justify-start items-center gap-1 inline-flex'>
                          <div className="text-slate-300 text-base font-normal font-['Inter'] leading-normal">
                            Tip: people count/estimation is supported (not subscribed yet).
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='justify-start items-center gap-1 inline-flex'>
                      <div className="text-slate-300 text-base font-normal font-['Inter'] leading-normal">
                        Head count:
                      </div>
                      <div className="text-slate-300 text-base font-semibold font-['Inter'] leading-normal">
                        {counter.headCount} person(s)
                      </div>
                    </div>
                    <div className='justify-start items-center gap-1 inline-flex'>
                      <div className="text-slate-300 text-base font-normal font-['Inter'] leading-normal">
                        Estimation:
                      </div>
                      <div className="text-slate-300 text-base font-semibold font-['Inter'] leading-normal">
                        {counter.estimation} person(s)
                      </div>
                    </div>
                    <div className='flex flex-row'>
                      {uploadedFiles.map((up, pidx) => (
                        <div className='flex flex-col text-neutral-400 w-64 pt-10' key={pidx}>
                          <p className='font-semibold '>Face {pidx + 1}</p>
                          <div className='flex flex-row gap-4'>
                            <div className='flex flex-col  gap-2'>
                              <p className='text-sm leading-tight'>Uploaded</p>
                              <img className='w-16 h-16' src={up[0].preview} alt='uploaded-image' />
                            </div>
                            <div className='flex flex-col  gap-2'>
                              <p className='text-sm leading-tight'>In Videos</p>
                              <div className='grid grid-cols-2 gap-1'>
                                {Object.keys(previewData).map((key, didx) => (
                                  <>
                                    {didx === pidx &&
                                      previewData[key].detections.map((urlPreview) => (
                                        <img
                                          className='w-16 h-16'
                                          key={didx}
                                          src={urlPreview}
                                          alt='face-preview'
                                        />
                                      ))}
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='flex w-full justify-end px-8 py-[14px] mt-5'>
            <Button small type={'primary'} onClick={closeModal}>
              <p>Close</p>
            </Button>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className='block md:hidden'>
        <div className='flex flex-col items-center pt-2 pb-5 '>
          <div className='w-11 h-1 rounded bg-neutral-800 cursor-pointer' />
          <p className='mt-6 text-neutral-50 font-semibold text-sm'>Footage Preview</p>
          <div className='w-full h-[1px] bg-neutral-800 mt-5' />
        </div>
        <div className='px-5 py-6'>
          <VideoPlayer
            playerRef={videoPlayerRef}
            isMobile
            accuracy={data.probabilities * 100}
            url={`${mediaPath}/video/media/${data.source}`}
            start={data.timestamp}
          />
          <div className='w-full grid grid-cols-1 mt-5 gap-5'>
            <div className='w-full px-8 justify-start items-start gap-10 inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex'>
                <div className='flex-col justify-center items-start gap-1 flex  w-full'>
                  {!isSubscibed ? (
                    <div className='px-8 justify-start items-start gap-10 inline-flex'>
                      <div className='grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex'>
                        <div className='flex-col justify-center items-start gap-1 flex'>
                          <div className='justify-start items-center gap-1 inline-flex'>
                            <div className="text-slate-300 text-base font-normal font-['Inter'] leading-normal">
                              Tip: people count/estimation is supported (not subscribed yet).
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className='justify-start items-center gap-1 inline-flex'>
                        <div className="text-slate-300 text-base font-normal font-['Inter'] leading-normal">
                          Head count:
                        </div>
                        <div className="text-slate-300 text-base font-semibold font-['Inter'] leading-normal">
                          {counter.headCount} person(s)
                        </div>
                      </div>
                      <div className='justify-start items-center gap-1 inline-flex'>
                        <div className="text-slate-300 text-base font-normal font-['Inter'] leading-normal">
                          Estimation:
                        </div>
                        <div className="text-slate-300 text-base font-semibold font-['Inter'] leading-normal">
                          {counter.estimation} person(s)
                        </div>
                      </div>
                      <div className='flex flex-col gap-4 pt-10'>
                        {uploadedFiles.map((up, pidx) => (
                          <div className='flex flex-col text-neutral-400 pt-10' key={pidx}>
                            <p className='font-semibold '>Face {pidx + 1}</p>
                            <div className='flex flex-row gap-4'>
                              <div className='flex flex-col  gap-2'>
                                <p className='text-sm leading-tight'>Uploaded</p>
                                <img
                                  className='w-16 h-16'
                                  src={up[0].preview}
                                  alt='uploaded-image'
                                />
                              </div>
                              <div className='flex flex-col gap-2'>
                                <p className='text-sm leading-tight'>In Videos</p>
                                <div className='grid grid-cols-2 gap-1'>
                                  {Object.keys(previewData).map((key, didx) => (
                                    <>
                                      {didx === pidx &&
                                        previewData[key].detections.map((urlPreview) => (
                                          <img
                                            className='w-16 h-16'
                                            key={didx}
                                            src={urlPreview}
                                            alt='face-preview'
                                          />
                                        ))}
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full h-[1px] bg-[#1E1E1E] mt-2' />
        <div className='w-full p-4'>
          <Button type={'primary'} onClick={closeModal}>
            <p>Close</p>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default Preview
