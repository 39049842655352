import React from 'react'

function CheckCircle({fill}) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16' className={`${fill ? `fill-${fill}` : ''}`}>
      <path
        fill='#5EF550'
        fillRule='evenodd'
        d='M8 14.4A6.4 6.4 0 108 1.6a6.4 6.4 0 000 12.8zm2.966-7.434a.8.8 0 00-1.132-1.132L7.2 8.47 6.166 7.434a.8.8 0 10-1.132 1.132l1.6 1.6a.8.8 0 001.132 0l3.2-3.2z'
        clipRule='evenodd'></path>
    </svg>
  )
}

export default CheckCircle
