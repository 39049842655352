import React from 'react'

function PaperAirplane() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none' viewBox='0 0 21 20'>
      <path
        stroke='#DEEAF5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M10.5 15.833L18 17.5l-7.5-15-7.5 15 7.5-1.667zm0 0V9.167'></path>
    </svg>
  )
}

export default PaperAirplane
