import React from 'react'

function FullScreen() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <g clipPath='url(#clip0_705_6363)'>
        <path
          fill='#fff'
          d='M7 14.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-2zm-2-5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v4zm12 7a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v2zM14.5 5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-4z'></path>
      </g>
      <defs>
        <clipPath id='clip0_705_6363'>
          <path fill='#fff' d='M0 0H24V24H0z'></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default FullScreen
