import React, { useState } from "react";
import { Checkbox, CheckboxOutline } from "~/svg";

function CheckboxLabel({ label, isSelected = false, onClick }) {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      <div className="inline-flex items-center space-x-2">
        <input type="checkbox" checked={isSelected} className="hidden" />
        {isSelected ? <Checkbox /> : <CheckboxOutline />}
        <span className="text-natural-200">{label}</span>
      </div>
    </div>
  );
}

export default CheckboxLabel;
