import React from 'react'

import { CevronDown, IconX } from '~/svg'
import { Button, Input, Modal } from '~/components'
import { ALERTNOTIFICATION, ALERTTYPE } from '~/constant'

const PriorityModal = ({
  loopRef,
  colorRef,
  chooseLoop,
  loopOptions,
  chooseColor,
  formPriority,
  colorOptions,
  priorityModal,
  setDropdownLoop,
  setPriorityModal,
  IconNotification,
  setDropdownColor,
  addNotifications,
  dropdownLoopState,
  dropdownColorState,
  handleChangePriority,
  handleSubmitPriority,
}) => {
  function ColortoText(colorHex) {
    return colorOptions.find((c) => c.colorHex === colorHex).colorText
  }
  return (
    <Modal isOpen={priorityModal} closeModal={() => setPriorityModal(false)} notOverflow>
      <div className='flex flex-col py-2 px-8 w-[600px]'>
        <div className='flex flex-row w-full justify-between max-md:border-b max-md:border-neutral-800 mt-3'>
          <p className='w-full text-neutral-50 font-semibold text-sm md:text-xl text-center md:text-left '>
            New Priority Level
          </p>
          <div
            data-testid='iconx'
            className='flex max-md:hidden cursor-pointer items-center'
            onClick={() => setPriorityModal(false)}>
            <IconX />
          </div>
        </div>
        <div className='flex flex-row gap-5 mt-5'>
          <div className='flex flex-col gap-1 w-full'>
            <p className='font-semibold text-neutral-200'>Priority name</p>
            <Input
              name={'name'}
              type={'text'}
              value={formPriority.name}
              onChange={handleChangePriority}
            />
          </div>
          <div className='flex flex-col gap-1 w-full' ref={colorRef}>
            <p className='font-semibold text-neutral-200'>Color</p>
            <div className='relative inline-block text-left'>
              <div>
                <button
                  data-testid='color-dropdown'
                  type='button'
                  className='inline-flex w-full justify-between rounded-md px-4 py-3 text-sm font-semibold text-neutral-400 border border-neutral-600 items-center'
                  id='menu-button'
                  name='color'
                  aria-expanded='true'
                  aria-haspopup='true'
                  onClick={() => setDropdownColor(!dropdownColorState)}>
                  <div
                    className='rounded-full h-5 w-5'
                    style={{
                      backgroundColor: formPriority.color == '' ? 'black' : formPriority.color,
                    }}
                  />
                  <p className='text-base'>
                    {' '}
                    {formPriority.color == '' ? 'Choose a color' : ColortoText(formPriority.color)}
                  </p>
                  <CevronDown customSize={'w-4 h-4'} />
                </button>
              </div>
              {dropdownColorState ? (
                <div
                  className='absolute right-0 z-50 mt-2 w-full origin-top-right rounded-md bg-neutral-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                  role='menu'
                  aria-orientation='vertical'
                  aria-labelledby='menu-button'
                  tabIndex='-1'>
                  <div className='py-1 rounded-md' role='none'>
                    {colorOptions.map((c, ckey) => {
                      return (
                        <div
                          data-testid='color-options'
                          key={ckey}
                          className='flex flex-row gap-3 items-center py-2 cursor-pointer'
                          onClick={() => chooseColor(c.colorHex)}>
                          <div
                            className='h-5 w-5 rounded-full cursor-pointer'
                            style={{ backgroundColor: c.colorHex }}></div>
                          <p className='text-neutral-200'>{c.colorText}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className='w-full flex flex-row justify-between mt-5 py-3'>
          <p className='font-semibold text-neutral-200 text-lg'>Description</p>
          <p className='font-semibold text-neutral-400 text-lg'>
            {formPriority.description.length} / 200
          </p>
        </div>
        <textarea
          maxLength={200}
          rows={3}
          value={formPriority.description}
          name={'description'}
          onChange={handleChangePriority}
          className='w-full border border-neutral-600 text-neutral-400 px-4 py-3 bg-neutral-900 rounded-lg resize-none'
        />
        <p className='font-semibold text-neutral-200 text-lg mt-6'>Notification</p>
        <div className='flex flex-row gap-2'>
          {Object.keys(ALERTNOTIFICATION).map((app) => {
            return (
              <div
                data-testid='notification-button'
                className={`flex flex-row px-3 py-1 w-fit border border-neutral-600 rounded-lg cursor-pointer gap-2 items-center ${
                  formPriority.apps_notification.includes(ALERTNOTIFICATION[app])
                    ? 'bg-primary-900 border-primary-600'
                    : 'bg-neutral-900 border-neutral-600'
                }`}
                key={app}
                onClick={() => addNotifications(ALERTNOTIFICATION[app], ALERTTYPE.PRIORITY)}>
                <IconNotification n={ALERTNOTIFICATION[app]} />
                <p className='text-neutral-200 font-medium text-sm'>{ALERTNOTIFICATION[app]}</p>
              </div>
            )
          })}
        </div>
        <div className='flex flex-row justify-between items-center mt-6 gap-4'>
          <p className='font-semibold text-neutral-200 text-base w-full'>
            Loop until status change
          </p>
          <div className='flex flex-col gap-1 w-full'>
            <div className='relative inline-block text-left' ref={loopRef}>
              <div>
                <button
                  type='button'
                  className='inline-flex w-full justify-between gap-x-1.5 rounded-md px-3 py-4 text-sm font-semibold text-neutral-400 shadow-sm ring-1 ring-neutral-600 items-center'
                  id='menu-button'
                  aria-expanded='true'
                  aria-haspopup='true'
                  onClick={() => setDropdownLoop(!dropdownLoopState)}>
                  {formPriority.loop == 0 ? 'No looping' : `${formPriority.loop} mins`}
                  <CevronDown customSize={'w-2'} />
                </button>
              </div>
              {dropdownLoopState ? (
                <div
                  className='absolute right-0 z-50 mt-2 w-full origin-top-right rounded-md bg-neutral-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                  role='menu'
                  aria-orientation='vertical'
                  aria-labelledby='menu-button'
                  tabIndex='-1'>
                  <div className='py-1 rounded-md' role='none'>
                    <div
                      className='flex flex-row gap-3 py-3 items-center border-b border-neutral-800 px-4 cursor-pointer'
                      onClick={() => chooseLoop(0, ALERTTYPE.PRIORITY)}>
                      <p className='text-neutral-200'>No looping</p>
                    </div>
                    {loopOptions.map((l, lkey) => {
                      return (
                        <div
                          data-testid='loop-button'
                          key={lkey}
                          className='flex flex-row gap-3 items-center py-3 px-4 cursor-pointer'
                          onClick={() => chooseLoop(l, ALERTTYPE.PRIORITY)}>
                          <p className='text-neutral-200 h-fit text-sm'>{l} mins</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className='mt-8 flex md:flex-row flex-col md:justify-end gap-2'>
          <div className='w-full md:w-fit'>
            <Button
              data-testid='button-cancel'
              type={'secondary'}
              onClick={() => setPriorityModal(false)}>
              Cancel
            </Button>
          </div>
          <div className='w-full md:w-fit'>
            <Button data-testid='button-set' type={'primary'} onClick={handleSubmitPriority}>
              Set Priority Level
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PriorityModal
