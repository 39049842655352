import React from 'react'

const Button = ({ type, children, disable, submit, onClick, small }) => {
  return (
    <>
      <button
        type={submit ? 'submit' : 'button'}
        disabled={disable}
        onClick={onClick}
        className={`font-semibold first-letter rounded-lg flex justify-center items-center gap-2 ${
          type === 'primary'
            ? disable
              ? 'bg-neutral-700 text-neutral-400 cursor-default'
              : 'bg-primary-600 hover:bg-primary-700 text-white'
            : type === 'secondary'
            ? disable
              ? 'bg-neutral-100 text-neutral-400 cursor-default'
              : 'bg-neutral-100 hover:bg-neutral-200 text-neutral-900'
            : type === 'teritary'
            ? disable
              ? 'bg-[#1E1E1E] border-[1px] border-neutral-700 text-neutral-700 cursor-default'
              : 'bg-neutral-800 hover:bg-[#1E1E1E] border-[1px] border-neutral-700 text-neutral-200 hover:text-neutral-500'
            : type === 'danger'
            ? disable
              ? 'bg-danger-500 text-neutral-400 cursor-default'
              : 'bg-danger-800 hover:bg-danger-700 text-white'
            : type === 'transparent'
            ? disable
              ? 'text-neutral-200 cursor-default'
              : 'hover:bg-[#1E1E1E] text-neutral-200 hover:text-neutral-500'
            : ''
        } ${small ? 'px-3 py-2 text-xs w-full md:w-auto' : 'py-[10px] px-4 text-base w-full'}`}>
        {children}
      </button>
    </>
  )
}

export default Button
