import React from 'react'

import { IconX, WrongOutline } from '~/svg'
import { Button, Input, Modal } from '~/components'
import { ALERTMODALTYPE, ALERTTYPE } from '~/constant'

const PasswordModal = ({
  handleKeyUp,
  formPassword,
  passwordModal,
  submitPassword,
  handleChangePass,
  closePasswordModal,
}) => {
  return (
    <Modal isOpen={passwordModal.isOpen} closeModal={closePasswordModal}>
      <div className='flex flex-col py-2 px-8 w-[600px]'>
        <div className='flex flex-row w-full justify-between max-md:border-b max-md:border-neutral-800 mt-3'>
          <p className='w-full text-neutral-50 font-semibold text-sm md:text-xl text-center md:text-left my-5'>
            {passwordModal.modalType} {passwordModal.alertName}
          </p>
          <div className='flex max-md:hidden cursor-pointer' onClick={closePasswordModal}>
            <IconX />
          </div>
        </div>
        <p data-testid='desc' className='text-neutral-200 text-sm my-4 md:my-2'>
          {passwordModal.modalType == ALERTMODALTYPE.EDIT ? (
            'To confirm your identity, please enter your password.'
          ) : (
            <span>
              Are you sure to delete {passwordModal.alertName}?{' '}
              {passwordModal.alertType === ALERTTYPE.PRIORITY
                ? `This will further delete
              ${passwordModal.data.incidents.length} alerts in this group.`
                : ''}
              <span className='font-bold'>This action is not reversible. </span>
              <br /> To confirm the deletion, please enter your password.
            </span>
          )}
        </p>
        <Input
          name={'password'}
          type={'password'}
          onKeyUp={handleKeyUp}
          onChange={handleChangePass}
          value={formPassword.password}
          error={formPassword.errPassword}
        />
        {formPassword.errPassword ? (
          <div data-testid='error' className='flex flex-row gap-1 items-center mt-1'>
            <WrongOutline />
            <p className='text-danger-100'>Wrong password, please try again</p>
          </div>
        ) : (
          ''
        )}
        <div className='mt-11 md:mt-4 flex md:flex-row flex-col justify-end w-full gap-2'>
          <div data-testid='close-modal' className='w-full md:w-fit'>
            <Button type={'secondary'} onClick={closePasswordModal}>
              Cancel
            </Button>
          </div>
          {passwordModal.modalType == ALERTMODALTYPE.EDIT ? (
            <div className='w-full md:w-fit'>
              <Button data-testid='edit' type={'primary'} onClick={submitPassword}>
                Edit {passwordModal.alertType} Level
              </Button>
            </div>
          ) : (
            <div className='w-full md:w-fit'>
              <Button data-testid='delete' type={'danger'} onClick={submitPassword}>
                Delete {passwordModal.alertType} Level
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default PasswordModal
