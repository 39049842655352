import { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { PATH } from '~/constant'
import { endSession, postEditProfile, postVerify } from '~/store/slice/account'

export function useAccSetting() {
  const dispatch = useDispatch()

  const isVerified = useSelector((state) => state.account.isVerified)
  const dataProfile = useSelector((state) => state.account.dataProfile, shallowEqual)
  const editLoading = useSelector((state) => state.account.editLoading)
  const userSessions = useSelector((state) => state.account.dataSession, shallowEqual)
  const dataVerified = useSelector((state) => state.account.dataVerified, shallowEqual)
  const verifyLoading = useSelector((state) => state.account.verifyLoading)
  const ipAddressUser = useSelector((state) => state.auth.ipAddress)
  const [formPassword, setFormPass] = useState({
    password: '',
    errPassword: false,
  })

  const [editModal, setEditModal] = useState(false)
  const [actionVerify, setActionVerify] = useState('')
  const [formEdit, setFormEdit] = useState(dataProfile)
  const [passwordModal, setPassModal] = useState(false)
  const [hideState, setHide] = useState({
    email: true,
    phone: true,
  })

  const [logoutModal, setLogoutModal] = useState(false)
  const [logoutBottomSheet, setLogoutBottomSheet] = useState(false)

  function handleChangeEdit(e) {
    const { name, value } = e.target

    setFormEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    const data = {
      photo: dataProfile.photo,
      email: dataProfile.email,
      fullname: dataProfile.fullname,
      censor_email: dataProfile.email,
      country_code: dataProfile.country_code,
      phone_number: dataProfile.phone_number,
      censor_phone: dataProfile.phone_number,
    }
    setFormEdit(data)
  }, [dataProfile])

  useEffect(() => {
    if (!dataVerified) {
      return
    }
    setFormEdit((prevState) => ({
      ...prevState,
      email: dataVerified.email,
      phone_number: dataVerified.phone,
    }))
  }, [dataVerified])

  useEffect(() => {
    if (!verifyLoading && isVerified) {
      accountAction(actionVerify)
      setPassModal(false)
    } else if (!verifyLoading && !isVerified && formPassword.password.length) {
      setFormPass((prevState) => ({
        ...prevState,
        errPassword: true,
      }))
    }
  }, [verifyLoading])

  function handleChangePass(e) {
    const { value } = e.target

    cleanError()

    setFormPass((prevState) => ({
      ...prevState,
      password: value,
    }))
  }

  function submitEndSession(session_id) {
    const req = {
      session_id,
    }
    dispatch(endSession(req))
  }

  function submitPassword() {
    const req = {
      password: formPassword.password,
    }

    dispatch(postVerify(req))
    setPassModal(false)
  }

  function handleKeyUp(event) {
    if (event.key === 'Enter') {
      submitPassword()
    }
  }

  function handleVerification(e) {
    if (!isVerified) {
      setActionVerify(e.currentTarget.id)
      setPassModal(true)
      return
    }

    accountAction(e.currentTarget.id)
  }

  function accountAction(menu) {
    switch (menu) {
      case 'edit':
        setEditModal(true)
        break
      case 'email':
        setHide(() => ({
          ...hideState,
          email: !hideState.email,
        }))
        break
      case 'phone':
        setHide(() => ({
          ...hideState,
          phone: !hideState.phone,
        }))
        break
      default:
        break
    }
  }

  function cleanError() {
    setFormPass((prevState) => ({
      ...prevState,
      errPassword: false,
    }))
  }

  function submitEditUser() {
    dispatch(postEditProfile(formEdit))
    setEditModal(false)
  }

  function submitLogout(session_id) {
    dispatch(endSession({ session_id }))
    setLogoutModal(false)
    setLogoutBottomSheet(false)
    window.location.assign(PATH.LOGIN)
  }

  function handleLogout() {
    if (window.innerWidth > 768) {
      setLogoutModal(true)
      return
    }
    setLogoutBottomSheet(true)

  }

  const tableHeader = ['IP Address', 'Device', 'Location', 'Last Active', '']
  const tableHeadMobile = ['Session', 'Location', '']

  return {
    datas: {
      formEdit,
      editModal,
      hideState,
      isVerified,
      tableHeader,
      dataProfile,
      editLoading,
      userSessions,
      formPassword,
      dataVerified,
      actionVerify,
      ipAddressUser,
      passwordModal,
      tableHeadMobile,
      logoutModal,
      logoutBottomSheet,
    },
    methods: {
      handleKeyUp,
      setFormEdit,
      setPassModal,
      setEditModal,
      submitPassword,
      handleChangePass,
      handleChangeEdit,
      submitEndSession,
      handleVerification,
      submitEditUser,
      submitLogout,
      handleLogout,
      setLogoutModal,
      setLogoutBottomSheet,
    },
  }
}
