import React from "react";
import { useRef, useContext } from 'react'
import { useDay } from '@datepicker-react/hooks'

import DatepickerContext from '../../context'

function getColor(isSelected, isSelectedStartOrEnd, isWithinHoverRange, isDisabled) {
  return ({
    normalColor,
    selectedColor,
    disabledColor,
    rangeHoverColor,
    selectedFirstOrLastColor,
  }) => {
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor
    } else if (isSelected) {
      return selectedColor
    } else if (isWithinHoverRange) {
      return rangeHoverColor
    } else if (isDisabled) {
      return disabledColor
    } else {
      return normalColor
    }
  }
}

function Day({ dayLabel, date, isStartDate, isEndDate }) {
  const dayRef = useRef(null)
  const {
    focusedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
    isDateFocused,
    isDateHovered,
    isDateBlocked,
    isDateSelected,
    isFirstOrLastSelectedDate,
  } = useContext(DatepickerContext)
  const {
    onClick,
    tabIndex,
    onKeyDown,
    isSelected,
    disabledDate,
    onMouseEnter,
    isWithinHoverRange,
    isSelectedStartOrEnd,
  } = useDay({
    date,
    dayRef,
    focusedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
    isDateFocused,
    isDateHovered,
    isDateBlocked,
    isDateSelected,
    isFirstOrLastSelectedDate,
  })

  if (!dayLabel) {
    return <div />
  }

  // function to get Status Color and Background
  const getColorFn = getColor(isSelected, isSelectedStartOrEnd, isWithinHoverRange, disabledDate)

  return (
    <div ref={dayRef} className='w-full relative'>
      <button
        type='button'
        onClick={onClick}
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        className='text-sm font-medium w-full'
        style={{
          border: 0,
          padding: '6px',
          color: getColorFn({
            normalColor: '#DEEAF5',
            selectedColor: '#ABDAFE',
            disabledColor: '#DEEAF5',
            rangeHoverColor: '#ABDAFE',
            selectedFirstOrLastColor: '##EBF6FF',
          }),
          background: getColorFn({
            normalColor: '#141618',
            selectedColor: '#105F9CA3',
            disabledColor: '#141618',
            rangeHoverColor: '#105F9CA3',
            selectedFirstOrLastColor: '#141618',
          }),
        }}>
        {dayLabel < 10 ? dayLabel.charAt(1) : dayLabel}
      </button>

      {/* Start Date and End Date */}
      {isStartDate(date) || isEndDate(date) ? (
        <div
          className={`absolute top-0 left-0 w-full h-full ${
            isEndDate(date) ? 'bg-gradient-to-r' : 'bg-gradient-to-l'
          } m-0 p-0 from-[#105F9CA3] from-0% via-[#141618] via-80% to-[#141618] to-100%`}>
          <button
            type='button'
            onClick={onClick}
            tabIndex={tabIndex}
            onKeyDown={onKeyDown}
            onMouseEnter={onMouseEnter}
            className='text-sm font-medium w-full'
            style={{
              padding: '5px',
              color: '#ABDAFE',
              borderRadius: '50%',
              background: '#1E87D7',
              border: '1px solid #76C2FC',
            }}>
            {dayLabel < 10 ? dayLabel.charAt(1) : dayLabel}
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Day
