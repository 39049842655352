import React from 'react'

function OutlineDots() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.00002 8.00016C4.00002 8.36835 3.70154 8.66683 3.33335 8.66683C2.96516 8.66683 2.66669 8.36835 2.66669 8.00016C2.66669 7.63197 2.96516 7.3335 3.33335 7.3335C3.70154 7.3335 4.00002 7.63197 4.00002 8.00016Z'
        fill='#DEEAF5'
      />
      <path
        d='M8.66669 8.00016C8.66669 8.36835 8.36821 8.66683 8.00002 8.66683C7.63183 8.66683 7.33335 8.36835 7.33335 8.00016C7.33335 7.63197 7.63183 7.3335 8.00002 7.3335C8.36821 7.3335 8.66669 7.63197 8.66669 8.00016Z'
        fill='#DEEAF5'
      />
      <path
        d='M13.3334 8.00016C13.3334 8.36835 13.0349 8.66683 12.6667 8.66683C12.2985 8.66683 12 8.36835 12 8.00016C12 7.63197 12.2985 7.3335 12.6667 7.3335C13.0349 7.3335 13.3334 7.63197 13.3334 8.00016Z'
        fill='#DEEAF5'
      />
      <path
        d='M3.33335 8.00016H3.34002M8.00002 8.00016H8.00669M12.6667 8.00016H12.6734M4.00002 8.00016C4.00002 8.36835 3.70154 8.66683 3.33335 8.66683C2.96516 8.66683 2.66669 8.36835 2.66669 8.00016C2.66669 7.63197 2.96516 7.3335 3.33335 7.3335C3.70154 7.3335 4.00002 7.63197 4.00002 8.00016ZM8.66669 8.00016C8.66669 8.36835 8.36821 8.66683 8.00002 8.66683C7.63183 8.66683 7.33335 8.36835 7.33335 8.00016C7.33335 7.63197 7.63183 7.3335 8.00002 7.3335C8.36821 7.3335 8.66669 7.63197 8.66669 8.00016ZM13.3334 8.00016C13.3334 8.36835 13.0349 8.66683 12.6667 8.66683C12.2985 8.66683 12 8.36835 12 8.00016C12 7.63197 12.2985 7.3335 12.6667 7.3335C13.0349 7.3335 13.3334 7.63197 13.3334 8.00016Z'
        stroke='#DEEAF5'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default OutlineDots
