import React from 'react'

function Settings() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
      <path
        stroke='#EBF6FF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M8.604 3.598c.355-1.464 2.437-1.464 2.792 0a1.437 1.437 0 002.144.888c1.286-.784 2.758.688 1.974 1.974a1.437 1.437 0 00.888 2.144c1.464.355 1.464 2.437 0 2.792a1.437 1.437 0 00-.888 2.144c.784 1.286-.688 2.758-1.974 1.974a1.437 1.437 0 00-2.144.888c-.355 1.464-2.437 1.464-2.792 0a1.437 1.437 0 00-2.144-.888c-1.286.784-2.758-.688-1.974-1.974a1.437 1.437 0 00-.888-2.144c-1.464-.355-1.464-2.437 0-2.792a1.437 1.437 0 00.888-2.144c-.784-1.286.688-2.758 1.974-1.974.831.506 1.914.057 2.144-.888z'></path>
      <path
        stroke='#EBF6FF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M12.5 10a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z'></path>
    </svg>
  )
}

export default Settings
