import React from 'react'

import { AnonyousAlertLoading, DesktopView, MobileView } from './components'

const AnonymousAlert = (props) => {
  return (
    <>
      {props.isLoading ? (
        <AnonyousAlertLoading />
      ) : (
        <>
          <div className='hidden md:block'>
            <DesktopView {...props} />
          </div>
          <div className='block md:hidden'>
            <MobileView {...props} />
          </div>
        </>
      )}
    </>
  )
}

export default AnonymousAlert
