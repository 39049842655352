import React from 'react'

import { Button, VideoPlayer } from '~/components'

const DesktopView = ({ typeToStr, selectedAlert, toLogin, alertVideoRef }) => {
  return (
    <div className='mt-10 w-full'>
      <div className='w-full px-[10%]'>
        <p className='text-neutral-200 font-semibold text-xl md:text-2xl'>
          {typeToStr(selectedAlert.type)}
        </p>
        <div className='w-full flex mt-6 gap-6'>
          <div className='w-[640px]'>
            <VideoPlayer
              playerRef={alertVideoRef}
              url={
                'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4'
              }
              accuracy={80}
            />
          </div>
          <div className='flex-auto'>
            <p className='text-neutral-200'>Location</p>
            <p className='text-neutral-50 font-semibold text-[18px] mt-2'>L1 Lobby</p>
            <p className='text-neutral-200 mt-8'>Date, time</p>
            <div className='flex items-center gap-4'>
              <div>
                <p className='text-neutral-50 font-semibold text-[18px] mt-2'>19 Sep 2023</p>
                <p className='text-neutral-50 font-semibold text-[18px] mt-[2px]'>23:18</p>
              </div>
              <p className='text-sm text-neutral-400'>until</p>
              <div>
                <p className='text-neutral-50 font-semibold text-[18px]'>20 Sep 2023</p>
                <p className='text-neutral-50 font-semibold text-[18px] mt-[2px]'>01:43</p>
              </div>
            </div>
            <p className='text-sm text-neutral-400 mt-1'>(2 hours, 25 mins)</p>
            <div className='mt-8 text-xs text-neutral-400'>
              <p className=' mt-2'>Emergency numbers</p>
              <div className='flex gap-4 mt-2'>
                <p>{`Police: ${selectedAlert.emergencies.police}`}</p>
                <p>{`Ambulance: ${selectedAlert.emergencies.ambulance}`}</p>
                <p>{`Firefighter: ${selectedAlert.emergencies.firefighter}`}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <p className='text-neutral-200'>History log</p>
          <p className='text-neutral-200 mt-2'>
            <span
              onClick={toLogin}
              className='font-semibold underline cursor-pointer text-primary-200'>
              Log into your account
            </span>{' '}
            to view this section.
          </p>
        </div>
      </div>
      <div className='w-full mt-28 border-t-[1px] border-t-neutral-800 py-3 px-[52px] flex justify-end'>
        <div className='w-[151px]'>
          <Button onClick={toLogin} type={'primary'}>
            <p>Login to Report</p>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DesktopView
