import React from 'react'
import { useUploadedVideos } from './hooks'
import { Plus, Search } from '~/svg'
import { SearchBar, Shimmer } from '~/components'
import { UploadedVideoItems } from './components'

const UploadedVideos = () => {
  const {
    datas: { progressUploaded, searchUploaded, savedVideos, submitLoadingVideo, isLoadingVideo },
    methods: {
      open,
      getRootProps,
      getInputProps,
      handleSubmitSearchUpload,
      handleChangeUploadedSearch,
    },
  } = useUploadedVideos()

  return (
    <div className='flex flex-col mt-6 md:mt-10 md:max-w-7xl max-md:max-w-md mx-auto px-4 md:px-0'>
      <div className='flex flex-row justify-between'>
        <p className='text-neutral-50 font-semibold text-2xl'>Uploaded Videos</p>
        <div className='flex flex-row items-center gap-4'>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <button onClick={open} className='flex my-auto'>
              <Plus />
            </button>
          </div>
          <div className='group'>
            <div className='block group-hover:hidden px-5 py-2'>
              <Search />
            </div>
            <div className='hidden group-hover:block w-full'>
              <SearchBar
                handleChange={handleChangeUploadedSearch}
                submitSearch={handleSubmitSearchUpload}
                keyword={searchUploaded}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='hidden md:block mt-4' />

      <div className='block overflow-x-scroll no-scrollbar scroll-smooth mt-4'>
        <div className='px-4 w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6'>
          {submitLoadingVideo && progressUploaded > 0 ? (
            <div className='flex flex-col gap-2'>
              <div className='relative inline-flex'>
                <Shimmer height={155} />
                <div className='absolute inline-flex m-auto w-full h-full items-center'>
                  <p className='text-neutral-50 w-full font-bold text-center'>
                    {progressUploaded}%
                  </p>
                </div>
              </div>
              <Shimmer height={32} />
            </div>
          ) : (
            <></>
          )}
          {isLoadingVideo ? (
            <></>
          ) : (
            <>
              {savedVideos &&
                savedVideos.map((it, idx) => <UploadedVideoItems key={idx} item={it} />)}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadedVideos
