import React from 'react'

import { Shimmer } from '~/components'

const AlertShimmer = () => {
  const items = [1, 2, 3, 4]
  return (
    <div className='w-full flex flex-col md:flex-row h-full'>
      <div className='w-full md:w-[407px] h-full md:border-r-[2px] md:border-r-neutral-800 px-4 md:px-8 pt-6 md:pt-9'>
        <div className='flex w-full justify-between items-center'>
          <div className='w-20'>
            <Shimmer height={24} />
          </div>
          <div className='w-[105px]'>
            <Shimmer height={16} />
          </div>
        </div>
        <div className='mt-9 w-full'>
          <Shimmer height={24} />
        </div>
        <div className='mt-6 md:mt-[60px]'>
          <div className='w-20'>
            <Shimmer height={24} />
          </div>
        </div>
        {items &&
          items.map((it) => (
            <div key={it} className='w-[288px] mt-2'>
              <Shimmer height={32} />
            </div>
          ))}
        <div className='mt-6'>
          <div className='w-20'>
            <Shimmer height={24} />
          </div>
        </div>
        {items &&
          items.map((it) => (
            <div key={it} className='w-[288px] mt-2'>
              <Shimmer height={32} />
            </div>
          ))}
      </div>
      <div className='w-full hidden md:block'>
        <div className='w-full h-full flex flex-col justify-center items-center'>
          <div className='w-48'>
            <Shimmer height={192} />
          </div>
          <div className='my-4 w-20'>
            <Shimmer height={24} />
          </div>
          <div className='w-64'>
            <Shimmer height={24} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertShimmer
