import React from 'react'

function OutlineX() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
      <path
        stroke='#DEEAF5'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M6 18L18 6M6 6l12 12'></path>
    </svg>
  )
}

export default OutlineX
