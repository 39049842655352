import React from 'react'
import moment from 'moment'

import { Search } from '~/svg'
import { Button, Modal, PendingAlerts } from '~/components'

import UserItems from '../user-items'

const ReportBottomSheet = ({
  closeModal,
  selectUser,
  userSelect,
  timeToStatus,
  selectedAlert,
  reportBotomSheet,
}) => {
  return (
    <Modal isOpen={reportBotomSheet} closeModal={closeModal}>
      <div className='w-full flex flex-col items-center pt-2 pb-5'>
        <div className='w-11 h-1 rounded bg-neutral-800' />
        <p className='mt-6 text-neutral-50 font-semibold text-sm'>Report Incident</p>
        <div className='w-full h-[1px] bg-neutral-800 mt-5' />
        <div className='w-full px-5 mt-6'>
          {selectedAlert !== undefined ? (
            <PendingAlerts
              type={selectedAlert.type}
              date={moment(new Date(selectedAlert.start_time)).format('DD MMM YYYY, HH:mm')}
              status={timeToStatus(selectedAlert.start_time)}
              location={selectedAlert.location}
            />
          ) : (
            <></>
          )}
          <p className='text-neutral-200 mt-4'>Report it to:</p>
          <div className='w-full flex justify-center h-12 rounded-lg mt-2 overflow-hidden'>
            <div className='w-full h-fulll border-[1px] border-neutral-600 rounded-l-lg'>
              <input
                type='text'
                id='search'
                name='search'
                placeholder='Search recipient'
                className='h-full w-full text-base text-neutral-50 placeholder:text-neutral-400 bg-transparent outline-none border-none px-4 truncate'
              />
            </div>
            <div className='w-[60px] flex justify-center items-center cursor-pointer border-l-[1px] border-l-neutral-600 bg-primary-600'>
              <Search />
            </div>
          </div>
          <p className='text-neutral-200 mt-4'>Suggested:</p>
        </div>
        <div className='w-full px-5 overflow-x-scroll no-scrollbar scroll-smooth'>
          <div className='mt-2 flex gap-2'>
            {userSelect &&
              userSelect.map((it, idx) => <UserItems key={idx} it={it} selectUser={selectUser} />)}
          </div>
        </div>
        <div className='w-full h-[1px] bg-[#1E1E1E] mt-6' />
        <div className='w-full p-4'>
          <Button onClick={closeModal} type={'primary'}>
            <p>Report Incident</p>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ReportBottomSheet
