import React from 'react'

function Upload() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' fill='none' viewBox='0 0 25 24'>
      <path
        stroke='#39A2F2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M4.5 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0l-4 4m4-4v12'></path>
    </svg>
  )
}

export default Upload
