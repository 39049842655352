import React from 'react'

function CevronRight({ height }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={height ? height : '24'}
      height={height ? height : '24'}
      fill='none'
      viewBox='0 0 24 24'>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M9 5l7 7-7 7'></path>
    </svg>
  )
}

export default CevronRight
